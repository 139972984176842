import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  requestNotifications: null,
  requestNotificationsSuccess: ['data'],
  requestNotificationsFailure: ['error'],

  receiveNotifications: ['data'],
  receiveNotificationsSuccess: ['data'],
  receiveNotificationsFailure: ['error'],

  markAsReadAllNotifications: ['notificationIds'],
  markAsReadAllNotificationsSuccess: ['data'],
  markAsReadAllNotificationsFailure: ['error'],

  requestNotificationSettings: [],
  requestNotificationSettingsSuccess: ['data'],
  requestNotificationSettingsError: ['error'],

  changeNotificationSettings: ['isEmail', 'notificationType'],
  updateNotificationSettings: ['data'],
  saveNotificationSettings: [],
  saveNotificationSettingsSuccess: ['data'],
  saveNotificationSettingsError: ['error'],
  resetNotificationSettings: [],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
