/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Formik } from 'formik';
import { personalInfoValidationSchema } from 'services/validation';
import Field from 'components/shared/Field';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';
import parsePhoneNumber from 'services/regex/parsePhoneNumber';
import MobileContext from 'context/MobileContext';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import history from 'store/history';
import styles from './styles.module.css';
import PickTopic from '../PickTopic';
import UploadImage from '../UploadImage';
import { getDurations } from './service';

const Form = ({
  user,
  profile,
  selectedClub,
  sendMessage,
  topics,
  isFreezeSelected,
  resetPreselectedFreezeTopic,
  inputRef,
  uploaded,
  setUploaded,
}) => {
  const sfDate = getSFTime();
  const [pickTopic, setPickTopic] = useState({});
  const [duration, setDuration] = useState('');
  const isMobile = useContext(MobileContext);

  useEffect(() => {
    setDuration('');
  }, [pickTopic]);

  return (
    <Formik
      initialValues={{
        message:
          isFreezeSelected === 'personalTraining'
            ? 'I`m interested in taking an InBody test'
            : '',
        files: [],
      }}
      onSubmit={(values, { resetForm }) => {
        const formData = new FormData();
        Array.from(values.files).forEach((file, i) => {
          formData.append(`image${i}`, file);
        });
        const payload = {
          ...values,
          email: user?.email,
          phone: profile?.phone,
          fullName: user?.name,
          location: selectedClub.sfId,
          pickTopic: pickTopic?.label,
          userID: user?.idCA,
          clubAutomationClubId: selectedClub.idCA,
          duration,
          freezeDate: moment(sfDate)
            .add(30, 'd')
            .startOf('month')
            .format('YYYY-MM-DD'),
          freezeReason: isFreezeSelected
            ? history.location?.search?.replace('?freezeReason=', '')
            : '',
        };
        for (const [key, value] of Object.entries(payload)) {
          formData.append(key, value);
        }
        formData.delete('files');
        sendMessage(formData);
        inputRef.current.value = '';
        setUploaded(false);
        resetForm();
        resetPreselectedFreezeTopic();
      }}
      validationSchema={personalInfoValidationSchema}
    >
      {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => {
        return (
          <div className={styles.info}>
            <div className={styles.section}>
              <h1>Personal Info</h1>
              <div>
                <Field
                  value={user?.name}
                  placeholder="Full Name"
                  inputClassName={styles.input}
                  readOnly
                />
                <Field
                  value={user?.email}
                  placeholder="Email Address"
                  inputClassName={styles.input}
                  readOnly
                />
                {profile?.phone && (
                  <Field
                    value={parsePhoneNumber(profile)}
                    placeholder="Phone Number"
                    inputClassName={styles.input}
                    readOnly
                  />
                )}
                <PickTopic
                  value={pickTopic?.value}
                  onChange={setPickTopic}
                  topics={topics}
                  isFreezeSelected={isFreezeSelected}
                  isDisabled={isFreezeSelected}
                />
                {pickTopic?.value === 6 && (
                  <div>
                    <p className={styles.note}>
                      You are able to Freeze in one-month increments, for up to three
                      months in a calendar year.
                    </p>
                    <div className={styles.durationSelect}>
                      <Select
                        placeholder="Choose duration"
                        width="100%"
                        containerStyle={{
                          borderRadius: 10,
                        }}
                        hideIndicatorSeparator
                        options={getDurations()}
                        value={duration}
                        onChange={e => setDuration(e.value)}
                      />
                    </div>
                  </div>
                )}
                <div className={styles.textareaContainer}>
                  <textarea
                    value={values.message}
                    onChange={handleChange}
                    className={styles.textarea}
                    placeholder={
                      pickTopic?.value === 6 ? 'Reason for freeze' : 'Your Message'
                    }
                    name="message"
                    id="message"
                  />
                  {errors.message && touched.message && (
                    <div className={styles.error}>{errors.message}</div>
                  )}

                  {pickTopic?.value === 7 && (
                    <p className={styles.note}>
                      Please note, by re-activating your subscription before your Freeze
                      ends, you may be subject to a prorated monthly charge.
                    </p>
                  )}

                  <UploadImage
                    onChange={setFieldValue}
                    value={values?.files}
                    inputRef={inputRef}
                    uploaded={uploaded}
                    setUploaded={setUploaded}
                  />
                </div>
              </div>
            </div>
            <div className={styles.button}>
              <Button
                width={isMobile && '250px'}
                onClick={handleSubmit}
                disabled={
                  pickTopic.value === 6
                    ? !duration || !values.message
                    : !pickTopic.value || !values.message
                }
              >
                Send Message
              </Button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
Form.propTypes = {
  user: PropTypes.object.isRequired,
  profile: PropTypes.object,
  selectedClub: PropTypes.object,
  sendMessage: PropTypes.func.isRequired,
  topics: PropTypes.array.isRequired,
  isFreezeSelected: PropTypes.string.isRequired,
  resetPreselectedFreezeTopic: PropTypes.func.isRequired,

  inputRef: PropTypes.object.isRequired,
  uploaded: PropTypes.bool.isRequired,
  setUploaded: PropTypes.func.isRequired,
};
Form.defaultProps = {
  profile: {},
  selectedClub: {},
};
export default Form;
