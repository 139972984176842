/* eslint-disable no-use-before-define */
/* eslint-disable require-yield */
/* eslint-disable no-param-reassign */
import { takeEvery, call, put, spawn, select } from 'redux-saga/effects';
import api from 'services/api';
import moment from 'moment';
import clubsAction from 'store/clubs/actions';
import errorActions from 'store/error/actions';
import cloneDeep from 'lodash/cloneDeep';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import actions from './actions';

const {
  Types: {
    LOAD_RESERVATIONS_STATUS_REQUEST,
    INIT_LOAD_RESERVATIONS_REQUEST,
    LOAD_RESERVATIONS_REQUEST,
    SAVE_RESERVATIONS_REQUEST,
    DELETE_RESERVATION_REQUEST,
    MODIFY_RESERVATIONS_REQUEST,
  },
  Creators: {
    loadReservationsSuccess,
    loadReservationsFailure,

    saveReservationsSuccess,
    saveReservationsFailure,

    deleteReservationSuccess,
    deleteReservationFailure,

    loadUpcomingReservationsSuccess,
    loadUpcomingReservationsFailure,

    calculateReservedSlots,

    modifyReservationsSuccess,
    modifyReservationsFailure,

    loadReservationsStatusSuccess,
    loadReservationsStatusFailure,
  },
} = actions;

const {
  Creators: { loadClubsRequest },
} = clubsAction;

const {
  Creators: { setError },
} = errorActions;

const selectUserReservations = state => state.reservations.reservations;
const selectUpcomingReservations = state => state.reservations.upcomingReservations;

function* calculateReservedTimeSlots(timeSlots) {
  const upcomingReservations = yield select(selectUpcomingReservations);
  const isReservationAvailable = !upcomingReservations.length;
  const reservedItems = timeSlots.filter(item => item.status === 'reserved');

  yield put(calculateReservedSlots(reservedItems, isReservationAvailable));
}

function* watchInitLoadReservations() {
  yield takeEvery(INIT_LOAD_RESERVATIONS_REQUEST, function* load({ date, club, room }) {
    try {
      yield put(loadClubsRequest());

      const { data } = yield call(
        api.get,
        `/reservations?date=${date}&clubId=${club}&room=${room}`
      );

      yield loadUpcomingReservations();
      const activeReservations = data.map(item => {
        const sfDateTime = getSFTime('full');
        const diffTime = moment(sfDateTime).diff(item.date, 'minutes');
        if (diffTime <= 45) {
          return item;
        }
        item.status = 'old';
        return item;
      });
      yield put(loadReservationsSuccess(activeReservations));
      yield calculateReservedTimeSlots(activeReservations);
    } catch (error) {
      yield put(loadReservationsFailure(error));
      yield put(setError('Failed to load reservations.'));
    }
  });
}

export function* loadUpcomingReservations() {
  try {
    const sfDateTime = getSFTime('full');
    const date = moment(sfDateTime).format('YYYY-MM-DD');
    const { data } = yield call(api.get, `/reservations/upcoming?date=${date}`);
    const { upcomingReservations, reservationsUpcomingDaySlotList } = data;
    const isQRAvailable = checkCalculateQrVisibility(upcomingReservations);
    yield put(
      loadUpcomingReservationsSuccess(
        upcomingReservations,
        reservationsUpcomingDaySlotList,
        isQRAvailable
      )
    );
  } catch (error) {
    yield put(loadUpcomingReservationsFailure(error));
    yield put(setError('Load upcoming reservations error.'));
  }
}

function* watchLoadReservations() {
  yield takeEvery(LOAD_RESERVATIONS_REQUEST, function* load({ date, clubId, room }) {
    try {
      const { data } = yield call(
        api.get,
        `/reservations?date=${date}&clubId=${clubId}&room=${room}`
      );
      const activeReservations = data.map(item => {
        const sfDateTime = getSFTime('full');
        const diffTime = moment(sfDateTime).diff(item.date, 'minutes');
        if (diffTime <= 45) {
          return item;
        }
        item.status = 'old';
        return item;
      });
      yield put(loadReservationsSuccess(activeReservations));
      yield calculateReservedTimeSlots(activeReservations);
    } catch (error) {
      yield put(loadReservationsFailure(error));
      yield put(setError('Failed to load reservations.'));
    }
  });
}

function* watchSaveReservations() {
  yield takeEvery(SAVE_RESERVATIONS_REQUEST, function* save({ selectedReservations }) {
    try {
      const { data } = yield call(api.post, `/reservations`, selectedReservations);
      const activeReservations = data.map(item => {
        const sfDateTime = getSFTime('full');
        const diffTime = moment(sfDateTime).diff(item.date, 'minutes');
        if (diffTime <= 45) {
          return item;
        }
        item.status = 'old';
        return item;
      });
      yield put(saveReservationsSuccess(activeReservations));
      yield loadUpcomingReservations();
      yield calculateReservedTimeSlots(activeReservations);
    } catch (error) {
      yield put(saveReservationsFailure(error));
      yield put(
        setError(error?.response?.data?.message || 'Failed to save reservations.')
      );
    }
  });
}

function* watchModifyReservations() {
  yield takeEvery(MODIFY_RESERVATIONS_REQUEST, function* modify({
    itemsIdsToDelete,
    itemsToCreate,
    withLoadReservationSlots,
  }) {
    try {
      const { data } = yield call(api.put, `/reservations`, {
        itemsIdsToDelete,
        itemsToCreate,
      });
      if (withLoadReservationSlots) {
        yield calculateReservedTimeSlots(data);
        yield put(modifyReservationsSuccess(data));
      }
      yield put(modifyReservationsSuccess());
      yield loadUpcomingReservations();
    } catch (error) {
      yield put(modifyReservationsFailure(error));
      yield put(setError('Failed to modify reservations.'));
    }
  });
}

function* watchDeleteReservation() {
  yield takeEvery(DELETE_RESERVATION_REQUEST, function* remove({
    reservationIds,
    shouldLoadUpcoming,
  }) {
    try {
      const reservations = yield select(selectUserReservations);
      const idsString = reservationIds.join(',');
      const { data } = yield call(api.remove, `/reservations?ids=${idsString}`);
      if (shouldLoadUpcoming) {
        yield call(loadUpcomingReservations);
        yield put(deleteReservationSuccess([]));
      } else if (data.success) {
        const refreshedReservations = cloneDeep(reservations).map(reservation => {
          if (reservationIds.includes(reservation.reservationId))
            return {
              ...reservation,
              status: 'open',
              numberOfSeats: reservation.numberOfSeats + 1,
            };
          return { ...reservation };
        });
        yield put(deleteReservationSuccess(refreshedReservations));
        yield loadUpcomingReservations();
        yield calculateReservedTimeSlots(refreshedReservations);
      } else {
        yield put(deleteReservationFailure(data));
        yield put(setError('Failed to cancel reservations.'));
      }
    } catch (error) {
      yield put(deleteReservationFailure(error));
      yield put(setError('Failed to cancel reservations.'));
    }
  });
}

function checkCalculateQrVisibility(upcomingReservations) {
  let isAvailable = false;
  const sfDateTime = getSFTime('full');

  const todaysReservations = upcomingReservations.filter(
    reservation =>
      moment(reservation.date).format('YYYY-MM-DD') ===
      moment(sfDateTime).format('YYYY-MM-DD')
  );
  if (!todaysReservations.length) return isAvailable;
  isAvailable = !!todaysReservations.filter(
    ({ date }) =>
      moment(sfDateTime).diff(date, 'minutes') >= -15 &&
      moment(sfDateTime).diff(date, 'minutes') <= 55
  ).length;
  return isAvailable;
}

function* watchLoadReservationsStatus() {
  yield takeEvery(LOAD_RESERVATIONS_STATUS_REQUEST, function* load() {
    try {
      const { data } = yield call(api.get, `/reservations/status`);

      yield put(
        loadReservationsStatusSuccess(
          data.isBanned,
          data.dateOfFinishBan,
          data.missedCount
        )
      );
    } catch (error) {
      yield put(loadReservationsStatusFailure(error));
    }
  });
}
export function* reservationsSaga() {
  yield spawn(watchInitLoadReservations);
  yield spawn(watchLoadReservations);
  yield spawn(watchSaveReservations);
  yield spawn(watchDeleteReservation);
  yield spawn(watchModifyReservations);
  yield spawn(watchLoadReservationsStatus);
}
