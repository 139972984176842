/* eslint-disable no-console */
import { takeEvery, put, spawn, call, select, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import actions from './actions';

const {
  Types: {
    GET_CALL_SESSION_TOKEN_REQUEST,
    REMOVE_USER,
    START_RECORDING_REQUEST,
    STOP_RECORDING_REQUEST,
  },
  Creators: {
    getCallSessionTokenSuccess,
    getCallSessionTokenFailure,
    startRecordingSuccess,
    startRecordingFailure,
    stopRecordingSuccess,
    stopRecordingFailure,
  },
} = actions;

const getMySessionToken = state => state.classCall.callSessionToken;
const getClassId = state => state.classCall.classId.classId;
const getRecordingId = state => state.classCall.recording.id;

function* watchGetCallSessionToken() {
  yield takeEvery(GET_CALL_SESSION_TOKEN_REQUEST, function* getToken() {
    try {
      const classId = yield select(getClassId);
      if (classId !== '') {
        const { data } = yield call(api.post, `/calls/get-token`, { classId });
        yield put(getCallSessionTokenSuccess(data));
      }
    } catch (error) {
      yield put(getCallSessionTokenFailure(error));
    }
  });
}

function* watchRemoveUser() {
  yield takeEvery(REMOVE_USER, function* remove() {
    try {
      const classId = yield select(getClassId);
      const token = yield select(getMySessionToken);
      if (classId !== '' && token !== '') {
        yield call(api.post, `/calls/remove-user`, { classId, token });
      }
    } catch (error) {
      console.log(error);
    }
  });
}

function* watchStartRecording() {
  yield takeLatest(START_RECORDING_REQUEST, function* startRecording() {
    try {
      const classId = yield select(getClassId);
      const { data } = yield call(api.post, '/calls/start-recording', { classId });
      yield put(startRecordingSuccess(data));
    } catch (error) {
      yield put(startRecordingFailure(error));
    }
  });
}

function* watchStopRecording() {
  yield takeLatest(STOP_RECORDING_REQUEST, function* stopRecording() {
    try {
      const recordingId = yield select(getRecordingId);
      const { data } = yield call(api.post, '/calls/stop-recording', { recordingId });
      yield put(stopRecordingSuccess(data));
    } catch (error) {
      yield put(stopRecordingFailure(error));
    }
  });
}

export function* classCallSaga() {
  yield spawn(watchGetCallSessionToken);
  yield spawn(watchRemoveUser);
  yield spawn(watchStartRecording);
  yield spawn(watchStopRecording);
}
