import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import Icon from 'components/shared/IconSVG';
import { imgLogo as iconCLose } from 'assets/logos';
import MobileContext from 'context/MobileContext';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { Box, Fade, LinearProgress, Typography } from '@material-ui/core';
import styles from './styles.module.css';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    background: '#FF5F1F',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    backgroundColor: 'red',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="up" ref={ref} {...props} />;
});

const PaymentMethod = ({
  notPrimaryCard,
  url,
  type,
  digits,
  expire,
  setAddNewCard,
  loadBillingUrl,
  saveCardInfo,
  buttonDisabled,
  deleteMode,
  setDeleteMode,
  editMode,
}) => {
  const [Gatewayopen, setGatewayOpen] = React.useState(false);

  const handleClose = () => {
    setGatewayOpen(false);
    setAddNewCard(false);
  };

  const handleSaveClose = () => {
    setGatewayOpen(false);
  };

  const handleOpen = () => {
    setGatewayOpen(true);
  };

  const classes = useStyles();
  const isMobile = useContext(MobileContext);

  function windowassignListeners() {
    window.addEventListener(
      'message',
      function listener(event) {
        if (event.origin !== process.env.REACT_APP_CLUB_AUTOMATION_URL) {
          return;
        }
        const {
          data: { cc_merchant_unique_id },
        } = event;
        if (!isEmpty(cc_merchant_unique_id)) {
          window.messageReceived = true;
          handleSaveClose();
        }
      },
      false
    );
  }

  return (
    <div className={styles.paymentContainer}>
      <>
        <Dialog
          fullScreen
          open={Gatewayopen}
          onClose={handleClose}
          width="100%"
          TransitionComponent={Transition}
        >
          <AppBar position="fixed">
            <Toolbar variant="dense">
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" component="div">
                Add New Card
              </Typography>
            </Toolbar>
          </AppBar>
          {url ? (
            <iframe
              title="add new card"
              name="payment"
              id="payment"
              src={`${url}&parentUrl=${window.location.href}`}
              width="700px"
              style={{
                display: 'block',
                alignSelf: 'center',
                overflow: 'hidden',
                position: 'relative',
                marginLeft: '-20px',
                marginTop: '40px',
              }}
              scrolling="no"
              height={document.documentElement.clientHeight - 50}
            />
          ) : (
            <Box sx={{ width: '100%' }}>
              <LinearProgress color="secondary" />
            </Box>
          )}
        </Dialog>
      </>

      {digits && (
        <>
          <div className={styles.header}>
            <h1>Payment Method</h1>
            <div className={styles.action} onClick={() => setDeleteMode(!deleteMode)}>
              {!deleteMode ? 'Manage' : 'Cancel'}
            </div>
          </div>

          {!isMobile && (
            <div className={styles.creditCard}>
              <div className={styles.backgroundColor}>
                <Icon
                  src={iconCLose}
                  height="180px"
                  width="180px"
                  className={styles.iconLogo}
                />
              </div>

              <div className={styles.cardInformation}>
                <p className={styles.type}>{type || ''}</p>
                <p className={styles.digits}>**** **** **** {digits}</p>
                <p className={styles.expire}>{expire}</p>
              </div>
            </div>
          )}
        </>
      )}

      {isMobile ? (
        <>
          <div className={styles.creditCard}>
            <div className={styles.backgroundColor}>
              <Icon
                src={iconCLose}
                height="180px"
                width="180px"
                className={styles.iconLogo}
              />
            </div>

            <div className={styles.cardInformation}>
              <p className={styles.type}>{type || ''}</p>
              <p className={styles.digits}>**** **** **** {digits}</p>
              <p className={styles.expire}>{expire}</p>
            </div>
          </div>
          <div
            className={styles.mobileButton}
            onClick={() => {
              handleOpen();
              setAddNewCard(true);
              loadBillingUrl();
              assignListeners(saveCardInfo);
              windowassignListeners();
            }}
          >
            + Add New Payment Method
          </div>
        </>
      ) : (
        <Button
          className={styles.buttonPaymentUpdate}
          onClick={() => {
            handleOpen();

            setAddNewCard(true);
            loadBillingUrl();
            assignListeners(saveCardInfo);
            windowassignListeners();
          }}
          type="text"
          disabled={buttonDisabled}
        >
          Add New Payment Method
        </Button>
      )}
    </div>
  );
};

export function assignListeners(saveCardInfo) {
  window.addEventListener(
    'message',
    function listener(event) {
      if (event.origin !== process.env.REACT_APP_CLUB_AUTOMATION_URL) {
        return;
      }
      const {
        data: {
          cc_number,
          expiration_year,
          expiration_month,
          cardConnect_expiry,
          cc_merchant_unique_id,
          expiration_date
        },
      } = event;
      if (!isEmpty(cc_merchant_unique_id)) {
        window.messageReceived = true;
        saveCardInfo({
          cc_number,
          expiration_year,
          expiration_month,
          cardConnect_expiry,
          cc_merchant_unique_id,
          expiration_date
        });
      }
    },
    false
  );
}

PaymentMethod.propTypes = {
  type: PropTypes.string.isRequired,
  digits: PropTypes.string,
  expire: PropTypes.string,
  setAddNewCard: PropTypes.func.isRequired,
  loadBillingUrl: PropTypes.func.isRequired,
  saveCardInfo: PropTypes.func.isRequired,
  buttonDisabled: PropTypes.bool.isRequired,
  setDeleteMode: PropTypes.func.isRequired,
  deleteMode: PropTypes.bool.isRequired,
};
PaymentMethod.defaultProps = {
  digits: null,
  expire: null,
};
export default PaymentMethod;
