import { connect } from 'react-redux';
import ChooseLocationsPage from 'pages/ChooseLocationsPage';
import Actions from 'store/clubs/actions';
import authActions from 'store/auth/actions';

const mapStateToProps = state => {
  return {
    clubs: state.clubs.clubs,
    isRequesting: state.clubs.isRequesting,
    changeFavoriteIsRequesting: state.auth.isRequesting,
  };
};
const mapDispatchToProps = {
  loadClubs: Actions.Creators.loadClubsRequest,
  changeFavoriteLocation: authActions.Creators.changeFavoriteLocationRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseLocationsPage);
