export const getGeneralInfo = user => {
  return [
    { title: 'First Name', value: user?.firstName },
    { title: 'Last Name', value: user?.lastName },
    { title: 'Birthday', value: user?.birthday?.replace('-', '/') },
    { title: 'Email Address', value: user?.email },
    { title: 'Phone Number', value: user?.phone },
    {
      title: 'Address',
      value: `${user?.address}\n${user?.city}\n${user?.state}\n${user?.zipCode}`,
    },
    {
      title: 'Emergency Contact',
      value: `${user?.emergencyContactName}, \n${user?.emergencyContactPhone}`,
    },
  ];
};

export const getSubscriptionPlanInfo = (planInfo, club) => {
  return [
    { title: 'Primary Location', value: club },
    { title: 'Recurring Billing Type', value: planInfo?.itemName },
    { title: 'Recurring Amount', value: planInfo?.enrollmentFee },
    { title: 'Start Date', value: planInfo?.starting },
    { title: 'First Month Prorated', value: planInfo?.membershipFee },
    { title: 'Prepaid Last Month', value: planInfo?.enrollmentFee },
    { title: 'Total Due Today', value: planInfo?.totalDue },
  ];
};
