import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const clubCards = (
  data,
  selectedClub,
  setSelectedClub,
  onClickBack,
  onClickMore,
  onNext,
  mode,
  setMode
) => {
  return (
    <>
      {data.map(club => {
        const amenities = club?.amenities1?.split(',');
        return (
          <div
            id={club.clubAutomationClubId}
            key={club.clubAutomationClubId}
            className={`${styles.card} ${selectedClub === club.clubAutomationClubId &&
              styles.activeCard} ${club.visible && styles.visible}`}
            onClick={() => {
              setSelectedClub(club.clubAutomationClubId);
              onNext();
            }}
          >
            <img src={club.siteImage} className={styles.img} alt="clubImage" />
            <div className={styles.textContainer}>
              <p className={styles.title}>{club.name}</p>
              <p className={styles.description}>Featured Amenities:</p>
              <div className={styles.amenities}>
                {amenities?.length > 0 &&
                  amenities.map(am => (
                    <li key={am} className={styles.description}>
                      {am}
                    </li>
                  ))}
              </div>
            </div>
          </div>
        );
      })}

      {mode === 'more' ? (
        <div
          className={styles.arrowContainer}
          onClick={() => {
            onClickMore();
            setMode('back');
          }}
        >
          <span className={styles.arrowNext} />
          <p>More Locations</p>
        </div>
      ) : (
        <div
          className={styles.arrowContainer}
          onClick={() => {
            onClickBack();
            setMode('more');
          }}
        >
          <span className={styles.arrowBack} />
        </div>
      )}
    </>
  );
};

const SelectClubSection = ({ clubs, selectedClub, setSelectedClub, onNext }) => {
  const [items, setItems] = useState(clubs);
  const [mode, setMode] = useState('more');

  const onClickMore = () => {
    const list = Array.from(clubs).slice(-4);
    const initialCards = [];
    list.map(item => {
      initialCards.push({ ...item, visible: true });
    });
    setItems(initialCards);
  };

  const onClickBack = () => {
    const list = Array.from(clubs).slice(0, 4);
    const initialCards = [];
    list.map(item => {
      initialCards.push({ ...item, visible: true });
    });
    setItems(initialCards);
  };

  useEffect(() => {
    onClickBack();
  }, [clubs]);

  return (
    <>
      {items.length > 0
        ? clubCards(
            items,
            selectedClub,
            setSelectedClub,
            onClickBack,
            onClickMore,
            onNext,
            mode,
            setMode
          )
        : clubCards(
            clubs,
            selectedClub,
            setSelectedClub,
            onClickBack,
            onClickMore,
            onNext,
            mode,
            setMode
          )}
    </>
  );
};

SelectClubSection.propTypes = {
  clubs: PropTypes.array.isRequired,
  selectedClub: PropTypes.string.isRequired,
  setSelectedClub: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default SelectClubSection;
