/* eslint-disable react/prop-types */
import React from 'react';
import Checkbox from 'components/shared/Checkbox';
import styles from './style.module.css';

export const handleClubs = clubs => {
  if (!Array.isArray(clubs)) return [];
  const activeClubs = clubs.map(club => {
    if (club.totalCapacity > 0) return { label: club.name, value: club.sfId, ...club };
    return {
      label: club.name,
      value: club.sfId,
      isDisabled: true,
    };
  });
  return activeClubs;
};

export const getColumns = (selectedItems, setSelectedItems) => {
  return [
    {
      Header: '',
      accessor: 'id',
      Cell: ({ cell: { value } }) => {
        return (
          <Checkbox
            value={selectedItems.find(e => e === value)}
            onChange={() => {
              const prevValue = selectedItems.find(e => e === value);
              const selectedItemsCopy = Array.from(selectedItems);
              if (prevValue) {
                const newSelectedItems = selectedItemsCopy.filter(e => e !== value);
                setSelectedItems(newSelectedItems);
              } else {
                selectedItemsCopy.push(value);
                setSelectedItems(selectedItemsCopy);
              }
            }}
          />
        );
      },
      width: '5%',
      id: 0,
    },
    {
      Header: 'User',
      accessor: row => row['user.name'],
      Cell: ({ cell: { value } }) => {
        return <span className={styles.cell}>{value}</span>;
      },
      width: '12%',
      id: 1,
    },
    {
      Header: 'User SF id',
      accessor: 'userId',
      Cell: ({ cell: { value } }) => {
        return <span className={styles.cell}>{value}</span>;
      },
      id: 2,
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ({ cell: { value } }) => {
        return <span className={styles.cell}>{value}</span>;
      },
      id: 3,
    },
    {
      Header: 'Day of Week',
      accessor: 'dayOfWeek',
      Cell: ({ cell: { value } }) => {
        return <span className={styles.cell}>{value}</span>;
      },
      width: '10%',
      id: 4,
    },
    {
      Header: 'Time',
      accessor: 'time',
      Cell: ({ cell: { value } }) => {
        return <span className={styles.cell}>{value}</span>;
      },
      width: '10%',
      id: 5,
    },
    {
      Header: 'Room',
      accessor: 'room',
      Cell: ({ cell: { value } }) => {
        return <span className={styles.cell}>{value}</span>;
      },
      width: '10%',
      id: 6,
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      Cell: ({ cell: { value } }) => {
        return <span className={styles.cell}>{value}</span>;
      },
      id: 7,
    },
  ];
};
