import React from 'react';
import PropTypes from 'prop-types';
import CallWindow from 'components/shared/SessionCallPopUp/MainContent/CallWindow';
import CallHeader from 'components/shared/SessionCallPopUp/MainContent/MainWindow/CallHeader';
import CallFooter from 'components/shared/SessionCallPopUp/MainContent/MainWindow/CallFooter';
import styles from './styles.module.css';

const MainWindow = ({
  localSrc,
  peerSrc,
  toggle,
  endCall,
  callDurationString,
  callIsActive,
  isRequesting,
  networkIsFailed,
}) => {
  return (
    <div className={styles.wrapper} id="video-call">
      <CallHeader callDurationString={callDurationString} />
      <CallWindow
        localSrc={localSrc}
        peerSrc={peerSrc}
        callIsActive={callIsActive}
        isRequesting={isRequesting}
        networkIsFailed={networkIsFailed}
      />
      <CallFooter
        toggle={toggle}
        endCall={endCall}
        localSrc={localSrc}
        peerSrc={peerSrc}
      />
    </div>
  );
};

MainWindow.propTypes = {
  localSrc: PropTypes.object.isRequired,
  peerSrc: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  endCall: PropTypes.func.isRequired,
  callDurationString: PropTypes.string.isRequired,
  callIsActive: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  networkIsFailed: PropTypes.bool.isRequired,
};

export default MainWindow;
