import { connect } from 'react-redux';
import LoginPage from 'pages/LoginPage';
import Actions from 'store/auth/actions';

const mapStateToProps = state => {
  return {
    requestType: state.auth.requestType,
    isRequesting: state.auth.isRequesting,
    error: state.auth.error,
    isFamily: state.auth.isFamily,
    checkFamilyIsRequesting: state.auth.checkFamilyIsRequesting,
    checkFamilyError: state.auth.checkFamilyError,
  };
};

const mapDispatchToProps = {
  login: Actions.Creators.loginRequest,
  clearError: Actions.Creators.clearAuthError,
  checkFamily: Actions.Creators.checkFamilyRequest,
  loginWithService: Actions.Creators.loginWithService,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
