import { createReducer } from 'reduxsauce';
import chunk from 'lodash/chunk';
import cloneDeep from 'lodash/cloneDeep';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  reservations: [],
  user: {},
  error: null,
};

const searchReservationsService = (reservations, searchValue) => {
  const result = [];

  for (let i = 0; i < reservations?.length; i++) {
    for (let j = 0; j < reservations[i]?.length; j++) {
      if (
        reservations[i][j].id
          .toString()
          .toLowerCase()
          .includes(searchValue.toString().toLowerCase()) ||
        reservations[i][j].userId
          .toString()
          .toLowerCase()
          .includes(searchValue.toString().toLowerCase())
      ) {
        result.push(reservations[i][j]);
      }
    }
  }

  return result;
};

const request = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
  };
};

const loadAdminReservationsSuccess = (state = INITIAL_STATE, { reservations }) => {
  return {
    ...state,
    reservations: chunk(
      Array.from(reservations),
      reservations.length > 10 ? Math.ceil(reservations.length / 10) : reservations.length
    ),
    isRequesting: false,
  };
};

const removeReservationsSuccess = (state = INITIAL_STATE, { ids }) => {
  const copyReservation = cloneDeep(state.reservations).map(resArr => {
    const res = resArr.filter(({ id }) => !ids.includes(`${id}`));
    return res;
  });

  return {
    ...state,
    reservations: copyReservation,
    isRequesting: false,
  };
};

const editReservationSuccess = (state = INITIAL_STATE, { reservation }) => {
  const copyReservation = cloneDeep(state.reservations).map(resArr => {
    const res = resArr.filter(({ id }) => id !== reservation.id);
    return res;
  });
  if (state.reservations[0][0]?.clubId === reservation.clubId)
    copyReservation[0].push(reservation);

  return {
    ...state,
    reservations: copyReservation,
    isRequesting: false,
  };
};

const addReservationSuccess = (state = INITIAL_STATE, { reservation }) => {
  const copyReservation = cloneDeep(state.reservations);
  copyReservation[0].push(reservation);

  return {
    ...state,
    reservations: copyReservation,
    isRequesting: false,
  };
};

const searchUserSuccess = (state = INITIAL_STATE, { user }) => {
  return {
    ...state,
    user,
    isRequesting: false,
  };
};

const searchUserReservations = (state = INITIAL_STATE, { searchValue }) => {
  return {
    ...state,
    searchReservations: searchReservationsService(state.reservations, searchValue),
  };
};

const failure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    error,
    isRequesting: false,
  };
};

export const HANDLERS = {
  [Types.LOAD_ADMIN_RESERVATIONS_REQUEST]: request,
  [Types.LOAD_ADMIN_RESERVATIONS_SUCCESS]: loadAdminReservationsSuccess,
  [Types.LOAD_ADMIN_RESERVATIONS_FAILURE]: failure,

  [Types.REMOVE_RESERVATIONS_REQUEST]: request,
  [Types.REMOVE_RESERVATIONS_SUCCESS]: removeReservationsSuccess,
  [Types.REMOVE_RESERVATIONS_FAILURE]: failure,

  [Types.EDIT_RESERVATION_REQUEST]: request,
  [Types.EDIT_RESERVATION_SUCCESS]: editReservationSuccess,
  [Types.EDIT_RESERVATION_FAILURE]: failure,

  [Types.ADD_RESERVATION_REQUEST]: request,
  [Types.ADD_RESERVATION_SUCCESS]: addReservationSuccess,
  [Types.ADD_RESERVATION_FAILURE]: failure,

  [Types.SEARCH_USER_REQUEST]: request,
  [Types.SEARCH_USER_SUCCESS]: searchUserSuccess,
  [Types.SEARCH_USER_FAILURE]: failure,

  [Types.SEARCH_USER_RESERVATIONS]: searchUserReservations,
};

export default createReducer(INITIAL_STATE, HANDLERS);
