import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth/reducers';
import topics from './topics/reducers';
import home from './home/reducers';
import clubs from './clubs/reducers';
import training from './training/reducers';
import analytics from './analytics/reducers';
import classes from './classes/reducers';
import pdf from './pdf/reducers';
import cases from './case/reducers';
import transaction from './transaction/reducers';
import checkHistory from './history/reducers';
import billing from './billing/reducers';
import history from './history';
import membership from './membership/reducers';
import notifications from './notifications/reducers';
import packages from './packages/reducers';
import error from './error/reducers';
import messages from './messages/reducers';
import sso from './sso/reducers';
import reservations from './reservations/reducers';
import clubsStatistic from './clubsStatistic/reducers';
import safetyCommitment from './safetyCommitment/reducers';
import sessionCall from './sessionCall/reducers';
import classCall from './classCall/reducers';
import premiumVideos from './premiumVideos/reducers';
import signUp from './signUp/reducers';
import admin from './admin/reducers';
import referral from './referral/reducers';
import newOls from './newOls/reducers';

const reducers = {
  router: connectRouter(history),
  auth,
  home,
  clubs,
  topics,
  training,
  analytics,
  classes,
  pdf,
  transaction,
  cases,
  checkHistory,
  notifications,
  billing,
  membership,
  packages,
  error,
  messages,
  sso,
  reservations,
  clubsStatistic,
  safetyCommitment,
  sessionCall,
  classCall,
  premiumVideos,
  signUp,
  admin,
  referral,
  newOls,
};

export default combineReducers(reducers);
