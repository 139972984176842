/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/shared/MaterialUITable';
import styles from './styles.module.css';

const createColumns = () => {
  return [
    {
      Header: 'Package',
      accessor: 'name',
      Cell: ({ cell: { value }, row }) => {
        return (
          <div className={styles.name}>
            {value}
            {row?.values['2'] > 0 && <span className={styles.note}>IN USE </span>}
          </div>
        );
      },
      textAlignHeader: 'left',
      textAlignCell: 'left',
      id: 1,
    },
    {
      Header: 'Sessions Remaining',
      accessor: 'sessionsRemaining',
      Cell: ({ cell: { value } }) => {
        return <span className={styles.remaining}>{value || 0}</span>;
      },
      textAlignHeader: 'center',
      textAlignCell: 'center',
      width: '20%',
      id: 2,
    },
    {
      Header: 'Amount',
      accessor: 'packagePrice',
      Cell: ({ cell: { value } }) => {
        return <span className={styles.amount}>{`$ ${value >= 0 ? value : '-'}`}</span>;
      },
      textAlignHeader: 'center',
      textAlignCell: 'center',
      width: '20%',
      id: 3,
    },
    {
      Header: 'Purchase Date',
      accessor: 'purchasedAt',
      Cell: ({ cell: { value } }) => {
        return <span className={styles.dateValue}>{value || '-'}</span>;
      },
      width: '20%',
      id: 4,
    },
  ];
};

function TableHistoryDesktop({ data, handleOpenPopUp }) {
  const columns = createColumns(handleOpenPopUp);
  return (
    <div style={{ padding: '0 20px' }}>
      {data.length ? (
        <div className={styles.packages}>
          <Table
            data={data}
            columns={columns}
            clickableRows
            rowAction={handleOpenPopUp}
          />
        </div>
      ) : (
        <div style={{ textAlign: 'center', fontFamily: 'Futura Book' }}>
          No packages yet.
        </div>
      )}
    </div>
  );
}
TableHistoryDesktop.propTypes = {
  data: PropTypes.object,
};
TableHistoryDesktop.defaultProps = {
  data: [],
};
export default TableHistoryDesktop;
