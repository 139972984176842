import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadPackagesRequest: [],
  loadPackagesSuccess: ['packages'],
  loadPackagesFailure: ['error'],
  setTargetPackage: ['targetPackage'],
});

const packagesActions = {
  Types,
  Creators,
};

export default packagesActions;
