import { connect } from 'react-redux';
import UpdatePassword from 'components/ProfilePage/UpdatePassword';
import Actions from 'store/auth/actions';

const mapStateToProps = state => {
  return {
    isRequesting: state.auth.isRequesting,
  };
};

const mapDispatchToProps = {
  updatePassword: Actions.Creators.updatePasswordRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
