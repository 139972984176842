import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import moment from 'moment';
import styles from './styles.module.css';

const getDateById = (selectedTimeSlotIds, reservedItems) => {
  let dateString = '';
  if (
    !Array.isArray(selectedTimeSlotIds) ||
    !Array.isArray(selectedTimeSlotIds) ||
    !selectedTimeSlotIds?.length ||
    !reservedItems?.length
  )
    return dateString;
  try {
    const firstSlotId = selectedTimeSlotIds[0];
    const cancellationDate = reservedItems.find(el => el.reservationId === firstSlotId)
      .date;
    const dayOfWeek = moment(cancellationDate).format('dddd');
    const dayMonth = moment(cancellationDate).format('MM/DD');
    const at = moment(cancellationDate).format('h:[00] A');
    dateString = `${dayOfWeek} ${dayMonth} at ${at}`;
    return dateString;
  } catch (e) {
    return dateString;
  }
};
const formatDate = date => {
  let dateString = '';
  if (!date || !date.length) return dateString;
  const dayOfWeek = moment(date).format('dddd');
  const dayMonth = moment(date).format('MM/DD');
  const at = moment(date).format('h:[00] A');
  dateString = `${dayOfWeek} ${dayMonth} at ${at}`;
  return dateString;
};
const CancelReservationPopUp = ({
  reservedItems,
  selectedTimeSlotIds,
  cancelReservation,
  handleClose,
  isDeleteRequesting,
  fullStartDate,
  isHomePage,
}) => {
  const cancellationDate = isHomePage
    ? formatDate(fullStartDate)
    : getDateById(selectedTimeSlotIds, reservedItems);
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Cancel the Reservation</h3>
      <p className={styles.question}>
        Are you sure you want to cancel your reservation scheduled{' '}
        {cancellationDate.length ? `on ${cancellationDate}` : ``}?
      </p>
      <div className={styles.buttonsWrapper}>
        <Button
          onClick={cancelReservation}
          isRequesting={isDeleteRequesting}
          width="255px"
          loaderStyles={{ left: '20px' }}
        >
          Cancel Reservation
        </Button>
        <Button
          styleType="text"
          className={styles.blackButton}
          onClick={() => {
            if (!isDeleteRequesting) handleClose();
          }}
        >
          No, I keep my reservation
        </Button>
      </div>
    </div>
  );
};

CancelReservationPopUp.propTypes = {
  reservedItems: PropTypes.arrayOf(PropTypes.object),
  selectedTimeSlotIds: PropTypes.array.isRequired,
  cancelReservation: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isDeleteRequesting: PropTypes.bool.isRequired,
  fullStartDate: PropTypes.string,
  isHomePage: PropTypes.bool,
};
CancelReservationPopUp.defaultProps = {
  isHomePage: false,
  reservedItems: null,
  fullStartDate: null,
};
export default CancelReservationPopUp;
