/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { attachementIcon } from 'assets/icons';
import styles from './styles.module.css';

const UploadImage = ({ onChange, inputRef, uploaded, setUploaded, value }) => {
  const data = [].concat(value);

  const handleChange = ({ target }) => {
    const onlyNameArray = data.flat().map(element => element.name);
    for (let i = 0; i < Array.from(target.files).length; i++) {
      if (!onlyNameArray.includes(Array.from(target.files)[i].name)) {
        data.push(Array.from(target.files)[i]);
      }
    }
    onChange('files', data);
    inputRef.current.value = '';
    setUploaded(true);
  };

  const removeImage = name => {
    const array = data.filter(item => item.name !== name);
    onChange('files', array);
    if (inputRef?.current?.value) inputRef.current.value = '';
    if (!array.length) {
      setUploaded(false);
    }
  };

  return (
    <div>
      {!uploaded && (
        <div>
          <label htmlFor="multi" className={styles.buttonContainer}>
            <p>Add a media</p>
            <img alt="attachment" src={attachementIcon} />
          </label>
          <input
            className={styles.fileButton}
            type="file"
            id="multi"
            onChange={handleChange}
            multiple
            ref={inputRef}
          />
        </div>
      )}
      <div className={styles.fileNameContainer}>
        {value.flat().map(({ name }) => (
          <div className={styles.paragraphContainer} key={name}>
            <p
              className={styles.fileName}
              style={{ background: uploaded ? `#d8d8d8` : 'none' }}
            >
              {name}
            </p>
            <FontAwesomeIcon
              icon={faTimes}
              style={{ display: uploaded ? `block` : 'none' }}
              onClick={() => removeImage(name)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
UploadImage.propTypes = {
  onChange: PropTypes.func.isRequired,
  inputRef: PropTypes.object.isRequired,
  uploaded: PropTypes.bool.isRequired,
  setUploaded: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
};
export default UploadImage;
