import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

export const Tabs = props => {
  const { tabsData, activeTabIndex, setActiveTabIndex, children, className } = props;
  const renderTabs = () => {
    return tabsData.map(tab => {
      const isActive = () => {
        return tab.index === activeTabIndex;
      };

      return (
        <div
          key={tab.name}
          className={isActive() ? `${styles.tab} ${styles.activeTab}` : styles.tab}
          onClick={() => setActiveTabIndex(tab.index)}
        >
          <span
            className={
              isActive() ? `${styles.tabText} ${styles.activeTabText}` : styles.tabText
            }
          >
            {tab.name}
          </span>
        </div>
      );
    });
  };

  return (
    <div className={`${styles.tabsContainer} ${className}`}>
      <div className={styles.tabsWrapper}>{renderTabs()}</div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

Tabs.propTypes = {
  tabsData: PropTypes.array.isRequired,
  activeTabIndex: PropTypes.number,
  setActiveTabIndex: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  className: PropTypes.string,
};

Tabs.defaultProps = {
  activeTabIndex: 0,
  children: <></>,
  className: null,
};
