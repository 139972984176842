import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './style.module.css';

const computePrice = data => {
  return data.length > 0
    ? data.reduce((num, item) => {
        return num + item['product.defaultPrice'];
      }, 0)
    : 0;
};

const ReferralHistory = ({ list }) => {
  const [price, setPrice] = useState(computePrice(list));

  useEffect(() => {
    setPrice(computePrice(list));
  }, [list]);

  return (
    list?.length > 0 && (
      <div className={styles.wrapper}>
        <div>
          <h1 className={styles.title}>List of you past referrals:</h1>
          <div className={styles.table}>
            {list.map(el => (
              <div className={styles.item}>
                <h1>{el.name}</h1>
                <p>Redeemed on {moment(el.createdDate).format('MM/DD/YYYY')}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.card}>
          <h2>Congratulations! You saved {list.length} Month(s) of Membership Dues.</h2>
          {/* <h2>Congratulations! You already saved</h2>
          <h1>${price}</h1>
          <p>or {list.length} months of subscription off!</p> */}
        </div>
      </div>
    )
  );
};

ReferralHistory.propTypes = {
  list: PropTypes.array.isRequired,
};

export default ReferralHistory;
