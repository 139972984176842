import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { calendarBlackIcon, locationSvg } from 'assets/icons';
import { classesCard as logo } from 'assets/background-images';
import MobileContext from 'context/MobileContext';
import SmallMobileContext from 'context/SmallMobileContext';
import Button from 'components/shared/Button';
import moment from 'moment';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import styles from './styles.module.css';

const clubAllowsTwoHoursReservations = [];

const getSelectOptionsFromReservations = reservations => {
  const sfDateTime = getSFTime('full');

  return reservations.map(reservation => ({
    value: reservation.timeSlot,
    label: `${moment(reservation.date).format('h:mma')}`,
    status: reservation.status,
    date: reservation.date,
    disabled:
      reservation.status === 'full' ||
      moment(sfDateTime).diff(reservation.date, 'minutes') >= 15,
  }));
};

const ReservePopUp = ({
  club,
  isOpen,
  targetDate,
  targetRoom,
  reservations,
  selectedTimeSlotNumber,
  handleSaveReservations,
  isSaveRequesting,
  missedCount,
  handleClose,
}) => {
  const isMobile = useContext(MobileContext);
  const isSmallMobiles = useContext(SmallMobileContext);
  const selectOptions = getSelectOptionsFromReservations(reservations);
  const [duration, setDuration] = useState(1);
  const [isTwoHoursSlotAvailable, setTwoHoursSlotAvailable] = useState(false);
  const [selectedSlotId, setSelectedSlotId] = useState(null);
  const [slotsToReserve, setSlotsToReserve] = useState([]);

  const handleDuration = durationNumber => {
    if (durationNumber === 2) {
      setDuration(2);
      selectOptions.forEach((option, index) => {
        if (option.value === selectedSlotId) {
          if (
            index === selectOptions.length - 1 ||
            selectOptions[index + 1]?.status !== 'open'
          )
            setSlotsToReserve([option]);
          else setSlotsToReserve([option, selectOptions[index + 1]]);
        }
      });
    } else {
      setDuration(1);
      setSlotsToReserve([slotsToReserve[0]]);
    }
  };
  useEffect(() => {
    selectOptions.forEach((option, index) => {
      if (option.value === selectedTimeSlotNumber) {
        if (
          index === selectOptions.length - 1 ||
          selectOptions[index + 1]?.status !== 'open'
        ) {
          setTwoHoursSlotAvailable(false);
          if (duration === 2) setDuration(1);
          setSlotsToReserve([option]);
          setSelectedSlotId(option.value);
        } else {
          setSelectedSlotId(option.value);
          if (duration === 2) setSlotsToReserve([option, selectOptions[index + 1]]);
          else setSlotsToReserve([option]);
        }
      }
    });
  }, [selectedTimeSlotNumber]);

  useEffect(() => {
    setDuration(1);
  }, [isOpen]);

  useEffect(() => {
    const isAllowTwoHoursReservations = clubAllowsTwoHoursReservations.find(
      c => c === club?.label
    );
    setTwoHoursSlotAvailable(isAllowTwoHoursReservations);
  }, [club]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo} style={{ backgroundImage: `url(${logo})` }} />

      <div className={styles.mainContent}>
        <span className={styles.title}>My reservation</span>

        {isMobile ? (
          <>
            <div className={styles.row}>
              <img alt="location" src={locationSvg} className={styles.icon} />
              <span className={styles.location}>
                {club?.label} {targetRoom && <span>&bull; {targetRoom}</span>}
              </span>
            </div>
            <div className={styles.row}>
              <div>
                <img alt="date" src={calendarBlackIcon} className={styles.icon} />
                <span className={styles.date}>
                  {`${targetDate.dayOfWeek} ${targetDate.dateReservation}  `}
                </span>
              </div>
              <div>
                <span className={styles.time}> at {slotsToReserve[0]?.label}</span>
                <span className={styles.duration}>(1 hour)</span>
                {isTwoHoursSlotAvailable && (
                  <span
                    className={[
                      styles.duration,
                      !isTwoHoursSlotAvailable && styles.durationDisabled,
                    ].join(' ')}
                  >
                    2 hours
                  </span>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.row}>
              <img alt="date" src={calendarBlackIcon} className={styles.icon} />
              <span className={styles.date}>
                {`${targetDate.dayOfWeek} ${targetDate.dateReservation}  `}
              </span>

              <span className={styles.time}> at {slotsToReserve[0]?.label}</span>
              <span className={styles.duration}>(1 hour)</span>
              {isTwoHoursSlotAvailable && (
                <span
                  className={[
                    styles.duration,
                    !isTwoHoursSlotAvailable && styles.durationDisabled,
                  ].join(' ')}
                >
                  2 hours
                </span>
              )}
            </div>
            <div className={styles.row}>
              <img alt="location" src={locationSvg} className={styles.icon} />
              <span className={styles.location}>
                {club?.label} {targetRoom && <span>&bull; {targetRoom}</span>}
              </span>
            </div>
          </>
        )}

        <p className={styles.listDescription}>What to know before you confirm:</p>
        <ul className={`${styles.description} ${styles.list}`}>
          <li>
            Only one reservation per person may be booked at a time. Once you have
            completed your reservation, you will be able to reserve another spot.
          </li>
          <li>Reservations are limited to a 55-minute workout.</li>
          <li>You may cancel your reservation up to 1 hour prior to the start time.</li>
          <li>
            {missedCount === 0 ? 'Three' : missedCount === 1 ? 'Two' : 'One'} “No Shows”
            will result in not being able to book for 24 hours.
          </li>
        </ul>

        <div className={styles.buttonWrapper}>
          <Button
            width={isSmallMobiles ? null : '335px'}
            className={styles.confirmButton}
            onClick={() => {
              handleSaveReservations(slotsToReserve);
            }}
            loaderStyles={{ left: '10px' }}
            isRequesting={isSaveRequesting}
          >
            Confirm Reservation
          </Button>
          {isMobile ? (
            <Button
              width={isSmallMobiles ? null : '335px'}
              styleType="text"
              className={styles.cancelButton}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
ReservePopUp.propTypes = {
  club: PropTypes.object,
  targetDate: PropTypes.object.isRequired,
  reservations: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  selectedTimeSlotNumber: PropTypes.number,
  handleSaveReservations: PropTypes.func.isRequired,
  isSaveRequesting: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  missedCount: PropTypes.number,
  handleClose: PropTypes.func.isRequired,
  targetRoom: PropTypes.string,
};

ReservePopUp.defaultProps = {
  club: null,
  selectedTimeSlotNumber: null,
  missedCount: 0,
  targetRoom: null,
};

export default ReservePopUp;
