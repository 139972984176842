import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '8 !important',
    maxWidth: '600px',
    margin: 'auto',
    overflowY: 'auto',
  },
  paper: {
    '@media(max-width: 450px)': {
      marginTop: 160,
    },
  },
}));

const ConfirmPopup = ({ open, onClose, children, isRequesting }) => {
  const classes = useStyles();
  useEffect(() => {
    if (isRequesting) onClose();
  }, [isRequesting, onClose]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper elevation={0} className={classes.paper}>
            {children}
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};

ConfirmPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  onClose: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default ConfirmPopup;
