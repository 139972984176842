import React from 'react';
import { InBodyCard, localPerks } from 'assets/background-images';
import ServicesCard from './ServicesCard';
import styles from './style.module.css';

const services = [
  {
    title: 'Ask Us about Inbody',
    redirectTo: '/training?tab=inbody',
    img: InBodyCard,
  },
  {
    title: 'Your Local Perks',
    subTitle: 'Coming Soon',
    redirectTo: null,
    img: localPerks,
  },
];

const MoreServices = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1 className={styles.title}>Other Things You Can Do</h1>
        <div className={styles.cards}>
          {services.map(el => (
            <ServicesCard key={el.title} service={el} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MoreServices;
