/* eslint-disable consistent-return */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import {
  likeEnabled,
  likeDisabled,
  playFillSvg,
  locationIcon,
  profileIcon,
  clockOrangeIcon,
  calendarOrangeIcon,
} from 'assets/icons';
import styles from './style.module.css';

const CLASS_CALL_FEATURE_ENABLED = process.env.REACT_APP_CLASS_CALL_FEATURE
  ? JSON.parse(process.env.REACT_APP_CLASS_CALL_FEATURE)
  : false;

const ClassCard = ({
  title,
  note,
  classDate,
  locationTitle,
  trainerName,
  cardStyles,
  classId,
  isFavorite,
  removeClassFromFavorite,
  addClassToFavorite,
  date,
  startTime,
  endTime,
  handleOpenClassCallPopUp,
  user,
  isVirtual,
  setClickedGroupEx,
  setIsDetailsOpened,
  mobileCard,
  isHomePage,
  ...props
}) => {
  const isTimeToStartStream = () => {
    const now = new Date();
    const userTimezone = momentTZ.tz.guess(true);
    let today = moment(now).format('YYYY-MM-DD HH:mm:ss');
    if (momentTZ.tz(userTimezone).format('z') !== 'PDT') {
      today = momentTZ(today)
        .tz('America/Los_Angeles')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    const startTimeFormatted = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm:ss');
    const endTimeFormatted = moment(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm:ss');
    if (
      moment(today).isBetween(startTimeFormatted, endTimeFormatted) &&
      moment(today).isSameOrAfter(startTimeFormatted, 'day')
    ) {
      return true;
    }
    return false;
  };
  const [isCallActive, setIsCallActive] = useState(false);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    if (componentIsMounted.current) {
      setIsCallActive(isTimeToStartStream());
      const interval = setInterval(() => {
        setIsCallActive(isTimeToStartStream());
      }, 1000);

      return () => {
        // will unmount
        clearTimeout(interval);
      };
    }
  }, []);

  useEffect(() => {
    return () => {
      componentIsMounted.current = null;
    };
  }, []);

  return (
    <div
      className={`${styles.classWrapper} ${isFavorite && styles.leftBorder}`}
      onClick={() => {
        setClickedGroupEx({
          isFavorite,
          startTime,
          endTime,
          date,
          classId,
          locationName: locationTitle,
          trainerName,
          ...props,
        });
        setIsDetailsOpened(true);
      }}
      style={cardStyles}
    >
      <>
        <div
          onClick={event => {
            event.stopPropagation();

            if (isFavorite) {
              removeClassFromFavorite(classId);
            } else {
              addClassToFavorite(classId);
            }
          }}
          className={styles.cardIconWrapper}
        >
          <img
            src={!isFavorite ? likeEnabled : likeDisabled}
            alt="like"
            className={styles.cardIcon}
          />
        </div>
        <p className={[styles.title, styles.classTitle].join(' ')}>{title}</p>
        <div className={styles.dateSection}>
          <div className={styles.items}>
            <div>
              {isHomePage ? (
                <div>
                  <div className={styles.infoItem}>
                    <img
                      src={calendarOrangeIcon}
                      alt="Calendar icon"
                      className={styles.profileIcon}
                    />
                    <p className={styles.location}>{mobileCard?.date}</p>
                  </div>
                  <div className={styles.infoItem}>
                    <img
                      src={clockOrangeIcon}
                      alt="Clock icon"
                      className={styles.profileIcon}
                    />
                    <p className={styles.location}>
                      {mobileCard?.time}{' '}
                      {note && <span className={styles.note}>{note}</span>}
                    </p>
                  </div>
                </div>
              ) : (
                <div>
                  <div className={styles.infoItem}>
                    <img
                      src={clockOrangeIcon}
                      alt="Clock icon"
                      className={styles.profileIcon}
                    />
                    <p className={styles.location}>
                      {mobileCard?.time}{' '}
                      {note && <span className={styles.note}>{note}</span>}
                    </p>
                  </div>
                  <div className={styles.infoItem}>
                    <img
                      src={locationIcon}
                      alt="Location icon"
                      className={`${styles.profileIcon} ${styles.orangeIcon}`}
                    />
                    <p className={styles.location}>
                      {mobileCard?.locationName}
                      {!!mobileCard?.studioName && `, ${mobileCard?.studioName}`}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div>
              {isHomePage && (
                <div className={styles.infoItem}>
                  <img
                    src={locationIcon}
                    alt="Location icon"
                    className={`${styles.profileIcon} ${styles.orangeIcon}`}
                  />
                  <p className={styles.location}>
                    {mobileCard?.locationName}
                    {!!mobileCard?.studioName && `, ${mobileCard?.studioName}`}
                  </p>
                </div>
              )}
              <div className={styles.infoItem}>
                <img
                  src={profileIcon}
                  alt="Profile icon"
                  className={`${styles.profileIcon} ${styles.orangeIcon}`}
                />
                <p className={styles.location}>{mobileCard?.trainer}</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.classFooter}>
          {locationTitle === 'Watch live!' && CLASS_CALL_FEATURE_ENABLED ? (
            <div className={styles.personalInfo}>
              <div
                className={[
                  ((user.isTrainer && user.name !== trainerName) || !isCallActive) &&
                  !isVirtual
                    ? styles.cameraIconWrapperDisabled
                    : styles.cameraIconWrapperActive,
                  isVirtual && styles.cameraIconDisableClick,
                ].join(' ')}
                onClick={event => {
                  event.stopPropagation();
                  if (!((user.isTrainer && user.name !== trainerName) || !isCallActive))
                    handleOpenClassCallPopUp(
                      { classId, name: title, date, startTime },
                      true
                    );
                }}
              >
                <img
                  src={playFillSvg}
                  alt="camera"
                  className={[
                    styles.sessionCardIcon,
                    ((user.isTrainer && user.name !== trainerName) || !isCallActive) &&
                    !isVirtual
                      ? styles.disabledCamera
                      : styles.activeCamera,
                  ].join(' ')}
                />
                <p className={`${styles.location} ${styles.activeLocation}`}>
                  {locationTitle}
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </>
    </div>
  );
};

ClassCard.propTypes = {
  title: PropTypes.string,
  note: PropTypes.string.isRequired,
  classDate: PropTypes.string,
  locationTitle: PropTypes.string,
  cardStyles: PropTypes.object,
  trainerName: PropTypes.string,
  isFavorite: PropTypes.bool,
  isHomePage: PropTypes.bool,
  addClassToFavorite: PropTypes.func,
  removeClassFromFavorite: PropTypes.func,
  classId: PropTypes.number,
  date: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  handleOpenClassCallPopUp: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isVirtual: PropTypes.bool.isRequired,
  mobileCard: PropTypes.object.isRequired,
  setClickedGroupEx: PropTypes.func.isRequired,
  setIsDetailsOpened: PropTypes.func.isRequired,
};
ClassCard.defaultProps = {
  title: '',
  isFavorite: false,
  classDate: '',
  locationTitle: '',
  trainerName: '',
  cardStyles: {},
  classId: null,
  addClassToFavorite: () => {},
  removeClassFromFavorite: () => {},
  isHomePage: false,
};
export default ClassCard;
