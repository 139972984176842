import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  removeUser: [],
  getCallSessionTokenRequest: [],
  getCallSessionTokenSuccess: ['callSessionToken'],
  getCallSessionTokenFailure: ['error'],
  handleOpenClassCallPopUp: ['classId', 'isPopUpOpened'],
  startRecordingRequest: ['classId'],
  startRecordingSuccess: ['recording'],
  startRecordingFailure: ['error'],
  stopRecordingRequest: ['recordingId'],
  stopRecordingSuccess: ['awsUrl'],
  stopRecordingFailure: ['error'],
  clearCallState: [],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
