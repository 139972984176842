import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import {
  LoginPage,
  ResetPasswordPage,
  NewPasswordPage,
  HomePage,
  ClassesPage,
  TrainingPage,
  ProfilePageMyAccount,
  ProfilePageUpdatePassword,
  ProfilePageTransaction,
  ProfilePageHistory,
  ProfilePageBilling,
  ProfileSettings,
  ProfilePackages,
  LocationsPage,
  ChooseLocationsPage,
  // ChatPage,
  EatLovePage,
  // ReservationPage,
  VideosDemandPage,
  VideoPage,
  RegistrationPage,
  RegistrationPageNew,
  ExperienceUsPage,
  ProfilePageContactUs,
  AdminPage,
  InviteBenefitPage,
  ProfilePageMembershipSubscription,
  GuestClassesPage,
  VaccineVerificationPage,
} from 'containers';
import { RequestSuccessPage, CreateSuccessPage } from 'components';
import Message from 'components/shared/Message';
import SmallMobileContext from 'context/SmallMobileContext';
import MobileContext from 'context/MobileContext';
import TabletContext from 'context/TabletContext';
import PrivateRoute from 'services/HOCs/privateRoute';
import ScrollToTop from 'services/HOCs/ScrollToTop';
import NotificationActions from 'store/notifications/actions';
import MessageActions from 'store/messages/actions';
import ErrorActions from 'store/error/actions';
import SessionCallActions from 'store/sessionCall/actions';
import history from 'store/history';
import { memberStatuses } from 'enums/memberStatuses';
import vaccineStatuses from 'enums/vaccineStatuses';
import { getSocket } from 'utils/socket';
import { usePrevious } from 'utils/hooks/usePrevious';
import checkIsAdmin from 'services/checkIsAdmin';
import QrCodePage from './QrCodePage';
import NotFoundPage from './NotFoundPage';
import Logout from './ProfilePage/Logout';
import { Modal } from './shared/Modal';
import VaccineVerificationModal from './shared/VaccineVerificationModal';

const socket = getSocket();

const App = ({
  isAuth,
  email,
  sfId,
  isTrainer,
  favoriteLocation,
  // requestNotifications,
  // receiveNotifications,
  receiveMessage,
  error,
  errorType,
  clearError,
  setMessage,
  memberStatus,
  membership,
  receiveReadMessages,
  loadMessages,
  messagesHistory,
  incomingCall,
  incomingCallRejected,
  vaccineStatus,
  
}) => {
  const [isSmallMobile, setIsSmallMobile] = useState(
    document.documentElement.clientWidth <= 450
  );
  const [isMobile, setIsMobile] = useState(document.documentElement.clientWidth <= 768);
  const [isTablet, setIsTablet] = useState(document.documentElement.clientWidth <= 992);
  const [isOpenModal, setOpenModal] = useState(false);

  const checkIsAdminRestrictions = checkIsAdmin(email);
  const membershipRestrictions =
    memberStatus !== memberStatuses.onHold && memberStatus !== memberStatuses.notActive;
  const prevVaccineStatus = usePrevious(vaccineStatus);

  const handleResize = () => {
    const { clientWidth } = document.documentElement;
    setIsSmallMobile(clientWidth <= 450);
    setIsMobile(clientWidth <= 768);
    setIsTablet(clientWidth <= 991);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (vaccineStatus === vaccineStatuses.notYetUploaded) setOpenModal(true);
    if (
      vaccineStatus === vaccineStatuses.pendingConfirmation &&
      prevVaccineStatus === vaccineStatuses.notYetUploaded
    ) {
      setOpenModal(true);
    }
  }, [vaccineStatus]);

  useEffect(() => {
    const body = document.getElementById('root');
    if (isAuth) body.className = 'logged-in';
    if (!isAuth) body.className = 'logged-out';
  }, [isAuth]);

  useEffect(() => {
    if (isAuth) {
      // if (!socket.hasListeners('notification'))
      //   socket.on('notification', notifications => {
      //     receiveNotifications(notifications);
      //   });

      if (!socket.hasListeners('message'))
        socket.on('message', message => {
          receiveMessage(message);
        });
      if (!socket.hasListeners('message read success'))
        socket.on('message read success', data => {
          receiveReadMessages(data);
        });
      socket.on('request-call', data => {
        incomingCall(data.friendId, data.friendSocketId);
      });
      if (!socket.hasListeners('stop-session-call'))
        socket.on('stop-session-call', () => {
          if (!isTrainer) incomingCallRejected();
        });
      if (!socket.hasListeners('peer-disconnected'))
        socket.on('peer-disconnected', () => {
          if (!isTrainer) incomingCallRejected();
        });
    }
  }, [
    isAuth,
    incomingCall,
    receiveMessage,
    // receiveNotifications,
    receiveReadMessages,
  ]);

  // useEffect(() => {
  //   // commented reason: chat feature was hided
  //   if (
  //     window.location.pathname !== '/login' &&
  //     window.location.pathname !== '/chat' &&
  //     !messagesHistory.length
  //   )
  //   loadMessages();
  // }, [loadMessages]);
  useEffect(
    () => {
      if (
        window.location.pathname !== '/login' &&
        window.location.pathname !== '/join' &&
        window.location.pathname !== '/experience-us'
      ) {
        // requestNotifications();
        history.listen(props => {
          // requestNotifications();
        });
      }
    },
    [
      // requestNotifications
    ]
  );

  useEffect(() => {
    if (sfId) {
      window.gtag('event', 'login', {
        event_label: `User sfId=${sfId} logged in`,
        event_category: 'login',
      });
      window.gtag('set', { user_id: sfId });
    }
  }, [sfId]);

  const redirect = !favoriteLocation ? '/choose_favorite_location' : '/login';

  const allowLimited = isAuth && Boolean(favoriteLocation);

  let locationNames = [
    'marin',
    'embarcadero',
    'fillmore',
    'transbay',
    'mid_market',
    'oakland',
    'soma',
    'castro',
  ];
  const currentPath = window.location.pathname.substring(1);
  const isLocationValid = locationNames.includes(currentPath);
  return (
    <>
      <TabletContext.Provider value={isTablet}>
        <MobileContext.Provider value={isMobile}>
          <SmallMobileContext.Provider value={isSmallMobile}>
            <React.StrictMode>
              <ScrollToTop>
                {/* <Modal
                  isOpened={
                    isOpenModal &&
                    window.location.pathname !== '/verify-status'
                  }
                  setIsOpened={() => setOpenModal(!isOpenModal)}
                  wrapperStyles={{
                    width: '315px',
                    height:
                      vaccineStatus === vaccineStatuses.notYetUploaded
                        ? '340px'
                        : '230px',
                  }}
                >
                  <VaccineVerificationModal
                    setOpenModal={setOpenModal}
                    vaccineStatus={vaccineStatus}
                  />
                </Modal> */}
                <Switch>
                  {/* Location routes Logic */}
                  {locationNames.includes(currentPath) ? (
                    <Route path="/:location" exact>
                      {/* {isAuth ? (
                        <Redirect to="/" />
                      ) : ( */}
                      <RegistrationPageNew
                        isLocationValid={isLocationValid}
                        locationRoute={currentPath}
                      />
                      {/* )} */}
                    </Route>
                  ) : (
                    <Route path="/" exact>
                      <RegistrationPageNew
                        isLocationValid={isLocationValid}
                        locationRoute="undefined"
                      />
                    </Route>
                  )}

                  {/* <PrivateRoute
                    allow={!isAuth}
                    path="/join"
                    exact
                    component={RegistrationPageNew}
                  /> */}

                  {/*  <PrivateRoute
                    allow={allowLimited}
                    path="/"
                    exact
                    component={HomePage}
                    redirectTo={redirect}
                  /> */}

                  {/* <PrivateRoute
                    allow={allowLimited && membershipRestrictions}
                    path="/nutrition"
                    exact
                    component={EatLovePage}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={allowLimited && membershipRestrictions}
                    path="/classes"
                    exact
                    component={ClassesPage}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={allowLimited && membershipRestrictions}
                    path="/on_demand"
                    exact
                    component={VideosDemandPage}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={allowLimited && membershipRestrictions}
                    path="/on_demand/:section"
                    exact
                    component={VideoPage}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={allowLimited}
                    path="/reservation"
                    exact
                    component={QrCodePage}
                    redirectTo={redirect}
                  /> */}
                  {/* <PrivateRoute
                    allow={isAuth ? allowLimited : !isAuth}
                    path="/verify-status"
                    exact
                    component={VaccineVerificationPage}
                  /> */}
                  {/* <PrivateRoute
                  allow={allow}
                  path="/chat"
                  exact
                  component={ChatPage}
                  redirectTo={redirect}
                /> */}
                  {/* <PrivateRoute
                    allow={allowLimited && membershipRestrictions}
                    path="/training"
                    exact
                    component={TrainingPage}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={allowLimited}
                    path="/locations"
                    exact
                    component={LocationsPage}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={!isAuth}
                    path="/reset_password"
                    exact
                    component={ResetPasswordPage}
                  />
                  <PrivateRoute
                    allow={!isAuth}
                    path="/new_password"
                    exact
                    component={NewPasswordPage}
                  />
                  <PrivateRoute
                    allow
                    path="/request_success"
                    exact
                    component={RequestSuccessPage}
                  /> */}
                  {/* <PrivateRoute
                    allow={!isAuth}
                    path="/join"
                    exact
                    component={RegistrationPageNew}
                  /> */}
                  {/* <PrivateRoute
                    allow={!isAuth}
                    path="/experience-us"
                    exact
                    component={ExperienceUsPage}
                  /> */}
                  {/* <PrivateRoute
                    allow
                    path="/create_success"
                    exact
                    component={CreateSuccessPage}
                  />
                  <PrivateRoute
                    allow={!isAuth}
                    path="/login"
                    exact
                    component={LoginPage}
                    redirectTo="/"
                  />
                  <PrivateRoute
                    allow={allowLimited && isTablet}
                    path="/profile"
                    exact
                    component={ProfilePageMyAccount}
                    redirectTo="/my-account"
                  />
                  <PrivateRoute
                    allow={allowLimited}
                    path="/my-account"
                    exact
                    component={ProfilePageMyAccount}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={allowLimited}
                    path="/update-user-password"
                    component={ProfilePageUpdatePassword}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={allowLimited}
                    path="/membership-subscription"
                    component={ProfilePageMembershipSubscription}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={allowLimited}
                    path="/invite"
                    exact
                    component={InviteBenefitPage}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={allowLimited}
                    path="/billing-information"
                    exact
                    component={ProfilePageBilling}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={allowLimited}
                    path="/statements-transactions"
                    exact
                    component={ProfilePageTransaction}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={allowLimited}
                    path="/packages"
                    exact
                    component={ProfilePackages}
                    redirectTo={redirect}
                  /> */}
                  {/* <PrivateRoute */}
                  {/*  allow={allowLimited} */}
                  {/*  path="/contact-us" */}
                  {/*  exact */}
                  {/*  component={ProfilePageContactUs} */}
                  {/*  redirectTo={redirect} */}
                  {/* /> */}
                  {/* <PrivateRoute
                    allow={allowLimited}
                    path="/settings"
                    exact
                    component={ProfileSettings}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={allowLimited}
                    path="/checkin-history"
                    exact
                    component={ProfilePageHistory}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={isAuth && !favoriteLocation && membershipRestrictions}
                    path="/choose_favorite_location"
                    exact
                    component={ChooseLocationsPage}
                    redirectTo="/login"
                  />
                  <PrivateRoute
                    allow={allowLimited && checkIsAdminRestrictions}
                    path="/admin"
                    exact
                    component={AdminPage}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={allowLimited && membershipRestrictions}
                    path="/qr"
                    exact
                    component={QrCodePage}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={allowLimited}
                    path="/logout"
                    exact
                    component={Logout}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={!isAuth}
                    path="/our-classes"
                    exact
                    component={GuestClassesPage}
                    redirectTo={redirect}
                  />
                  <PrivateRoute
                    allow={!isAuth}
                    path="/experience-us"
                    exact
                    component={() => {
                      window.location.replace(
                        'https://experience.fitnesssf.com/free-1-day-membership'
                      );
                      return null;
                    }}
                  /> */}
                  <Route path="*">
                    <NotFoundPage />
                  </Route>
                </Switch>
              </ScrollToTop>
            </React.StrictMode>
          </SmallMobileContext.Provider>
        </MobileContext.Provider>
      </TabletContext.Provider>

      {error && (
        <Message type={errorType} text={error} timeout={5000} onClose={clearError} />
      )}
    </>
  );
};

App.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  sfId: PropTypes.string.isRequired,
  // requestNotifications: PropTypes.func.isRequired,
  // receiveNotifications: PropTypes.func.isRequired,
  receiveMessage: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  errorType: PropTypes.string.isRequired,
  clearError: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  favoriteLocation: PropTypes.string.isRequired,
  memberStatus: PropTypes.string.isRequired,
  membership: PropTypes.array.isRequired,
  receiveReadMessages: PropTypes.func.isRequired,
  loadMessages: PropTypes.func.isRequired,
  messagesHistory: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.array,
  ]).isRequired,
  incomingCall: PropTypes.func.isRequired,
  incomingCallRejected: PropTypes.func.isRequired,
  isTrainer: PropTypes.bool.isRequired,
  vaccineStatus: PropTypes.string,
};

App.defaultProps = {
  vaccineStatus: '',
};

const mapStateToProps = state => ({
  isAuth: state.auth.isAuth,
  email: state.auth?.user?.email,
  sfId: state.auth?.user?.sfId,
  isTrainer: state.auth?.user?.isTrainer ? state.auth.user.isTrainer : false,
  error: state.error.message,
  errorType: state.error.type,
  favoriteLocation: state.auth?.isAuth ? state.auth.user.favoriteLocation : '',
  memberStatus: state.auth?.isAuth ? state.auth.user.memberStatus : '',
  membership: state.auth?.membership || [],
  vaccineStatus: state.auth?.profile?.vaccineStatus,
  messagesHistory: state.messages.messagesHistory,
  hasIncomingCall: state.messages.hasIncomingCall,
});

const mapDispatchToProps = dispatch => {
  return {
    // requestNotifications: () => {
    //   dispatch(NotificationActions.Creators.requestNotifications());
    // },
    loadMessages: () => {
      dispatch(MessageActions.Creators.loadMessagesRequest());
    },
    // receiveNotifications: data =>
    // dispatch(NotificationActions.Creators.receiveNotifications(data)),
    receiveMessage: data => dispatch(MessageActions.Creators.receiveMessage(data)),
    receiveReadMessages: data =>
      dispatch(MessageActions.Creators.receiveReadMessages(data)),
    clearError: () => {
      dispatch(ErrorActions.Creators.clearError());
    },
    setMessage: data => {
      dispatch(ErrorActions.Creators.setMessage(data));
    },
    incomingCall: (friendId, friendSocketId) => {
      dispatch(SessionCallActions.Creators.incomingCall(friendId, friendSocketId));
    },
    incomingCallRejected: () => {
      dispatch(SessionCallActions.Creators.incomingCallRejected());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
