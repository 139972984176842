/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { calendarBlackIcon, locationIcon } from 'assets/icons';
import styles from './styles.module.css';
import { getClassImage, getClassTime, getDescription } from './services';

export const ClassDetails = ({
  data: { classInfo = {}, startTime, endTime, date, studioName, description },
}) => {
  const { club = {}, name, programName } = classInfo;
  const cuttedClubName = club.name && club.name.replace('SF ', '');
  const classDate = getClassTime(date, startTime, endTime);
  const formattedDescription = getDescription(description);

  return (
    <div className={styles.detailsContainer}>
      <div>
        <img
          className={styles.classTypeImage}
          src={getClassImage(programName || name)}
          alt=""
        />
        <div className={styles.descriptionWrapper}>
          <p className={styles.title}>{programName || name}</p>
          <div className={styles.row}>
            <img alt="date" src={calendarBlackIcon} />
            <span> {classDate}</span>
          </div>
          <p className={styles.row}>
            <img src={locationIcon} alt="location" />
            <a
              className={styles.location}
              href={`https://www.google.com/maps/search/?api=1&query=FITNESS+SF+${cuttedClubName}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <span>
                {club.name}
                {studioName ? `, ${studioName}` : ''}
              </span>
            </a>
          </p>
          {description && (
            <span
              className={styles.descriptionText}
              dangerouslySetInnerHTML={{ __html: formattedDescription }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ClassDetails.propTypes = {
  data: PropTypes.object.isRequired,
};
