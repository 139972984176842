import React, { useEffect, useState } from 'react';
import Select from 'components/shared/Select';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const handleClubs = clubs => {
  if (!Array.isArray(clubs)) return [];
  const activeClubs = clubs.map(club => {
    if (club.totalCapacity > 0)
      return { label: club.name, value: club.clubAutomationClubId };
    return {
      label: club.name,
      value: club.clubAutomationClubId,
      withLabelClosed: true,
    };
  });
  activeClubs.push({ label: 'All locations', value: 'all' });
  return activeClubs.sort((a, b) => (a.label > b.label ? 1 : -1));
};

export const ClassesHeader = ({
  selectedClub,
  setSelectedClub,
  clubs,
  changeSelectedClub,
}) => {
  const [clubsOptions, setClubsOptions] = useState([]);
  const activeLocationClub = useSelector(state => state.clubs.activeLocationClub);

  useEffect(() => {
    setClubsOptions(handleClubs(clubs));
  }, [clubs]);

  useEffect(() => {
    changeSelectedClub('');
  }, [changeSelectedClub]);

  const handleClubChange = select => {
    setSelectedClub(select.value);
  };

  useEffect(() => {
    if (
      !selectedClub &&
      activeLocationClub?.length &&
      activeLocationClub[0].clubAutomationClubId
    ) {
      handleClubChange({ value: activeLocationClub[0].clubAutomationClubId });
    }
  }, [clubsOptions, activeLocationClub]);

  return (
    <>
      <div className={styles.wrapper}>
        <p className={styles.title}>Classes</p>
        <div className={styles.section}>
          <div className={styles.search}>
            <Select
              value={selectedClub}
              width="250px"
              onChange={handleClubChange}
              options={clubsOptions}
              hideIndicatorSeparator
              hideBorder
              boldValue
            />
          </div>
        </div>
      </div>
    </>
  );
};

ClassesHeader.propTypes = {
  selectedClub: PropTypes.string,
  setSelectedClub: PropTypes.func.isRequired,
  clubs: PropTypes.array,
  changeSelectedClub: PropTypes.func.isRequired,
};

ClassesHeader.defaultProps = {
  clubs: [],
  selectedClub: '',
};
