export default function loadGoogleMapApi(callback) {
  const isScriptExisted = document.getElementById('googleMapApi');

  if (!isScriptExisted) {
    const script = document.createElement('script');

    script.src =
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyBm-BX9sE6RjRSGi0ICOcR32_1BZExm5qQ&callback=myMap';
    script.id = 'googleMapApi';
    script.onload = () => {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }
  if (isScriptExisted && callback) callback();
}
