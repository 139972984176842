/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { iconPDF } from 'assets/icons';
import { plankImage } from 'assets/background-images';
import { memberStatuses } from 'enums/memberStatuses';
import Input from 'components/shared/Input';
import Button from 'components/shared/Button';
import Spinner from 'components/shared/Spinner';
import ConfirmPopup from 'components/shared/ConfirmPopup';
import MobileContext from 'context/MobileContext';
import styles from './styles.module.css';
import DiscoverNewMembership from '../DiscoverNewMembership';
import CancelPlan from '../CancelPlan';
import FreezePlan from '../FreezePlan';

const activeMembership = 'Virtual & Outdoor';

const convertMemberShip = data => {
  if (!data) return [];
  const arr = data.map(item => {
    if (item.name.toLowerCase().includes(activeMembership))
      return {
        label: item.name,
        value: item.groupId,
        since: item.startDate,
        disabled: false,
      };
    return {
      label: item.name,
      value: item.groupId,
      since: item.startDate,
      disabled: true,
    };
  });
  return arr;
};

const SubscriptionPlan = ({
  profile,
  membership,
  loadAgreement,
  preselectFreezeTopic,
}) => {
  const isMobile = useContext(MobileContext);
  const history = useHistory();
  const balance = useSelector(({ billing }) => billing?.billing?.balData?.combined);
  const memberStatus = useSelector(({ auth }) => auth.user.memberStatus);
  const options = convertMemberShip(membership);
  const [currentMembership, setCurrentMemberShip] = useState({});
  const [openDiscoverMembership, setOpenDiscoverMembership] = useState('');
  const [openCancelMembership, setOpenCancelMembership] = useState(false);
  const [openFreezeReasonPopup, setFreezeReasonPopup] = useState(false);
  const [openBillingPopup, setOpenBillingPopup] = useState(false);

  useEffect(() => {
    const current = options.find(
      option => option.label.toLowerCase() !== activeMembership.toLowerCase()
    );
    if (current) setCurrentMemberShip(current);
  }, [membership]);

  const actions = {
    freeze: {
      primaryAction: () => setFreezeReasonPopup(true),
      secondaryAction: () => setOpenDiscoverMembership(false),
    },
    cancel: {
      primaryAction: () => setFreezeReasonPopup(true),
      secondaryAction: () => {
        if (+balance?.replace('$', '')?.split(',')[0] > 0) {
          setOpenDiscoverMembership(false);
          setOpenBillingPopup(true);
        } else {
          setOpenCancelMembership(true);
          setOpenDiscoverMembership(false);
        }
      },
    },
  };

  const existMembership = (
    <div className={styles.content}>
      <div className={styles.mobileContent}>
        <div className={styles.firstInput}>
          <Input
            value={
              isEmpty(currentMembership) || !currentMembership.label
                ? ''
                : currentMembership.label
            }
            type="text"
            name="memberSince"
            placeholder="Membership Type"
            className={`${styles.input} ${styles.membershipType}`}
            readOnly
          />
        </div>
        <div className={styles.secondInput}>
          <Input
            value={
              isEmpty(currentMembership) || !currentMembership.since
                ? ''
                : moment(currentMembership.since).format('MM/DD/YYYY')
            }
            type="text"
            name="memberSince"
            placeholder="Membership Since"
            className={styles.input}
            readOnly
          />
          <div
            className={styles.containerAgreement}
            onClick={() => {
              loadAgreement(profile.clubId);
            }}
          >
            <span>Membership Agreement</span>
            <img src={iconPDF} alt="iconPDF" />
          </div>
        </div>
      </div>
    </div>
  );

  const freezeMembership = (
    <>
      <div
        className={styles.reactivateSection}
        style={{ backgroundImage: `url(${plankImage})` }}
      >
        <p>Your membership is currently paused.</p>
        <Button
          className={styles.reactivateButton}
          onClick={() => {
            preselectFreezeTopic('reactivate');
            history.push('/contact-us');
          }}
        >
          Re-activate my membership
        </Button>
      </div>
      <div className={styles.freeze}>
        <span
          className={styles.freezeItem}
          onClick={() => {
            if (+balance?.replace('$', '')?.split(',')[0] > 0) {
              setOpenBillingPopup(true);
            } else setOpenCancelMembership(true);
          }}
        >
          Cancel membership
        </span>
      </div>
    </>
  );

  const notExistMembership = (
    <div className={styles.notExistMembership}>
      <p>Sorry, your membership plan not found</p>
      {/* <Button onClick={() => history.push('/contact-us')}>Contact us</Button> */}
    </div>
  );

  return (
    <div className={styles.wrapper}>
      {!isMobile && (
        <h2 className={styles.title}>
          <p>My Subscription Plan</p>
        </h2>
      )}
      {membership?.length > 0 ? existMembership : notExistMembership}
      <DiscoverNewMembership
        type={openDiscoverMembership}
        isOpened={!!openDiscoverMembership}
        setIsOpened={setOpenDiscoverMembership}
        primaryAction={actions[openDiscoverMembership]?.primaryAction}
        secondaryAction={actions[openDiscoverMembership]?.secondaryAction}
      />
      {/* <CancelPlan isOpened={openCancelMembership} setIsOpened={setOpenCancelMembership} />
       <FreezePlan
       isOpened={openFreezeReasonPopup}
       setIsOpened={setFreezeReasonPopup}
       primaryAction={reason => {
       preselectFreezeTopic('freeze');
       history.push(`/contact-us?freezeReason=${reason}`);
       }}
       secondaryAction={() => setFreezeReasonPopup(false)}
       /> */}

      <ConfirmPopup open={openBillingPopup} onClose={() => setOpenBillingPopup(false)}>
        <div className={styles.billingPopup}>
          <h1>Past Due Balance</h1>
          <p>
            It seems that you have a past due balance. Please pay the balance before
            canceling.
          </p>
          <Button
            onClick={() => history.push('/billing-information')}
            className={styles.balanceButton}
          >
            Go to My Balance
          </Button>
          <footer
            className={styles.cancelButton}
            onClick={() => setOpenBillingPopup(false)}
          >
            Cancel
          </footer>
        </div>
      </ConfirmPopup>
    </div>
  );
};
SubscriptionPlan.propTypes = {
  profile: PropTypes.object,
  membership: PropTypes.array,
  loadAgreement: PropTypes.func.isRequired,
  preselectFreezeTopic: PropTypes.func.isRequired,
};
SubscriptionPlan.defaultProps = {
  profile: null,
  membership: null,
};
export default SubscriptionPlan;
