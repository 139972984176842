import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Button from 'components/shared/Button';
import styles from './styles.module.css';

const text = {
  freeze: {
    title: 'Freeze your membership plan at no cost.',
    text: [
      'You are able to Freeze in one-month increments, for up to three months in a calendar year.',
      'While on Freeze, you will not be able to access the gyms or our Digital Features.',
    ],

    primaryButtonText: 'Continue to Freeze',
    secondaryButtonText: 'Go Back',
  },
  cancel: {
    title:
      'Instead of canceling, would you like to Freeze your Membership Plan at no cost?',
    text: [
      'You are able to Freeze in one month increments. Please note that your Freeze will begin on the 1st of the following month.',
      'While on Freeze, you will not be able to access the gyms or our Digital Features.',
    ],
    primaryButtonText: 'Yes, Freeze my Membership Plan',
    secondaryButtonText: 'No, Cancel my Membership Plan',
  },
};

const DiscoverNewMembership = ({
  type,
  isOpened,
  setIsOpened,
  primaryAction,
  secondaryAction,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={styles.modal}
      open={isOpened}
      onClose={() => setIsOpened(!isOpened)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpened}>
        <Paper elevation={0} className={styles.paper}>
          <div className={styles.container}>
            <h2 className={styles.header}>{text[`${type}`]?.title}</h2>
            <ul>
              {text[`${type}`]?.text.map(item => (
                <li className={styles.item}>{item}</li>
              ))}
            </ul>
            <div>
              <Button className={styles.button} onClick={primaryAction}>
                {text[`${type}`]?.primaryButtonText}
              </Button>
              <div className={styles.secondaryButton} onClick={secondaryAction}>
                {text[`${type}`]?.secondaryButtonText}
              </div>
            </div>
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
};

DiscoverNewMembership.propTypes = {
  type: PropTypes.string.isRequired,
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  primaryAction: PropTypes.func.isRequired,
  secondaryAction: PropTypes.func.isRequired,
};

export default DiscoverNewMembership;
