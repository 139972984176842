const awsS3Url = process.env.REACT_APP_AMAZON_S3_STORAGE_URL;

export const cyclingImage = `${awsS3Url}/background-images/cycling.jpg`;
export const loginPageBackground = `${awsS3Url}/background-images/loginPageBackground.jpg`;
export const trxImage = `${awsS3Url}/background-images/trx.jpg`;
export const athleticImage = `${awsS3Url}/background-images/athletic.jpg`;
export const danceImage = `${awsS3Url}/background-images/dance.jpg`;
export const martialImage = `${awsS3Url}/background-images/martial.jpg`;
export const recoveryImage = `${awsS3Url}/background-images/recovery.jpg`;
export const yogaImage = `${awsS3Url}/background-images/yoga.jpg`;
export const classDetailsImage = `${awsS3Url}/background-images/classDetails.jpg`;
export const registrationBackground = `${awsS3Url}/background-images/registration.jpg`;
export const reservationBannerDesktop = `${awsS3Url}/background-images/reservationBannerDesktop.png`;
export const reservationBannerMobile = `${awsS3Url}/background-images/reservationBannerMobile.png`;
export const sessionsCard = `${awsS3Url}/background-images/bannerOnDemand.jpg`;
export const classesCard = `${awsS3Url}/background-images/classesCard_compressed.jpg`;
export const nutritionImage = `${awsS3Url}/background-images/nutritionImage.jpg`;
export const liveClass = `${awsS3Url}/background-images/liveClass.jpg`;
export const livePersonalSessions = `${awsS3Url}/background-images/livePersonalSessions.jpg`;
export const loginImage = `${awsS3Url}/background-images/welcome.jpg`;
export const videoOnDemand = `${awsS3Url}/background-images/video_on_demand.png`;
export const myAccountDigital = `${awsS3Url}/background-images/myaccount_digital.png`;
export const bridgePicture = `${awsS3Url}/background-images/sign_in.jpg`;
export const analyticsVisitsDesktop = `${awsS3Url}/background-images/analyticsVisits_desktop.jpg`;
export const analyticsSessionsDesktop = `${awsS3Url}/background-images/analyticsSessions_desktop.jpg`;
export const analyticsVisitsMobile = `${awsS3Url}/background-images/analyticsVisits_mobile.jpg`;
export const analyticsSessionsMobile = `${awsS3Url}/background-images/analyticsSessions_mobile.jpg`;
export const tutorial = `${awsS3Url}/background-images/tutorial_dnauta.png`;
export const bridge = `${awsS3Url}/background-images/bridge_compressed.png`;
export const youAreInvited = `${awsS3Url}/background-images/youAreInvited.jpg`;
export const Nutrition = `${awsS3Url}/background-images/Nutrition.png`;
export const Workout = `${awsS3Url}/background-images/Workout.png`;
export const MyTrainings = `${awsS3Url}/background-images/MyTrainings.png`;
export const plankImage = `${awsS3Url}/background-images/plank.png`;
export const inBody = `${awsS3Url}/background-images/inBodyImg.png`;
export const newMemberShipPlan = `${awsS3Url}/background-images/newMemberShipPlan.png`;
export const newMemberShipPlanFull = `${awsS3Url}/background-images/newMemberShipPlanFull.png`;
export const newOnDemand = `${awsS3Url}/background-images/newOnDemand.jpg`;
export const newOnDemand2 = `${awsS3Url}/background-images/newOnDemand-2.jpg`;
export const newOnDemandFull = `${awsS3Url}/background-images/newOnDemandFull.jpg`;
export const newOnDemandFull2 = `${awsS3Url}/background-images/newOnDemandFull2.jpg`;
export const inBodyApp = `${awsS3Url}/background-images/inbody.png`;
export const InBodyCard = `${awsS3Url}/background-images/InBodyCard.jpg`;
export const localPerks = `${awsS3Url}/background-images/local_perks.jpg`;
export const trainingPageInbody = `${awsS3Url}/background-images/training_pg_InBody.jpg`;
export const trainingPageTrainers = `${awsS3Url}/background-images/training_pg_Trainers.jpg`;
