import React from 'react';
import PropTypes from 'prop-types';

import { horizontalLogo } from 'assets/logos';
import { registrationBackground } from 'assets/background-images';
import styles from './style.module.css';

const ExperienceUsLayout = ({ children }) => {
  const goToHomePage = () => {
    window.location = 'https://www.fitnesssf.com/';
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.formWrapper}>
        <div className={styles.logoWrapper} onClick={goToHomePage}>
          <img src={horizontalLogo} alt="logo" className={styles.logo} />
        </div>
        {children}
      </div>
      <div className={styles.image}>
        <img src={registrationBackground} alt="background" />
      </div>
    </div>
  );
};

ExperienceUsLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ExperienceUsLayout;
