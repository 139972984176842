/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import styles from './styles.module.css';

const Filtration = ({ categories, setCategories }) => {
  return (
    <div className={styles.categoriesWrapper}>
      {categories.map(el => (
        <div
          className={`${styles.category} ${el.isSelected && styles.selectedCategory}`}
          style={{ height: !el.icon && 65 }}
          onClick={() => {
            const list = cloneDeep(categories).map(item => {
              if (item.title === el.title) item.isSelected = !el.isSelected;
              return item;
            });
            setCategories(list);
          }}
        >
          <p>{el.title}</p>
          {el?.icon && <img src={el.icon} alt=" " />}
        </div>
      ))}
    </div>
  );
};

Filtration.propTypes = {
  categories: PropTypes.array.isRequired,
  setCategories: PropTypes.func.isRequired,
};

export default Filtration;
