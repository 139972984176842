import React from 'react';
import moment, { duration } from 'moment';
import PropTypes from 'prop-types';
import { locationIcon, hours, profileIcon, aimIcon } from 'assets/icons';
import styles from './styles.module.css';

export const CalendarCard = ({ groupEx, setIsDetailsOpened }) => {
  const { classInfo, startTime, endTime, date, trainerName, isLive, isVirtual } = groupEx;

  const { club, name, programName } = classInfo;

  const calculateDuration = () => {
    const classDuration = duration(
      moment(`${date} ${endTime}`).valueOf() - moment(`${date} ${startTime}`).valueOf()
    );
    return `(${classDuration.hours() * 60 + classDuration.minutes()}min)`;
  };

  return (
    <div onClick={() => setIsDetailsOpened(true)} className={styles.cardContainer}>
      <div className={styles.column}>
        <h1>{programName || name}</h1>
        <p className={styles.subTitle}>
          <img src={isLive || isVirtual ? aimIcon : locationIcon} alt="location" />
          <span>{isLive || isVirtual ? 'Live Class' : club.name}</span>
        </p>
        <p className={styles.subTitle}>
          <img src={hours} alt="hours" />
          <span>
            {moment(`${date} ${startTime}`).format('h:mma')} {calculateDuration()}
          </span>
        </p>
        <p className={styles.subTitle}>
          {trainerName && (
            <img className={styles.instructorIcon} src={profileIcon} alt="instructor" />
          )}
          <span>{trainerName}</span>
        </p>
      </div>
    </div>
  );
};

CalendarCard.propTypes = {
  groupEx: PropTypes.object.isRequired,
  setIsDetailsOpened: PropTypes.func.isRequired,
};
