import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { arrowRight } from 'assets/icons';
import moment from 'moment';
import styles from './styles.module.css';

const Notification = ({ title, isRead, content, date, link }) => {
  const convertedDate = moment(date)
    .local()
    .fromNow();
  return (
    <>
      {link ? (
        <Link to={link}>
          <div className={styles.wrapper}>
            <div className={styles.linkWrapper}>
              <img className={styles.link} src={arrowRight} alt="Forward" />
            </div>

            <p className={[isRead ? styles.titleIsRead : styles.title].join(' ')}>
              {title}
            </p>
            <p className={[styles.content, !isRead && styles.contentIsRead].join(' ')}>
              {content}
            </p>
            {convertedDate ? <p className={styles.date}>{convertedDate}</p> : null}
          </div>
        </Link>
      ) : (
        <div className={styles.wrapper}>
          <p className={[isRead ? styles.titleIsRead : styles.title].join(' ')}>
            {title}
          </p>
          <p className={[styles.content, !isRead && styles.contentIsRead].join(' ')}>
            {content}
          </p>
          {convertedDate ? <p className={styles.date}>{convertedDate}</p> : null}
        </div>
      )}
    </>
  );
};

Notification.propTypes = {
  title: PropTypes.string,
  isRead: PropTypes.bool,
  content: PropTypes.string,
  date: PropTypes.string,
  link: PropTypes.string,
};

Notification.defaultProps = {
  title: '',
  isRead: true,
  content: '',
  date: '',
  link: '',
};

export default Notification;
