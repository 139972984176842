/* eslint-disable consistent-return */
import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import classGoals from 'enums/classGoals';

export const filteringClasses = (classes, filters) => {
  const { categories, isShowVirtualClasses, dayTime, goals } = filters;
  const classesCopy = cloneDeep(classes);

  const checkCategories = categories?.length > 0;
  const checkDayTime = dayTime?.length > 0;
  const checkGoals = goals?.length > 0;

  const filteredList = compact(
    classesCopy.filter(el => {
      const { description, classInfo, isLive, isVirtual, startTime } = el;
      let setElement = false;
      // apply categories
      if (checkCategories) {
        const elName = classInfo?.programName?.toLowerCase().split(' ');
        if (categories.includes('other')) setElement = true;
        else
          for (let i = 0; i < elName.length; i++) {
            if (categories.includes(elName[i].trim())) setElement = true;
          }
      }

      // set virtual classes
      if (isShowVirtualClasses) {
        if (isLive || isVirtual) setElement = true;
        else return null;
      }

      // apply day time
      if (checkDayTime) {
        const startTimeNumber = +startTime.substr(0, 5).replace(':', '');
        for (let i = 0; i < dayTime.length; i++) {
          if (
            startTimeNumber >= dayTime[i].valueFrom &&
            startTimeNumber <= dayTime[i].valueTo
          )
            setElement = true;
        }
      }

      // apply goals
      if (checkGoals) {
        const goalsList = [];
        for (let i = 0; i < goals.length; i++) {
          goalsList.push(...classGoals[goals[i]]);
        }

        const elName = classInfo?.programName?.toLowerCase().split(' ');
        const elDescription = description?.toLowerCase().split(' ');
        for (let i = 0; i < elName.length; i++) {
          if (goalsList.includes(elName[i].trim())) setElement = true;
        }

        for (let i = 0; i < elDescription.length; i++) {
          if (goalsList.includes(elDescription[i].trim())) setElement = true;
        }
      }

      if (
        checkCategories || checkDayTime || checkGoals || isShowVirtualClasses
          ? setElement
          : !setElement
      )
        return el;
    })
  );
  return filteredList;
};

export const searchingClasses = (classes, searchValue) => {
  const filteredList = [];
  for (let i = 0; i < classes.length; i++) {
    if (
      classes[i]?.studioName
        .toString()
        .toLowerCase()
        .includes(searchValue.toString().toLowerCase()) ||
      classes[i]?.classInfo?.programName
        .toString()
        .toLowerCase()
        .includes(searchValue.toString().toLowerCase()) ||
      classes[i].trainerName
        .toString()
        .toLowerCase()
        .includes(searchValue.toString().toLowerCase())
    )
      filteredList.push(classes[i]);
  }
  return filteredList;
};
