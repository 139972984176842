import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import history from 'store/history';
import vaccineStatuses from 'enums/vaccineStatuses';
import TakePhotoSection from './TakePhotoSection';
import PhotoSourceSection from './PhotoSourceSection';
import styles from './style.module.css';
import FinalSection from './FinalSection';

const VaccineVerification = ({
  step,
  setStep,
  user,
  setVaccineStatus,
  checkContactId,
  personByContactId,
  isAuth,
  vaccineStatus,
}) => {
  const [photo, takePhoto] = useState('');
  const [preview, setPreview] = useState('');
  const [contactId, setContactId] = useState('');
  const [isAgree, setIsAgree] = useState(false);

  useEffect(() => {
    const params = history?.location?.search;
    if (params && !isAuth) {
      checkContactId(params, false);
    }
  }, []);

  useEffect(() => {
    if (
      (vaccineStatus === vaccineStatuses.pendingConfirmation ||
        vaccineStatus === vaccineStatuses.confirmed) &&
      !isAuth
    )
      setStep(2);
  }, [vaccineStatus]);

  useEffect(() => {
    if (user && user.sfId) setContactId(user.sfId);
  }, [user]);

  useEffect(() => {
    if (personByContactId?.name) setContactId(personByContactId.sfId);
    else if (personByContactId?.error) history.push('/');
  }, [personByContactId]);

  const handleSubmit = () => {
    if (photo) {
      const sfId = isAuth ? user.sfId : contactId;
      const idCA = isAuth ? user.idCA : '';

      const formData = new FormData();
      formData.append('files', photo, 'vaccine-proof.jpg');
      formData.append('sfId', sfId);
      formData.append('vaccineStatus', vaccineStatuses.pendingConfirmation);

      setVaccineStatus({
        userGroupBody: { idCA, sfId },
        vaccineStatusBody: formData,
        isAuth,
      });
    }
  };

  const steps = [
    <PhotoSourceSection
      photo={photo}
      isAuth={isAuth}
      takePhoto={takePhoto}
      preview={preview}
      setPreview={setPreview}
      onSubmit={handleSubmit}
      onNext={() => setStep(1)}
      isAgree={isAgree}
      setIsAgree={setIsAgree}
      contactId={contactId}
    />,
    <TakePhotoSection
      photo={photo}
      takePhoto={takePhoto}
      preview={preview}
      setPreview={setPreview}
      onSubmit={handleSubmit}
      onBack={() => setStep(0)}
      isAgree={isAgree}
      setIsAgree={setIsAgree}
      contactId={contactId}
      isAuth={isAuth}
    />,
    <FinalSection />,
  ];

  return (
    <div>
      <div className={styles.content}>{steps[step]}</div>
    </div>
  );
};

VaccineVerification.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  setVaccineStatus: PropTypes.func.isRequired,
  checkContactId: PropTypes.func.isRequired,
  personByContactId: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  isAuth: PropTypes.bool.isRequired,
  vaccineStatus: PropTypes.string.isRequired,
};

export default VaccineVerification;
