/* eslint-disable no-use-before-define */
import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import NotificationRow from 'components/ProfilePage/Settings/NotificationRow';
import NotificationRowGroup from 'components/ProfilePage/Settings/NotificationRowGroup';
import Spinner from 'components/shared/Spinner';
import Button from 'components/shared/Button';
import MobileContext from 'context/MobileContext';
import styles from './styles.module.css';

const groupCategories = categories => {
  const trainingIds = [6];
  const paymentIds = [1, 2, 4, 5];
  // const messaging = [7];
  const grouped = {
    trainingSessions: {
      name: 'Training Sessions',
      categories: [],
    },
    payment: {
      name: 'Payment Events',
      categories: [],
    },
    messaging: {
      name: 'Messaging',
      categories: [],
    },
  };
  categories.forEach(category => {
    if (trainingIds.includes(category.type))
      grouped.trainingSessions.categories.push(category);
    if (paymentIds.includes(category.type)) grouped.payment.categories.push(category);
    // hide chat notifications settings
    // if (messaging.includes(category.type)) grouped.messaging.categories.push(category);
  });
  return grouped;
};
const MainContent = ({
  requestNotificationSettings,
  pushNotifications,
  emailNotifications,
  categories,
  changeNotificationSettings,
  saveNotificationSettings,
  isRequesting,
  isSaveRequesting,
  resetNotificationSettings,
}) => {
  const isMobile = useContext(MobileContext);

  useEffect(() => {
    requestNotificationSettings();
  }, [requestNotificationSettings]);

  useEffect(() => {}, []);

  const grouped = groupCategories(categories);
  return (
    <HeaderFooterLayout profile="Notification Settings">
      {isRequesting ? (
        <Spinner isWhite />
      ) : (
        <>
          {!isMobile && <p className={styles.header}>Notification Settings</p>}
          {categories?.length > 0 && (
            <div className={styles.wrapper}>
              <NotificationRow isInitial />
              <NotificationRowGroup
                name={grouped.messaging.name}
                categories={grouped.messaging.categories}
                emailNotifications={emailNotifications}
                pushNotifications={pushNotifications}
                changeNotificationSettings={changeNotificationSettings}
              />
              <NotificationRowGroup
                name={grouped.trainingSessions.name}
                categories={grouped.trainingSessions.categories}
                emailNotifications={emailNotifications}
                pushNotifications={pushNotifications}
                changeNotificationSettings={changeNotificationSettings}
              />
              <NotificationRowGroup
                name={grouped.payment.name}
                categories={grouped.payment.categories}
                emailNotifications={emailNotifications}
                pushNotifications={pushNotifications}
                changeNotificationSettings={changeNotificationSettings}
              />
              <div className={styles.footerWrapper}>
                <div className={styles.footer}>
                  <Button
                    type="submit"
                    width="200px"
                    isRequesting={isSaveRequesting}
                    className={styles.saveButton}
                    onClick={() => {
                      saveNotificationSettings();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    styleType="tertiary"
                    width="200px"
                    onClick={() => {
                      resetNotificationSettings();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </HeaderFooterLayout>
  );
};

MainContent.propTypes = {
  requestNotificationSettings: PropTypes.func.isRequired,
  resetNotificationSettings: PropTypes.func.isRequired,
  pushNotifications: PropTypes.array,
  emailNotifications: PropTypes.array,
  categories: PropTypes.array,
  changeNotificationSettings: PropTypes.func.isRequired,
  saveNotificationSettings: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  isSaveRequesting: PropTypes.bool.isRequired,
};
MainContent.defaultProps = {
  categories: [],
  pushNotifications: null,
  emailNotifications: null,
};
export default MainContent;
