/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Field from 'components/shared/Field';
import Button from 'components/shared/Button';
import { arrowLeft } from 'assets/icons';
import {
  AppBar,
  Box,
  Dialog,
  Fade,
  LinearProgress,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TermsPopup from '../TermsPopup';
import styles from './style.module.css';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    background: '#FF5F1F',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    backgroundColor: 'red',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="up" ref={ref} {...props} />;
});

const TermsConditions = ({
  url,
  plan,
  onNext,
  userName,
  card,
  loadBillingUrl,
  addCard,
  onBack,
}) => {
  const [isAgree, setIsAgree] = useState(false);
  const [cardInfo, setCardInfo] = useState({});
  const [Gatewayopen, setGatewayOpen] = React.useState(false);

  const classes = useStyles();
  const handleClose = () => {
    setGatewayOpen(false);
  };

  function assignListeners() {
    window.addEventListener(
      'message',
      function listener(event) {
        if (event.origin !== process.env.REACT_APP_CLUB_AUTOMATION_URL) {
          return;
        }
        const {
          data: {
            cc_number,
            expiration_year,
            expiration_month,
            cardConnect_expiry,
            cc_merchant_unique_id,
            expiration_date,
          },
        } = event;
        if (!isEmpty(cc_merchant_unique_id)) {
          window.messageReceived = true;
          setCardInfo({
            cc_number,
            expiration_year,
            expiration_month,
            cardConnect_expiry,
            cc_merchant_unique_id,
            expiration_date,
          });
          handleClose();
        }
      },
      false
    );
  }

  const openPayment = () => {
    if (Object.keys(card).length === 0) {
      setGatewayOpen(true);
      loadBillingUrl();
      assignListeners();
    } else onNext();
  };
  const submitInfo = () => {
    const payload = {
      cardNumber: cardInfo ? cardInfo.cc_number : card?.cardNumber || '',
      billingName: userName,
      isPrimary: true,
      i4goUniqueId: card?.i4goUniqueId || cardInfo.cc_merchant_unique_id, // cardInfo.i4go_uniqueid,
      i4goCardType: card?.i4goCardType || cardInfo.i4go_cardtype,
      cardExpirationMonth: card?.cardExpirationMonth || cardInfo.expiration_month,
      cardExpirationYear: card?.cardExpirationYear || cardInfo.expiration_year,
    };
    addCard(payload);
    onNext();
  };

  useEffect(() => {
    if (Object.keys(cardInfo).length !== 0) submitInfo();
  }, [cardInfo]);

  return (
    <div className={styles.container}>
      <Dialog
        fullScreen
        open={Gatewayopen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar position="fixed">
          <Toolbar variant="dense">
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" component="div">
              Add New Card
            </Typography>
          </Toolbar>
        </AppBar>
        {url ? (
          <iframe
            title="payment"
            id="payment"
            src={`${url}&parentUrl=${window.location.href}`}
            width="700px"
            style={{
              display: 'block',
              alignSelf: 'center',
              overflow: 'hidden',
              position: 'relative',
              marginLeft: '-20px',
              marginTop: '40px',
            }}
            scrolling="no"
            height={document.documentElement.clientHeight - 50}
          />
        ) : (
          <div className={classes.root}>
            <Box sx={{ width: '100%' }}>
              <LinearProgress color="secondary" />
            </Box>
          </div>
        )}
      </Dialog>
      <div className={styles.scroll}>
        <p
          className={styles.card}
          dangerouslySetInnerHTML={{ __html: plan?.contractDetails }}
        />
      </div>
      <div className={styles.checkbox}>
        <Field type="checkbox" value={isAgree} onChange={() => setIsAgree(!isAgree)} />
        <div onClick={() => setIsAgree(!isAgree)}>I agree</div>
      </div>
      <div className={styles.buttonsContainer}>
        <div className={styles.back} onClick={onBack}>
          <img src={arrowLeft} alt="Back" className={styles.arrow} />
          <span>Back</span>
        </div>
        <Button disabled={!isAgree} className={styles.button} onClick={openPayment}>
          Accept Terms and Conditions
        </Button>
      </div>

      <TermsPopup open />
    </div>
  );
};

TermsConditions.propTypes = {
  plan: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  card: PropTypes.object.isRequired,
  userName: PropTypes.string.isRequired,
  loadBillingUrl: PropTypes.func.isRequired,
  addCard: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default TermsConditions;
