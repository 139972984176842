import { connect } from 'react-redux';
import CheckHistory from 'components/ProfilePage/CheckHistory';
import historyActions from 'store/history/actions';
import pdfActions from 'store/pdf/actions';
import Actions from 'store/analytics/actions';

const mapStateToProps = state => {
  return {
    checkHistory: state.checkHistory.history?.response
      ? state.checkHistory.history.response
      : [],
    visits: state.analytics.visits,
    amount: state.checkHistory.history?.amount ? state.checkHistory.history.amount : null,
    idCA: state.auth.user.idCA,
    isRequestingPDF: state.pdf.isRequesting,
    error: state.checkHistory.error,
    errorPDF: state.pdf.error,
    isRequesting: state.checkHistory.isRequesting,
  };
};

const mapDispatchToProps = {
  loadHistory: historyActions.Creators.loadHistoryRequest,
  resetHistory: historyActions.Creators.loadHistorySuccess,
  loadHistoryPDF: pdfActions.Creators.loadHistoryPDFRequest,
  loadActivity: Actions.Creators.loadActivityRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckHistory);
