import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/shared/Select';
import Field from 'components/shared/Field';
import Button from 'components/shared/Button';
import ConfirmPopup from 'components/shared/ConfirmPopup';
import styles from './style.module.css';

const AddReservation = ({
  clubsOptions,
  addReservation,
  open,
  onClose,
  isRequesting,
}) => {
  const [data, setData] = useState({});
  useEffect(() => {
    setData({ ...data, clubId: clubsOptions[0]?.sfId });
  }, [clubsOptions]);

  return (
    <ConfirmPopup open={open} onClose={onClose} isRequesting={isRequesting}>
      <div className={styles.container}>
        <div className={styles.select}>
          <Select
            hideIndicatorSeparator
            options={clubsOptions}
            value={data?.clubId}
            onChange={e => setData({ ...data, clubId: e.sfId })}
          />
        </div>

        <Field
          value={data?.userId}
          onChange={e => setData({ ...data, userId: e.target.value })}
          name="UserId"
          placeholder="User Id"
          className={styles.input}
        />
        <Field
          value={data?.date}
          onChange={e => setData({ ...data, date: e.target.value })}
          placeholder="date (YYYY-MM-DD)"
          name="date"
          className={styles.input}
        />
        <Field
          type="number"
          value={data?.dayOfWeek}
          onChange={e => setData({ ...data, dayOfWeek: e.target.value })}
          placeholder="Day of week"
          name="dayOfWeek"
          className={styles.input}
        />
        <Field
          type="number"
          value={data?.time}
          onChange={e => setData({ ...data, time: e.target.value })}
          placeholder="time"
          name="time"
          className={styles.input}
        />
        <Field
          type="number"
          value={data?.room}
          onChange={e => setData({ ...data, room: e.target.value })}
          placeholder="room Id"
          name="room"
          className={styles.input}
        />

        <Button
          className={styles.button}
          onClick={() => {
            addReservation({ ...data });
            setData({ clubId: clubsOptions[0]?.sfId });
          }}
        >
          Add
        </Button>
      </div>
    </ConfirmPopup>
  );
};

AddReservation.propTypes = {
  clubsOptions: PropTypes.array.isRequired,
  addReservation: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

export default AddReservation;
