import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadClubsStatisticRequest: ['clubId'],
  loadClubsStatisticSuccess: ['data'],
  loadClubsStatisticFailure: ['error'],

  selectClub: ['clubId'],
});

const clubActions = {
  Types,
  Creators,
};

export default clubActions;
