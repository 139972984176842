import React from 'react';
import PropTypes from 'prop-types';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Button from 'components/shared/Button';
import { arrowLeft } from 'assets/icons';
import styles from './style.module.css';

const TakePhotoSection = ({ photo, takePhoto, onNext, onBack }) => {
  return (
    <div className={styles.container}>
      {photo ? (
        <div className={styles.imgSection}>
          <img src={photo} className={styles.img} alt="photos" />
          <Button className={styles.button} onClick={() => takePhoto('')}>
            To try one more time
          </Button>
        </div>
      ) : (
        <Camera
          onTakePhoto={dataUri => {
            takePhoto(dataUri);
          }}
          idealFacingMode={FACING_MODES.USER}
          idealResolution={{ width: 350, height: 350 }}
          imageType={IMAGE_TYPES.JPG}
          sizeFactor={1}
        />
      )}

      <div className={styles.buttonsContainer}>
        <div className={styles.back} onClick={onBack}>
          <img src={arrowLeft} alt="Back" className={styles.arrow} />
          <span>Back</span>
        </div>
        <Button disabled={!photo} className={styles.button} onClick={onNext}>
          Next
        </Button>
      </div>
    </div>
  );
};

TakePhotoSection.propTypes = {
  photo: PropTypes.string.isRequired,
  takePhoto: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default TakePhotoSection;
