import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadAdminReservationsRequest: ['club'],
  loadAdminReservationsSuccess: ['reservations'],
  loadAdminReservationsFailure: ['error'],

  removeReservationsRequest: ['reservationsId'],
  removeReservationsSuccess: ['ids'],
  removeReservationsFailure: ['error'],

  editReservationRequest: ['reservation'],
  editReservationSuccess: ['reservation'],
  editReservationFailure: ['error'],

  addReservationRequest: ['reservation'],
  addReservationSuccess: ['reservation'],
  addReservationFailure: ['error'],

  searchUserReservations: ['searchValue'],

  searchUserRequest: ['params'],
  searchUserSuccess: ['user'],
  searchUserFailure: ['error'],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
