import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Tabs } from 'components';
import { PopUp } from 'components/shared/PopUp';
import Select from 'components/shared/Select';
import Input from 'components/shared/Input';
import { SessionDetails, SessionCallPopUpInner, SessionCallModal } from 'containers';
import MyTrainersTab from 'components/TrainingPage/MyTrainersTab';
import TrainersTab from 'components/TrainingPage/TrainersTab';
import TrainerDetails from 'components/TrainingPage/TrainerDetails';
import InBodyTab from 'components/AnalyticsPage/InBodyTab';
import MobileContext from 'context/MobileContext';
import {
  analyticsSessionsDesktop,
  trainingPageTrainers,
  trainingPageInbody,
} from 'assets/background-images';
import { Modal } from 'components/shared/Modal';
import CancelSessionSuccessModal from 'components/shared/SessionDetails/CancelSessionModals/CancelSessionSuccessModal';
import CancelSessionModal from 'components/shared/SessionDetails/CancelSessionModals/CancelSessionModal';
import { back } from 'assets/icons';
import history from 'store/history';
import styles from './style.module.css';

const convertClubs = data => {
  if (!data) return [];
  const locations = data.map(item => {
    return { label: item.name, value: item.clubAutomationClubId };
  });
  locations.push({ label: 'All locations', value: 'all' });
  return locations.sort((a, b) => (a.label > b.label ? 1 : -1));
};

const MainContent = ({
  trainers,
  myPersonalTrainers,
  selectedClubId,
  favoriteClubId,
  selectTrainingFavoriteClub,
  changeTrainingSearch,
  searchValue,
  clubs,
  sessions,
  setTargetSession,
  setTrainerId,
  handleOpenCallPopUp,
  isSessionCallPopUpOpened,
  cleanCancelSessionEmail,
  sendCancelSessionEmail,
  preselectFreezeTopic,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [mobileTabIsOpened, setOpenMobileTab] = useState(false);
  const [targetTabTitle, setTargetTabTitle] = useState(null);
  const [popUpIsOpened, setOpenPopUp] = useState(false);
  const [sessionsPopUpIsOpened, setOpenSessionsPopUp] = useState(false);
  const [selectedTrainer, setSelectedTrainer] = useState({});
  const [isCancelSessionSuccessModal, setCancelSessionSuccessModal] = useState(false);
  const [cancelSessionOpened, setCancelSession] = useState(false);
  const isOpenCanceledSessionModal = useSelector(state => state.training.isSentEmail);
  const [isHideOtherLocations, hideOtherLocations] = useState(false);

  const locations = convertClubs(clubs);
  const isMobile = useContext(MobileContext);

  useEffect(() => {
    if (history?.location?.search?.substr(5)) {
      if (history?.location?.search?.substr(5) === 'inbody') {
        setActiveTabIndex(myPersonalTrainers?.length > 0 ? 2 : 1);
        setOpenMobileTab(true);
      } else if (history?.location?.search?.substr(5) === 'trainers') {
        setActiveTabIndex(myPersonalTrainers?.length > 0 ? 1 : 0);
        setOpenMobileTab(true);
      }
    }
  }, []);

  const tabsData =
    myPersonalTrainers?.length > 0
      ? [
          {
            name: 'My Personal Training',
            index: 0,
            image: analyticsSessionsDesktop,
            mobileTabHeader: 'My Personal Training',
          },
          {
            name: 'Trainers',
            index: 1,
            image: trainingPageTrainers,
            mobileTabHeader: 'Our trainers',
          },
          {
            name: 'InBody',
            index: 2,
            image: trainingPageInbody,
            mobileTabHeader: 'My Activity',
          },
        ]
      : [
          {
            name: 'Trainers',
            index: 0,
            image: trainingPageTrainers,
            mobileTabHeader: 'Our trainers',
          },
          {
            name: 'InBody',
            index: 1,
            image: trainingPageInbody,
            mobileTabHeader: 'My Activity',
          },
        ];
  const handleSessionInfoClick = values => {
    setOpenSessionsPopUp(true);
    setTargetSession(values);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (searchValue !== '') {
      hideOtherLocations(true);
    } else {
      hideOtherLocations(false);
    }
  }, [searchValue]);

  useEffect(() => {
    if (isOpenCanceledSessionModal) setCancelSessionSuccessModal(true);
  }, [isOpenCanceledSessionModal]);

  useEffect(() => {
    tabsData.map(item => {
      if (activeTabIndex === item.index) {
        setTargetTabTitle(item.mobileTabHeader);
      }
    });
  }, [activeTabIndex]);

  const handleOpenPopUp = () => {
    setOpenPopUp(!popUpIsOpened);
  };

  const handleSelectTrainer = trainer => {
    const { sfId } = clubs.find(({ name }) => trainer.location === name);
    setSelectedTrainer({ ...trainer, clubId: sfId });
  };

  const closeCancelSessionSuccessModal = () => {
    setCancelSessionSuccessModal(false);
    cleanCancelSessionEmail();
  };
  return (
    <>
      {isMobile ? (
        <>
          {mobileTabIsOpened ? (
            <>
              <div className={styles.wrapperTabTitle}>
                <div onClick={() => setOpenMobileTab(false)}>
                  <img src={back} alt="back" />
                </div>
                <span className={styles.title}>{targetTabTitle}</span>
              </div>
              {((myPersonalTrainers?.length > 0 && activeTabIndex === 1) ||
                (myPersonalTrainers?.length === 0 && activeTabIndex === 0)) && (
                <div className={styles.inputsWrapper}>
                  <Select
                    hideBorder
                    hideIndicatorSeparator
                    width="50%"
                    options={locations}
                    value={selectedClubId}
                    onChange={e => {
                      selectTrainingFavoriteClub(e.value);
                    }}
                    boldValue
                    containerStyle={isMobile ? { width: '100%' } : {}}
                  />
                  <div className={styles.searchSection}>
                    <Input
                      type="search"
                      value={searchValue}
                      onChange={e => {
                        changeTrainingSearch(e.target.value);
                      }}
                      placeholder="Search"
                      className={isMobile ? styles.searchInput : null}
                    />
                  </div>
                </div>
              )}
              {myPersonalTrainers?.length > 0
                ? {
                    0: (
                      <MyTrainersTab
                        myPersonalTrainers={myPersonalTrainers}
                        selectedClubId={selectedClubId}
                        locations={locations}
                        selectTrainer={handleSelectTrainer}
                        setIsOpened={handleOpenPopUp}
                        sessions={sessions}
                        handleSessionInfoClick={handleSessionInfoClick}
                        setTrainerId={setTrainerId}
                        handleOpenCallPopUp={handleOpenCallPopUp}
                        isMobile={isMobile}
                      />
                    ),
                    1: (
                      <TrainersTab
                        trainers={trainers}
                        selectedClubId={selectedClubId}
                        favoriteClubId={favoriteClubId}
                        locations={locations}
                        setIsOpened={handleOpenPopUp}
                        selectTrainer={handleSelectTrainer}
                        setTrainerId={setTrainerId}
                        isMobile={isMobile}
                        isHideOtherLocations={isHideOtherLocations}
                      />
                    ),
                    2: <InBodyTab />,
                  }[activeTabIndex]
                : {
                    0: (
                      <TrainersTab
                        trainers={trainers}
                        selectedClubId={selectedClubId}
                        favoriteClubId={favoriteClubId}
                        locations={locations}
                        setIsOpened={handleOpenPopUp}
                        selectTrainer={handleSelectTrainer}
                        setTrainerId={setTrainerId}
                        isMobile={isMobile}
                        isHideOtherLocations={isHideOtherLocations}
                      />
                    ),
                    1: <InBodyTab />,
                  }[activeTabIndex]}
            </>
          ) : (
            <div className={styles.wrapper}>
              {tabsData.map(item => {
                return (
                  <div
                    className={styles.wrapperItem}
                    style={{ backgroundImage: `url(${item.image})` }}
                    onClick={() => {
                      setActiveTabIndex(item.index);
                      setOpenMobileTab(true);
                    }}
                  >
                    <div className={styles.wrapperShadow}>
                      <span className={styles.title}>{item.name}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      ) : (
        <>
          <div className={styles.headerBackground}>
            <div className={styles.headerSection}>
              <span className={styles.title}>Training </span>
              <Tabs
                tabsData={tabsData}
                activeTabIndex={activeTabIndex}
                setActiveTabIndex={setActiveTabIndex}
              />

              {((myPersonalTrainers?.length > 0 && activeTabIndex === 1) ||
                (myPersonalTrainers?.length === 0 && activeTabIndex === 0)) && (
                <div className={styles.inputsWrapper}>
                  <Select
                    hideBorder
                    hideIndicatorSeparator
                    width="250px"
                    options={locations}
                    value={selectedClubId}
                    onChange={e => {
                      selectTrainingFavoriteClub(e.value);
                    }}
                    boldValue
                    containerStyle={isMobile ? styles.select : {}}
                  />
                  <Input
                    type="search"
                    width="250px"
                    value={searchValue}
                    onChange={e => {
                      changeTrainingSearch(e.target.value);
                    }}
                    placeholder="Search"
                  />
                </div>
              )}
            </div>
          </div>

          {myPersonalTrainers?.length > 0 &&
            {
              0: (
                <MyTrainersTab
                  myPersonalTrainers={myPersonalTrainers}
                  selectedClubId={selectedClubId}
                  locations={locations}
                  selectTrainer={handleSelectTrainer}
                  setIsOpened={handleOpenPopUp}
                  sessions={sessions}
                  handleSessionInfoClick={handleSessionInfoClick}
                  setTrainerId={setTrainerId}
                  handleOpenCallPopUp={handleOpenCallPopUp}
                  isMobile={isMobile}
                />
              ),
              1: (
                <TrainersTab
                  trainers={trainers}
                  selectedClubId={selectedClubId}
                  favoriteClubId={favoriteClubId}
                  locations={locations}
                  setIsOpened={handleOpenPopUp}
                  selectTrainer={handleSelectTrainer}
                  setTrainerId={setTrainerId}
                  isHideOtherLocations={isHideOtherLocations}
                  isMobile={isMobile}
                />
              ),
              2: <InBodyTab />,
            }[activeTabIndex]}
          {myPersonalTrainers?.length === 0 &&
            {
              0: (
                <TrainersTab
                  trainers={trainers}
                  selectedClubId={selectedClubId}
                  favoriteClubId={favoriteClubId}
                  locations={locations}
                  setIsOpened={handleOpenPopUp}
                  selectTrainer={handleSelectTrainer}
                  setTrainerId={setTrainerId}
                  isHideOtherLocations={isHideOtherLocations}
                  isMobile={isMobile}
                />
              ),
              1: <InBodyTab />,
            }[activeTabIndex]}
        </>
      )}
      <PopUp
        isOpened={popUpIsOpened}
        setIsOpened={handleOpenPopUp}
        wrapperStyles={{ backgroundColor: '#F6F4F1' }}
      >
        <TrainerDetails
          {...selectedTrainer}
          handleOpenPopUp={handleOpenPopUp}
          setTrainerId={setTrainerId}
          preselectFreezeTopic={preselectFreezeTopic}
        />
      </PopUp>

      <PopUp isOpened={sessionsPopUpIsOpened} setIsOpened={setOpenSessionsPopUp}>
        <SessionDetails
          setCancelSession={setCancelSession}
          handleOpenCallPopUp={handleOpenCallPopUp}
        />
      </PopUp>

      <Modal isOpened={!!cancelSessionOpened} setIsOpened={setCancelSession}>
        <CancelSessionModal
          setClose={() => setCancelSession(false)}
          cancelSessions={() => {
            sendCancelSessionEmail(cancelSessionOpened);
          }}
        />
      </Modal>

      <Modal
        isOpened={isCancelSessionSuccessModal}
        setIsOpened={closeCancelSessionSuccessModal}
      >
        <CancelSessionSuccessModal setClose={closeCancelSessionSuccessModal} />
      </Modal>

      <SessionCallModal isOpened={isSessionCallPopUpOpened}>
        <SessionCallPopUpInner />
      </SessionCallModal>
    </>
  );
};
MainContent.propTypes = {
  trainers: PropTypes.arrayOf(PropTypes.object),
  myPersonalTrainers: PropTypes.array.isRequired,
  selectedClubId: PropTypes.string.isRequired,
  favoriteClubId: PropTypes.string.isRequired,
  selectTrainingFavoriteClub: PropTypes.func.isRequired,
  changeTrainingSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  clubs: PropTypes.arrayOf(PropTypes.object).isRequired,
  sessions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isMarkTrainerSuccess: PropTypes.bool.isRequired,
  setTargetSession: PropTypes.func.isRequired,
  setTrainerId: PropTypes.func.isRequired,
  handleOpenCallPopUp: PropTypes.func.isRequired,
  isSessionCallPopUpOpened: PropTypes.bool.isRequired,
  cleanCancelSessionEmail: PropTypes.func.isRequired,
  sendCancelSessionEmail: PropTypes.func,
  preselectFreezeTopic: PropTypes.func.isRequired,
};

MainContent.defaultProps = {
  trainers: [],
  sessions: null,
  sendCancelSessionEmail: () => {},
};

export default MainContent;
