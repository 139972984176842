import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'components/shared/Spinner';
import styles from './styles.module.css';
import GraphikCard from '../GraphikCard';
import EmptySection from './EmptySection';

const graphics = [
  {
    key: 'weight',
    label: 'Weight',
    units: 'lbs',
  },
  {
    key: 'SMM(SkeletalMuscleMass)',
    label: 'SMM (Skeletal Muscle Mass)',
    units: 'lbs',
  },
  {
    key: 'BFM(BodyFatMass)',
    label: 'BFM (Body Fat Mass)',
    units: 'lbs',
  },
  {
    key: 'BMI(BodyMassIndex)',
    label: 'BMI (Body Mass Index)',
    units: 'kg/m2',
  },
  {
    key: 'PBF(PercentBodyFat)',
    label: 'PBF (Percent Body Fat)',
    units: '%',
  },
];

const InBodyTab = () => {
  const isRequesting = useSelector(({ analytics }) => analytics.loadInBodyIsRequesting);
  const inBody = useSelector(({ analytics }) => analytics.inBody);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'LOAD_ACTIVITY_REQUEST',
    });
  }, []);

  return (
    <>
      {isRequesting ? (
        <div className={styles.spinnerWrapper}>
          <Spinner />
        </div>
      ) : (
        <div className={styles.wrapper}>
          {inBody?.length ? (
            graphics.map((key, index) => (
              <GraphikCard
                inBody={inBody}
                key={graphics[index].key}
                lineDataKey={graphics[index].key}
                units={graphics[index].units}
                label={graphics[index].label}
              />
            ))
          ) : (
            <EmptySection />
          )}
        </div>
      )}
    </>
  );
};

export default InBodyTab;
