import { calendarGreyIcon, fireIcon, nutrition, playIconSvg } from 'assets/icons';

export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const slides = [
  // {
  //   index: 2,
  //   img: hours,
  //   title: 'Reserve a spot',
  //   text: 'Easily book a time to workout',
  // },
  {
    index: 2,
    img: calendarGreyIcon,
    title: 'Group Classes',
    text: 'Experience our group classes at select FITNESS SF locations',
  },
  {
    index: 3,
    img: fireIcon,
    title: 'Workouts',
    text: 'Access dynamic, interactive workouts with audio coaching',
  },
  {
    index: 4,
    img: nutrition,
    title: 'Nutrition',
    text: 'Get personalized meal planning and recommendations',
  },
  {
    index: 5,
    img: playIconSvg,
    title: 'On Demand',
    text: 'Challenge yourself at home with Les Mills On Demand',
  },
];
