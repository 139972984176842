import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import styles from './styles.module.css';

const CancelSessionSuccessModal = ({ setClose }) => {
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>The Session was canceled</h3>
      <div className={styles.dash} />
      <p className={styles.question}>
        Please wait when system delete the session from session`s list
      </p>
      <div className={styles.buttonsWrapper}>
        <Button styleType="text" className={styles.blackButton} onClick={setClose}>
          Ok
        </Button>
      </div>
    </div>
  );
};

CancelSessionSuccessModal.propTypes = {
  setClose: PropTypes.func,
};
CancelSessionSuccessModal.defaultProps = {
  setClose: () => {},
};
export default CancelSessionSuccessModal;
