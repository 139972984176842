import React, { useState } from 'react';
import TrainerCard from 'components/TrainingPage/TrainerCard';
import TrainersSection from 'components/TrainingPage/TrainersSection';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import NoTrainers from 'components/TrainingPage/NoTrainers';
import {
  findClubName,
  findTrainersByClubId,
  findTrainersForOtherClubs,
} from '../../helpers';
import styles from '../styles.module.css';

const RenderAllClubs = ({
  trainers,
  favoriteClubId,
  locations,
  setIsOpened,
  selectTrainer,
  setTrainerId,
  isMobile,
  isHideOtherLocations,
}) => {
  const [visibleTrainers, setVisibleTrainers] = useState(5);
  const favoriteLocationTrainers = findTrainersByClubId(favoriteClubId, trainers);
  const otherLocationsTrainers = findTrainersForOtherClubs(favoriteClubId, trainers);
  const favoriteClubName = findClubName(favoriteClubId, locations);
  const loadMore = () => {
    setVisibleTrainers(visibleTrainers + 5);
  };
  return (
    <>
      {favoriteLocationTrainers?.length > 0 && (
        <TrainersSection background={isMobile ? '#F6F4F1' : 'transparent'}>
          <div className={styles.inner}>
            {favoriteLocationTrainers?.length
              ? favoriteLocationTrainers.map(trainer => {
                  return (
                    <div key={trainer.idCA} className={styles.cardWrapper}>
                      <TrainerCard
                        {...trainer}
                        setIsOpened={setIsOpened}
                        selectTrainer={selectTrainer}
                        setTrainerId={setTrainerId}
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </TrainersSection>
      )}
      {isHideOtherLocations && favoriteLocationTrainers?.length && !otherLocationsTrainers?.length ? null : (
        <TrainersSection title="All Locations" textAlign={isMobile ? 'center' : null}>
          {otherLocationsTrainers?.length ? (
            <div className={styles.inner}>
              {otherLocationsTrainers?.length
                ? otherLocationsTrainers.slice(0, visibleTrainers).map(trainer => (
                    <div key={trainer.idCA} className={styles.cardWrapper}>
                      <TrainerCard
                        {...trainer}
                        showClub
                        setIsOpened={setIsOpened}
                        selectTrainer={selectTrainer}
                        setTrainerId={setTrainerId}
                      />
                    </div>
                  ))
                : null}
            </div>
          ) : (
            <NoTrainers />
          )}
          {otherLocationsTrainers.length > visibleTrainers ? (
            <div className={styles.loadMoreSection}>
              <div className={styles.youSeeSection}>
                <span className={styles.youSeeTitle}>
                  You saw {visibleTrainers} of {otherLocationsTrainers.length} coaches
                </span>
                <div className={styles.progressOuter}>
                  <div
                    className={styles.progressInner}
                    style={{
                      width: `${(visibleTrainers / otherLocationsTrainers.length) *
                        100}%`,
                    }}
                  />
                </div>
              </div>
              <Button onClick={loadMore}>Load more</Button>
            </div>
          ) : null}
        </TrainersSection>
      )}
    </>
  );
};

RenderAllClubs.propTypes = {
  trainers: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  favoriteClubId: PropTypes.string.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  selectTrainer: PropTypes.func.isRequired,
  setTrainerId: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isHideOtherLocations: PropTypes.bool.isRequired,
};

export default RenderAllClubs;
