import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from 'components/shared/Button';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import TabletContext from 'context/TabletContext';

import styles from './styles.module.css';

const handleReservedItems = item => {
  const dayOfWeek = moment(item.date).format('dddd');
  const dayMonth = moment(item.date).format('MM/DD');
  const at = moment(item.date).format('h:[00]a');
  return `${dayOfWeek} ${dayMonth} at ${at} (1 hour)`;
};

const checkModifyAvailable = item => {
  const sfDateTime = getSFTime('full');
  const diff = moment(sfDateTime).diff(item.date, 'hours');
  return diff <= -1;
};

const MyReservation = ({ reservedItems, action }) => {
  const isTablet = useContext(TabletContext);

  return (
    <div className={styles.wrapper}>
      {reservedItems?.length > 0 &&
        reservedItems.map(r => (
          <div key={r.reservationId} className={isTablet ? styles.reservationItem : null}>
            <div>
              <h3 className={styles.title}>My Reservation</h3>
              <p className={styles.reservation}>{handleReservedItems(r)}</p>
              <p
                className={`${styles.reservation} ${!checkModifyAvailable(r) &&
                  styles.isCancelEnable}`}
              >
                Location: {r.clubName}
                {r?.room && <span className={styles.room}>&bull; {r?.room}</span>}
              </p>
            </div>
            <div className={styles.button}>
              {checkModifyAvailable(r) && (
                <Button
                  styleType="text"
                  className={isTablet ? styles.blackButton : null}
                  onClick={() => action('reserved', r?.reservationId)}
                >
                  Cancel
                </Button>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

MyReservation.propTypes = {
  reservedItems: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  action: PropTypes.func,
};

MyReservation.defaultProps = {
  reservedItems: [],
  action: () => {},
};
export default MyReservation;
