import React from 'react';
import PropTypes from 'prop-types';
import { loginPageBackground } from 'assets/background-images';
import { fitnessWhiteIcon, sfOrangeIcon } from 'assets/icons';
import styles from './style.module.css';

const SignUpLayout = ({ children, height }) => {
  return (
    <div
      style={{ backgroundImage: `url(${loginPageBackground})` }}
      className={styles.background}
    >
      <div className={styles.blackOverlay}>
        <div className={styles.wrapper}>
          <div>
            <img src={fitnessWhiteIcon} alt="logo" className={styles.logo} />
            <img src={sfOrangeIcon} alt="logo" className={styles.logo} />
          </div>
          <div style={{ height }} className={styles.formWrapper}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

SignUpLayout.propTypes = {
  children: PropTypes.element.isRequired,
  height: PropTypes.number,
};

SignUpLayout.defaultProps = {
  height: 600,
};

export default SignUpLayout;
