/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import history from 'store/history';
import SmallMobileContext from 'context/SmallMobileContext';
import ClassCard from 'components/shared/LessonCard/ClassCard';
import styles from './style.module.css';
import { formattedClass } from './service';

const ClassList = ({
  data,
  addClassToFavorite,
  removeClassFromFavorite,
  handleOpenClassCallPopUp,
  setClickedGroupEx,
  setIsDetailsOpened,
  user,
}) => {
  const isSmallMobile = useContext(SmallMobileContext);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <h1>Upcoming Favorite Classes</h1>
        <div className={styles.line} />
        <p onClick={() => history.push('/classes')}>
          {isSmallMobile ? 'See all' : 'See all Classes'}
        </p>
      </div>
      <div className={styles.classes}>
        {data?.length &&
          data.map((item, i) => {
            const info = formattedClass(item);
            return (
              <div className={styles.item} key={`${i * 24}`}>
                <ClassCard
                  {...info}
                  {...item}
                  addClassToFavorite={addClassToFavorite}
                  removeClassFromFavorite={removeClassFromFavorite}
                  handleOpenClassCallPopUp={handleOpenClassCallPopUp}
                  setClickedGroupEx={setClickedGroupEx}
                  setIsDetailsOpened={setIsDetailsOpened}
                  user={user}
                  isHomePage
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

ClassList.propTypes = {
  data: PropTypes.array.isRequired,
  addClassToFavorite: PropTypes.func.isRequired,
  removeClassFromFavorite: PropTypes.func.isRequired,
  handleOpenClassCallPopUp: PropTypes.func.isRequired,
  setClickedGroupEx: PropTypes.func.isRequired,
  setIsDetailsOpened: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default ClassList;
