/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import Toggle from 'components/shared/Toggle';
import styles from './styles.module.css';

const Sorting = ({
  isShowVirtualClasses,
  setShowVirtualClasses,
  dayTime,
  setDayTime,
  goal,
  setGoal,
}) => {
  return (
    <div>
      <div className={styles.toggle}>
        <Toggle
          isActive={isShowVirtualClasses}
          onChange={() => setShowVirtualClasses(!isShowVirtualClasses)}
        />
        <p>Show broadcasted classes only</p>
      </div>
      <div className={styles.section}>
        <p>Time of the day</p>
        <div className={`${styles.categoriesWrapper} ${styles.dayTimesWrapper}`}>
          {dayTime.map(d => (
            <div
              className={`${styles.category} ${d.isSelected && styles.selectedCategory}`}
              onClick={() => {
                const list = cloneDeep(dayTime).map(item => {
                  if (item.title === d.title) item.isSelected = !d.isSelected;
                  return item;
                });
                setDayTime(list);
              }}
            >
              <p>{d.title}</p>
              <img src={d.icon} alt=" " />
            </div>
          ))}
        </div>
      </div>
      <div className={styles.section}>
        <p>Goals</p>
        <div className={`${styles.categoriesWrapper} ${styles.goalWrapper}`}>
          {goal.map(g => (
            <div
              className={`${styles.category} ${g.isSelected && styles.selectedCategory}`}
              onClick={() => {
                const list = cloneDeep(goal).map(item => {
                  if (item.title === g.title) item.isSelected = !g.isSelected;
                  return item;
                });
                setGoal(list);
              }}
            >
              <p>{g.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

Sorting.propTypes = {
  isShowVirtualClasses: PropTypes.bool.isRequired,
  setShowVirtualClasses: PropTypes.func.isRequired,
  dayTime: PropTypes.array.isRequired,
  setDayTime: PropTypes.func.isRequired,
  goal: PropTypes.array.isRequired,
  setGoal: PropTypes.func.isRequired,
};

export default Sorting;
