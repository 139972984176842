import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Notification from 'components/Header/Notification';
import TabletContext from 'context/TabletContext';
import styles from './styles.module.css';

const NotificationCenter = ({ notifications }) => {
  const isTablet = useContext(TabletContext);
  return (
    <div className={styles.wrapper} style={{ marginTop: isTablet && '40px' }}>
      {notifications.length ? (
        notifications.map(notification => (
          <Notification
            key={notification.id}
            isRead={notification.isRead}
            content={notification.text}
            link={notification.link}
            title={notification.title}
            date={notification.createdAt}
          />
        ))
      ) : (
        <div className={styles.noNotifications}>There are no notifications yet.</div>
      )}
    </div>
  );
};
NotificationCenter.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default NotificationCenter;
