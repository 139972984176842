import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Header as AuthHeader } from 'containers';
import Header from './Header';
import styles from './style.module.css';

const VaccineVerificationLayout = ({ children, step, isAuth }) => {
  const [isOpenProfileModal, setOpenProfileModal] = useState(false);
  return (
    <div className={styles.templateWrapper}>
      {isAuth ? (
        <AuthHeader
          isOpenProfileModal={isOpenProfileModal}
          setOpenProfileModal={setOpenProfileModal}
        />
      ) : (
        <Header />
      )}
      <div className={styles.mainWrapper}>
        <div>{children}</div>
      </div>
    </div>
  );
};

VaccineVerificationLayout.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  step: PropTypes.number.isRequired,
};

export default VaccineVerificationLayout;
