import { takeLatest, call, put, delay } from 'redux-saga/effects';
import api from 'services/api';
import transactionActions from './actions';
import errorActions from '../error/actions';

const {
  Types: { LOAD_TRANSACTION_REQUEST, LOAD_STATEMENT_REQUEST },
  Creators: {
    loadTransactionSuccess,
    loadTransactionFailure,
    loadStatementSuccess,
    loadStatementFailure,
  },
} = transactionActions;

const {
  Creators: { setError },
} = errorActions;

function* loadTransaction({ payload }) {
  const { start, end, queryArray } = payload;

  try {
    const { data } = yield call(
      api.get,
      `/transaction?startDate=${start}&endDate=${end}&queryParameters=${queryArray}`
    );
    yield put(loadTransactionSuccess(data));
  } catch (error) {
    yield put(loadTransactionFailure(error));
    yield put(setError('Load transaction error.'));
  }
}

function* loadStatement({ payload }) {
  try {
    yield call(api.post, `/statement`, payload);
    yield put(loadStatementSuccess(true));
    yield delay(2000);
    yield put(loadStatementSuccess(false));
  } catch (error) {
    yield put(loadStatementFailure(error));
    yield put(setError('Load statement error.'));
  }
}

export function* transactionSaga() {
  yield takeLatest(LOAD_TRANSACTION_REQUEST, loadTransaction);
  yield takeLatest(LOAD_STATEMENT_REQUEST, loadStatement);
}
