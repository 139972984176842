/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { getSocket } from 'utils/socket';
import isFunction from 'lodash/isFunction';
import { useSelector } from 'react-redux';
import PeerConnection from 'utils/webRtc/PeerConnection';
import moment from 'moment';
import MobileContext from 'context/MobileContext';
import MainContent from 'components/shared/SessionCallPopUp/MainContent/MainWindow';
import MainContentMobile from 'components/shared/SessionCallPopUp/MainContentMobile/MainWindow';

let pc = {};
const socket = getSocket();
let participantSocketId = null;
let crushTimer = null;
let reconnectAttempts = 0;
let networkIsFailed = false;
let localSrc = null;
let peerSrc = null;
let usernameLocal = '';
let credentialLocal = '';
const Page = ({
  isCallAllow,
  isRequesting,
  friendId,
  checkCallPermission,
  friendSocketId,
  clearCallState,
  hasIncomingCall,
  callId,
  username,
  credential,
  clearIncomingCall,
}) => {
  const isMobile = useContext(MobileContext);
  const mySfId = useSelector(state => state.auth.user.sfId);
  const myName = useSelector(state => state.auth.user.name);
  const isTrainer = useSelector(state => state.auth.user.isTrainer);
  const [callIsActive, setCallIsActive] = useState(false);
  const componentIsMounted = useRef(true);
  const startDate = Date.now();
  const [callDurationString, setCallDurationString] = useState('');

  useEffect(() => {
    checkCallPermission();
  }, [checkCallPermission]);
  useEffect(() => {
    usernameLocal = username;
    credentialLocal = credential;
  }, [username, credential]);

  useEffect(() => {
    if (networkIsFailed) {
      stopPC();

      crushTimer = setInterval(() => {
        if (reconnectAttempts === 20) {
          clearInterval(crushTimer);
        }
        if (!isTrainer) {
          socket.emit('ping-participant', { to: friendSocketId || participantSocketId });
          if (hasIncomingCall) {
            clearIncomingCall();
          }
        }

        reconnectAttempts += 1;
      }, 1000);
    }

    if (!networkIsFailed) {
      if (crushTimer) clearInterval(crushTimer);
    }
  }, [networkIsFailed, clearIncomingCall]);

  useEffect(() => {
    if (callIsActive && componentIsMounted.current) {
      const interval = setInterval(() => {
        const now = moment(Date.now());
        const start = moment(startDate);
        const diff = now.diff(start, 'seconds');
        const formatted = moment.utc(diff * 1000).format('mm:ss');
        setCallDurationString(formatted);
      }, 1000);
      if (!callIsActive && componentIsMounted.current) {
        clearInterval(interval);
      }
      return () => {
        clearInterval(interval);
      };
    }
  }, [callIsActive]);

  useEffect(() => {
    const asyncStartCall = async value => {
      await startCall(value);
    };

    if (isCallAllow) {
      if (isTrainer && !callIsActive) {
        asyncStartCall(true);
      } else if (!isTrainer && hasIncomingCall) {
        stopPC();
        asyncStartCall(false);
        if (networkIsFailed) {
          networkIsFailed = false;
          if (crushTimer) clearInterval(crushTimer);
        }
      } else if (!isTrainer && !callIsActive) {
        socket.emit('restore-call', { callId, userSocketId: socket.id });
      }
    }
  }, [isCallAllow, hasIncomingCall, friendSocketId]);

  useEffect(() => {
    if (!socket.hasListeners('web-rtc-connection-message'))
      socket.on('web-rtc-connection-message', async ({ type, data }) => {
        switch (type) {
          case 'candidate': {
            if (isFunction(pc.addIceCandidate)) pc.addIceCandidate(data.candidate);
            break;
          }
          case 'offer': {
            if (networkIsFailed) {
              networkIsFailed = false;
            }
            if (crushTimer) {
              clearInterval(crushTimer);
              reconnectAttempts = 0;
            }
            participantSocketId = data.from;
            if (isFunction(pc.handleOffer))
              await pc.handleOffer({ offer: data.offer, to: data.from });
            break;
          }
          case 'answer': {
            if (isFunction(pc.handleAnswer)) await pc.handleAnswer(data.answer);
            break;
          }

          default:
            return false;
        }
      });
    socket.on('stop-session-call', () => {
      endCall(false);
    });
    if (!socket.hasListeners('ping-participant'))
      socket.on('ping-participant', async () => {
        if (networkIsFailed) {
          await startCall(true);
          networkIsFailed = false;
        }
      });
  }, []);

  useEffect(() => {
    return () => {
      componentIsMounted.current = null;
      if (callIsActive) endCall(true);
      if (crushTimer) clearInterval(crushTimer);
      localSrc = null;
      peerSrc = null;
      usernameLocal = '';
      credentialLocal = '';
      networkIsFailed = false;
    };
  }, []);

  const startCall = async isCaller => {
    const pcInstance = new PeerConnection(usernameLocal, credentialLocal)
      .on('localStream', src => {
        if (componentIsMounted.current) {
          localSrc = src;
        }
      })
      .on('peerStream', src => {
        if (componentIsMounted.current) {
          setCallIsActive(true);
          peerSrc = src;
        }
      })
      .on('iceCandidate', data => {
        try {
          socket.emit('web-rtc-connection-message', {
            type: 'candidate',
            data: {
              to: friendSocketId || participantSocketId,
              candidate: data.candidate,
            },
          });
        } catch (e) {
          console.log('iceCandidateFailed', e);
        }
      })
      .on('startCall', () => {
        socket.emit('request-call', {
          to: friendId,
          from: mySfId,
          fromSocketId: socket.id,
          fromName: myName,
          callId,
        });
      })
      .on('stopCall', () => {
        if (friendSocketId || participantSocketId) {
          socket.emit('stop-session-call', {
            socketId: friendSocketId || participantSocketId,
            isTrainer,
            callId,
          });
        } else {
          socket.emit('stop-session-call', {
            friendId,
            isTrainer,
            callId,
          });
        }
      })
      .on('offerCreated', ({ offer }) => {
        socket.emit('web-rtc-connection-message', {
          type: 'offer',
          data: {
            to: friendSocketId,
            from: socket.id,
            fromId: mySfId,
            fromName: myName,
            offer,
            callId,
          },
        });
        networkIsFailed = false;
      })
      .on('answerCreated', ({ answer, to }) => {
        socket.emit('web-rtc-connection-message', {
          type: 'answer',
          data: {
            to,
            answer,
          },
        });
      })
      .on('peerDisconnected', () => {
        networkIsFailed = true;
      });

    pc = pcInstance;
    setCallIsActive(true);
    await pc.start(isCaller);
  };

  const stopPC = () => {
    if (isFunction(pc.stop)) {
      pc.stop(false);
    }
    pc = {};
    participantSocketId = null;
    setCallIsActive(false);
  };
  const endCall = isInitiator => {
    if (isFunction(pc.stop)) {
      pc.stop(isInitiator);
    }
    pc = {};
    participantSocketId = null;
    if (componentIsMounted.current) {
      setCallIsActive(false);
      localSrc = null;
      peerSrc = null;
    }
    crushTimer = null;
    reconnectAttempts = 0;
    networkIsFailed = false;
    clearCallState();
  };

  const toggle = deviceType => {
    if (isFunction(pc.toggle)) {
      if (deviceType === 'video') {
        pc.toggle('Video');
      }
      if (deviceType === 'audio') {
        pc.toggle('Audio');
      }
    }
  };

  return (
    <>
      {isMobile ? (
        <MainContentMobile
          localSrc={localSrc}
          peerSrc={peerSrc}
          toggle={toggle}
          endCall={endCall}
          callDurationString={callDurationString}
          callIsActive={callIsActive}
          isRequesting={isRequesting}
          networkIsFailed={networkIsFailed}
        />
      ) : (
        <MainContent
          localSrc={localSrc}
          peerSrc={peerSrc}
          toggle={toggle}
          endCall={endCall}
          callDurationString={callDurationString}
          callIsActive={callIsActive}
          isRequesting={isRequesting}
          networkIsFailed={networkIsFailed}
        />
      )}
    </>
  );
};

Page.propTypes = {
  isCallAllow: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  friendId: PropTypes.string.isRequired,
  checkCallPermission: PropTypes.func.isRequired,
  friendSocketId: PropTypes.string.isRequired,
  clearCallState: PropTypes.func.isRequired,
  hasIncomingCall: PropTypes.bool.isRequired,
  callId: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  credential: PropTypes.string.isRequired,
  clearIncomingCall: PropTypes.func.isRequired,
};
export default Page;
