import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const TrainersSection = ({ title, children, textAlign, background }) => {
  return (
    <div className={styles.wrapper} style={{ background }}>
      <p className={styles.title} style={{ textAlign }}>
        {title}
      </p>
      {children}
    </div>
  );
};

TrainersSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  textAlign: PropTypes.string,
  background: PropTypes.string,
};
TrainersSection.defaultProps = {
  title: '',
  textAlign: '',
  background: '',
};
export default TrainersSection;
