import { connect } from 'react-redux';
import ExperienceUsPage from 'pages/ExperienceUsPage';
import Actions from 'store/signUp/actions';
import ClubActions from 'store/clubs/actions';

const mapStateToProps = state => {
  return {
    clubs: state.clubs.clubs,
    user: state.signUp.user,
    isRequesting: state.signUp.isRequesting,
  };
};
const mapDispatchToProps = {
  loadClubs: ClubActions.Creators.loadClubsRequest,
  saveUser: Actions.Creators.createGuestRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceUsPage);
