/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LocationBlock from 'components/shared/LocationBlock';
import MobileContext from 'context/MobileContext';
import TabletContext from 'context/TabletContext';
import { Link } from 'react-router-dom';
import styles from './style.module.css';

const LocationBlockSet = ({
  data,
  selectedLocation,
  selectLocation,
  isRegistration,
  isLinks,
  isLocationPage,
  favoriteClub,
  changeFavoriteLocation,
}) => {
  const isMobile = useContext(MobileContext);
  const isTablet = useContext(TabletContext);
  return (
    <div
      className={`${
        isLocationPage ? styles.locationPageWrapper : styles.wrapper
      } ${isRegistration && styles.registration}`}
    >
      {data?.length
        ? isLinks
          ? data.map(block => {
              const routeName = block.name.replace(/\s+/g, '-').toLowerCase();
              return (
                <Link key={block.clubAutomationClubId} to={`/locations/${routeName}`}>
                  <LocationBlock
                    key={block.clubAutomationClubId}
                    title={block.name}
                    src={block.siteImage}
                    value={block.clubAutomationClubId.toString()}
                    handleClick={selectLocation}
                    maxWidth={isTablet ? '100%' : '500px'}
                    minHeight={isTablet ? '120px' : '240px'}
                    wrapperStyles={
                      isRegistration
                        ? {
                            margin: 0,
                            boxShadow:
                              block.clubAutomationClubId === selectedLocation
                                ? 'inset 0px 0px 0px 500px rgba(254,80,0,0.5)'
                                : 'inset 0px 0px 0px 500px rgba(0,0,0,0.5)',
                            minWidth: '120px',
                          }
                        : isMobile
                        ? {
                            marginBottom: '0',
                            boxShadow: 'inset 0px 0px 0px 500px rgba(0,0,0,0.5)',
                          }
                        : { boxShadow: 'inset 0px 0px 0px 500px rgba(0,0,0,0.5)' }
                    }
                  />
                </Link>
              );
            })
          : data.map(block => {
              return (
                <div key={block.clubAutomationClubId}>
                  <LocationBlock
                    key={block.clubAutomationClubId}
                    favoriteClub={favoriteClub}
                    changeFavoriteLocation={() =>
                      changeFavoriteLocation(block.clubAutomationClubId.toString())
                    }
                    title={block.name}
                    src={block.siteImage}
                    value={block.clubAutomationClubId.toString()}
                    city={block.city}
                    selectedLocation={selectedLocation}
                    street={block.street}
                    clubPhoneNumber={block.clubPhoneNumber}
                    handleClick={selectLocation}
                    maxWidth={isMobile ? null : isTablet ? '300px' : null}
                    minHeight={isTablet ? '140px' : '135px'}
                  />
                </div>
              );
            })
        : null}
    </div>
  );
};

LocationBlockSet.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedLocation: PropTypes.oneOfType([PropTypes.number, PropTypes.any]),
  selectLocation: PropTypes.func,
  isRegistration: PropTypes.bool,
  isLocationPage: PropTypes.bool,
  isLinks: PropTypes.bool,
  favoriteClub: PropTypes.string,
  changeFavoriteLocation: PropTypes.func,
};

LocationBlockSet.defaultProps = {
  selectedLocation: null,
  selectLocation: () => {},
  isRegistration: false,
  isLinks: false,
  isLocationPage: false,
  favoriteClub: '',
  changeFavoriteLocation: () => {},
};

export default LocationBlockSet;
