import { dumbbellOrange, calendarOrangeIcon, card } from 'assets/icons';

export const personalTrainingSection = {
  icon: dumbbellOrange,
  title: 'Personal Sessions',
  text: `Looks like you haven't booked a training session yet!`,
  note: 'Meet your trainer',
  redirectTo: '/training?tab=trainers',
};
export const classesSection = {
  icon: calendarOrangeIcon,
  title: 'Group Fitness Classes',
  text: `Looks like you haven't favorite classes yet!`,
  note: 'Find your favorite classes',
  redirectTo: '/classes',
};
export const vaccinationBannerSection = {
  icon: card,
  title: 'Proof of Vaccination',
  text: `In order to continue to access our locations, you must provide proof of full vaccination.`,
  note: 'Submit now',
  redirectTo: '/verify-status',
};
