import React, { useContext, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import Spinner from 'components/shared/Spinner';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import moment from 'moment';
import PickHistoryDate from './Header';
import TableHistoryDesktop from './TableHistory/Desktop';
import TableHistoryMobile from './TableHistory/Mobile';
import StatusBar from './StatusBar';
import styles from './styles.module.css';

const CheckHistory = ({
  visits,
  loadHistory,
  checkHistory,
  amount,
  idCA,
  resetHistory,
  loadHistoryPDF,
  isRequestingPDF,
  loadActivity,
  isRequesting,
}) => {
  const sfDate = getSFTime();
  const isMobile = useContext(MobileContext);
  const [startDate, setStartDate] = useState(sfDate);

  const limit = 15;
  const [used, setUsed] = useState(limit);

  useEffect(() => {
    loadActivity();
  }, [loadActivity]);

  useEffect(() => {
    loadHistory({
      end: moment(sfDate)
        .endOf('month')
        .format('YYYY-MM-DD'),
      start: moment(sfDate)
        .startOf('month')
        .format('YYYY-MM-DD'),
      limit,
    });
  }, []);

  useEffect(() => {
    if (used > amount && typeof amount === 'number') {
      setUsed(amount);
    }
  }, [amount, checkHistory]);

  const data = checkHistory
    ? checkHistory.sort((a,b) => Date.parse(a.time) - Date.parse(b.time)).map(({ time, club }) => ({
        time,
        location: club.name,
      }))
    : [];

  return (
    <HeaderFooterLayout profile="Check-in History">
      <div className={styles.containerMain}>
        {isRequesting ? (
          <Spinner />
        ) : (
          <>
            {isRequestingPDF && <div className="popupBackground" />}

            <PickHistoryDate
              checkHistory={checkHistory}
              visits={visits}
              resetHistory={resetHistory}
              loadHistory={loadHistory}
              changeParentState={setStartDate}
              limit={!isMobile ? limit : 10000}
              idCA={idCA}
              startDate={startDate}
              loadHistoryPDF={loadHistoryPDF}
              setUsed={setUsed}
            />

            {checkHistory?.length > 0 ? (
              <>
                {isMobile ? (
                  <TableHistoryMobile data={data} />
                ) : (
                  <>
                    <TableHistoryDesktop data={data} />
                    <StatusBar
                      amount={amount}
                      loadHistory={loadHistory}
                      startDate={startDate}
                      used={used}
                      setUsed={setUsed}
                      limit={limit}
                    />
                  </>
                )}
              </>
            ) : (
              <div className={styles.notExist}>History not found.</div>
            )}
          </>
        )}
      </div>
    </HeaderFooterLayout>
  );
};
CheckHistory.propTypes = {
  loadHistory: PropTypes.func.isRequired,
  loadHistoryPDF: PropTypes.func.isRequired,
  resetHistory: PropTypes.func.isRequired,
  checkHistory: PropTypes.array.isRequired,
  idCA: PropTypes.string.isRequired,
  isRequestingPDF: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  visits: PropTypes.object.isRequired,
  amount: PropTypes.number,
  loadActivity: PropTypes.func.isRequired,
};
CheckHistory.defaultProps = {
  amount: null,
};
export default CheckHistory;
