import * as Yup from 'yup';

export const personalInfoValidationSchema = Yup.object().shape({
  message: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .required('Please write your message'),
});
export const creditCardValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(40, 'Must be 40 characters or less')
    .min(2, 'Must be 2 characters or more')
    .matches(/^[A-z 0-9]+$/gi, { message: 'Wrong card name' })
    .required('Name on card is required'),
  cardNumber: Yup.string().required('Card number is required'),
  expiration: Yup.string().required('Expiry date is required'),
});
