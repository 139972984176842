import React, { useEffect, useState } from 'react';
import Select from 'components/shared/Select';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const PickTopic = ({ onChange, isFreezeSelected, value, topics, isDisabled }) => {
  const [filteredTopics, setFilteredTopics] = useState([]);

  useEffect(() => {
    if (isFreezeSelected) {
      const number = isFreezeSelected.includes('Training with ')
        ? 3
        : isFreezeSelected === 'freeze'
        ? 6
        : isFreezeSelected === 'reactivate'
        ? 7
        : isFreezeSelected === 'personalTraining'
        ? 3
        : 8;
      const freezeTopic = topics ? topics.find(o => o.id === number) : '';
      if (freezeTopic?.id && freezeTopic?.name)
        onChange({ value: freezeTopic.id, label: freezeTopic.name });
      if (topics) setFilteredTopics(topics);
    } else {
      setFilteredTopics(
        topics
          ? topics.filter(
              topic =>
                !(
                  topic.name.includes('Freeze membership') ||
                  topic.name.includes('Cancel membership') ||
                  topic.name.includes('Re-activate membership')
                )
            )
          : []
      );
    }
  }, [isFreezeSelected, topics]);

  const options = filteredTopics?.length
    ? filteredTopics.map(topic => Object.freeze({ value: topic.id, label: topic.name }))
    : [];
  return (
    <>
      <div className={styles.pickTopic}>
        <Select
          width="100%"
          containerStyle={{
            borderRadius: 10,
          }}
          options={options}
          onChange={onChange}
          value={value}
          placeholder="Pick a topic"
          hideIndicatorSeparator
          isDisabled={isDisabled}
        />
      </div>
    </>
  );
};
PickTopic.propTypes = {
  value: PropTypes.number,
  topics: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  isFreezeSelected: PropTypes.string,
  isDisabled: PropTypes.bool,
};
PickTopic.defaultProps = {
  topics: [],
  value: null,
  isFreezeSelected: '',
  isDisabled: false,
};
export default PickTopic;
