/* eslint-disable no-alert */
/* eslint-disable no-console */
import set from 'lodash/set';
import isFunction from 'lodash/isFunction';
import Emitter from './Emitter';

const CONSTRAINTS = {
  video: {
    facingMode: 'user',
    height: { min: 360, ideal: 720, max: 1080 },
  },
  audio: true,
};

class PeerConnection extends Emitter {
  constructor(username, credential) {
    super();
    try {
      this.pc = new RTCPeerConnection(
        {
          iceServers: [
            {
              urls: 'stun:global.stun.twilio.com:3478?transport=udp',
            },
            {
              urls: [
                'turn:global.turn.twilio.com:3478?transport=udp',
                'turn:global.turn.twilio.com:3478?transport=tcp',
                'turn:global.turn.twilio.com:443?transport=tcp',
              ],
              username,
              credential,
            },
          ],
        },
        { optional: [{ DtlsSrtpKeyAgreement: 'true' }] }
      );
    } catch (e) {
      console.log({ e });
    }
    this.stream = {};
    this.pc.oniceconnectionstatechange = () => {
      if (this.pc.iceConnectionState === 'disconnected') this.emit('peerDisconnected');
    };
    this.pc.onicecandidate = event => {
      try {
        this.emit('iceCandidate', { candidate: event.candidate });
      } catch (e) {
        console.log('iceCandidateFailed', e);
      }
    };
    this.pc.ontrack = event => {
      this.emit('peerStream', event.streams[0]);
    };
  }

  addIceCandidate(candidate) {
    try {
      if (candidate) {
        const iceCandidate = new RTCIceCandidate(candidate);
        this.pc.addIceCandidate(iceCandidate);
      }
      return this;
    } catch (e) {
      console.log(` add ice candidate error ${e}`);
      return this;
    }
  }

  async start(isCaller) {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia(CONSTRAINTS);
      this.stream.getTracks().forEach(track => {
        this.pc.addTrack(track, this.stream);
      });
      this.emit('localStream', this.stream);
    } catch (e) {
      console.log({ e });
      alert('Unable to access camera');
    }
    if (isCaller) this.emit('startCall');
    else await this.createOffer();
    return this;
  }

  stop(isStarter) {
    if (isStarter) this.emit('stopCall');
    if (isFunction(this.stream.getTracks)) {
      this.stream.getTracks().forEach(track => track.stop());
    }
    this.pc.close();
    this.pc = {};
    this.stream = {};
    this.off();
    return this;
  }

  async handleOffer({ offer, to }) {
    try {
      await this.setRemoteDescription(offer);
      await this.createAnswer({ to });

      return this;
    } catch (e) {
      console.log(` handle offer error ${e}`);
      return this;
    }
  }

  async handleAnswer(value) {
    try {
      await this.pc.setRemoteDescription(value);
      return this;
    } catch (e) {
      console.log(` handle answer error ${e}`);
      return this;
    }
  }

  async createOffer() {
    if (isFunction(this.pc.createOffer)) {
      try {
        const offer = await this.pc.createOffer();
        await this.setLocalDescription(offer);
        this.emit('offerCreated', {
          offer: this.pc.localDescription,
        });
      } catch (e) {
        console.log(e);
      }
    }
    return this;
  }

  async createAnswer({ to }) {
    if (isFunction(this.pc.createAnswer)) {
      try {
        const answer = await this.pc.createAnswer();
        await this.setLocalDescription(answer);
        this.emit('answerCreated', {
          answer: this.pc.localDescription,
          to,
        });
      } catch (e) {
        console.log({ e });
      }
    }
    return this;
  }

  async setLocalDescription(value) {
    try {
      if (isFunction(this.pc.setLocalDescription))
        await this.pc.setLocalDescription(value);
      return this;
    } catch (e) {
      return this;
    }
  }

  async setRemoteDescription(value) {
    try {
      if (isFunction(this.pc.setRemoteDescription)) {
        if (value === null) throw Error('remote description is null');
        await this.pc.setRemoteDescription(value);
      }
      return this;
    } catch (e) {
      console.log(` setRemoteDescription error ${e}`);
      return this;
    }
  }

  toggle(type, on) {
    try {
      const len = arguments.length;
      if (this.stream) {
        this.stream[`get${type}Tracks`]().forEach(track => {
          const state = len === 2 ? on : !track.enabled;

          set(track, 'enabled', state);
        });
      }
      return this;
    } catch (e) {
      console.log(` toggle device error ${e}`);
      return this;
    }
  }
}

export default PeerConnection;
