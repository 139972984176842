/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import NotificationRow from '../NotificationRow';
import styles from './styles.module.css';

const NotificationRowGroup = ({
  name,
  categories,
  emailNotifications,
  pushNotifications,
  changeNotificationSettings,
}) => {
  return (
    <>
      {categories.length > 0 && (
        <div className={styles.wrapper}>
          <NotificationRow isHeaderGroup headerGroupTitle={name} />
          {categories.map((category, index) => (
            <NotificationRow
              key={`${category.name}${index * 10}`}
              notificationName={category.name}
              isEmailAvailable={category.isEmailAvailable}
              isPushAvailable={category.isPushAvailable}
              isEmailChecked={validateIsChecked(emailNotifications, category.type)}
              isPushChecked={validateIsChecked(pushNotifications, category.type)}
              changeNotificationSettings={changeNotificationSettings}
              notificationType={category.type}
              activeDisable={name === 'Payment Events' && index !== 3}
            />
          ))}
        </div>
      )}
    </>
  );
};

const validateIsChecked = (notificationTypes, targetType) => {
  if (notificationTypes.includes(targetType.toString())) return true;
  return false;
};

NotificationRowGroup.propTypes = {
  pushNotifications: PropTypes.array,
  emailNotifications: PropTypes.array.isRequired,
  categories: PropTypes.array,
  changeNotificationSettings: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};
NotificationRowGroup.defaultProps = {
  categories: [],
  pushNotifications: null,
};

export default NotificationRowGroup;
