import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import VideoContent from './VideoContent';

const Content = ({ videos, setActiveVideo, showContent }) => {
  const [list, setList] = useState({});

  useEffect(() => {
    if (videos.length) setList(groupBy(sortBy(videos, 'section'), 'section'));
  }, [videos]);

  return (
    <>
      {Object.keys(list).map(key => (
        <VideoContent
          key={key}
          title={key}
          videoList={list[key]}
          theme="grey"
          setActiveVideo={setActiveVideo}
          showContent={showContent}
        />
      ))}
    </>
  );
};

Content.propTypes = {
  videos: PropTypes.array.isRequired,
  setActiveVideo: PropTypes.func.isRequired,
  showContent: PropTypes.bool.isRequired,
};

export default Content;
