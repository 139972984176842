import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  status: false,
  error: null,
};

const sendSafetyCommitmentRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const sendSafetyCommitmentSuccess = (state = INITIAL_STATE, { status }) => {
  return {
    ...state,
    status,
    isRequesting: true,
  };
};
const sendSafetyCommitmentFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    error,
  };
};

export const HANDLERS = {
  [Types.SEND_SAFETY_COMMITMENT_REQUEST]: sendSafetyCommitmentRequest,
  [Types.SEND_SAFETY_COMMITMENT_SUCCESS]: sendSafetyCommitmentSuccess,
  [Types.SEND_SAFETY_COMMITMENT_FAILURE]: sendSafetyCommitmentFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
