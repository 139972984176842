import { connect } from 'react-redux';
import ClubActivity from 'components/HomePage/ClubActivity';
import Actions from 'store/clubsStatistic/actions';

const mapStateToProps = state => {
  return {
    clubsStatistic: state.clubsStatistic.data,
    clubs: state.clubs.clubs,
    membership: state.auth.membership,
  };
};
const mapDispatchToProps = {
  loadStatistic: Actions.Creators.loadClubsStatisticRequest,
  selectClub: Actions.Creators.selectClub,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClubActivity);
