import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const StatusPopup = ({ text, className }) => {
  return (
    <>
      <div
        className={
          className === 'contactUs'
            ? styles.contactContainer
            : className === 'statement'
            ? styles.statementContainer
            : styles.membershipContainer
        }
      >
        <div className={styles.text}>{text}</div>
      </div>
    </>
  );
};

StatusPopup.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default StatusPopup;
