/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import QRCOde from 'qrcode.react';
import { useSelector } from 'react-redux';
import { v1 as uuid } from 'uuid';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import { closeIcon } from 'assets/icons';
import history from 'store/history';
import styles from './styles.module.css';

const QrCodePage = () => {
  const code = useSelector(state => state.auth.user.scanCode);
  const fullName = useSelector(state => state.auth.user.name);

  return (
    <HeaderFooterLayout>
      <div className={styles.wrapper}>
        <h1>
          <span>Time to hit the gym!</span>
          <img src={closeIcon} alt="" onClick={() => history.go(-1)} />
        </h1>
        {code && (
          <div className={styles.codeSection}>
            <p>Scan the QR Code at the front desk to check-in</p>
            <QRCOde className={styles.code} value={code} />
            <span>{uuid(8)}</span>
          </div>
        )}
        <h2>{fullName}</h2>
      </div>
    </HeaderFooterLayout>
  );
};

export default QrCodePage;
