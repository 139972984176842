import { connect } from 'react-redux';
import VideoRoomComponent from 'components/ClassCall';
import callActions from 'store/classCall/actions';

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    callSessionToken: state.classCall.callSessionToken,
    isRequesting: state.classCall.isRequesting,
    classId: state.classCall.classId,
    isRecording: state.classCall.isRecording,
    recordingUrl: state.classCall.awsUrl,
    isStopRecordingRequest: state.classCall.isStopRecordingRequest,
  };
};
const mapDispatchToProps = {
  removeUser: callActions.Creators.removeUser,
  getCallSessionToken: callActions.Creators.getCallSessionTokenRequest,
  clearCallState: callActions.Creators.clearCallState,
  startRecording: callActions.Creators.startRecordingRequest,
  stopRecording: callActions.Creators.stopRecordingRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoRoomComponent);
