import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import ConfirmPopup from 'components/shared/ConfirmPopup';
import styles from './style.module.css';

const ConfirmActionPopup = ({ open, onClose, onRemoveItems, isRequesting }) => {
  return (
    <ConfirmPopup open={open} onClose={onClose} isRequesting={isRequesting}>
      <div className={styles.container}>
        <p>Are you sure you want to remove the items?</p>
        <div className={styles.actions}>
          <Button onClick={onRemoveItems} className={styles.button}>
            Remove
          </Button>
          <Button styleType="tertiary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </ConfirmPopup>
  );
};

ConfirmActionPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemoveItems: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

export default ConfirmActionPopup;
