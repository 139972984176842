import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  postCaseRequest: ['payload'],
  postCaseSuccess: ['status'],
  postCaseFailure: ['error'],
});

const caseActions = {
  Types,
  Creators,
};

export default caseActions;
