import { connect } from 'react-redux';
import VideoPage from 'pages/VideoPage';
import Actions from 'store/auth/actions';
import PremiumVideosActions from 'store/premiumVideos/actions';

const mapStateToProps = state => {
  return {
    membership: state.auth.membership,
    memberStatus: state.auth.user.memberStatus,
    videos: state.premiumVideos.videos,
    isRequesting: state.premiumVideos.isRequesting,
    isRequestingMembership: state.auth.isRequesting,
  };
};
const mapDispatchToProps = {
  loadMembership: Actions.Creators.loadMembershipRequest,
  loadPremiumVideos: PremiumVideosActions.Creators.loadPremiumVideosRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPage);
