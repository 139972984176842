import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { memberStatuses } from 'enums/memberStatuses';
import styles from './styles.module.css';

const DialogItem = ({ selectTargetDialog, targetDialog, ...item }) => {
  const {
    createdAt,
    dialogPersonName,
    text,
    isMyMessage,
    isDirector,
    photo,
    receiverUserId,
    senderUserId,
    isRead,
  } = item;
  const memberStatus = useSelector(state => state.auth.user.memberStatus);
  const isStaff = memberStatus === memberStatuses.staff;
  const {
    receiverUserId: receiverUserIdTargetDialog,
    senderUserId: senderUserIdTargetDialog,
  } = targetDialog;
  const isActive =
    receiverUserIdTargetDialog === receiverUserId &&
    senderUserIdTargetDialog === senderUserId;
  const isToday = createdAt ? moment(createdAt).isSame(Date.now(), 'day') : '';
  return (
    <div
      className={[styles.wrapper, isDirector && styles.directorWrapper].join(' ')}
      onClick={() => {
        selectTargetDialog(item);
      }}
    >
      <div className={styles.inner}>
        {!isStaff ? (
          <div className={styles.photo} style={{ backgroundImage: `url(${photo})` }} />
        ) : null}
        <div className={styles.contentWrapper}>
          <p
            className={[
              styles.name,
              (isActive || isDirector) && styles.nameActive,
              !isMyMessage && !isRead && styles.nameUnread,
            ].join(' ')}
          >
            {dialogPersonName}
          </p>
          <p className={styles.lastMessage}>
            {isMyMessage && text
              ? `you: ${text}`
              : isDirector && !text
              ? `Hello! Let's connect`
              : text
              ? `${text}`
              : null}
          </p>
        </div>
        {createdAt ? (
          <div className={styles.lastDate}>
            {isDirector && !text
              ? ''
              : isToday
              ? moment(createdAt).format('hh:mm A')
              : moment(createdAt).format('MM/DD/YYYY, hh:mm A')}
          </div>
        ) : null}
      </div>
    </div>
  );
};

DialogItem.propTypes = {
  selectTargetDialog: PropTypes.func.isRequired,
  targetDialog: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default DialogItem;
