import { connect } from 'react-redux';
import ContactUs from 'components/ProfilePage/ContactUs';
import Actions from 'store/auth/actions';
import topicActions from 'store/topics/actions';
import clubActions from 'store/clubs/actions';
import caseActions from 'store/case/actions';

const mapStateToProps = state => {
  return {
    clubs: state.clubs.clubs,
    profile: state.auth.profile,
    user: state.auth.user,
    topics: state.topics.topics,
    isCreated: state.cases.isCreated,
    isRequesting: state.cases.isRequesting,
    isRequestingUser: state.auth.isRequesting,
  };
};

const mapDispatchToProps = {
  loadTopics: topicActions.Creators.loadTopicsRequest,
  resetPreselectedFreezeTopic: topicActions.Creators.resetPreselectedFreezeTopic,
  loadClubs: clubActions.Creators.loadClubsRequest,
  postCase: caseActions.Creators.postCaseRequest,
  changeCaseStatus: caseActions.Creators.postCaseSuccess,
  changeFavoriteLocation: Actions.Creators.changeFavoriteLocationRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
