import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import Spinner from 'components/shared/Spinner';
import { checkDigitalPlan } from 'services/checkDigitalPlan';
import SubscriptionPlan from './SubscriptionPlan';
import DigitalMembership from './DigitalMembership';
import Popup from './Popup';
import styles from './styles.module.css';

const MembershipSubscription = ({
  user,
  profile,
  membership,
  loadMembership,
  loadAgreement,
  addUserGroup,
  isAddUserGroup,
  isRequestingMembership,
  isRequesting,
  isRequestingPDF,
  preselectFreezeTopic,
  loadBilling,
}) => {
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [isShowNewPlan, setShowNewPlan] = useState(false);
  const memberStatus = useSelector(state => state.auth.user.memberStatus);
  const isLimited = memberStatus === 'Canceled';

  useEffect(() => {
    loadBilling();
  }, [loadBilling]);

  useEffect(() => {
    loadMembership();
  }, [loadMembership]);

  useEffect(() => {
    setShowSubscribePopup(false);
  }, [isAddUserGroup]);

  useEffect(() => {
    const memberShipDigital = membership?.length > 0 && checkDigitalPlan(membership);
    setShowNewPlan(memberShipDigital);
  }, [membership]);

  return (
    <HeaderFooterLayout profile="Membership Plan">
      <div className={styles.containerMain}>
        {isRequesting ? <Spinner isWhite /> : null}

        {(isAddUserGroup || isRequestingMembership || isRequestingPDF) && (
          <div className={styles.popupBackground} />
        )}

        {showSubscribePopup && (
          <Popup
            handleCancelClick={() => setShowSubscribePopup(false)}
            text="Please confirm that you would like to subscribe for $39.95/month"
            confirm="Subscribe"
            handleConfirmClick={addUserGroup}
          />
        )}
        {!isRequesting && !isLimited ? (
          <SubscriptionPlan
            user={user}
            profile={profile}
            membership={membership}
            loadAgreement={loadAgreement}
            preselectFreezeTopic={preselectFreezeTopic}
          />
        ) : null}
        {!isRequesting && isShowNewPlan && (
          <DigitalMembership
            user={user}
            profile={profile}
            membership={membership}
            loadAgreement={loadAgreement}
            preselectFreezeTopic={preselectFreezeTopic}
          />
        )}
      </div>
    </HeaderFooterLayout>
  );
};
MembershipSubscription.propTypes = {
  user: PropTypes.object.isRequired,
  profile: PropTypes.object,
  loadMembership: PropTypes.func.isRequired,
  membership: PropTypes.array,
  addUserGroup: PropTypes.func.isRequired,
  isAddUserGroup: PropTypes.bool,
  isRequestingMembership: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool,
  isRequestingPDF: PropTypes.bool.isRequired,
  preselectFreezeTopic: PropTypes.func.isRequired,
  loadAgreement: PropTypes.func.isRequired,
  loadBilling: PropTypes.func.isRequired,
};
MembershipSubscription.defaultProps = {
  isAddUserGroup: null,
  isRequesting: null,
  profile: null,
  membership: null,
};
export default MembershipSubscription;
