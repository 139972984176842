import React from 'react';
import styles from './style.module.css';

const FinalSection = () => {
  return (
    <div className={styles.container}>
      <p className={styles.paragraph}>Thanks!</p>
      <p className={styles.paragraph}>Proof of vaccination uploaded successfully.</p>
    </div>
  );
};

export default FinalSection;
