import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { memberStatuses } from 'enums/memberStatuses';
import DialogItem from './DialogItem';
import styles from './styles.module.css';

const Dialogs = ({ resentMessages, selectTargetDialog, targetDialog }) => {
  const memberStatus = useSelector(state => state.auth.user.memberStatus);
  const isStaff = memberStatus === memberStatuses.staff;
  return (
    <div className={styles.wrapper}>
      {resentMessages?.length ? (
        <>
          <h3 className={styles.title}>
            {isStaff ? 'My Recent Messages' : 'My Trainers'}
          </h3>
          {resentMessages.map(dialogItem => (
            <DialogItem
              {...dialogItem}
              key={dialogItem.id}
              selectTargetDialog={selectTargetDialog}
              targetDialog={targetDialog}
            />
          ))}
        </>
      ) : null}
    </div>
  );
};

Dialogs.propTypes = {
  selectTargetDialog: PropTypes.func.isRequired,
  targetDialog: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  resentMessages: PropTypes.arrayOf(PropTypes.object),
};

Dialogs.defaultProps = {
  resentMessages: [],
};

export default Dialogs;
