import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import Spinner from 'components/shared/Spinner';
import { PopUp } from 'components/shared';
import Balance from './Balance';
import PaymentMethod, { assignListeners } from './PaymentMethod';
import AddPaymentMethod from './AddPaymentMethod';
import CreditCards from './Cards';
import { isEmpty } from 'lodash';

const Billing = ({
  url,
  loadBilling,
  profile,
  balance,
  loadBillingUrl,
  notPrimaryCard,
  primaryCard,
  makePrimary,
  makePayment,
  isRequesting,
  addCard,
  deleteCard,
  saveCardInfo,
  cardInfo,
}) => {
  useEffect(() => {
    loadBilling();
  }, [loadBilling]);

  const [addNewCard, setAddNewCard] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  // console.log("not primary cards",notPrimaryCard);
  // console.log(" primary cards",primaryCard);
  // console.log(" deleteCard",deleteCard);
  // console.log(" edit mode",editMode);
  // console.log(" setAddNewCard",setAddNewCard);

  useEffect(() => {}, []);
  return (
    <HeaderFooterLayout profile="Billing Information">
      <>
        {isRequesting && !addNewCard ? (
          <Spinner isWhite />
        ) : (
          <>
            <Balance
              balance={balance}
              makePayment={makePayment}
              primaryCard={primaryCard}
            />

            <PaymentMethod
              type={primaryCard?.type ? primaryCard.type.toUpperCase() : ''}
              digits={primaryCard ? primaryCard.lastFourDigits : '****'}
              expire={primaryCard ? primaryCard.expiration : '--/--'}
              setAddNewCard={setAddNewCard}
              loadBillingUrl={loadBillingUrl}
              saveCardInfo={saveCardInfo}
              buttonDisabled={Boolean(!primaryCard)}
              deleteMode={deleteMode}
              setDeleteMode={setDeleteMode}
              url={url}
              notPrimaryCard={notPrimaryCard}
            />

            { !isEmpty(primaryCard) || notPrimaryCard.length ? (
              <CreditCards
                notPrimaryCard={notPrimaryCard}
                makePrimary={makePrimary}
                profile={profile}
                deleteCard={deleteCard}
                deleteMode={deleteMode}
                primaryCard={primaryCard}
                loadBillingUrl={loadBillingUrl}
                url={url}
                setAddNewCard={setAddNewCard}
                saveCardInfo={saveCardInfo}
                setEditMode={id => {
                  setEditMode(id);
                }}
              />
            ) : null}

            <PopUp
              isOpened={addNewCard}
              setIsOpened={e => {
                setAddNewCard(e);
                setEditMode(false);
              }}
            >
              <AddPaymentMethod
                editMode={editMode}
                setEditMode={setEditMode}
                deleteCard={deleteCard}
                setAddNewCard={setAddNewCard}
                profile={profile}
                addCard={addCard}
                cardInfo={cardInfo}
                editCardInfo={
                  editMode &&
                  (primaryCard?.id === editMode
                    ? primaryCard
                    : notPrimaryCard.find(({ id }) => id === editMode))
                }
              />
            </PopUp>
          </>
        )}
      </>
    </HeaderFooterLayout>
  );
};
Billing.propTypes = {
  loadBilling: PropTypes.func.isRequired,
  addCard: PropTypes.func.isRequired,
  saveCardInfo: PropTypes.func.isRequired,
  deleteCard: PropTypes.func.isRequired,
  loadBillingUrl: PropTypes.func.isRequired,
  makePrimary: PropTypes.func.isRequired,
  makePayment: PropTypes.func.isRequired,
  balance: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  primaryCard: PropTypes.object.isRequired,
  notPrimaryCard: PropTypes.array.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  cardInfo: PropTypes.object,
  profile: PropTypes.object,
};
Billing.defaultProps = {
  balance: '',
  cardInfo: null,
  profile: null,
};
export default Billing;
