import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  isCreated: false,
  error: null,
};

const postCaseRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const postCaseSuccess = (state = INITIAL_STATE, { status }) => {
  return {
    ...state,
    isRequesting: false,
    isCreated: status,
  };
};
const postCaseFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    isCreated: false,
    error,
  };
};

export const HANDLERS = {
  [Types.POST_CASE_REQUEST]: postCaseRequest,
  [Types.POST_CASE_SUCCESS]: postCaseSuccess,
  [Types.POST_CASE_FAILURE]: postCaseFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
