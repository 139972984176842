import { connect } from 'react-redux';
import NewPasswordPage from 'pages/NewPasswordPage';
import Actions from 'store/auth/actions';

const mapStateToProps = state => {
  return {
    isRequesting: state.auth.isRequesting,
  };
};

const mapDispatchToProps = {
  resetPassword: Actions.Creators.resetPasswordRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordPage);
