import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  sendSafetyCommitmentRequest: [],
  sendSafetyCommitmentSuccess: ['status'],
  sendSafetyCommitmentFailure: ['error'],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
