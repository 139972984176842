/* eslint-disable react/prop-types */
import React from 'react';

export const getUserInfo = user => {
  return [
    { value: user?.id, label: 'Id' },
    { value: user?.sfId, label: 'Sf Id' },
    { value: user?.clubAutomationId, label: 'Club Automation Id' },
    { value: user?.contactType, label: 'Contact Type' },
    { value: user?.memberStatus, label: 'Member Status' },
    { value: user?.email, label: 'Email' },
    { value: user?.firstName, label: 'First Name' },
    { value: user?.lastName, label: 'Last Name' },
    { value: user?.favoriteLocation, label: 'Favorite Location' },
    { value: user?.phone, label: 'Phone' },
    { value: user?.birthDate, label: 'BirthDate' },
    { value: user?.isTrainer, label: 'Is trainer' },
  ];
};

export const getColumns = () => {
  return [
    {
      Header: 'Id',
      accessor: 'id',
      Cell: ({ cell: { value } }) => {
        return <span>{value}</span>;
      },
      width: '12%',
      id: 0,
    },
    {
      Header: 'Club SF id',
      accessor: 'clubId',
      Cell: ({ cell: { value } }) => {
        return <span>{value}</span>;
      },
      id: 1,
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ({ cell: { value } }) => {
        return <span>{value}</span>;
      },
      id: 2,
    },
    {
      Header: 'Day of Week',
      accessor: 'dayOfWeek',
      Cell: ({ cell: { value } }) => {
        return <span>{value}</span>;
      },
      width: '10%',
      id: 3,
    },
    {
      Header: 'Time',
      accessor: 'time',
      Cell: ({ cell: { value } }) => {
        return <span>{value}</span>;
      },
      width: '10%',
      id: 4,
    },
    {
      Header: 'Room',
      accessor: 'room',
      Cell: ({ cell: { value } }) => {
        return <span>{value}</span>;
      },
      width: '10%',
      id: 5,
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      Cell: ({ cell: { value } }) => {
        return <span>{value}</span>;
      },
      id: 6,
    },
  ];
};
