import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import IconSVG from 'components/shared/IconSVG';
import { resizeIcon } from 'assets/icons';
import styles from './styles.module.css';

const CallHeader = ({ callDurationString }) => {
  const participantName = useSelector(state => state.sessionCall.friendName);
  const handleFullScreen = () => {
    const isInFullScreen =
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);
    const docElm = document.getElementById('video-call');
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };
  return (
    <div className={styles.headerWrapper}>
      <span className={styles.duration}>{callDurationString}</span>
      <span className={styles.participantName}>{participantName || ''}</span>
      <div onClick={handleFullScreen} className={styles.resizeWrapper}>
        <IconSVG src={resizeIcon} width="15px" height="15px" />
      </div>
    </div>
  );
};
CallHeader.propTypes = {
  callDurationString: PropTypes.string.isRequired,
};

export default CallHeader;
