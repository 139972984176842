import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import styles from './styles.module.css';

const CancelSessionModal = ({ setClose, cancelSessions }) => {
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Cancel the Session</h3>
      <p className={styles.question}>Are you sure you want to cancel your session?</p>
      <div className={styles.buttonsWrapper}>
        <Button onClick={cancelSessions} width="255px" loaderStyles={{ left: '20px' }}>
          Cancel the Session
        </Button>
        <Button styleType="text" className={styles.blackButton} onClick={setClose}>
          No, I keep my session
        </Button>
      </div>
    </div>
  );
};

CancelSessionModal.propTypes = {
  setClose: PropTypes.func,
  cancelSessions: PropTypes.func,
};
CancelSessionModal.defaultProps = {
  setClose: () => {},
  cancelSessions: () => {},
};
export default CancelSessionModal;
