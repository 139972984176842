import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/shared/Spinner';
import MainContent from 'components/ChatPage/MainContent';
import MainContentMobile from 'components/ChatPage/MainContentMobile';
import MobileContext from 'context/MobileContext';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';

const ChatPage = ({ isRequesting, loadMessages, clearTargetDialog, ...props }) => {
  const isMobile = useContext(MobileContext);

  useEffect(() => {
    loadMessages();
  }, [loadMessages]);

  useEffect(() => {
    return () => {
      clearTargetDialog();
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <MainContentMobile
          isRequesting={isRequesting}
          loadMessages={loadMessages}
          clearTargetDialog={clearTargetDialog}
          {...props}
        />
      ) : (
        <HeaderFooterLayout>
          {isRequesting ? (
            <Spinner />
          ) : (
            <MainContent
              isRequesting={isRequesting}
              loadMessages={loadMessages}
              clearTargetDialog={clearTargetDialog}
              {...props}
            />
          )}
        </HeaderFooterLayout>
      )}
    </>
  );
};

ChatPage.propTypes = {
  loadMessages: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  clearTargetDialog: PropTypes.func.isRequired,
};

ChatPage.defaultProps = {};
export default ChatPage;
