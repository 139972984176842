export default function parsePhoneNumber(profile) {
  let phone = profile?.phone ? profile.phone.replace(/[+]/g, '') : '';
  if (phone.length === 10) {
    phone = `+1 (${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
  } else if (phone.length === 11) {
    phone = `+${phone.slice(0, 1)} (${phone.slice(1, 4)}) ${phone.slice(
      4,
      7
    )}-${phone.slice(7)}`;
  } else {
    phone = profile?.phone || '';
  }
  return phone;
}
