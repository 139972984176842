import { takeLatest, call, put } from 'redux-saga/effects';
import api from 'services/api';
import membershipActions from './actions';
import errorActions from '../error/actions';

const {
  Creators: { setError },
} = errorActions;

const {
  Types: {
    ADD_USER_GROUP_REQUEST,
    REMOVE_MEMBERSHIP_GROUP_REQUEST,
    GET_CANCEL_MEMBERSHIP_INFO_REQUEST,
  },
  Creators: {
    getCancelMembershipInfoSuccess,
    getCancelMembershipInfoFailure,

    addUserGroupSuccess,
    addUserGroupFailure,

    removeMembershipGroupSuccess,
    removeMembershipGroupFailure,
  },
} = membershipActions;

function* getCancelMembershipInfo() {
  try {
    const { data } = yield call(api.get, '/membership/cancel-info');
    yield put(getCancelMembershipInfoSuccess({ ...data }));
    if (data?.error) yield put(setError(data.error));
  } catch (error) {
    yield put(getCancelMembershipInfoFailure(error));
  }
}

function* addUserGroup() {
  try {
    const { data } = yield call(api.put, '/membership');
    yield put(addUserGroupSuccess(true));
    if (data?.error) yield put(setError(data.error));
  } catch (error) {
    yield put(addUserGroupFailure(error));
  }
}

function* removeMembershipGroup({ reason }) {
  try {
    const { data } = yield call(api.remove, `/membership?reason=${reason}`);
    yield put(removeMembershipGroupSuccess(data));
  } catch (error) {
    yield put(removeMembershipGroupFailure(error));
  }
}

export function* membershipSaga() {
  yield takeLatest(GET_CANCEL_MEMBERSHIP_INFO_REQUEST, getCancelMembershipInfo);
  yield takeLatest(ADD_USER_GROUP_REQUEST, addUserGroup);
  yield takeLatest(REMOVE_MEMBERSHIP_GROUP_REQUEST, removeMembershipGroup);
}
