import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RegistrationLayout from 'layouts/RegistrationLayout';
import Registration from 'components/Registration';

const RegistrationPage = ({ loadClubs, ...props }) => {
  const [title, setTitle] = useState('Choose your primary location');
  const [tab, setTab] = useState(0);

  useEffect(() => {
    loadClubs();
  }, [loadClubs]);

  return (
    <RegistrationLayout tab={tab} title={title}>
      <Registration {...props} setTab={setTab} title={title} setTitle={setTitle} />
    </RegistrationLayout>
  );
};

RegistrationPage.propTypes = {
  clubs: PropTypes.array.isRequired,
  loadClubs: PropTypes.func.isRequired,
};

export default RegistrationPage;
