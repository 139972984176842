import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { PopUp } from 'components/shared/PopUp';
import Spinner from 'components/shared/Spinner';
import DatePicker from 'components/ReservationPage/DatePicker';
import DatePickerMobile from 'components/ReservationPage/DatePickerMobile';
import { OneToManyVideoCallPage, ClassCallModal } from 'containers';
import ClassCard from 'components/shared/LessonCard/ClassCard';
import { formattedClass } from 'components/HomePage/ClassList/service';
import { CalendarCard } from './CalendarCard';
import { ClassDetails } from '../ClassDetails';
import styles from './styles.module.css';

export const ClassesContent = ({
  datesRange,
  targetDate,
  setTargetDate,
  isMobile,
  classes,
  addClassToFavorite,
  removeClassFromFavorite,
  isRequesting,
  isClassCallPopUpOpened,
  handleOpenClassCallPopUp,
  user,
}) => {
  const [clickedGroupEx, setClickedGroupEx] = useState({});
  const [isDetailsOpened, setIsDetailsOpened] = useState(false);

  const setSelectDate = dayIndex => {
    if (targetDate.index !== dayIndex) {
      const selectedDate = datesRange.find(date => date.index === dayIndex);
      setTargetDate(selectedDate);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.datePickerWrapper}>
        {isMobile ? (
          <DatePickerMobile
            datesRange={datesRange}
            selectDate={setSelectDate}
            targetDate={targetDate}
          />
        ) : (
          <DatePicker
            datesRange={datesRange}
            selectDate={setSelectDate}
            targetDate={targetDate}
          />
        )}
      </div>
      {isRequesting ? (
        <Spinner />
      ) : (
        <div className={styles.classesWrapper}>
          {classes &&
            !isRequesting &&
            classes.map(item => {
              const info = formattedClass(item);
              return (
                <div className={styles.classesContainer} key={item.classId}>
                  {isMobile ? (
                    <ClassCard
                      {...info}
                      {...item}
                      addClassToFavorite={addClassToFavorite}
                      removeClassFromFavorite={removeClassFromFavorite}
                      handleOpenClassCallPopUp={handleOpenClassCallPopUp}
                      setClickedGroupEx={setClickedGroupEx}
                      setIsDetailsOpened={setIsDetailsOpened}
                      user={user}
                    />
                  ) : (
                    <div
                      onClick={() => {
                        setIsDetailsOpened(true);
                        setClickedGroupEx(item);
                      }}
                    >
                      <CalendarCard
                        groupEx={{
                          ...item,
                          note: info?.note,
                        }}
                        setIsDetailsOpened={setIsDetailsOpened}
                        addClassToFavorite={addClassToFavorite}
                        removeClassFromFavorite={removeClassFromFavorite}
                        handleOpenClassCallPopUp={handleOpenClassCallPopUp}
                        user={user}
                      />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      )}

      <PopUp isOpened={isDetailsOpened} setIsOpened={setIsDetailsOpened} isFullyOpen>
        <ClassDetails
          data={clickedGroupEx}
          addClassToFavorite={addClassToFavorite}
          removeClassFromFavorite={removeClassFromFavorite}
          setIsOpened={setIsDetailsOpened}
          handleOpenClassCallPopUp={handleOpenClassCallPopUp}
          user={user}
        />
      </PopUp>
      <ClassCallModal isOpened={isClassCallPopUpOpened}>
        <OneToManyVideoCallPage />
      </ClassCallModal>
    </div>
  );
};

ClassesContent.propTypes = {
  datesRange: PropTypes.array,
  targetDate: PropTypes.object,
  setTargetDate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  classes: PropTypes.array,
  addClassToFavorite: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool,
  removeClassFromFavorite: PropTypes.func.isRequired,
  isClassCallPopUpOpened: PropTypes.bool.isRequired,
  handleOpenClassCallPopUp: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

ClassesContent.defaultProps = {
  datesRange: [],
  targetDate: {},
  isMobile: false,
  classes: [],
  isRequesting: null,
};
