import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { experienceUsSchema } from 'validation/auth.validation';
import MobileContext from 'context/MobileContext';
import Field from 'components/shared/Field';
import Button from 'components/shared/Button';
import MaskInput from 'components/shared/MaskInput';
import Select from 'components/shared/Select';
import styles from './style.module.css';
import { initialValues } from './service';

const ExperienceUs = ({ user, clubs, saveUser, isRequesting }) => {
  const isMobile = useContext(MobileContext);

  const [clubsOptions, setClubsOptions] = useState([]);
  const [club, selectClub] = useState();
  const [isAgree, setIsAgree] = useState(false);
  const [personalTrainingCb, setPersonalTrainingCb] = useState(false);

  useEffect(() => {
    if (clubs && clubs.length > 0) {
      const activeClubs = clubs.map(({ name, sfId }) => {
        return { label: name, value: sfId };
      });
      setClubsOptions(activeClubs);
      selectClub(activeClubs[0].value);
    }
  }, [clubs]);

  const inputWidth = isMobile ? '260px' : '300px';
  return (
    <div>
      <div className={styles.header}>
        <h1 className={styles.title}>FREE 1-DAY MEMBERSHIP</h1>
      </div>
      {user && user.id ? (
        <div className={styles.signUpSuccess}>
          <h1>AWESOME!</h1>
          <p>
            You are one step closer to joining the FITNESS SF Community.
            <br />
            <br />
            You should receive an email within the next 5-10 minutes. Please do not submit
            twice.
          </p>
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={values => {
            saveUser({
              ...values,
              club,
              interestedInPersonalTraining: personalTrainingCb,
            });
          }}
          validationSchema={experienceUsSchema}
        >
          {({ handleSubmit, handleChange, values, errors, touched }) => {
            return (
              <div>
                <div className={styles.select}>
                  <p className={styles.label}>Choose a location:</p>
                  <Select
                    label="Gym"
                    hideIndicatorSeparator
                    options={clubsOptions}
                    value={club}
                    onChange={e => selectClub(e.value)}
                  />
                </div>
                <div className={styles.inputsWrapper}>
                  <Field
                    value={values.firstName}
                    onChange={handleChange}
                    name="firstName"
                    width={inputWidth}
                    placeholder="FIRST NAME"
                    className={styles.input}
                    invalid={touched.firstName && Boolean(errors.firstName)}
                    error={errors.firstName}
                  />
                  <Field
                    value={values.lastName}
                    onChange={handleChange}
                    name="lastName"
                    width={inputWidth}
                    placeholder="LAST NAME"
                    className={styles.input}
                    invalid={touched.lastName && Boolean(errors.lastName)}
                    error={errors.lastName}
                  />
                  <Field
                    value={values.email}
                    onChange={handleChange}
                    name="email"
                    type="email"
                    width={inputWidth}
                    placeholder="EMAIL"
                    className={styles.input}
                    invalid={touched.email && Boolean(errors.email)}
                    error={errors.email}
                  />
                  <MaskInput
                    value={values.phone}
                    onChange={handleChange}
                    name="phone"
                    label="PHONE NUMBER"
                    width={inputWidth}
                    className={styles.input}
                    invalid={touched.phone && Boolean(errors.phone)}
                    error={errors.phone}
                    mask="(999) 999 - 9999"
                  />
                </div>
                <div className={styles.checkbox}>
                  <Field
                    type="checkbox"
                    value={personalTrainingCb}
                    onChange={() => setPersonalTrainingCb(!personalTrainingCb)}
                  />
                  <div onClick={() => setPersonalTrainingCb(!personalTrainingCb)}>
                    I&apos;m Interested in Personal Training.
                  </div>
                </div>
                <div className={styles.checkbox}>
                  <Field
                    type="checkbox"
                    value={isAgree}
                    onChange={() => setIsAgree(!isAgree)}
                  />
                  <div onClick={() => setIsAgree(!isAgree)}>
                    I agree to the{' '}
                    <a
                      href="https://fitnesssf.com/liability-waiver/"
                      className={styles.link}
                      target="blank"
                      onClick={e => e.stopPropagation()}
                    >
                      Fitness SF Liability Waiver{' '}
                    </a>
                    and{' '}
                    <a
                      href="https://www.fitnesssf.com/terms-of-service"
                      className={styles.link}
                      target="blank"
                      onClick={e => e.stopPropagation()}
                    >
                      Terms of Service
                    </a>
                    .
                  </div>
                </div>

                <p className={styles.submitText}>
                  By submitting, you agree to receive email promotions and other general
                  email messages from FITNESS SF and you confirm that you are the
                  subscriber or customary user of any information you submit. You can
                  unsubscribe at any time. Please refer to our{' '}
                  <a
                    href="https://fitnesssf.com/privacy-policy/"
                    className={styles.link}
                    target="blank"
                  >
                    Privacy Policy
                  </a>{' '}
                  for further details.
                  <br />
                  <br />
                  *Some restrictions may apply. See gym for details. Must provide a valid
                  local photo ID and must be of 18 years of age or older. The Free 1-Day
                  Membership can only be redeemed Monday through Friday 8am-8pm and
                  Saturday & Sunday 8am-5pm. The Free 1-Day Membership can only be used
                  once per person per calendar year.
                </p>

                <Button
                  isRequesting={isRequesting}
                  disabled={!isAgree || isRequesting}
                  className={styles.button}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

ExperienceUs.propTypes = {
  user: PropTypes.object.isRequired,
  clubs: PropTypes.array.isRequired,
  saveUser: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

export default ExperienceUs;
