import { takeLatest, call, put } from 'redux-saga/effects';
import api from 'services/api';

import pdfActions from './actions';
import errorActions from '../error/actions';

const {
  Types: { LOAD_AGREEMENT_REQUEST, LOAD_HISTORY_PDF_REQUEST },
  Creators: {
    loadAgreementSuccess,
    loadAgreementFailure,
    loadHistoryPDFSuccess,
    loadHistoryPDFFailure,
  },
} = pdfActions;

const {
  Creators: { setError },
} = errorActions;

function* loadAgreement({ payload }) {
  try {
    yield call(api.get, `/pdf/agreement/?location=${payload}`);

    const link = document.createElement('a');
    link.setAttribute(
      'href',
      `${process.env.REACT_APP_API_URI}/pdf/agreement?location=${payload}`
    );
    link.setAttribute('download', 'agreement');

    link.click();

    yield put(loadAgreementSuccess());
  } catch (error) {
    yield put(loadAgreementFailure(error));
    yield put(setError('Load agreement error.'));
  }
}

function* loadHistoryPDF({ payload }) {
  const { pdfStart, pdfEnd, idCA } = payload;

  try {
    yield call(
      api.get,
      `/pdf/history?startDate=${pdfStart}&endDate=${pdfEnd}&sfId=${idCA}`
    );

    const link = document.createElement('a');
    link.setAttribute(
      'href',
      `${process.env.REACT_APP_API_URI}/pdf/history?startDate=${pdfStart}&endDate=${pdfEnd}&sfId=${idCA}`
    );
    link.setAttribute('download', 'agreement');
    link.click();

    yield put(loadHistoryPDFSuccess());
  } catch (error) {
    yield put(loadHistoryPDFFailure(error));
    yield put(setError('Load history PDF error.'));
  }
}

export function* pdfSaga() {
  yield takeLatest(LOAD_AGREEMENT_REQUEST, loadAgreement);
  yield takeLatest(LOAD_HISTORY_PDF_REQUEST, loadHistoryPDF);
}
