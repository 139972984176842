import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/shared/Spinner';
import Main from 'components/ChoseLocationPage';

const ChooseLocationsPage = ({ loadClubs, isRequesting, ...props }) => {
  useEffect(() => {
    loadClubs();
  }, [loadClubs]);

  return <div>{isRequesting ? <Spinner /> : <Main {...props} />}</div>;
};

ChooseLocationsPage.propTypes = {
  loadClubs: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool,
};

ChooseLocationsPage.defaultProps = {
  isRequesting: false,
};

export default ChooseLocationsPage;
