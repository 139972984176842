import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ExperienceUsLayout from 'layouts/ExperienceUsLayout';
import ExperienceUs from 'components/ExperienceUs';

const ExperienceUsPage = ({ loadClubs, ...props }) => {
  useEffect(() => {
    loadClubs();
  }, [loadClubs]);

  return (
    <ExperienceUsLayout>
      <ExperienceUs {...props} />
    </ExperienceUsLayout>
  );
};

ExperienceUsPage.propTypes = {
  loadClubs: PropTypes.func.isRequired,
};

export default ExperienceUsPage;
