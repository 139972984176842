import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const Count = ({ range, duration }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (range > 0) {
      let current = 0;
      const stepTime = Math.abs(Math.floor(duration / range));

      const timer = setInterval(function spin() {
        current += 1;

        setValue(current);
        if (current === range) {
          clearInterval(timer);
        }
      }, stepTime);
    } else {
      setValue(range);
    }
  }, [range, duration]);

  return <p className={styles.activityNumber}>{value}</p>;
};

Count.propTypes = {
  range: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
};

Count.defaultProps = {};

export default Count;
