import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const NoTrainers = ({ myPersonalTrainers }) => (
  <div className={styles.wrapper}>
    {myPersonalTrainers
      ? 'You have no personal trainers yet'
      : 'Looks like there are no trainers who match your parameters.'}
  </div>
);

NoTrainers.propTypes = {
  myPersonalTrainers: PropTypes.bool,
};
NoTrainers.defaultProps = {
  myPersonalTrainers: false,
};

export default NoTrainers;
