export const slides = [
  {
    title: 'Invite your friends',
    text: 'Send them your referral link',
  },
  {
    title: 'They join FITNESS SF',
    text: 'Using your referral link',
  },
  {
    title: 'You receive a free month',
    text: 'Once a friend joins, you receive a free month.',
    link: {
      text: 'Certain limitations apply.*',
      ref: 'https://fitnesssf.com/referral-program',
    },
  },
];
