import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';

import styles from './styles.module.css';

const DatePickerItem = ({
  dayOfWeek,
  dateIndex,
  dayMonth,
  day,
  selectDate,
  selectedDateIndex,
}) => {
  const isMobile = useContext(MobileContext);

  if (dateIndex === selectedDateIndex)
    return (
      <div
        className={styles.wrapperSelected}
        onClick={() => {
          selectDate(dateIndex);
        }}
      >
        <span className={styles.dayOfWeekSelected}>{dayOfWeek.slice(0, 3)}</span>
        {isMobile ? (
          <div className={styles.selectedCircle}>
            <span className={styles.dayOfWeekNumberSelected}>{day}</span>
          </div>
        ) : (
          <span className={styles.dayMonthSelected}>{dayMonth}</span>
        )}
      </div>
    );
  return (
    <div
      className={styles.wrapper}
      onClick={() => {
        selectDate(dateIndex);
      }}
    >
      <span className={styles.dayOfWeek}>{dayOfWeek.slice(0, 3)}</span>
      {isMobile ? (
        <span className={styles.dayOfWeekNumber}>{day}</span>
      ) : (
        <span className={styles.dayMonth}>{dayMonth}</span>
      )}
    </div>
  );
};

DatePickerItem.propTypes = {
  dayOfWeek: PropTypes.string.isRequired,
  dayMonth: PropTypes.string.isRequired,
  day: PropTypes.string,
  selectDate: PropTypes.func.isRequired,
  dateIndex: PropTypes.number.isRequired,
  selectedDateIndex: PropTypes.number.isRequired,
};

DatePickerItem.defaultProps = {
  day: '',
};

export default DatePickerItem;
