import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components/shared/Modal';
import styles from './style.module.css';

const items = [
  {
    title: 'INTERACTIVE FITNESS CLASSES',
    description: 'Experience FITNESS group classes.',
  },
  {
    title: 'ON-DEMAND',
    description: 'Access our workout library that will be available anytime, anywhere.',
  },
  {
    title: 'ONE-ON-ONE VIRTUAL TRAINING',
    description:
      'Training clients can now workout with their Personal Trainer via videoconferencing.',
  },
  {
    title: 'NUTRITION',
    description:
      'Get Personalized meal recommendations and coaching through our partner EatLove to help you build lasting, healthy eating habits.',
  },
];

const RestrictionPopup = ({ isOpen, myProfile }) => {
  return (
    <div>
      <Modal
        isOpened={isOpen}
        setIsOpened={() => {}}
        inputClassName={`${styles.container} ${myProfile && styles.profileContainer}`}
        showCloseIcon={false}
      >
        <div className={styles.section}>
          <h1 className={`${styles.title} ${styles.popupTitle}`}>
            Your membership is ON HOLD
          </h1>
          <h2 className={styles.subHeader}>
            Reactivate your membership to gain access to our cool features:
          </h2>
          <div className={styles.servicesList}>
            {items.map(el => (
              <p key={el.title} className={styles.service}>
                &bull;
                <b>{el.title}</b> | {el.description}
              </p>
            ))}
          </div>
          <div className={styles.linkContent}>
            <p className={styles.link}>
              To reactivate your account, get in touch with us today!
            </p>
            <a
              href="mailto:freeze@fitnesssf.com?subject=Unfreeze Membership"
              className={styles.link}
            >
              freeze@fitnesssf.com
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
};

RestrictionPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  myProfile: PropTypes.bool,
};

RestrictionPopup.defaultProps = {
  myProfile: false,
};

export default RestrictionPopup;
