import { createReducer } from 'reduxsauce';

import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  isDownload: false,
  error: null,
};

const loadRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};

const loadAgreementSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: false,
    isDownload: true,
    error: null,
  };
};

const loadHistorySuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: false,
    isDownload: true,
    error: null,
  };
};

const loadFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    pdf: null,
    error,
  };
};

const clearError = (state = INITIAL_STATE) => {
  return {
    ...state,
    error: '',
  };
};

export const HANDLERS = {
  [Types.LOAD_AGREEMENT_REQUEST]: loadRequest,
  [Types.LOAD_AGREEMENT_SUCCESS]: loadAgreementSuccess,
  [Types.LOAD_AGREEMENT_FAILURE]: loadFailure,

  [Types.LOAD_HISTORY_PDF_REQUEST]: loadRequest,
  [Types.LOAD_HISTORY_PDF_SUCCESS]: loadHistorySuccess,
  [Types.LOAD_HISTORY_PDF_FAILURE]: loadFailure,
  [Types.CLEAR_ERROR]: clearError,
};

export default createReducer(INITIAL_STATE, HANDLERS);
