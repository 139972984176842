import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import AdminPageContent from 'components/AdminPageContent';

const AdminPage = ({ loadClubs, ...props }) => {
  useEffect(() => {
    loadClubs();
  }, [loadClubs]);

  return (
    <HeaderFooterLayout>
      <AdminPageContent {...props} />
    </HeaderFooterLayout>
  );
};

AdminPage.propTypes = {
  loadClubs: PropTypes.func.isRequired,
};

export default AdminPage;
