import { facebookSvg, twitterSvg, whatsAppSvg } from 'assets/icons';

export const getOptions = link => {
  const a = document.createElement('a');
  a.setAttribute('target', 'blank');
  return [
    {
      title: 'WhatsApp',
      icon: { url: whatsAppSvg, color: 'green' },
      onClick: () => {
        a.setAttribute('href', `https://wa.me/?text=${link}`);
        a.click();
      },
    },
    {
      title: 'Facebook',
      icon: { url: facebookSvg, color: 'blue' },
      onClick: () => {
        a.setAttribute('href', `https://www.facebook.com/sharer/sharer.php?u=${link}`);
        a.click();
      },
    },
    {
      title: 'Tweet',
      icon: { url: twitterSvg, color: 'light-blue' },
      onClick: () => {
        a.setAttribute('href', `http://twitter.com/share?text=${link}`);
        a.click();
      },
    },
  ];
};
