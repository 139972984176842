import React from 'react';
import { appIcon, cardIcon, emailIcon, workoutIcon } from 'assets/icons';
import styles from './style.module.css';

const items = [
  { img: emailIcon, text: 'You will receive a confirmation email' },
  { img: appIcon, text: 'Log into the app and make your first reservation' },
  { img: cardIcon, text: 'Bring a photo ID on your first visit' },
  {
    img: workoutIcon,
    text:
      'Access our digital features including Live Group Fitness Classes, On Demand Workouts and Personalized Nutrition Coaching through EatLove',
  },
];

const CongratulationsSection = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1 className={styles.title}>Congratulations!</h1>
        <p>
          Thank you for joining FITNESS SF. You`re the newest member of our thriving
          community.
        </p>
        <p className={styles.subtitle}>Here`s what to expect next:</p>
        <ul>
          {items.map(({ img, text }) => (
            <li key={text} className={styles.card}>
              <img src={img} alt="img" className={styles.image} />
              <p>{text}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CongratulationsSection;
