/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { creditCardValidationSchema } from 'services/validation';
import Button from 'components/shared/Button';
import Field from 'components/shared/Field';
import styles from './styles.module.css';

const MyForm = props => {
  const {
    setEditMode,
    values,
    errors,
    handleChange,
    setFieldValue,
    handleSubmit,
    setAddNewCard,
    touched,
    editMode,
  } = props;

  return (
    <div className={styles.container}>
      <h1>{editMode ? 'Update Card' : 'Add Payment Method'}</h1>
      <form onSubmit={handleSubmit}>
        <Field
          value={values.expiration}
          name="expiration"
          id="expiration"
          type="text"
          placeholder="EXPIRY DATE"
          inputClassName={styles.disabledinput}
          invalid={touched.expiration && Boolean(errors.expiration)}
          error={errors.expiration}
          readOnly
        />
        <Field
          value={values.cardNumber}
          name="cardNumber"
          id="cardNumber"
          type="text"
          placeholder="CARD NUMBER"
          inputClassName={styles.disabledinput}
          error={errors.cardNumber}
          invalid={touched.cardNumber && Boolean(errors.cardNumber)}
          readOnly
        />
        <Field
          autoFocus
          value={values.name}
          name="name"
          id="name"
          type="text"
          placeholder="NAME ON CARD"
          inputClassName={styles.Nameinput}
          onChange={handleChange}
          invalid={touched.name && Boolean(errors.name)}
          error={errors.name}
        />

        <div className={styles.checkBox}>
          <Field
            type="checkbox"
            value={values.checkBox}
            onChange={() => setFieldValue('checkBox', !values.checkBox)}
          />
          <p className={styles.label}>This is my primary card</p>
        </div>

        <Button className={styles.button} type="submit">
          Add Payment Method
        </Button>
      </form>
      <p className={styles.cancel} onClick={() => setAddNewCard(false)}>
        Cancel
      </p>
    </div>
  );
};

const hocForMyForm = withFormik({
  enableReinitialize: true,
  validationSchema: creditCardValidationSchema,
  mapPropsToValues: ({ cardInfo, editCardInfo }) => {
    return {
      name: editCardInfo?.cardNickName || '',
      cardNumber: cardInfo
        ? cardInfo.cc_number
        : editCardInfo?.lastFourDigits
        ? `**** **** **** ${editCardInfo?.lastFourDigits}`
        : '',
      expiration: cardInfo
        ? `${cardInfo.expiration_month}/${cardInfo.expiration_year}`
        : editCardInfo?.expiration || '',
      checkBox: editCardInfo ? editCardInfo.isPrimary : true,
    };
  },
  handleSubmit: (
    values,
    {
      props: {
        addCard,
        profile,
        cardInfo,
        setAddNewCard,
        editMode,
        deleteCard,
        setEditMode,
      },
      setSubmitting,
    }
  ) => {
    const payload = {
      cardNumber: values.cardNumber,
      billingName: profile?.name || '',
      nickname: values.name,
      isPrimary: values.checkBox,
      i4goUniqueId: cardInfo.cc_merchant_unique_id, // cardInfo.i4go_uniqueid,
      cardExpirationMonth: cardInfo.expiration_month, // cardInfo.i4go_expirationmonth,
      cardExpirationYear: cardInfo.expiration_year, // cardInfo.i4go_expirationyear,
    };

    addCard(payload);
    if (editMode) {
      deleteCard(editMode);
    }
    setEditMode(false);
    setAddNewCard(false);
    setSubmitting(false);
  },
});

const AddPaymentMethod = hocForMyForm(MyForm);
MyForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setAddNewCard: PropTypes.func.isRequired,
  editMode: PropTypes.any.isRequired,
  setEditMode: PropTypes.any.isRequired,
};
export default AddPaymentMethod;
