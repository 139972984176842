import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { PopUp } from 'components/shared/PopUp';
import Spinner from 'components/shared/Spinner';
import DatePicker from 'components/ReservationPage/DatePicker';
import DatePickerMobile from 'components/ReservationPage/DatePickerMobile';
import { formattedClass } from 'components/HomePage/ClassList/service';
import { CalendarCard } from './CalendarCard';
import { ClassDetails } from '../ClassDetails';
import styles from './styles.module.css';

export const ClassesContent = ({
  datesRange,
  targetDate,
  setTargetDate,
  isMobile,
  classes,
  isRequesting,
  handleOpenClassCallPopUp,
}) => {
  const [clickedGroupEx, setClickedGroupEx] = useState({});
  const [isDetailsOpened, setIsDetailsOpened] = useState(false);

  const setSelectDate = dayIndex => {
    if (targetDate.index !== dayIndex) {
      const selectedDate = datesRange.find(date => date.index === dayIndex);
      setTargetDate(selectedDate);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.datePickerWrapper}>
        {isMobile ? (
          <DatePickerMobile
            datesRange={datesRange}
            selectDate={setSelectDate}
            targetDate={targetDate}
          />
        ) : (
          <DatePicker
            datesRange={datesRange}
            selectDate={setSelectDate}
            targetDate={targetDate}
          />
        )}
      </div>
      {isRequesting ? (
        <Spinner />
      ) : (
        <div className={styles.classesWrapper}>
          {classes &&
            !isRequesting &&
            classes.map(item => {
              const info = formattedClass(item);
              return (
                <div
                  className={styles.classesContainer}
                  key={item.classId}
                  onClick={() => {
                    setIsDetailsOpened(true);
                    setClickedGroupEx(item);
                  }}
                >
                  <CalendarCard
                    groupEx={{
                      ...item,
                      note: info?.note,
                    }}
                    setIsDetailsOpened={setIsDetailsOpened}
                  />
                </div>
              );
            })}
        </div>
      )}

      <PopUp isOpened={isDetailsOpened} setIsOpened={setIsDetailsOpened}>
        <ClassDetails data={clickedGroupEx} />
      </PopUp>
    </div>
  );
};

ClassesContent.propTypes = {
  datesRange: PropTypes.array,
  targetDate: PropTypes.object,
  setTargetDate: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  classes: PropTypes.array,
  isRequesting: PropTypes.bool,
  handleOpenClassCallPopUp: PropTypes.func.isRequired,
};

ClassesContent.defaultProps = {
  datesRange: [],
  targetDate: {},
  isMobile: false,
  classes: [],
  isRequesting: null,
};
