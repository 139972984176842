/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react';
import screenfull from 'screenfull';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import { playIcon } from 'assets/icons';
import IconSVG from 'components/shared/IconSVG';
import styles from './styles.module.css';

export default class OvVideoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoIsPlaying: false,
    };
    this.videoRef = React.createRef();
    this.playVideo = this.playVideo.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    if (this.props && user.streamManager && !!this.videoRef) {
      user.getStreamManager().addVideoElement(this.videoRef.current);
    }

    if (this.props && user.streamManager.session && user && !!this.videoRef) {
      user.streamManager.session.on('signal:userChanged', event => {
        const data = JSON.parse(event.data);
        if (data.isScreenShareActive !== undefined) {
          user.getStreamManager().addVideoElement(this.videoRef.current);
        }
      });
    }
  }

  componentDidUpdate(props) {
    const { user } = this.props;
    if (props && !!this.videoRef) {
      user.getStreamManager().addVideoElement(this.videoRef.current);
    }
  }

  playVideo() {
    this.videoRef.current.play();
    this.setState({ videoIsPlaying: true });
  }

  render() {
    const isMobile = this.context;
    const { user, mutedSound, isTrainer } = this.props;
    const { videoIsPlaying } = this.state;
    const isInFullScreen = screenfull.isFullscreen;
    function iOSSafari(userAgent) {
      return (
        /iP(ad|od|hone)/i.test(userAgent) &&
        /WebKit/i.test(userAgent) &&
        !/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent)
      );
    }
    const isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') === -1 &&
      navigator.userAgent.indexOf('FxiOS') === -1;
    return (
      <div
        className={isMobile ? styles.callWindowWrapperMobile : styles.callWindowWrapper}
      >
        <video
          autoPlay
          playsInline
          id={`video-${user.getStreamManager().stream.streamId}`}
          ref={this.videoRef}
          muted={mutedSound}
          className={isMobile ? styles.localMobile : styles.local}
          style={{
            maxHeight: !isInFullScreen && !isMobile ? '50vh' : 'none',
            height: !isInFullScreen && !isMobile ? '480px' : '100%',
          }}
        />
        {(iOSSafari(navigator.userAgent) || isSafari) &&
          !isTrainer &&
          !videoIsPlaying && (
            <IconSVG
              className={styles.playButton}
              handleClick={this.playVideo}
              src={playIcon}
              height="50px"
              width="50px"
            />
          )}
      </div>
    );
  }
}

OvVideoComponent.contextType = MobileContext;

OvVideoComponent.propTypes = {
  user: PropTypes.object.isRequired,
  mutedSound: PropTypes.bool,
  isTrainer: PropTypes.bool.isRequired,
};

OvVideoComponent.defaultProps = {
  mutedSound: false,
};
