import moment from 'moment';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';

const calculateClassDate = ({ date, startTime, endTime }) => {
  if (!date || !startTime || !endTime) return '';
  const classDurationFormatted = `(${moment(`${date}T${endTime}`).diff(
    `${date}T${startTime}`,
    'minutes'
  )}min)`;
  const dateFormatted = moment(`${date} ${startTime}`).format('ddd MM/DD - h:mma');
  const resultDate = `${dateFormatted} ${classDurationFormatted}`;
  const reduceDate = `${moment(`${date} ${startTime}`).format(
    'h:mma'
  )} ${classDurationFormatted}`;
  return { resultDate, reduceDate };
};

export const formattedClass = _class => {
  const { isLive, isVirtual } = _class;
  const sfDateTime = getSFTime('full');
  const title = _class?.classInfo?.programName || _class?.classInfo?.name;
  const { resultDate, reduceDate } = calculateClassDate(_class);
  const location = _class?.classInfo?.club?.name;
  const timeDiff = moment(sfDateTime).diff(
    moment(`${_class.date}T${_class.startTime}`).format('YYYY-MM-DDTHH:mm'),
    'minutes'
  );
  const studioName = _class?.studioName || 'Not set';

  const note =
    timeDiff < 0 && timeDiff >= -10
      ? 'Starts in 10 mins!'
      : timeDiff >= 0 && timeDiff < 50 && !isLive && !isVirtual
      ? 'Now!'
      : '';

  const locationTitle =
    isLive || isVirtual
      ? timeDiff >= 0 && timeDiff < 50
        ? 'Watch live!'
        : 'Live Class'
      : location;

  const time = moment(`${_class.date} ${_class.startTime}`).format('h:mm a');

  return {
    title,
    date: resultDate,
    classDate: reduceDate,
    locationTitle,
    note,
    mobileCard: {
      date: moment(`${_class.date}T${_class.startTime}`).format('MMMM DD'),
      time: moment(time, 'h:mm a').minutes()
        ? time
        : moment(time, 'h:mm a').format('h a'),
      locationName: location,
      trainer: _class.trainerName,
      studioName,
    },
  };
};
