import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { roundLogo } from 'assets/logos';
import Text from 'components/shared/Text';
import history from 'store/history';
import styles from './index.module.css';

const ThankYouPage = ({ message, header, linkTo, linkText }) => {
  useEffect(() => {}, []);

  const handleBackClick = () => {
    history.push('/login');
  };

  return (
    <div className={styles.page}>
      <img src={roundLogo} alt="logo" className={styles.logo} />
      <Text tag="p" type="p1">
        {header}
      </Text>
      <Text tag="p" type="p4">
        {message}
      </Text>
      {linkTo && (
        <button type="button" className={styles.link} onClick={() => handleBackClick()}>
          {linkText}
        </button>
      )}
    </div>
  );
};

ThankYouPage.propTypes = {
  message: PropTypes.string,
  header: PropTypes.string,
  linkTo: PropTypes.string,
  linkText: PropTypes.string,
};

ThankYouPage.defaultProps = {
  message: '',
  linkTo: '',
  linkText: '',
  header: 'Thank you!',
};

export default ThankYouPage;
