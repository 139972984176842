import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/object';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import { ConnectedRouter as Router } from 'connected-react-router';
import App from 'components/App';
import store from 'store';
import history from 'store/history';

import { checkHttps } from './utils/checkHttps';
import * as serviceWorker from './serviceWorker';
import './index.css';

const env = process.env.REACT_APP_ENV;
console.log(env);
if (env !== "Dev") {
  checkHttps();
}

const dataWalletPolicyChange = policy => {
  /* eslint-disable-next-line no-console */
  console.warn('Data Policy Privacy changed');
  /* eslint-disable-next-line no-console */
  console.warn(policy);
};

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

const RootApp = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  );
};
ReactDOM.render(<RootApp />, document.getElementById('root'));

serviceWorker.register();
