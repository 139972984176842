import React, { useState, useEffect, useContext } from 'react';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import PropTypes from 'prop-types';
import Spinner from 'components/shared/Spinner';
import MobileContext from 'context/MobileContext';
import { horizontalLogo } from 'assets/logos';
import history from 'store/history';
import { ClassesHeader } from './ClassesHeader';
import { ClassesContent } from './ClassesContent';
import { calculateFourWeeksDates } from './service';
import styles from './styles.module.css';
import { useLocation } from 'react-router-dom';

const GuestClasses = ({
  loadClubs,
  clubs,
  classes,
  isRequesting,
  loadClassesSchedule,
  loadClubsIsRequesting,
  changeSelectedClub,
}) => {
  const isMobile = useContext(MobileContext);
  const datesRange = calculateFourWeeksDates();
  const [targetDate, setTargetDate] = useState(datesRange[0]);
  const [selectedClub, setSelectedClub] = useState('all');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const appQuery = queryParams.get("type") === "app-classes";

  useEffect(() => {
    loadClubs();
  }, [loadClubs]);

  useEffect(() => {
    loadClassesSchedule({
      startDate: targetDate.date || getSFTime(),
      clubId: selectedClub,
    });
  }, [selectedClub]);

  useEffect(() => {
    loadClassesSchedule({
      startDate: targetDate.date,
      clubId: selectedClub,
    });
  }, [targetDate]);

  return (
    <>
      <div className={styles.headerContainer}>
        <div
          onClick={ !appQuery ? () => history.push('/') : "" }
          style={ !appQuery ? { cursor: 'pointer', marginLeft: '10px' } : { marginLeft: '10px' }}
        >
          <img src={horizontalLogo} alt="logo" className={styles.logoImage} />
        </div>
        {
          !appQuery ? 
            <div className={styles.signIn} onClick={() => history.push('/login')}>
              Sign in
            </div> 
            : ""
        }
      </div>
      {isRequesting && loadClubsIsRequesting && !clubs.length ? (
        <Spinner />
      ) : (
        <div>
          <ClassesHeader
            selectedClub={selectedClub}
            setSelectedClub={setSelectedClub}
            clubs={clubs}
            changeSelectedClub={changeSelectedClub}
          />
          <ClassesContent
            datesRange={datesRange}
            targetDate={targetDate}
            setTargetDate={setTargetDate}
            isMobile={isMobile}
            isRequesting={isRequesting}
            classes={classes}
          />
        </div>
      )}
    </>
  );
};

GuestClasses.propTypes = {
  classes: PropTypes.array,
  clubs: PropTypes.array,
  loadClubs: PropTypes.func.isRequired,
  loadClassesSchedule: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  loadClubsIsRequesting: PropTypes.bool.isRequired,
  changeSelectedClub: PropTypes.func.isRequired,
};

GuestClasses.defaultProps = {
  classes: [],
  clubs: [],
};

export default GuestClasses;
