const awsS3Url = process.env.REACT_APP_AMAZON_S3_STORAGE_URL;

export const powerYoga = `${awsS3Url}/classes-images/power_yoga1.jpg`;
export const vinyasaYoga = `${awsS3Url}/classes-images/vinyasa_yoga1.jpg`;
export const zumba = `${awsS3Url}/classes-images/zumba.jpg`;
export const nidraYoga = `${awsS3Url}/classes-images/yoga_nidra.jpg`;
export const zumbaGold = `${awsS3Url}/classes-images/zumba_gold.jpg`;
export const sixPack = `${awsS3Url}/classes-images/6pack.jpg`;
export const hiit = `${awsS3Url}/classes-images/hiit.jpg`;
export const hipHop = `${awsS3Url}/classes-images/hiphop.jpg`;
export const urbanKick = `${awsS3Url}/classes-images/urban_kick.jpg`;
export const bootCamp = `${awsS3Url}/classes-images/bootcamp.jpg`;
