import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getInvitedUsersRequest: [],
  getInvitedUsersSuccess: ['invitedUsers'],
  getInvitedUsersFailure: [],
  getTrainerPromoCodesRequest: [],
  getTrainerPromoCodesSuccess: ['promoCodes'],
  getTrainerPromoCodesFailure: [],
});

export default {
  Types,
  Creators,
};
