import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/shared/Select';
import Field from 'components/shared/Field';
import Button from 'components/shared/Button';
import ConfirmPopup from 'components/shared/ConfirmPopup';
import styles from './style.module.css';

const EditReservation = ({
  clubsOptions,
  reservation,
  updateReservation,
  open,
  onClose,
  isRequesting,
}) => {
  const [data, setData] = useState(reservation);
  useEffect(() => {
    setData({
      ...reservation,
      time: `${reservation?.time}`,
      dayOfWeek: `${reservation?.dayOfWeek}`,
      room: reservation?.room ? `${reservation?.room}` : null,
    });
  }, [reservation]);

  return (
    <ConfirmPopup open={open} onClose={onClose} isRequesting={isRequesting}>
      <div className={styles.container}>
        <div className={styles.select}>
          <Select
            hideIndicatorSeparator
            options={clubsOptions}
            value={data?.clubId}
            onChange={e => setData({ ...data, clubId: e.sfId })}
          />
        </div>

        <Field
          value={data?.userId}
          onChange={e => setData({ ...data, userId: e.target.value })}
          name="UserId"
          placeholder="User Id"
          className={styles.input}
        />
        <Field
          value={data?.date}
          onChange={e => setData({ ...data, date: e.target.value })}
          placeholder="date"
          name="date"
          className={styles.input}
        />
        <Field
          type="number"
          value={data?.dayOfWeek}
          onChange={e => setData({ ...data, dayOfWeek: e.target.value })}
          placeholder="Day of week"
          name="dayOfWeek"
          className={styles.input}
        />
        <Field
          type="number"
          value={data?.time}
          onChange={e => setData({ ...data, time: e.target.value })}
          placeholder="time"
          name="time"
          className={styles.input}
        />
        <Field
          type="number"
          value={data?.room}
          onChange={e => setData({ ...data, room: e.target.value })}
          placeholder="room Id"
          name="room"
          className={styles.input}
        />
        <Field
          value={data?.createdAt}
          onChange={e => setData({ ...data, createdAt: e.target.value })}
          placeholder="createdAt"
          name="createdAt"
          className={styles.input}
        />

        <Button className={styles.button} onClick={() => updateReservation(data)}>
          Update
        </Button>
      </div>
    </ConfirmPopup>
  );
};

EditReservation.propTypes = {
  clubsOptions: PropTypes.array.isRequired,
  reservation: PropTypes.object.isRequired,
  updateReservation: PropTypes.func.isRequired,

  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

export default EditReservation;
