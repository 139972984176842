import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { memberStatuses } from 'enums/memberStatuses';
import styles from './styles.module.css';

const Header = ({ targetDialog }) => {
  const { dialogPersonName, createdAt, photo } = targetDialog;
  const memberStatus = useSelector(state => state.auth.user.memberStatus);
  const isStaff = memberStatus === memberStatuses.staff;
  const isToday = createdAt && moment(createdAt).isSame(Date.now(), 'day');
  return (
    <>
      {targetDialog ? (
        <div className={styles.wrapper}>
          <div className={styles.profileWrapper}>
            {!isStaff ? (
              <div
                className={styles.photo}
                style={{ backgroundImage: `url(${photo})` }}
              />
            ) : null}

            <div className={styles.name}>{dialogPersonName || ''}</div>
          </div>
          <div className={styles.lastDate}>
            {createdAt
              ? isToday
                ? moment(createdAt).format('hh:mm A')
                : moment(createdAt).format('MMM Do YY')
              : ''}
          </div>
        </div>
      ) : null}
    </>
  );
};

Header.propTypes = {
  targetDialog: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default Header;
