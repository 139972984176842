import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { responsive, slides } from './service';
import styles from './style.module.css';

const IntroductionSection = () => {
  const [slideIndex, setSlideIndex] = useState(2);
  return (
    <>
      <div className={styles.circle}>
        <img
          src={slides.find(({ index }) => index === slideIndex)?.img}
          alt=""
          className={styles.img}
        />
      </div>
      <p className={styles.title}>Welcome to FITNESS SF!</p>
      <Carousel
        responsive={responsive}
        infinite
        keyBoardControl
        showDots
        renderDotsOutside
        arrows={false}
        afterChange={(e, i) => setSlideIndex(i?.currentSlide)}
      >
        {slides.map(({ img, title, text }) => (
          <div className={styles.container} key={title}>
            <h3 className={styles.subtitle}>{title}</h3>
            <div className={styles.dash} />
            <div className={styles.description}>{text}</div>
          </div>
        ))}
      </Carousel>
    </>
  );
};

export default IntroductionSection;
