import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadClassesScheduleRequest: ['payload'],
  loadClassesScheduleSuccess: ['payload'],
  loadClassesScheduleFailure: ['error'],

  loadGuestClassesScheduleRequest: ['payload'],
  loadGuestClassesScheduleSuccess: ['payload'],
  loadGuestClassesScheduleFailure: ['error'],

  loadFavoriteClassesRequest: ['payload'],
  loadFavoriteClassesSuccess: ['favoriteClasses'],
  loadFavoriteClassesFailure: ['error'],

  addClassToFavoriteRequest: ['classId'],
  addClassToFavoriteSuccess: ['addedClass'],
  addClassToFavoriteFailure: ['error'],

  removeClassFromFavoriteRequest: ['classId'],
  removeClassFromFavoriteSuccess: ['removedClassId'],
  removeClassFromFavoriteFailure: ['error'],

  searchClasses: ['searchValue'],
  filterClasses: ['filters'],
  changeSelectedClub: ['preselectedClub'],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
