/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'components/shared/Carousel';
import history from 'store/history';
import { useDispatch, useSelector } from 'react-redux';
import MobileContext from 'context/MobileContext';
import styles from './style.module.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 2,
  },
};

const TrainerSection = ({ selectTrainer, setIsOpened }) => {
  const isMobile = useContext(MobileContext);
  const trainers = useSelector(state => state.training.trainers);
  const favoriteLocation = useSelector(state => state.auth.user?.favoriteLocationCAId);
  const favoriteLocationTrainers =
    trainers?.length > 0 &&
    trainers.filter(({ locationId }) => locationId === favoriteLocation);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'LOAD_TRAINERS_REQUEST',
    });
  }, []);

  return favoriteLocationTrainers?.length > 0 ? (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>
        <span>{favoriteLocationTrainers[0]?.locationName} Trainers</span>
        <div className={styles.line} />
        <p onClick={() => history.push('/training?tab=trainers')}>See all</p>
      </h1>
      <div className={styles.cards}>
        <Carousel responsive={responsive}>
          {favoriteLocationTrainers.map(t => (
            <div
              key={t.idCA}
              className={styles.trainer}
              onClick={() => {
                setIsOpened(true);
                selectTrainer({
                  name: t.name,
                  firstName: t.firstName,
                  photo: t.photo,
                  location: t.locationName,
                  specializations: t.specializations,
                  credentials: t.credentials,
                  trainerId: t.trainerId,
                  idCA: t.idCA,
                  isChatAvailable: t.isChatAvailable,
                  isFavorite: t.isFavorite,
                });
              }}
            >
              <img src={t.photo} alt="" />
              <p>{t.firstName}</p>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  ) : (
    <></>
  );
};

TrainerSection.propTypes = {
  selectTrainer: PropTypes.func.isRequired,
  setIsOpened: PropTypes.func.isRequired,
};

export default TrainerSection;
