import React from 'react';
import PropTypes from 'prop-types';

const IconSVG = ({ src, width, height, className, handleClick }) => (
  <span onClick={handleClick} style={{ userSelect: 'none' }}>
    <img src={src} className={className} alt="" style={{ width, height }} />
  </span>
);

IconSVG.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleClick: PropTypes.func,
};

IconSVG.defaultProps = {
  className: '',
  handleClick: () => {},
};

export default IconSVG;
