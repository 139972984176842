import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { memberStatuses } from 'enums/memberStatuses';
import { useSelector } from 'react-redux';
import {
  likeEnabled,
  locationSvg,
  phoneIcon,
  hours as hoursIcon,
  Amenities1,
  Amenities2,
  Amenities3,
} from 'assets/icons/index';
import styles from './styles.module.css';

const validateOpeningHours = openingHours => {
  try {
    if (openingHours === null) return [];
    const result = [];

    for (let i = 0; i < openingHours.length; i++) {
      if (openingHours[i] !== '') {
        const splitDate = openingHours[i].split(/(\d+)/);
        const [days, ...hours] = splitDate;
        result.push({ days, hours: hours.join('') });
      }
    }
    return result;
  } catch (e) {
    return [];
  }
};
const destructClub = club => {
  if (club === null) {
    return {
      name: '',
      description: '',
      city: '',
      street: '',
      image: '',
      imagePosition: '',
      image2x: '',
      imagePosition2x: '',
      clubPhoneNumber: '',
      openingHours: '',
      roomServices: [],
      exServices: [],
      otherServices: [],
      sfId: '',
      clubAutomationClubId: '',
      videoLink: '',
    };
  }
  return {
    name: club.name,
    description: club.description,
    city: club.city,
    street: club.street,
    image: club.siteImage,
    imagePosition: club.backgroundPosition,
    image2x: club.siteImage,
    imagePosition2x: club.backgroundPosition,
    clubPhoneNumber: club.clubPhoneNumber,
    openingHours: club.openingHours.split(','),
    roomServices: club.amenities1.split(','),
    exServices: club.amenities2.split(','),
    otherServices: club.amenities3.split(','),
    sfId: club.Id,
    clubAutomationClubId: club.clubAutomationClubId,
    videoLink: club?.videoLink,
  };
};

const isAllowedSchedule = name => {
  return !['Marin', 'SF Castro', 'SF SOMA'].includes(name);
};

const LocationPopUp = ({
  targetClub,
  favoriteClub,
  changeFavoriteLocation,
  changeClassesSelectedClub,
}) => {
  const {
    name,
    description,
    city,
    street,
    image2x,
    imagePosition2x,
    clubPhoneNumber,
    openingHours,
    roomServices,
    exServices,
    otherServices,
    sfId,
    clubAutomationClubId,
  } = destructClub(targetClub);

  const validatedHours = validateOpeningHours(openingHours);
  const memberStatus = useSelector(state => state.auth.user.memberStatus);
  const membershipRestrictions =
    memberStatus !== memberStatuses.onHold && memberStatus !== memberStatuses.notActive;
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.logo}
        style={{
          backgroundImage: `url(${image2x})`,
          backgroundPositionY: imagePosition2x,
        }}
      />
      <div className={styles.mainContent}>
        <span className={styles.title}>{name}</span>
        <div className={styles.clubInfoWrapper}>
          <div className={styles.clubInfo}>
            {openingHours && (
              <div className={styles.schedule}>
                <img src={hoursIcon} alt="location" className={styles.icon} />
                <div className={styles.wrapperBlock}>
                  {validatedHours.length
                    ? validatedHours.map(item => {
                        return (
                          <div
                            key={`${item.days}-${item.hours}`}
                            className={styles.scheduleItem}
                          >
                            <span className={styles.scheduleDays}>{item.days}</span>
                            <span className={styles.scheduleHours}>{item.hours}</span>
                          </div>
                        );
                      })
                    : '-'}
                </div>
              </div>
            )}
            {city || street ? (
              <div className={styles.location}>
                <img src={locationSvg} alt="location" className={styles.icon} />
                <div className={styles.wrapperBlock}>
                  {city}
                  <br />
                  {street}
                </div>
              </div>
            ) : null}
            {clubPhoneNumber && (
              <div className={styles.phone}>
                <img src={phoneIcon} alt="location" className={styles.icon} />
                <div className={styles.wrapperBlock}>(+1) {clubPhoneNumber}</div>
              </div>
            )}
          </div>
          {favoriteClub !== clubAutomationClubId && (
            <div
              className={[styles.button, styles.activeButton].join(' ')}
              onClick={() => {
                changeFavoriteLocation(clubAutomationClubId);
              }}
            >
              Mark as favorite location
              <img className={styles.heartIcon} src={likeEnabled} alt="" />
            </div>
          )}
        </div>
      </div>
      {description && (
        <div className={styles.paragraphWrapper}>
          <p className={styles.paragraph}>{description || null}</p>
        </div>
      )}
      <div className={styles.mainContent}>
        <div className={styles.services}>
          <p className={styles.servicesTitle}> Amenities &amp; Services</p>
          <div className={styles.serviceItems}>
            {exServices.length ? (
              <div className={styles.serviceItemWrapper}>
                <div className={styles.amenitiesIconWrapper}>
                  <img src={Amenities1} alt="location" className={styles.amenitiesIcon} />
                </div>
                {exServices.map(service => (
                  <p key={service} className={styles.serviceItem}>
                    {service}
                  </p>
                ))}
              </div>
            ) : null}
            {roomServices.length ? (
              <div className={styles.serviceItemWrapper}>
                <div className={styles.amenitiesIconWrapper}>
                  <img src={Amenities2} alt="location" className={styles.amenitiesIcon} />
                </div>
                {roomServices.map(service => (
                  <p key={service} className={styles.serviceItem}>
                    {service}
                  </p>
                ))}
              </div>
            ) : null}

            {otherServices.length ? (
              <div className={styles.serviceItemWrapper}>
                <div className={styles.amenitiesIconWrapper}>
                  <img src={Amenities3} alt="location" className={styles.amenitiesIcon} />
                </div>
                {otherServices.map(service => (
                  <p key={service} className={styles.serviceItem}>
                    {service}
                  </p>
                ))}
              </div>
            ) : null}
          </div>
          <span className={styles.text}>
            Personal &amp; Small Group Training offered at an additional cost
          </span>
          {membershipRestrictions && isAllowedSchedule(name) && (
            <NavLink
              className={[styles.button, styles.scheduleButton].join(' ')}
              to="/classes"
              onClick={() => {
                changeClassesSelectedClub(clubAutomationClubId);
              }}
            >
              See Class Schedule
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
};
LocationPopUp.propTypes = {
  targetClub: PropTypes.object,
  favoriteClub: PropTypes.string.isRequired,
  changeFavoriteLocation: PropTypes.func.isRequired,
  changeClassesSelectedClub: PropTypes.func.isRequired,
};
LocationPopUp.defaultProps = {
  targetClub: {},
};
export default LocationPopUp;
