import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/shared/Spinner';
import ResentDialogs from './ResentDialogs';
import Dialog from './Dialog';

const MainContent = ({
  sendMessage,
  messageText,
  typeMessage,
  targetDialog,
  selectTargetDialog,
  resentMessages,
  targetDialogMessagesHistory,
  clearTargetDialog,
  isRequesting,
}) => {
  return (
    <>
      {isRequesting ? (
        <Spinner />
      ) : targetDialog ? (
        <Dialog
          targetDialog={targetDialog}
          targetDialogMessagesHistory={targetDialogMessagesHistory}
          sendMessage={sendMessage}
          messageText={messageText}
          typeMessage={typeMessage}
          clearTargetDialog={clearTargetDialog}
        />
      ) : (
        <ResentDialogs
          targetDialog={targetDialog}
          selectTargetDialog={selectTargetDialog}
          resentMessages={resentMessages}
        />
      )}
    </>
  );
};

MainContent.propTypes = {
  sendMessage: PropTypes.func.isRequired,
  messageText: PropTypes.string.isRequired,
  typeMessage: PropTypes.func.isRequired,
  targetDialog: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  selectTargetDialog: PropTypes.func.isRequired,
  resentMessages: PropTypes.arrayOf(PropTypes.object),
  targetDialogMessagesHistory: PropTypes.arrayOf(PropTypes.object),
  clearTargetDialog: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

MainContent.defaultProps = {
  targetDialogMessagesHistory: [],
  resentMessages: [],
};

export default MainContent;
