import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, withGoogleMap } from 'react-google-maps';
import PropTypes from 'prop-types';

// TODO add column 'coordinates' in Club__c table
const points = [
  { name: 'Marin', point: { lat: 37.938629, lng: -122.518055 } },
  { name: 'SF Mid Market', point: { lat: 37.776323, lng: -122.416657 } },
  { name: 'SF Embarcadero', point: { lat: 37.795034, lng: -122.398335 } },
  { name: 'Oakland', point: { lat: 37.812785, lng: -122.249777 } },
  { name: 'SF SOMA', point: { lat: 37.769931, lng: -122.407167 } },
  { name: 'SF Castro', point: { lat: 37.76397, lng: -122.433411 } },
  { name: 'SF Transbay', point: { lat: 37.788912, lng: -122.397359 } },
  { name: 'SF Fillmore', point: { lat: 37.783164, lng: -122.433742 } },
];

const MapComponent = withGoogleMap(({ favoriteClubName, onMarkerClick }) => {
  const [center, setCenter] = useState({ lat: 37.76397, lng: -122.433411 });

  useEffect(() => {
    points.map(el => {
      if (el.name === favoriteClubName) {
        setCenter(el.point);
      }
    });
  }, [favoriteClubName]);

  return (
    <GoogleMap defaultZoom={11} defaultCenter={center}>
      {points.map(item => (
        <Marker
          key={item.name}
          labelContent={item.name}
          position={{ lat: item.point.lat, lng: item.point.lng }}
          onClick={() => {
            onMarkerClick(item.name);
          }}
        />
      ))}
    </GoogleMap>
  );
});

MapComponent.propTypes = {
  favoriteClubName: PropTypes.string.isRequired,
  onMarkerClick: PropTypes.func.isRequired,
};

export default MapComponent;
