import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import styles from './style.module.css';

const DigitalReservationPopup = ({ open, handleClose, isRequesting }) => {
  useEffect(() => {
    if (isRequesting) handleClose();
  }, [isRequesting, handleClose]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper elevation={0} className={styles.paper}>
            <>
              <h3 className={styles.title}>Reserve a Spot</h3>
              <div className={styles.dash} />
              <p className={styles.content}>
                Reservations are required for accessing our locations. If you would like
                to gain access, please upgrade your membership.
              </p>
            </>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};

DigitalReservationPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
};

DigitalReservationPopup.defaultProps = {
  isRequesting: false,
};

export default DigitalReservationPopup;
