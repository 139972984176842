import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import { getSocket } from 'utils/socket';
import { useSelector } from 'react-redux';
import styles from './styles.module.css';

const SessionModal = ({ children, isOpened, wrapperStyles, handleOpenCallPopUp }) => {
  const isMobile = useContext(MobileContext);
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    if (isOpened) {
      document.body.style.overflowY = 'hidden';
      document.body.style.height = '100%';
    } else {
      document.body.style.position = 'static';
      document.body.style.overflowY = 'auto';
    }
  }, [isOpened]);
  useEffect(() => {
    const socket = getSocket();
    if (!socket.hasListeners('check-active-call-response'))
      socket.on('check-active-call-response', data => {
        if (user.isTrainer) handleOpenCallPopUp(data.callId, data.userName, true);
        else if (data.userName) handleOpenCallPopUp(data.callId, data.trainerName, true);
      });

    socket.emit('check-active-call', {
      sfId: user.sfId,
      isTrainer: user.isTrainer,
    });
  }, []);
  return (
    <div className={styles.container}>
      {isOpened ? (
        <>
          <div className={styles.shadow} />
          <div
            className={`${styles.modalContainer} ${
              isOpened ? styles.openedModalContainer : null
            } ${isMobile ? styles.containerMobile : null}`}
            style={wrapperStyles}
          >
            {children}
          </div>
        </>
      ) : null}
    </div>
  );
};

SessionModal.propTypes = {
  children: PropTypes.element.isRequired,
  isOpened: PropTypes.bool.isRequired,
  wrapperStyles: PropTypes.object,
  handleOpenCallPopUp: PropTypes.func.isRequired,
};
SessionModal.defaultProps = {
  wrapperStyles: {},
};

export default SessionModal;
