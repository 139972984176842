import React, { useContext, useState } from 'react';
import history from 'store/history';
import { Footer } from 'components';
import { Header } from 'containers';
import PropTypes from 'prop-types';
import TabletContext from 'context/TabletContext';
import MobileContext from 'context/MobileContext';
import { arrowLeftFull } from 'assets/icons';
import Sidebar from './Sidebar';
import styles from './styles.module.css';

const HeaderFooterLayout = ({ children, profile }) => {
  const isTablet = useContext(TabletContext);
  const isMobile = useContext(MobileContext);
  const [isOpenProfileModal, setOpenProfileModal] = useState(false);
  const show = history.location.pathname.includes('/profile');
  const showWithLogout = history.location.pathname.includes('/logout');
  return (
    <div className={styles.templateWrapper}>
      <Header
        isOpenProfileModal={isOpenProfileModal}
        setOpenProfileModal={setOpenProfileModal}
      />
      <div className={styles.mainWrapper}>
        {(show || showWithLogout || !isTablet) && <Sidebar />}
        <div className={styles.hiddenSection} />
        {(!show || !isTablet) && (
          <div className={styles.content}>
            {profile && isMobile && (
              <div
                className={styles.breadcrumb}
                onClick={() => setOpenProfileModal(true)}
              >
                <img src={arrowLeftFull} alt="" />
                <p>{profile}</p>
              </div>
            )}
            {children}
          </div>
        )}
      </div>
      <Footer email="info@fitnesssf.com" />
    </div>
  );
};

HeaderFooterLayout.propTypes = {
  children: PropTypes.element.isRequired,
  profile: PropTypes.string,
};

HeaderFooterLayout.defaultProps = {
  profile: '',
};

export default HeaderFooterLayout;
