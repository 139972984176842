import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { checkDigitalPlan } from 'services/checkDigitalPlan';
import { checkIn } from 'assets/icons';
import DigitalReservationPopup from 'components/Header/DigitalReservationPopup';
import history from 'store/history';
import styles from './styles.module.css';

export const FloatingButton = () => {
  const membership = useSelector(state => state.auth.membership);
  const isExistDigitalPlan = checkDigitalPlan(membership);
  const [openDigitalReservationPopup, setOpenDigitalReservationPopup] = useState(false);

  return (
    <>
      <div
        className={styles.button}
        onClick={() => {
          if (isExistDigitalPlan) setOpenDigitalReservationPopup(true);
          history.push('/qr');
        }}
      >
        <img className={styles.icon} src={checkIn} alt="QR" />
      </div>
      <DigitalReservationPopup
        open={openDigitalReservationPopup}
        handleClose={() => setOpenDigitalReservationPopup(!openDigitalReservationPopup)}
      />
    </>
  );
};
