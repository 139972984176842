import { connect } from 'react-redux';
import VideosDemandPage from 'pages/VideosDemandPage';
import Actions from 'store/auth/actions';
import PremiumVideosActions from 'store/premiumVideos/actions';
import membershipActions from 'store/membership/actions';

const mapStateToProps = state => {
  return {
    membership: state.auth.membership,
    memberStatus: state.auth.user.memberStatus,
    videos: state.premiumVideos.videos,
    categories: state.premiumVideos.categories,
    favoriteVideos: state.premiumVideos.favoriteVideos,
    searchVideos: state.premiumVideos.searchVideos,
    isRequesting: state.premiumVideos.isRequesting,
    isRequestingMembership: state.auth.isRequesting,
    isRequestingAddUserGroup: state.membership.isRequesting,
  };
};
const mapDispatchToProps = {
  loadMembership: Actions.Creators.loadMembershipRequest,
  loadPremiumVideos: PremiumVideosActions.Creators.loadPremiumVideosRequest,
  loadFavoriteVideo: PremiumVideosActions.Creators.loadFavoriteVideosRequest,
  addFavoriteVideo: PremiumVideosActions.Creators.addFavoriteVideosRequest,
  removeFavoriteVideo: PremiumVideosActions.Creators.removeFavoriteVideosRequest,
  searchPremiumVideo: PremiumVideosActions.Creators.searchPremiumVideo,
  filterPremiumVideo: PremiumVideosActions.Creators.filterPremiumVideo,
  addUserGroup: membershipActions.Creators.addUserGroupRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideosDemandPage);
