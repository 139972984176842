import { call, put, fork, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import actions from './actions';
import errorActions from '../error/actions';

const {
  Types: { LOAD_ACTIVITY_REQUEST },
  Creators: {
    loadInBodySuccess,
    loadInBodyFailure,

    loadVisitsSuccess,
    loadVisitsFailure,

    // loadSessionsSuccess,
    // loadSessionsFailure,
  },
} = actions;

const {
  Creators: { setError },
} = errorActions;

function* loadVisits() {
  // try {
  //   const { data } = yield call(api.get, `analytics/visits`);

  //   yield put(loadVisitsSuccess(data));
  // } catch (error) {
  //   yield put(loadVisitsFailure(error));
  //   yield put(setError('Failed to load Visits.'));
  // }
  try {
    const { data } = yield call(api.get, `analytics/sessions`);

    yield put(loadVisitsSuccess(data));
  } catch (error) {
    yield put(loadVisitsFailure(error));
    yield put(setError('Failed to load Sessions'));
  }
}

// function* loadSessions() {
//   try {
//     const { data } = yield call(api.get, `analytics/sessions`);

//     yield put(loadSessionsSuccess(data));
//   } catch (error) {
//     yield put(loadSessionsFailure(error));
//     yield put(setError('Failed to load Sessions'));
//   }
// }

function* loadInBody() {
  try {
    // const { data } = yield call(api.get, `analytics/in_body`);
    yield put(loadInBodySuccess());
  } catch (error) {
    yield put(loadInBodyFailure(error));
    yield put(setError('Failed to load InBody data.'));
  }
}

export function* analyticsSaga() {
  yield takeLatest(LOAD_ACTIVITY_REQUEST, function* flow() {
    yield fork(loadVisits);
    // yield fork(loadSessions);
    yield fork(loadInBody);
  });
}
