/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import EmptyCard from 'components/HomePage/EmptyCard';
import SessionList from 'components/HomePage/SessionList';
import { FloatingButton } from 'components/FloatingButton';
import checkIsAdmin from 'services/checkIsAdmin';
import TabletContext from 'context/TabletContext';
import {
  SessionDetails,
  SessionCallPopUpInner,
  OneToManyVideoCallPage,
  SessionCallModal,
  ClassCallModal,
} from 'containers';
import { PopUp } from 'components/shared/PopUp';
import { Modal } from 'components/shared/Modal';
import CancelReservationPopUpInner from 'components/ReservationPage/CancelReservationPopUp';
import ReservationsList from 'components/HomePage/ReservationsList';
import CancelSessionSuccessModal from 'components/shared/SessionDetails/CancelSessionModals/CancelSessionSuccessModal';
import CancelSessionModal from 'components/shared/SessionDetails/CancelSessionModals/CancelSessionModal';
import RestrictionPopup from 'components/shared/RestrictionPopup';
import checkMembershipRestriction from 'services/checkMembershipRestriction';
import TrainerDetails from 'components/TrainingPage/TrainerDetails';
import { ClassDetails } from 'components/Classes/ClassDetails';
import vaccineStatuses from 'enums/vaccineStatuses';
import MoreServices from '../MoreServices';
import ClassList from '../ClassList';
import IntroductionSection from '../IntroductionSection';
import styles from './styles.module.css';
import {
  classesSection,
  personalTrainingSection,
  vaccinationBannerSection,
} from './service';
import VideoSection from '../VideoSection';
import MainSlider from '../MainSlider';
import TrainerSection from '../TrainerSection';

const MainContent = ({
  sessions,
  favoriteLocationClasses = [],
  addClassToFavorite,
  removeClassFromFavorite,
  setTargetSession,
  upcomingReservations,
  deleteReservation,
  isDeleteReservationRequesting,
  handleOpenCallPopUp,
  isSessionCallPopUpOpened,
  setTrainerId,
  cleanCancelSessionEmail,
  sendCancelSessionEmail,
  isClassCallPopUpOpened,
  handleOpenClassCallPopUp,
  user,
  checkMemberStatus,
  vaccineStatus,
  preselectFreezeTopic,
  clubs,
}) => {
  const [isVisited, setIsVisited] = useState(true);
  const [clickedGroupEx, setClickedGroupEx] = useState({});
  const [isClassesDetailsOpened, setIsClassesDetailsOpened] = useState(false);
  const [isSessionDetailsOpened, setIsSessionDetailsOpened] = useState(false);
  const [isTrainerCardOpened, setIsTrainerCardOpened] = useState(false);
  const [selectedTrainer, setSelectedTrainer] = useState({});
  const [isCancelReservationOpened, setIsCancelReservationOpened] = useState(false);
  const [reservationIdsToCancel, setReservationIdsToCancel] = useState([]);
  const [reservationDateToCancel, setReservationDateToCancel] = useState('');
  const [isCancelSessionSuccessModal, setCancelSessionSuccessModal] = useState(false);
  const [cancelSessionOpened, setCancelSession] = useState(false);
  const [isQRCodeModalVisible, setQRCodeModalVisible] = useState(false);

  const isOpenCanceledSessionModal = useSelector(state => state.training.isSentEmail);
  const memberStatus = useSelector(state => state.auth.user.memberStatus);
  const isLimited = memberStatus === 'Canceled';
  const isTablet = useContext(TabletContext);
  const isRestrictionPopup = checkMembershipRestriction(memberStatus);
  const history = useHistory();
  const checkIsAdminRestrictions = checkIsAdmin(user.email);

  useEffect(() => {
    getVisits();
    checkMemberStatus();
  }, [checkMemberStatus]);

  useEffect(() => {
    if (isDeleteReservationRequesting === false) setIsCancelReservationOpened(false);
  }, [isDeleteReservationRequesting]);

  useEffect(() => {
    if (isOpenCanceledSessionModal) setCancelSessionSuccessModal(true);
  }, [isOpenCanceledSessionModal]);

  const handleSessionInfoClick = values => {
    setIsSessionDetailsOpened(true);
    setTargetSession(values);
  };

  const handleSelectTrainer = trainer => {
    const { sfId } = clubs.find(({ name }) => trainer.location === name);
    setSelectedTrainer({ ...trainer, clubId: sfId });
  };

  const handleOpenModal = () => {
    setIsCancelReservationOpened(!isCancelReservationOpened);
  };
  const handleCancelReservationClick = (ids, date) => {
    setIsCancelReservationOpened(true);
    setReservationIdsToCancel(ids);
    setReservationDateToCancel(date);
  };
  const handleCancelReservationConfirm = () => {
    const shouldLoadUpcoming = true;
    deleteReservation(reservationIdsToCancel, shouldLoadUpcoming);
  };
  const getVisits = () => {
    try {
      if (localStorage.getItem('covidRestrictionsVisitedAt')) {
        setIsVisited(true);
      } else {
        setIsVisited(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const setVisit = () => {
    setIsVisited(true);
    try {
      localStorage.setItem('covidRestrictionsVisitedAt', Date.now());
    } catch (err) {
      console.log(err);
    }
  };

  const closeCancelSessionSuccessModal = () => {
    setCancelSessionSuccessModal(false);
    cleanCancelSessionEmail();
  };

  return (
    <>
      <RestrictionPopup isOpen={isRestrictionPopup} />
      {isTablet && !isLimited && <FloatingButton />}
      {!isQRCodeModalVisible && (
        <>
          <MainSlider />

          {upcomingReservations.length !== 0 && (
            <ReservationsList
              reservations={upcomingReservations}
              handleCancelReservationClick={handleCancelReservationClick}
            />
          )}
          {sessions?.length !== 0 && (
            <SessionList
              sessions={sessions}
              handleSessionInfoClick={handleSessionInfoClick}
              handleOpenCallPopUp={handleOpenCallPopUp}
              setTrainerId={setTrainerId}
              setCancelSession={setCancelSession}
              showAction
            />
          )}
          {favoriteLocationClasses?.length > 0 && (
            <ClassList
              data={favoriteLocationClasses}
              addClassToFavorite={addClassToFavorite}
              removeClassFromFavorite={removeClassFromFavorite}
              handleOpenClassCallPopUp={handleOpenClassCallPopUp}
              setClickedGroupEx={setClickedGroupEx}
              setIsDetailsOpened={setIsClassesDetailsOpened}
              user={user}
            />
          )}
          <div className={styles.cardsClasses}>
            {/* {vaccineStatus === vaccineStatuses.notYetUploaded &&
              checkIsAdminRestrictions && <EmptyCard {...vaccinationBannerSection} />} */}
            {sessions?.length === 0 && <EmptyCard {...personalTrainingSection} />}
            {favoriteLocationClasses?.length === 0 && <EmptyCard {...classesSection} />}
          </div>
          <VideoSection />
          <TrainerSection
            selectTrainer={handleSelectTrainer}
            setIsOpened={setIsTrainerCardOpened}
          />
          <MoreServices />
        </>
      )}
     
      <PopUp
        isOpened={isClassesDetailsOpened}
        setIsOpened={setIsClassesDetailsOpened}
        isFullyOpen
      >
        <ClassDetails
          data={clickedGroupEx}
          addClassToFavorite={addClassToFavorite}
          removeClassFromFavorite={removeClassFromFavorite}
          setIsOpened={setIsClassesDetailsOpened}
          handleOpenClassCallPopUp={handleOpenClassCallPopUp}
          user={user}
        />
      </PopUp>
      <PopUp isOpened={isSessionDetailsOpened} setIsOpened={setIsSessionDetailsOpened}>
        <SessionDetails
          setCancelSession={setCancelSession}
          handleOpenCallPopUp={handleOpenCallPopUp}
        />
      </PopUp>
      <PopUp
        isOpened={isTrainerCardOpened}
        setIsOpened={setIsTrainerCardOpened}
        wrapperStyles={{ backgroundColor: '#F6F4F1' }}
      >
        <TrainerDetails
          {...selectedTrainer}
          setTrainerId={setTrainerId}
          handleOpenPopUp={setIsTrainerCardOpened}
          preselectFreezeTopic={preselectFreezeTopic}
        />
      </PopUp>
      <Modal isOpened={!!cancelSessionOpened} setIsOpened={setCancelSession}>
        <CancelSessionModal
          setClose={() => setCancelSession(false)}
          cancelSessions={() => {
            sendCancelSessionEmail(cancelSessionOpened);
          }}
        />
      </Modal>
      <Modal
        isOpened={isCancelSessionSuccessModal}
        setIsOpened={closeCancelSessionSuccessModal}
      >
        <CancelSessionSuccessModal setClose={closeCancelSessionSuccessModal} />
      </Modal>
      <Modal isOpened={isCancelReservationOpened} setIsOpened={handleOpenModal}>
        <CancelReservationPopUpInner
          cancelReservation={handleCancelReservationConfirm}
          handleClose={handleOpenModal}
          isDeleteRequesting={isDeleteReservationRequesting}
          fullStartDate={reservationDateToCancel}
          selectedTimeSlotIds={reservationIdsToCancel}
          isHomePage
        />
      </Modal>
      <Modal
        isOpened={!isVisited}
        setIsOpened={setVisit}
        wrapperStyles={{ width: '315px', height: '300px' }}
      >
        <IntroductionSection />
      </Modal>
      <SessionCallModal isOpened={isSessionCallPopUpOpened}>
        <SessionCallPopUpInner />
      </SessionCallModal>
      <ClassCallModal isOpened={isClassCallPopUpOpened}>
        <OneToManyVideoCallPage />
      </ClassCallModal>
    </>
  );
};

MainContent.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.object),
  sessions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  favoriteLocationClasses: PropTypes.arrayOf(PropTypes.object),
  addClassToFavorite: PropTypes.func.isRequired,
  removeClassFromFavorite: PropTypes.func.isRequired,
  setTargetSession: PropTypes.func.isRequired,
  upcomingReservations: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.array,
  ]).isRequired,
  deleteReservation: PropTypes.func.isRequired,
  isDeleteReservationRequesting: PropTypes.bool.isRequired,
  handleOpenCallPopUp: PropTypes.func.isRequired,
  isSessionCallPopUpOpened: PropTypes.bool.isRequired,
  setTrainerId: PropTypes.func.isRequired,
  cleanCancelSessionEmail: PropTypes.func.isRequired,
  sendCancelSessionEmail: PropTypes.func.isRequired,
  isClassCallPopUpOpened: PropTypes.bool.isRequired,
  handleOpenClassCallPopUp: PropTypes.func.isRequired,
  checkMemberStatus: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  vaccineStatus: PropTypes.string.isRequired,
  preselectFreezeTopic: PropTypes.func.isRequired,
  clubs: PropTypes.arrayOf(PropTypes.object).isRequired,
};
MainContent.defaultProps = {
  favoriteLocationClasses: [],
  classes: [{}],
  sessions: null,
};

export default MainContent;
