export const findClubName = (clubId, locations) => {
  const found = locations.find(location => location.value === clubId);
  return found?.label;
};

export const findTrainersByClubId = (clubId, trainers) => {
  if (!trainers) return [];
  const found = trainers.filter(trainer => trainer.locationId === clubId);

  if (!found) return [];
  return found;
};

export const findTrainersForOtherClubs = (favoriteClubId, trainers) => {
  if (!trainers) return [];
  const found = trainers.filter(trainer => trainer.locationId !== favoriteClubId);
  if (!found) return [];
  return found;
};

export const findOtherClubs = (favoriteClubId, trainers, locations) => {
  if (!trainers) return [];
  if (!locations) return [];
  const otherLocations = locations.filter(
    location => location.value !== favoriteClubId && location.value !== 'all'
  );
  const otherClubs = [];
  for (let i = 0; i < otherLocations.length; i++) {
    const trainersByLocations = trainers.filter(
      trainer => trainer.locationId === otherLocations[i].value
    );
    if (!trainersByLocations)
      otherClubs.push({ location: otherLocations[i].label, trainers: [] });
    else
      otherClubs.push({
        location: otherLocations[i].label,
        trainers: trainersByLocations,
      });
  }
  return otherClubs;
};
