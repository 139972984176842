import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import download from 'assets/icons/download.svg';
import MonthDatePicker from 'components/shared/MonthDatePicker';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import moment from 'moment';
import styles from './styles.module.css';

const TransactionHeader = ({ resetTransaction, loadTransaction, dataExists }) => {
  const sfDate = getSFTime();
  const [date, setDate] = useState(sfDate);

  useEffect(() => {
    resetTransaction(null);
  }, [resetTransaction]);

  useEffect(() => {
    loadTransaction({
      end: moment(sfDate)
        .endOf('month')
        .format('YYYY-MM-DD'),
      start: moment(sfDate)
        .startOf('month')
        .format('YYYY-MM-DD'),
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1>Transactions</h1>
        <div className={styles.pickHistoryDate}>
          <MonthDatePicker
            initialDate={date}
            search={d => {
              setDate(d);
              loadTransaction({
                end: moment(d, 'YYYY-MM-DD')
                  .endOf('month')
                  .format('YYYY-MM-DD'),
                start: moment(d, 'YYYY-MM-DD').format('YYYY-MM-DD'),
              });
            }}
            searchButtonLabel="View Transactions"
          />
          {dataExists && (
            <div className={styles.buttonHistory} onClick={() => {}}>
              Download PDF
              <img src={download} className={styles.iconDownload} alt="" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
TransactionHeader.propTypes = {
  loadTransaction: PropTypes.func.isRequired,
  dataExists: PropTypes.object,
};
TransactionHeader.defaultProps = {
  dataExists: false,
};

export default TransactionHeader;
