import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { penIcon, profileIcon } from 'assets/icons';
import Button from 'components/shared/Button';
import MobileContext from 'context/MobileContext';
import history from 'store/history';
import styles from './styles.module.css';
import { items } from './service';

const Item = ({ name, icon, path, isMobile }) => {
  return (
    <NavLink
      key={name}
      to={path}
      className={`${styles.wrapper} ${!isMobile && styles.wrapperHover}`}
      activeClassName={styles.active}
      exact
    >
      <div className={styles.menuItem}>
        <span className={styles.itemName}>{name}</span>
        {path && <img src={icon} className={styles.icon} alt="" />}
      </div>
    </NavLink>
  );
};

Item.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const ProfileMenu = () => {
  const profile = useSelector(({ auth }) => auth.profile);
  const clubs = useSelector(state => state.clubs?.clubs || []);
  const favoriteLocationSfId = useSelector(({ auth }) => auth.user?.favoriteLocationCAId);
  const userFirstName = useSelector(({ auth }) => auth.user?.firstName);
  // const favoriteLocation = clubs.find((clubAutomationClubId ) => clubAutomationClubId == favoriteLocationSfId.toString())?.name
  const favoriteLocation = clubs.find(
    ({ clubAutomationClubId }) => clubAutomationClubId === favoriteLocationSfId
  )?.name;
  // const dispatch = useDispatch();
  const isMobile = useContext(MobileContext);

  const logoutClick = () => {
    // dispatch({
    //   type: 'LOGOUT_REQUEST',
    // });
    history.push('/logout');
    localStorage.setItem('isRedirectToStore', 0)
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.header}>
          <img
            src={profile?.photo || profileIcon}
            className={!profile?.photo && styles.profileIcon}
            alt="profile"
          />
          <div>
            <h1>
              <span>My Account</span>
              <NavLink to="/my-account">
                <img src={penIcon} alt="pen" />
              </NavLink>
            </h1>
            <h2>{userFirstName}</h2>
            <p>Primary club : {favoriteLocation}</p>

            <NavLink to="/update-user-password">Update Password</NavLink>
          </div>
        </div>
        {items.map(item =>
          isMobile ? (
            !item?.isHide && <Item isMobile key={item.path} {...item} />
          ) : (
            <Item {...item} />
          )
        )}
      </div>

      <div className={styles.logout}>
        <Button
          styleType="tertiary"
          height="60px"
          width={isMobile ? '100%' : '315px'}
          onClick={logoutClick}
        >
          Logout
        </Button>
      </div>
      {process.env.REACT_APP_RELEASE_VERSION && (
        <div className={styles.version}>{process.env.REACT_APP_RELEASE_VERSION}</div>
      )}
    </div>
  );
};

export default ProfileMenu;
