import React, { useState, useEffect, useRef, useContext } from 'react';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import PropTypes from 'prop-types';
import Spinner from 'components/shared/Spinner';
import { PopUp } from 'components/shared/PopUp';
import MobileContext from 'context/MobileContext';
import { ClassesHeader } from './ClassesHeader';
import { ClassesContent } from './ClassesContent';
import { calculateFourWeeksDates } from './service';
import ClassFilter from './ClassFilter';

export const Classes = ({
  loadClubs,
  clubs,
  classes,
  filteredClasses,
  filters,
  filterClasses,
  isRequesting,
  loadClassesSchedule,
  addClassToFavorite,
  removeClassFromFavorite,
  searchValue,
  searchClasses,
  lastSucceeded,
  setError,
  loadClubsIsRequesting,
  preselectedClub,
  favoriteClub,
  isClassCallPopUpOpened,
  handleOpenClassCallPopUp,
  changeSelectedClub,
  user,
  isExistFilters,
}) => {
  const isMobile = useContext(MobileContext);
  const datesRange = calculateFourWeeksDates();
  const [targetDate, setTargetDate] = useState(datesRange[0]);
  const [selectedClub, setSelectedClub] = useState(preselectedClub || 'all');
  const [isShowFilters, showFilters] = useState(false);

  const bodyRef = useRef(null);

  useEffect(() => {
    loadClubs();
  }, [loadClubs]);

  useEffect(() => {
    loadClassesSchedule({
      startDate: targetDate.date || getSFTime(),
      clubId: selectedClub,
    });
  }, [selectedClub]);

  useEffect(() => {
    loadClassesSchedule({
      startDate: targetDate.date,
      clubId: selectedClub,
    });
  }, [targetDate]);

  return isRequesting && loadClubsIsRequesting && !clubs.length ? (
    <Spinner />
  ) : (
    <div ref={bodyRef}>
      <ClassesHeader
        selectedClub={selectedClub}
        setSelectedClub={setSelectedClub}
        clubs={clubs}
        isMobile={isMobile}
        pdfRef={bodyRef}
        lastSucceeded={lastSucceeded}
        searchValue={searchValue}
        searchClasses={searchClasses}
        setError={setError}
        favoriteClub={favoriteClub}
        changeSelectedClub={changeSelectedClub}
        showFilters={() => showFilters(!isShowFilters)}
      />
      <ClassesContent
        datesRange={datesRange}
        targetDate={targetDate}
        setTargetDate={setTargetDate}
        pdfRef={bodyRef}
        isMobile={isMobile}
        addClassToFavorite={addClassToFavorite}
        removeClassFromFavorite={removeClassFromFavorite}
        isRequesting={isRequesting}
        classes={
          isExistFilters ? filteredClasses : searchValue ? filteredClasses : classes
        }
        isClassCallPopUpOpened={isClassCallPopUpOpened}
        handleOpenClassCallPopUp={handleOpenClassCallPopUp}
        user={user}
      />
      <PopUp isOpened={isShowFilters} setIsOpened={showFilters}>
        <ClassFilter filters={filters} setFilters={filterClasses} />
      </PopUp>
    </div>
  );
};

Classes.propTypes = {
  classes: PropTypes.array,
  filteredClasses: PropTypes.array,
  clubs: PropTypes.array,
  loadClubs: PropTypes.func.isRequired,
  preselectedClub: PropTypes.string.isRequired,
  loadClassesSchedule: PropTypes.func.isRequired,
  addClassToFavorite: PropTypes.func.isRequired,
  removeClassFromFavorite: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  searchValue: PropTypes.string,
  searchClasses: PropTypes.func.isRequired,
  lastSucceeded: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setError: PropTypes.func.isRequired,
  favoriteClub: PropTypes.string,
  loadClubsIsRequesting: PropTypes.bool.isRequired,
  isClassCallPopUpOpened: PropTypes.bool.isRequired,
  handleOpenClassCallPopUp: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  changeSelectedClub: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  filterClasses: PropTypes.func.isRequired,
  isExistFilters: PropTypes.bool.isRequired,
};

Classes.defaultProps = {
  classes: [],
  filteredClasses: [],
  clubs: [],
  favoriteClub: '',
  searchValue: '',
  lastSucceeded: '',
};
