import React, { useContext, useEffect } from 'react';

import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import Spinner from 'components/shared/Spinner';
import StatementsHeader from './StatementsHeader';
import TransactionHeader from './TransactionHeader';
import TableTransactionDesktop from './TableTransaction/Desktop';
import TableTransactionMobile from './TableTransaction/Mobile';
import StatusPopup from '../ContactUs/StatusPopup';
import styles from './styles.module.css';

const Transaction = ({
  loadTransaction,
  resetTransaction,
  loadStatement,
  transaction,
  isStatementRequesting,
  isDownload,
  isTransactionRequesting,
  user,
}) => {
  const isMobile = useContext(MobileContext);

  useEffect(() => {}, []);

  const data = transaction
    ? transaction.map(({ item, date, price, name }) => ({
        description: item
          .replace(/[0-9,$.()|=>â„¢/]/g, '')
          .replace(
            /January|February|March|April|May|June|July|August|September|October|November|December|ADDED/g,
            ''
          )
          .replace(/&quot;/g, '"'),
        price,
        date,
        name,
      }))
    : [];

  const statementProps = {
    loadStatement,
    isStatementRequesting,
    isDownload,
    user,
  };
  return (
    <HeaderFooterLayout profile="Statements &amp; transactions">
      <div className={styles.containerMain}>
        {isStatementRequesting ? (
          <Spinner />
        ) : (
          <>
            <StatementsHeader statementProps={statementProps} />
            <TransactionHeader
              loadTransaction={loadTransaction}
              resetTransaction={resetTransaction}
              // dataExists={data.length > 0}
              dataExists={false}
            />
            {isDownload && (
              <StatusPopup
                text="Statements successfully sent to your email"
                className="statement"
              />
            )}
            {isTransactionRequesting ? (
              <Spinner />
            ) : transaction?.length > 0 ? (
              <>
                {isMobile ? (
                  <TableTransactionMobile data={data} />
                ) : (
                  <TableTransactionDesktop data={data} />
                )}
              </>
            ) : (
              <div className={styles.notExist}>No Transactions</div>
            )}
          </>
        )}
      </div>
    </HeaderFooterLayout>
  );
};
Transaction.propTypes = {
  loadTransaction: PropTypes.func.isRequired,
  resetTransaction: PropTypes.func.isRequired,
  loadStatement: PropTypes.func.isRequired,
  transaction: PropTypes.array,
  isStatementRequesting: PropTypes.bool.isRequired,
  isDownload: PropTypes.bool.isRequired,
  isTransactionRequesting: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};
Transaction.defaultProps = {
  transaction: null,
};
export default Transaction;
