import { connect } from 'react-redux';
import NotificationCenter from 'components/Header/NotificationCenter';
// import Actions from 'store/home/actions';

const mapStateToProps = state => {
  return {
    notifications: state.notifications.data,
  };
};
const mapDispatchToProps = {
  // loadHomeClasses: Actions.Creators.loadHomeClassesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenter);
