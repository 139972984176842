/* eslint-disable react/no-danger */
/* eslint-disable consistent-return */
import React from 'react';
import moment from 'moment';
import momentTZ from 'moment-timezone';

import PropTypes from 'prop-types';
import {
  calendarBlackIcon,
  locationIcon,
  likeDisabled,
  cameraSvgIcon,
  clockIcon,
} from 'assets/icons';
import styles from './styles.module.css';
import { getClassImage, getClassTime, getDescription } from './services';

const CLASS_CALL_FEATURE_ENABLED = process.env.REACT_APP_CLASS_CALL_FEATURE
  ? JSON.parse(process.env.REACT_APP_CLASS_CALL_FEATURE)
  : false;

export const ClassDetails = ({
  data: {
    classInfo = {},
    isFavorite,
    startTime,
    classId,
    endTime,
    date,
    description,
    studioName,
    trainerName,
    isLive,
  },
  addClassToFavorite,
  setIsOpened,
  removeClassFromFavorite,
  handleOpenClassCallPopUp,
  user,
}) => {
  const { club = {}, name, programName } = classInfo;
  const cuttedClubName = club.name && club.name.replace('SF ', '');
  const formattedDescription = getDescription(description);
  const { classDate, classTime } = getClassTime(date, startTime, endTime);

  const isTimeToStartStream = () => {
    const now = new Date();
    const userTimezone = momentTZ.tz.guess(true);
    let today = moment(now).format('YYYY-MM-DD HH:mm:ss');
    if (momentTZ.tz(userTimezone).format('z') !== 'PDT') {
      today = momentTZ(today)
        .tz('America/Los_Angeles')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    const startTimeFormatted = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm:ss');
    const endTimeFormatted = moment(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm:ss');
    if (
      moment(today).isBetween(startTimeFormatted, endTimeFormatted) &&
      moment(today).isSameOrAfter(startTimeFormatted, 'day')
    ) {
      return true;
    }
    return false;
  };

  const handleAddClick = () => {
    addClassToFavorite(classId);
    setIsOpened(false);
  };

  const handleRemoveClick = () => {
    removeClassFromFavorite(classId);
    setIsOpened(false);
  };

  return (
    <div className={styles.detailsContainer}>
      <div>
        <img
          className={styles.classTypeImage}
          src={getClassImage(programName || name)}
          alt=""
        />
        <div className={styles.descriptionWrapper}>
          <p className={styles.title}>{programName || name}</p>
          <div className={styles.row}>
            <img alt="date" src={calendarBlackIcon} />
            <span>{classDate}</span>
          </div>
          <div className={styles.row}>
            <img alt="time" src={clockIcon} />
            <span>{classTime}</span>
          </div>
          <p className={styles.row}>
            <img src={locationIcon} alt="location" />
            <a
              className={styles.location}
              href={`https://www.google.com/maps/search/?api=1&query=FITNESS+SF+${cuttedClubName}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <span>
                {club.name}
                {studioName ? `, ${studioName}` : ''}
              </span>
            </a>
          </p>
          {description && (
            <span
              className={styles.descriptionText}
              dangerouslySetInnerHTML={{ __html: formattedDescription }}
            />
          )}
        </div>
      </div>

      <div className={styles.bottomControl}>
        {CLASS_CALL_FEATURE_ENABLED && isLive ? (
          <div
            className={[
              styles.button,
              styles.liveButton,
              (user.isTrainer && user.name !== trainerName) || !isTimeToStartStream()
                ? styles.buttonDisabled
                : styles.addButton,
              styles.cameraButton,
            ].join(' ')}
            onClick={() =>
              handleOpenClassCallPopUp(
                { classId, name: programName || name, date, startTime },
                true
              )
            }
          >
            <img className={styles.cameraIcon} src={cameraSvgIcon} alt="liked" />
            {user.isTrainer ? 'Start the Video Class' : 'Watch Live'}
          </div>
        ) : null}
        {isFavorite ? (
          <div
            className={[styles.button, styles.removeButton].join(' ')}
            onClick={() => handleRemoveClick()}
          >
            Remove from favorites
            <img className={styles.heartIcon} src={likeDisabled} alt="liked" />
          </div>
        ) : (
          <>
            <div
              className={[styles.button, styles.addButton].join(' ')}
              onClick={() => handleAddClick()}
            >
              Add to favorites
              <img className={styles.heartIcon} src={likeDisabled} alt="liked" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ClassDetails.propTypes = {
  data: PropTypes.object,
  setIsOpened: PropTypes.func.isRequired,
  addClassToFavorite: PropTypes.func.isRequired,
  removeClassFromFavorite: PropTypes.func.isRequired,
  handleOpenClassCallPopUp: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

ClassDetails.defaultProps = {
  data: {},
};
