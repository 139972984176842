import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { readIcon } from 'assets/icons';
import styles from './styles.module.css';

const Message = ({ isMyMessage, text, isRead, createdAt }) => {
  const isToday = createdAt && moment(createdAt).isSame(Date.now(), 'day');
  return (
    <div className={styles.wrapper}>
      {isMyMessage ? (
        <div className={styles.innerWrapperRight}>
          <div className={styles.myMessage}>
            <span className={styles.myMessageText}>{text}</span>

            {isRead ? <img className={styles.isRead} alt="read" src={readIcon} /> : null}
          </div>
          {createdAt ? (
            <div className={styles.timeRight}>
              {' '}
              {isToday
                ? moment(createdAt).format('hh:mm A')
                : moment(createdAt).format('MM/DD/YYYY, hh:mm A')}
            </div>
          ) : null}
        </div>
      ) : (
        <div className={styles.innerWrapperLeft}>
          <div className={styles.message}>
            <span className={styles.messageText}>{text}</span>
          </div>
          {createdAt ? (
            <div className={styles.timeLeft}>
              {' '}
              {isToday
                ? moment(createdAt).format('hh:mm A')
                : moment(createdAt).format('MM/DD/YYYY, hh:mm A')}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

Message.propTypes = {
  isMyMessage: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  isRead: PropTypes.bool.isRequired,
  createdAt: PropTypes.string.isRequired,
};

export default Message;
