import React from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';
import Button from 'components/shared/Button';
import DepositLimitStatus from './DepositLimitStatus';
import styles from './styles.module.css';

const StatusBar = ({ amount, loadHistory, startDate, used, setUsed, limit }) => {
  return (
    <div className={styles.container}>
      <DepositLimitStatus total={amount} used={used} />

      {used !== amount ? (
        <Button
          className={styles.buttonStatusBar}
          onClick={() => {
            setUsed(used + limit);
            loadHistory({
              end: moment(startDate)
                .endOf('month')
                .format('YYYY-MM-DD'),
              start: moment(startDate).format('YYYY-MM-DD'),
              used: used + limit,
            });
          }}
          type={styles.text}
        >
          Load more
        </Button>
      ) : null}
    </div>
  );
};
StatusBar.propTypes = {
  amount: PropTypes.number.isRequired,
  loadHistory: PropTypes.func.isRequired,
  setUsed: PropTypes.func.isRequired,
  used: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  startDate: PropTypes.object.isRequired,
};
StatusBar.defaultProps = {};
export default StatusBar;
