import React, { useState, useMemo, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import SmallMobileContext from 'context/SmallMobileContext';
import { ReactComponent as Arrow } from 'assets/icons/arrow_right.svg';
import getMax from 'lodash/max';
import getMin from 'lodash/min';

import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
} from 'recharts';
import Text from 'components/shared/Text';
import styles from './styles.module.css';

const renderTooltip = ({ payload, units }) => {
  return (
    <>
      {payload.length ? (
        <div>
          <Text type="s1">{payload[0].value}</Text>
          <Text>{units}</Text>
        </div>
      ) : null}
    </>
  );
};

renderTooltip.propTypes = {
  payload: PropTypes.array.isRequired,
  units: PropTypes.string,
};

renderTooltip.defaultProps = {
  units: '',
};

const CustomizedTick = ({ x, y, payload, data, isMobile, isSmallMobile }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        textAnchor="middle"
        y={0}
        dy={isMobile ? 8 : 16}
        fontWeight="bold"
        fontFamily="Futura Book"
        fontSize={isSmallMobile ? '9px' : '10px'}
        className={styles.tick}
      >
        {data[payload.value] && data[payload.value].date}
      </text>
      <text
        y={0}
        dy={isMobile ? 20 : 30}
        textAnchor="middle"
        fontFamily="Futura Book"
        fontSize={isSmallMobile ? '7px' : '9px'}
        className={styles.tick}
      >
        {data[payload.value] && data[payload.value].time}
      </text>
    </g>
  );
};

CustomizedTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
  data: PropTypes.array,
  isMobile: PropTypes.bool.isRequired,
  isSmallMobile: PropTypes.bool.isRequired,
};

CustomizedTick.defaultProps = {
  x: 0,
  y: 0,
  payload: {},
  data: [],
};

const GraphikCard = ({ inBody, lineDataKey, units, label }) => {
  const isMobile = useContext(MobileContext);
  const isSmallMobile = useContext(SmallMobileContext);
  const domainSize = 7;
  const [domain, setDomain] = useState(() => [
    inBody.length - (domainSize + 1),
    inBody.length,
  ]);
  const [leftDisabled, setLeftDisabled] = useState(
    inBody.length - (domainSize + 0.5) < 0
  );
  const [rightDisabled, setRightDisabled] = useState(true);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const indexes = useMemo(() => {
    const result = [];

    const max = inBody ? inBody.length : 0;

    for (let i = -11; i <= max; i++) {
      result.push(i);
    }

    return result;
  }, [inBody]);

  useEffect(() => {
    const values = inBody.map(i => Number(i[lineDataKey]));
    setMinValue(getMin(values));
    setMaxValue(getMax(values));
  }, [inBody, lineDataKey]);

  function handleClick(moveLeft) {
    const start = moveLeft ? domain[0] - domainSize : domain[0] + domainSize;
    const finish = moveLeft ? domain[1] - domainSize : domain[1] + domainSize;

    if (finish >= inBody.length) {
      setRightDisabled(true);
    } else {
      setRightDisabled(false);
    }

    if (start <= 0) {
      setLeftDisabled(true);
    } else {
      setLeftDisabled(false);
    }

    setDomain([start, finish]);
  }

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <Text tag="p" type="p5">
          {`${label} (${units})`}
        </Text>

        <div className={styles.arrows}>
          <div
            className={`${styles.arrowWrapper} ${leftDisabled && styles.disabled}`}
            onClick={leftDisabled ? () => {} : () => handleClick(true)}
          >
            <Arrow className={`${styles.left} ${styles.arrow}`} />
          </div>
          <div
            className={`${styles.arrowWrapper} ${rightDisabled && styles.disabled}`}
            onClick={rightDisabled ? () => {} : () => handleClick(false)}
          >
            <Arrow className={styles.arrow} />
          </div>
        </div>
      </div>
      <ResponsiveContainer
        width={isSmallMobile ? 300 : isMobile ? 420 : 600}
        height={isMobile ? 210 : 300}
      >
        <LineChart
          data={inBody}
          margin={{
            top: 5,
            right: isMobile ? 10 : 20,
            left: isMobile ? 10 : 20,
            bottom: 5,
          }}
          width={750}
          height={100}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="index"
            axisLine={false}
            tickLine={false}
            allowDataOverflow
            type="number"
            domain={domain}
            tick={
              <CustomizedTick
                data={inBody}
                isMobile={isMobile}
                isSmallMobile={isSmallMobile}
              />
            }
            height={70}
            ticks={indexes}
            minTickGap={0}
          />
          <YAxis hide domain={[() => minValue - 10, () => maxValue + 10]} tickCount={4} />

          <Tooltip content={renderTooltip} units={units} cursor={false} />
          <Line
            strokeWidth={2}
            type="linear"
            dataKey={lineDataKey}
            stroke="#FF854D"
            dot={{ stroke: 'black', strokeWidth: 2, r: 4 }}
            activeDot={{
              stroke: 'black',
              fill: 'white',
              strokeWidth: 2,
              r: 5,
              cursor: 'pointer',
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

GraphikCard.propTypes = {
  inBody: PropTypes.array.isRequired,
  lineDataKey: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default GraphikCard;
