import React from 'react';
import { useTable } from 'react-table';
import MaUTable from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

export const StyledHeaderCell = styled(TableCell)({
  backgroundColor: '#A9A8A7',
  padding: '10px 22px',
  boxSizing: 'border-box',
  border: 'none',
  width: ({ width }) => width || 'initial',
  fontFamily: 'Futura Medium',
  textAlign: ({ textAlign }) => textAlign || 'left',
  fontSize: '14px',
  lineHeight: '19px',
  letterSpacing: '0',
  color: '#fff',
  height: '40px',
  userSelect: 'none',
  ':first-child': {
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '17px',
  },
});
export const StyledCell = styled(TableCell)({
  backgroundColor: '#ffffff',
  fontFamily: 'Futura Book',
  padding: '10px 22px',
  boxSizing: 'border-box',
  border: 'none',
  width: ({ width }) => width || 'initial',
  textAlign: ({ textAlign }) => textAlign || 'left',
  fontSize: '14px',
  lineHeight: '19px',
  letterSpacing: '0',
  color: '#000000',
  height: '40px',
});
export const StyledHeaderRow = styled(TableRow)({
  height: '40px',
  borderRadius: '10px',
  borderBottom: '10px solid #f8f8f8 !important',
});
export const StyledRow = styled(TableRow)({
  height: '60px',
  boxShadow: '0 2px 0 0 rgba(0,0,0,0.1)',
  borderBottom: '10px solid #f8f8f8 !important',
});

export const StyledClickableRow = styled(StyledRow)({
  cursor: 'pointer',
});
export default function Table({ data, columns, clickableRows, rowAction }) {
  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } = useTable({
    columns,
    data,
  });

  return (
    <MaUTable {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <StyledHeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <StyledHeaderCell
                textAlign={column.textAlignHeader}
                width={column.width}
                {...column.getHeaderProps()}
              >
                {column.render('Header')}
              </StyledHeaderCell>
            ))}
          </StyledHeaderRow>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          const { original } = row;
          prepareRow(row);

          return clickableRows ? (
            <StyledClickableRow
              {...row.getRowProps()}
              onClick={() => rowAction(original)}
            >
              {row.cells.map(cell => {
                return (
                  <StyledCell
                    textAlign={cell.column.textAlignCell}
                    width={cell.column.width}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </StyledCell>
                );
              })}
            </StyledClickableRow>
          ) : (
            <StyledRow {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <StyledCell
                    textAlign={cell.column.textAlignCell}
                    width={cell.column.width}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </StyledCell>
                );
              })}
            </StyledRow>
          );
        })}
      </tbody>
    </MaUTable>
  );
}
Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  clickableRows: PropTypes.bool,
  rowAction: PropTypes.func,
};
Table.defaultProps = {
  clickableRows: false,
  rowAction: () => {},
};
