import React, { useContext } from 'react';
import history from 'store/history';
import Carousel from 'components/shared/Carousel';
import 'react-multi-carousel/lib/styles.css';
import MobileContext from 'context/MobileContext';
import { slides, responsive } from './service';
import styles from './style.module.css';

const MainSlider = () => {
  const isMobile = useContext(MobileContext);

  return (
    <div className={styles.carouselWrapper}>
      <Carousel responsive={responsive} showDots arrows={false}>
        {slides.map(currentSlide => (
          <div
            key={currentSlide.img}
            className={`${styles.container} ${currentSlide?.leftText && styles.leftText}`}
            style={{
              backgroundImage: `url(${
                isMobile ? currentSlide.mobileImg : currentSlide.img
              })`,
            }}
          >
            <h1 className={styles.title}>{currentSlide.title}</h1>
            <h2 className={styles.subtitle}>{currentSlide.subtitle}</h2>
            <div className={styles.text} onClick={() => history.push(currentSlide.link)}>
              {currentSlide.text}
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default MainSlider;
