import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const menuItems = ['Personal', 'Take photo', 'Confirm & pay'];

const Menu = ({ tab }) => {
  return (
    <div className={styles.menu}>
      {menuItems.map((el, i) => (
        <div key={el} className={`${styles.item} ${tab >= i && styles.activeTab}`}>
          <span>{el}</span>
        </div>
      ))}
    </div>
  );
};

Menu.propTypes = {
  tab: PropTypes.number.isRequired,
};

export default Menu;
