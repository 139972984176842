import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import { arrowLeftBlack as arrowLeft, arrowRight } from 'assets/icons';
import styles from './style.module.css';

const CustomLeft = ({ onClick }) => (
  <button className={`${styles.arrow} ${styles.left}`} onClick={onClick}>
    <img src={arrowLeft} alt="Back" />
  </button>
);

const CustomRight = ({ onClick }) => (
  <button className={`${styles.arrow} ${styles.right}`} onClick={onClick}>
    <img src={arrowRight} alt="Forward" />
  </button>
);

const CustomCarousel = ({ children, responsive, ...props }) => {
  return (
    <Carousel
      responsive={responsive}
      infinite
      arrows
      keyBoardControl
      customLeftArrow={<CustomLeft />}
      customRightArrow={<CustomRight />}
      {...props}
    >
      {children}
    </Carousel>
  );
};

CustomCarousel.propTypes = {
  children: PropTypes.array,
  responsive: PropTypes.object.isRequired,
};

CustomCarousel.defaultProps = {
  children: '',
};

export default CustomCarousel;
