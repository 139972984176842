import {
  emailIcon,
  creditCard,
  subscriptionIcon,
  statementsTransactions,
  checkIn,
  settingsIcon,
  packagesIcon,
} from 'assets/icons';

export const items = [
  {
    name: 'My Membership Plan',
    path: '/membership-subscription',
    icon: subscriptionIcon,
  },
  { name: 'Billing Information', path: '/billing-information', icon: creditCard },
  {
    name: 'Statements & Transactions',
    path: '/statements-transactions',
    icon: statementsTransactions,
  },
  { name: 'Training Packages', path: '/packages', icon: packagesIcon },
  { name: 'Check-in History', path: '/checkin-history', icon: checkIn },
  { name: 'Notification Settings', path: '/settings', icon: settingsIcon },
  // { name: 'Contact Us', path: '/contact-us', icon: emailIcon },
];
