import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePickerItem from 'components/ReservationPage/DatePickerItem';
import {
  arrowRight,
  arrowLeftBlack as arrowLeft,
  arrowLeftGray,
  arrowRightGray,
} from 'assets/icons';
import styles from './styles.module.css';

const DatePicker = ({ datesRange, selectDate, targetDate }) => {
  const [weekIndex, handleWeekIndex] = useState(0);

  const handleNextClick = () => {
    if (weekIndex !== 1) handleWeekIndex(weekIndex + 1);
  };
  const handlePrevClick = () => {
    if (weekIndex === 1) handleWeekIndex(weekIndex - 1);
  };

  useEffect(() => {
    selectDate(datesRange?.slice(weekIndex * 7, weekIndex * 7 + 7)[0]?.index);
  }, [weekIndex]);

  return (
    <div className={styles.wrapper}>
      <div onClick={handlePrevClick} className={styles.arrowWrapper}>
        <img
          src={weekIndex !== 0 ? arrowLeft : arrowLeftGray}
          alt="Back"
          className={styles.arrow}
        />
      </div>
      {datesRange?.slice(weekIndex * 7, weekIndex * 7 + 7).map(date => (
        <DatePickerItem
          key={`${date.index}${date.dayOfWeek}${date.dayMonth}}`}
          dayOfWeek={date.dayOfWeek}
          dayMonth={date.dayMonth}
          selectDate={selectDate}
          dateIndex={date.index}
          selectedDateIndex={targetDate.index}
        />
      ))}
      <div onClick={handleNextClick} className={styles.arrowWrapper}>
        <img
          src={weekIndex !== 1 ? arrowRight : arrowRightGray}
          alt="Back"
          className={styles.arrow}
        />
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  datesRange: PropTypes.oneOfType([PropTypes.array, PropTypes.arrayOf(PropTypes.object)]),
  selectDate: PropTypes.func.isRequired,
  targetDate: PropTypes.object.isRequired,
};
DatePicker.defaultProps = {
  datesRange: [],
};

export default DatePicker;
