import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import SignUpLayout from 'layouts/SignUpLayout';
import { resetPasswordValidation } from 'validation/auth.validation';
import MobileContext from 'context/MobileContext';

import Field from 'components/shared/Field';
import Button from 'components/shared/Button';
import Text from 'components/shared/Text';
import styles from './style.module.css';

const initialValues = {
  password: '',
  confirmPassword: '',
};

const NewPassword = ({ resetPassword, isRequesting }) => {
  const isMobile = useContext(MobileContext);
  const inputWidth = isMobile ? '260px' : '300px';

  useEffect(() => {}, []);

  return (
    <SignUpLayout height={450}>
      <div className={styles.page}>
        <Text tag="p" type="p1" className={styles.header}>
          Create New Password
        </Text>

        <Text tag="p" type="p2" className={styles.text}>
          Please enter a new password for your FITNESS SF account.
        </Text>

        <Text tag="p" type="p3" className={styles.paragraph}>
          Your password must be a minimum of 6 characters long. {!isMobile && <br />} We
          suggest it contains at least one capital letter, one number and a special
          character (?!@#$%^--&amp;*()_+`~).
        </Text>
        <Formik
          initialValues={initialValues}
          validationSchema={resetPasswordValidation}
          onSubmit={values => {
            resetPassword(values);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            handleBlur,
            isValid,
            dirty,
          }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <Field
                type="password"
                width={inputWidth}
                onChange={handleChange}
                value={values.password}
                invalid={touched.password && Boolean(errors.password)}
                error={errors.password}
                name="password"
                placeholder="New password"
                onBlur={handleBlur}
                withStrength
                disabled={isRequesting}
              />

              <Field
                type="password"
                width={inputWidth}
                onChange={handleChange}
                value={values.confirmPassword}
                invalid={touched.confirmPassword && Boolean(errors.confirmPassword)}
                error={errors.confirmPassword}
                name="confirmPassword"
                placeholder="Confirm new password"
                onBlur={handleBlur}
                disabled={isRequesting}
              />

              <Button
                isRequesting={isRequesting}
                disabled={isRequesting || !(isValid && dirty)}
                width="255px"
                type="submit"
              >
                Save
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </SignUpLayout>
  );
};

NewPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool,
};

NewPassword.defaultProps = {
  isRequesting: false,
};

export default NewPassword;
