import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import VideosOnDemand from 'components/VideosOnDemand';
import Spinner from 'components/shared/Spinner';

const VideosDemandPage = ({
  membership,
  videos,
  categories,
  favoriteVideos,
  searchVideos,
  loadMembership,
  loadPremiumVideos,
  addFavoriteVideo,
  removeFavoriteVideo,
  addUserGroup,
  searchPremiumVideo,
  filterPremiumVideo,
  isRequestingAddUserGroup,
  isRequesting,
  isRequestingMembership,
  memberStatus,
}) => {
  useEffect(() => {
    loadMembership();
    loadPremiumVideos();
  }, [loadMembership, loadPremiumVideos]);
  return (
    <HeaderFooterLayout>
      {isRequesting ? (
        <Spinner />
      ) : (
        <VideosOnDemand
          membership={membership}
          videos={videos}
          categories={categories}
          favoriteVideos={favoriteVideos}
          searchVideos={searchVideos}
          addUserGroup={addUserGroup}
          addFavoriteVideo={addFavoriteVideo}
          removeFavoriteVideo={removeFavoriteVideo}
          isRequestingAddUserGroup={isRequestingAddUserGroup}
          isRequestingMembership={isRequestingMembership}
          searchPremiumVideo={searchPremiumVideo}
          filterPremiumVideo={filterPremiumVideo}
          memberStatus={memberStatus}
        />
      )}
    </HeaderFooterLayout>
  );
};

VideosDemandPage.propTypes = {
  membership: PropTypes.array,
  videos: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  favoriteVideos: PropTypes.array.isRequired,
  searchVideos: PropTypes.array.isRequired,
  filterPremiumVideo: PropTypes.func.isRequired,
  loadMembership: PropTypes.func.isRequired,
  loadPremiumVideos: PropTypes.func.isRequired,
  addFavoriteVideo: PropTypes.func.isRequired,
  removeFavoriteVideo: PropTypes.func.isRequired,
  addUserGroup: PropTypes.func.isRequired,
  searchPremiumVideo: PropTypes.func.isRequired,
  isRequestingAddUserGroup: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  isRequestingMembership: PropTypes.bool.isRequired,
  memberStatus: PropTypes.string.isRequired,
};

VideosDemandPage.defaultProps = {
  membership: [],
};

export default VideosDemandPage;
