/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import styles from './style.module.css';

const VideoPopup = ({ link, open, handleClose, content }) => {
  return open ? (
    <ClickAwayListener
      onClickAway={event => {
        event.stopPropagation();
        event.preventDefault();
        handleClose();
      }}
    >
      <div className={styles.modal}>
        <Fade in={open}>
          <>
            {content === 'iframe' && (
              <iframe
                title={link}
                width={800}
                height={500}
                src={link}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
            {content === 'video' && (
              <video
                className={styles.videosPopupVideo}
                src={link}
                type="video/mp4"
                autoPlay
                controls
                controlsList="nodownload"
              />
            )}
          </>
        </Fade>
      </div>
    </ClickAwayListener>
  ) : null;
};

VideoPopup.propTypes = {
  link: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  content: PropTypes.string,
};

VideoPopup.defaultProps = {
  content: 'iframe',
};

export default VideoPopup;
