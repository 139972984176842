import React, { useContext, useState, useEffect } from 'react';
import MobileContext from 'context/MobileContext';
import Spinner from 'components/shared/Spinner';
import PropTypes from 'prop-types';
import { PopUp } from 'components/shared/PopUp';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import SessionDetails from './SessionDetails';
import TableDesktop from './Table/Desktop';
import TableMobile from './Table/Mobile';
import styles from './styles.module.css';

const MainContent = ({
  packages,
  isRequesting,
  loadPackages,
  setTargetPackage,
  targetPackage,
  loadActivity,
}) => {
  const isMobile = useContext(MobileContext);
  const [popUpIsOpened, setOpenPopUp] = useState(false);

  const handleOpenPopUp = _package => {
    setTargetPackage(_package);
    setOpenPopUp(!popUpIsOpened);
  };

  useEffect(() => {
    loadPackages();
    loadActivity();
  }, [loadPackages, loadActivity]);

  return (
    <>
      <HeaderFooterLayout profile="Packages">
        <div>
          {!isMobile && (
            <header className={styles.header}>
              <h1 className={styles.title}>My Personal Training Packages</h1>
            </header>
          )}

          <div className={styles.table}>
            {isRequesting ? (
              <>{isMobile ? <Spinner /> : <Spinner isWhite />}</>
            ) : isMobile ? (
              <TableMobile data={packages || []} handleOpenPopUp={handleOpenPopUp} />
            ) : (
              <TableDesktop data={packages || []} handleOpenPopUp={handleOpenPopUp} />
            )}
          </div>
        </div>
      </HeaderFooterLayout>
      <PopUp
        isOpened={popUpIsOpened}
        setIsOpened={handleOpenPopUp}
        backgroundColor="#f9f9f9"
      >
        <SessionDetails {...targetPackage} />
      </PopUp>
    </>
  );
};

MainContent.propTypes = {
  packages: PropTypes.arrayOf(PropTypes.object).isRequired,
  sessions: PropTypes.object.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  loadPackages: PropTypes.func.isRequired,
  loadActivity: PropTypes.func.isRequired,
  setTargetPackage: PropTypes.func.isRequired,
  targetPackage: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

MainContent.defaultProps = {
  targetPackage: null,
};

export default MainContent;
