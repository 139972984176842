import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import Select from 'components/shared/Select';
import PropTypes from 'prop-types';
import { profileIcon } from 'assets/icons';
import MobileContext from 'context/MobileContext';
import styles from './styles.module.css';

export const convertClubs = data => {
  if (!data) return [];
  const locations = data.map(item => {
    return { label: item.name, value: item.clubAutomationClubId.toString() };
  });
  return locations.sort((a, b) => (a.label > b.label ? 1 : -1));
};

const Location = ({ clubs, photo, user, changeFavoriteLocation }) => {
  const isMobile = useContext(MobileContext);
  const memberStatus = useSelector(state => state.auth.user.memberStatus);
  const isLimited = memberStatus === 'Canceled';

  function handleChange({ value }) {
    changeFavoriteLocation(value);
  }

  const options = convertClubs(clubs);

  return (
    <div className={styles.location}>
      <div>
        <h1>My Favorite Location</h1>
        <Select
          hideIndicatorSeparator
          hideBorder
          options={options}
          onChange={handleChange}
          value={user.favoriteLocationCAId}
          width={isMobile ? 280 : 300}
          isDisabled={isLimited}
        />
      </div>
      {!isMobile && (
        <img
          src={photo || profileIcon}
          className={photo ? styles.photo : styles.profileIcon}
          alt="profile"
        />
      )}
    </div>
  );
};
Location.propTypes = {
  clubs: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(null)]).isRequired,
  photo: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  changeFavoriteLocation: PropTypes.func.isRequired,
};
export default Location;
