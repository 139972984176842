import { connect } from 'react-redux';
import SessionCallPopUp from 'components/shared/SessionCallPopUp';
import callActions from 'store/sessionCall/actions';

const mapStateToProps = state => {
  return {
    isCallAllow: state.sessionCall.allow,
    isRequesting: state.sessionCall.isRequesting,
    friendId: state.sessionCall.friendId,
    friendSocketId: state.sessionCall.friendSocketId,
    hasIncomingCall: state.sessionCall.hasIncomingCall,
    callId: state.sessionCall.callId,
    username: state.sessionCall.username,
    credential: state.sessionCall.credential,
  };
};
const mapDispatchToProps = {
  checkCallPermission: callActions.Creators.checkCallPermissionRequest,
  clearCallState: callActions.Creators.clearCallState,
  clearIncomingCall: callActions.Creators.clearIncomingCall,
  incomingCallRejected: callActions.Creators.incomingCallRejected,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionCallPopUp);
