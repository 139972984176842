import { connect } from 'react-redux';
import SessionDetails from 'components/shared/SessionDetails';
import chatActions from 'store/messages/actions';

const mapStateToProps = state => {
  return {
    targetSession: state.home.targetSession,
  };
};
const mapDispatchToProps = {
  setTrainerId: chatActions.Creators.setTrainerId,
};
export default connect(mapStateToProps, mapDispatchToProps)(SessionDetails);
