import { connect } from 'react-redux';
import ResetPasswordPage from 'components/Auth/ResetPasswordPage';
import Actions from 'store/auth/actions';

const mapStateToProps = state => {
  return {
    isRequesting: state.auth.isRequesting,
    requestType: state.auth.requestType,
    error: state.auth.error,
  };
};

const mapDispatchToProps = {
  requestReset: Actions.Creators.requestResetRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
