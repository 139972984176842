import React, { useState } from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';
import Button from 'components/shared/Button';
import ConfirmPopup from 'components/shared/ConfirmPopup';
import styles from './styles.module.css';

const Balance = ({ balance, makePayment, primaryCard }) => {
  const [confirmPopup, setConfirmPopup] = useState(false);
  let balanceForChecking = null;

  if (balance.combined) {
    balanceForChecking = +(+balance.combined.replace('$', '')).toFixed(2);
  }

  const date = moment()
    .startOf('month')
    .format('MM.DD.YYYY');

  return balance?.combined ? (
    <>
      <div className={styles.container}>
        <h1 className={styles.title}>My Balance</h1>
        <div className={styles.card}>
          <p className={styles.balance}>{balance?.combined || ''}</p>
          {balanceForChecking > 0 ? <p className={styles.pastDue}>Past Due</p> : null}
          {balanceForChecking > 0 && <p className={styles.date}>Due date: {date}</p>}
        </div>
        {/* {balanceForChecking > 0 && ( */}
        <Button
          className={styles.buttonPayment}
          onClick={() => setConfirmPopup(true)}
          type="button"
          disabled={!balance}
        >
          Make a Payment
        </Button>
        {/* )} */}
      </div>
      <ConfirmPopup open={confirmPopup} onClose={setConfirmPopup}>
        <div className={styles.confirmPopup}>
          <h1>Would you like to pay your balance of {balance?.combined} ?</h1>
          <div className={styles.buttons}>
            <Button
              onClick={() => {
                makePayment({
                  paymentAmount: balanceForChecking,
                  paymentType: 'Credit card',
                  paymentMethodId: primaryCard.id,
                });
                setConfirmPopup(false);
              }}
              className={styles.button}
              width="100px"
            >
              Confirm
            </Button>
            <Button
              styleType="tertiary"
              onClick={() => setConfirmPopup(false)}
              width="100px"
            >
              Cancel
            </Button>
          </div>
        </div>
      </ConfirmPopup>
    </>
  ) : null;
};

Balance.propTypes = {
  balance: PropTypes.object.isRequired,
  makePayment: PropTypes.func.isRequired,
  primaryCard: PropTypes.object.isRequired,
};
export default Balance;
