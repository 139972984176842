import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import statisticActions from './actions';
import errorActions from '../error/actions';

const {
  Types: { LOAD_CLUBS_STATISTIC_REQUEST },
  Creators: { loadClubsStatisticSuccess, loadClubsStatisticFailure },
} = statisticActions;

const {
  Creators: { setError },
} = errorActions;

function* loadClubsStatistic({ clubId }) {
  try {
    const { data } = yield call(api.get, `/reservations/statistic?clubId=${clubId}`);

    yield put(loadClubsStatisticSuccess(data));
  } catch (error) {
    yield put(loadClubsStatisticFailure(error));
    yield put(setError('Load club statistic error.'));
  }
}

export function* clubsStatisticSaga() {
  yield takeLatest(LOAD_CLUBS_STATISTIC_REQUEST, loadClubsStatistic);
}
