import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import actions from './actions';
import errorActions from '../error/actions';

const {
  Types: { GET_INVITED_USERS_REQUEST, GET_TRAINER_PROMO_CODES_REQUEST },
  Creators: {
    getInvitedUsersSuccess,
    getInvitedUsersFailure,
    getTrainerPromoCodesSuccess,
    getTrainerPromoCodesFailure,
  },
} = actions;

const {
  Creators: { setError },
} = errorActions;

function* getInvitedUsers() {
  try {
    const { data } = yield call(api.get, '/referral');
    yield put(getInvitedUsersSuccess(data));
    if (data?.error) yield put(setError(data.error));
  } catch (error) {
    yield put(getInvitedUsersFailure());
    yield put(setError('Load invited users error.'));
  }
}

function* getTrainerPromoCodes() {
  try {
    const { data } = yield call(api.get, '/referral/trainer-promo-codes');
    yield put(getTrainerPromoCodesSuccess(data));
    if (data?.error) yield put(setError(data.error));
  } catch (error) {
    yield put(getTrainerPromoCodesFailure());
    yield put(setError('Load promo codes error.'));
  }
}

export function* referralSaga() {
  yield takeLatest(GET_INVITED_USERS_REQUEST, getInvitedUsers);
  yield takeLatest(GET_TRAINER_PROMO_CODES_REQUEST, getTrainerPromoCodes);
}
