import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import MainContent from 'components/HomePage/MainContent';
import Spinner from 'components/shared/Spinner';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import { Card, Modal, Typography } from '@material-ui/core';
import MobileContext from 'context/MobileContext';
import Button from 'components/shared/Button';
import { isIOS } from 'react-device-detect';

const HomePage = ({
  loadHome,
  isRequesting,
  favoriteClassesIsRequesting,
  favoriteLocationClassesIsRequesting,
  ...props
}) => {
  const isMobile = useContext(MobileContext);
  const [showModal, setShowModal] = useState(true);
  const appLink = isIOS
    ? process.env.REACT_APP_LINK_FOR_IOS
    : process.env.REACT_APP_LINK_FOR_ANDROID;
  useEffect(() => {
    loadHome();
  }, [loadHome]);

  useEffect(() => {}, [isIOS]);

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleDownloadClick = e => {
    e.preventDefault();
    window.location = appLink;
    localStorage.setItem('isRedirectToStore', 1);
  };
  const isRedirectToStore = localStorage.getItem('isRedirectToStore') || 0;
  return (
    <HeaderFooterLayout>
      {isRequesting ||
      favoriteClassesIsRequesting ||
      favoriteLocationClassesIsRequesting ? (
        <Spinner />
      ) : (
        <>
          {isMobile && showModal && parseInt(isRedirectToStore) === 0 ? (
            <>
              <Modal
                open={showModal}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Card
                  style={{
                    marginTop: '57%',
                    marginLeft: '10%',
                    paddingBottom: '20px',
                    borderRadius: 28,
                    width: '80%',
                  }}
                >
                  <Typography
                    // class="futura"
                    style={{
                      fontFamily: 'Futura Bold',
                      fontSize: '24px',
                      textAlign: 'center',
                      marginTop: 20,
                      letterSpacing: '0.05em',
                    }}
                  >
                    New
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: 'Futura Bold',
                      fontSize: '24px',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      letterSpacing: '0.05em',
                    }}
                  >
                    App Available
                  </Typography>
                  <center>
                    <div>
                      <Button
                        style={{
                          marginTop: 18,
                          borderRadius: '44px',
                          border: 0,
                          width: '80%',
                          height: '60px',
                          fontSize: '22px',
                        }}
                        onClick={handleDownloadClick}
                      >
                        Download Now
                      </Button>
                      <Typography
                        style={{
                          marginTop: '9%',
                          fontFamily: 'Inter Italic',
                        }}
                      >
                        **Please note that this app will
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'Inter Italic',
                          font: 1,
                          paddingLeft: '12px',
                        }}
                      >
                        soon be discontinued, so we kindly ask you to uninstall it.
                      </Typography>
                    </div>
                  </center>
                </Card>
              </Modal>
              <MainContent {...props} />
            </>
          ) : (
            <MainContent {...props} />
          )}
        </>
      )}
    </HeaderFooterLayout>
  );
};

HomePage.propTypes = {
  loadHome: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  favoriteClassesIsRequesting: PropTypes.bool.isRequired,
  favoriteLocationClassesIsRequesting: PropTypes.bool.isRequired,
};

export default HomePage;
