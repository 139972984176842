import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Popup = ({ handleCancelClick, handleConfirmClick, text, confirm }) => {
  return (
    <>
      <div className={styles.popupBackground} />
      <div className={styles.container}>
        <div className={styles.text}>{text}</div>
        <div className={styles.buttonContainer}>
          <div className={styles.buttonCancel} onClick={handleCancelClick}>
            Cancel
          </div>
          <div className={styles.buttonAction} onClick={handleConfirmClick}>
            {confirm}
          </div>
        </div>
      </div>
    </>
  );
};

Popup.propTypes = {
  handleCancelClick: PropTypes.func.isRequired,
  handleConfirmClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  confirm: PropTypes.string.isRequired,
};

export default Popup;
