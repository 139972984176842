import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadSsoTokenRequest: [],
  loadSsoTokenSuccess: ['token'],
  loadSsoTokenFailure: ['error'],
});

const ssoActions = {
  Types,
  Creators,
};

export default ssoActions;
