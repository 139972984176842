import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadTopicsRequest: [],
  loadTopicsSuccess: ['topics'],
  loadTopicsFailure: ['error'],
  preselectFreezeTopic: ['option'],
  resetPreselectedFreezeTopic: [],
});

const topicActions = {
  Types,
  Creators,
};

export default topicActions;
