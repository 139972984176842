import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadActivityRequest: [],
  loadActivitySuccess: [],
  loadActivityFailure: ['error'],

  loadVisitsRequest: [],
  loadVisitsSuccess: ['visits'],
  loadVisitsFailure: ['error'],

  // loadSessionsRequest: [],
  // loadSessionsSuccess: ['sessions'],
  // loadSessionsFailure: ['error'],

  loadInBodyRequest: [],
  loadInBodySuccess: ['inBody'],
  loadInBodyFailure: ['error'],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
