import { connect } from 'react-redux';
import ChatPage from 'pages/ChatPage';
import messageActions from 'store/messages/actions';

const mapStateToProps = state => {
  return {
    isRequesting: state.messages.isRequesting,
    messageText: state.messages.messageText,
    targetDialog: state.messages.targetDialog,
    resentMessages: state.messages.resentMessages,
    targetDialogMessagesHistory: state.messages.targetDialogMessagesHistory,
    messagesHistory: state.messages.messagesHistory,
  };
};
const mapDispatchToProps = {
  sendMessage: messageActions.Creators.sendMessageRequest,
  typeMessage: messageActions.Creators.typeMessage,
  selectTargetDialog: messageActions.Creators.selectTargetDialog,
  loadMessages: messageActions.Creators.loadMessagesRequest,
  clearTargetDialog: messageActions.Creators.clearTargetDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatPage);
