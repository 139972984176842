import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Field from 'components/shared/Field';
import Button from 'components/shared/Button';
import Spinner from 'components/shared/Spinner';
import Table from 'components/shared/MaterialUITable';
import styles from './style.module.css';
import { getUserInfo, getColumns } from './service';

const UserInfo = ({ user, searchUserRequest, isRequesting }) => {
  const [searchUser, setSearchUser] = useState('');
  const info = getUserInfo(user);
  const columns = getColumns();

  return (
    <>
      <div className={styles.searchSection}>
        <p className={styles.title}>Write id or sfIf or caId or email</p>
        <Field value={searchUser} onChange={e => setSearchUser(e.target.value)} />
        <Button disabled={!searchUser} onClick={() => searchUserRequest(searchUser)}>
          Search
        </Button>
      </div>

      {isRequesting ? (
        <Spinner />
      ) : (
        <>
          <div>
            {info.map(el => (
              <>
                {el.value && (
                  <p className={styles.userDetails}>
                    {el.label}: {el.value}
                  </p>
                )}
              </>
            ))}
          </div>
          {user?.reservations?.length > 0 && (
            <div>
              <p className={styles.title}>Reservations: </p>
              <div className={styles.table}>
                <Table data={user?.reservations} columns={columns} />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  searchUserRequest: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

export default UserInfo;
