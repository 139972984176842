import React, { useContext } from 'react';

import { facebook, instagram, linkedin, twitter } from 'assets/icons';
import styles from './style.module.css';

export const Footer = () => {
  const externalLinks = [
    {
      name: 'About us',
      url: 'https://fitnesssf.com/about-us',
      eventName: 'About_Us_BTN',
    },
    {
      name: 'FAQ',
      url: 'https://www.fitnesssf.com/faq',
      eventName: 'FAQ_BTN',
    },
    {
      name: 'Terms and Conditions',
      url: 'https://www.fitnesssf.com/terms-of-service',
      eventName: 'Terms_And_Conditions_BTN',
    },
    {
      name: 'Privacy Policies',
      url: 'https://www.fitnesssf.com/privacy-policy',
      eventName: 'Privacy_Policies_BTN',
    },
  ];

  const mediaButtons = [
    {
      iconDefault: twitter,
      url: 'https://twitter.com/fitnesssf',
      eventName: 'Twitter_BTN',
    },
    {
      iconDefault: facebook,
      url: 'https://facebook.com/fitnesssfhome',
      eventName: 'Facebook_BTN',
    },
    {
      iconDefault: instagram,
      url: 'https://instagram.com/fitnesssf/',
      eventName: 'Instagram_BTN',
    },
    {
      iconDefault: linkedin,
      url: 'https://linkedin.com/company/fitness-sf',
      eventName: 'LinkedIn_BTN',
    },
  ];

  const renderExternalLinks = () => {
    return (
      <div className={styles.linksContainer}>
        {externalLinks.map(item => {
          return (
            <a href={item.url} target="_blank" rel="noreferrer noopener" key={item.name}>
              <span className={styles.link} key={item.name}>
                {item.name}
              </span>
            </a>
          );
        })}
      </div>
    );
  };

  const renderMediaButtons = () => {
    return (
      <div className={styles.mediaButtonsContainer}>
        {mediaButtons.map(button => {
          return (
            <a
              href={button.url}
              target="_blank"
              rel="noreferrer noopener"
              key={button.url}
            >
              <img
                src={button.iconDefault}
                className={styles.mediaButton}
                alt={button.url}
              />
            </a>
          );
        })}
      </div>
    );
  };

  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div className={styles.footerWrapper} id="app-footer">
      <div className={styles.hiddenSection} />
      <div className={styles.container}>
        {renderExternalLinks()}
        {renderMediaButtons()}
        <span className={styles.copyrightText}>
          © {getYear()} All rights reserved. FitnessSF / FITNESS SF is a brand of
          Jackovics Enterprises, Inc.
        </span>
      </div>
    </div>
  );
};
