import React, { useContext } from 'react';
import MobileContext from 'context/MobileContext';
import PropTypes from 'prop-types';
import { analyticsSessionsMobile } from 'assets/background-images';
import { calendarOrangeIcon, cardIcon } from 'assets/icons';
import TableSessionsDetails from '../TableSessionsDetails';
import MobileTableSessionsDetails from '../MobileTableSessionsDetails';
import styles from './styles.module.css';

const SessionDetails = ({
  name,
  packagePrice,
  purchasedAt,
  historySession,
  sessionsRemaining,
}) => {
  const isMobile = useContext(MobileContext);
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.photo}
        style={{
          backgroundImage: `url(${analyticsSessionsMobile})`,
          boxShadow: 'inset 0px 0px 0px 500px rgba(0,0,0,0.5)',
        }}
      />
      <div
        className={[styles.mainContent, isMobile && styles.mainContentMobile].join(' ')}
      >
        <div className={styles.nameWrapper}>
          <p className={styles.name}>{name}</p>
          {sessionsRemaining > 0 && <span className={styles.inUse}>IN USE</span>}
        </div>

        <div className={styles.item}>
          <img src={calendarOrangeIcon} alt="" />
          <p>{purchasedAt}</p>
        </div>

        <div className={styles.item}>
          <img src={cardIcon} alt="" />
          <p>${packagePrice}</p>
        </div>
      </div>
      <div
        className={[
          styles.scheduleWrapper,
          isMobile && styles.scheduleWrapperMobile,
        ].join(' ')}
      >
        {isMobile ? (
          <MobileTableSessionsDetails data={historySession} />
        ) : (
          <TableSessionsDetails data={historySession} />
        )}
      </div>
    </div>
  );
};

SessionDetails.propTypes = {
  name: PropTypes.string,
  packagePrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  purchasedAt: PropTypes.string,
  historySession: PropTypes.arrayOf(PropTypes.object),
  sessionsRemaining: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SessionDetails.defaultProps = {
  name: null,
  packagePrice: null,
  purchasedAt: null,
  historySession: [],
  sessionsRemaining: null,
};

export default SessionDetails;
