import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styles from './styles.module.css';

const TableHistory = ({ data }) => {
  return (
    <div className={styles.history}>
      {data.map(({ time, location }) => (
        <div key={time} className={styles.card}>
          <p>{location}</p>
          <p className={styles.time}>
            <span>{moment(time).format('MM/DD/YYYY')}</span>
            <span>{moment(time).format('h:mm a')}</span>
          </p>
        </div>
      ))}
    </div>
  );
};
TableHistory.propTypes = {
  data: PropTypes.array.isRequired,
};
export default TableHistory;
