import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { profileInfoSchema } from 'validation/auth.validation';
import { penIcon } from 'assets/icons';
import MaskInput from 'components/shared/MaskInput';
import Field from 'components/shared/Field';
import Button from 'components/shared/Button';
import styles from './styles.module.css';

const Info = ({ user, profile, changeProfileInfo }) => {
  const [infoIsChanged, setIsChanged] = useState(false);
  return (
    <Formik
      initialValues={{
        phone: profile?.phone,
        address: profile?.mailingstreet,
        city: profile?.mailingcity,
        state: profile?.mailingstate,
        zipCode: profile?.mailingpostalcode,
        emergencyContact: profile?.emergencyContact,
        emergencyPhone: profile?.emergencyPhone,
      }}
      onSubmit={values => {
        changeProfileInfo(values);
      }}
      validationSchema={profileInfoSchema}
    >
      {({ handleSubmit, handleChange, values, errors, touched, isValid, dirty }) => {
        return (
          <div className={styles.info}>
            <div className={styles.section}>
              <h1>Personal Info</h1>
              <div>
                <Field
                  value={user?.firstName}
                  name="firstName"
                  placeholder="Name"
                  inputClassName={styles.input}
                  readOnly
                />
                <Field
                  value={profile?.lastName}
                  name="lastName"
                  placeholder="Last Name"
                  inputClassName={styles.input}
                  readOnly
                />
              </div>
            </div>
            <div className={styles.section}>
              <h1>Contact Info</h1>
              <div>
                <Field
                  value={profile?.email}
                  name="email"
                  placeholder="Email Address (username)"
                  inputClassName={styles.input}
                  readOnly
                />
                <MaskInput
                  value={values.phone}
                  onChange={e => {
                    setIsChanged(true);
                    handleChange(e);
                  }}
                  name="phone"
                  label="Phone Number"
                  inputClassName={styles.input}
                  invalid={touched.phone && Boolean(errors.phone)}
                  error={errors.phone}
                  mask="(999) 999 - 9999"
                  rightIcon={penIcon}
                />
                <div className={styles.addressSection}>
                  <Field
                    value={values.city}
                    onChange={e => {
                      setIsChanged(true);
                      handleChange(e);
                    }}
                    name="city"
                    placeholder="Сity"
                    inputClassName={styles.input}
                    invalid={touched.city && Boolean(errors.city)}
                    error={errors.city}
                    rightIcon={penIcon}
                  />
                  <Field
                    value={values.state}
                    onChange={e => {
                      setIsChanged(true);
                      handleChange(e);
                    }}
                    name="state"
                    placeholder="State"
                    inputClassName={styles.input}
                    invalid={touched.state && Boolean(errors.state)}
                    error={errors.state}
                    rightIcon={penIcon}
                  />
                  <Field
                    value={values.address}
                    onChange={e => {
                      setIsChanged(true);
                      handleChange(e);
                    }}
                    name="address"
                    placeholder="Street"
                    inputClassName={styles.input}
                    invalid={touched.address && Boolean(errors.address)}
                    error={errors.address}
                    rightIcon={penIcon}
                  />
                  <Field
                    value={values.zipCode}
                    onChange={e => {
                      setIsChanged(true);
                      handleChange(e);
                    }}
                    name="zipCode"
                    type="number"
                    placeholder="Zip code"
                    inputClassName={styles.input}
                    invalid={touched.zipCode && Boolean(errors.zipCode)}
                    error={errors.zipCode}
                    rightIcon={penIcon}
                  />
                </div>
              </div>
            </div>
            <div className={styles.section}>
              <h1>Emergency Contact</h1>
              <div>
                <Field
                  value={values.emergencyContact}
                  onChange={e => {
                    setIsChanged(true);
                    handleChange(e);
                  }}
                  name="emergencyContact"
                  placeholder="Emergency contact"
                  inputClassName={styles.input}
                  rightIcon={penIcon}
                  invalid={touched.emergencyContact && Boolean(errors.emergencyContact)}
                  error={errors.emergencyContact}
                />
                <MaskInput
                  value={values.emergencyPhone}
                  onChange={e => {
                    setIsChanged(true);
                    handleChange(e);
                  }}
                  name="emergencyPhone"
                  label="Emergency phone number"
                  inputClassName={styles.input}
                  rightIcon={penIcon}
                  invalid={touched.emergencyPhone && Boolean(errors.emergencyPhone)}
                  error={errors.emergencyPhone}
                  mask="(999) 999 - 9999"
                />
              </div>
              <Button
                className={styles.button}
                onClick={handleSubmit}
                disabled={!infoIsChanged}
              >
                Update
              </Button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
Info.propTypes = {
  user: PropTypes.object.isRequired,
  profile: PropTypes.object,
  changeProfileInfo: PropTypes.func.isRequired,
};
Info.defaultProps = {
  profile: {},
};
export default Info;
