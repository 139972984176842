import React from 'react';
import { useSelector } from 'react-redux';
import {
  houseIcon,
  // reserveSpotIcon,
  calendarGreyIcon,
  playIconSvg,
  fireIcon,
  nutrition,
  locationSvg,
  giftIcon,
} from 'assets/icons';
import { memberStatuses } from 'enums/memberStatuses';
import SidebarItem from './SidebarItem';
import styles from './style.module.css';

const items = [
  { name: 'Home', path: '/', icon: houseIcon },
  // { name: 'Reserve a Spot', path: '/reservation', icon: reserveSpotIcon },
  { name: 'Classes', path: '/classes', icon: calendarGreyIcon },
  { name: 'Nutrition', path: '/nutrition', icon: nutrition },
  { name: 'On-Demand', path: '/on_demand', icon: playIconSvg },
  { name: 'Locations', path: '/locations', icon: locationSvg },
  { name: 'Training', path: '/training', icon: fireIcon },
  { name: 'Get a Free Month', path: '/invite', icon: giftIcon },
];

const limitedItems = [
  { name: 'Home', path: '/', icon: houseIcon },
  // { name: 'Reserve a Spot', icon: reserveSpotIcon },
  { name: 'Classes', icon: calendarGreyIcon },
  { name: 'Nutrition', icon: nutrition },
  { name: 'On-Demand', icon: playIconSvg },
  { name: 'Locations', icon: locationSvg },
  { name: 'Training', path: '/training', icon: fireIcon },
  { name: 'Get a Free Month', icon: giftIcon },
];

function Sidebar() {
  // const activeLocationClub = useSelector(state => state.clubs.activeLocationClub || []);
  const memberStatus = useSelector(state =>
    state.auth.user ? state.auth.user.memberStatus : null
  );

  const isLimited =
    memberStatus === memberStatuses.onHold || memberStatus === memberStatuses.notActive;

  // const menuItems = isLimited
  //  ? limitedItems
  //  : activeLocationClub.length !== 0
  //  ? items
  //  : withoutReservationItems;

  const menuItems = isLimited ? limitedItems : items;

  return (
    <div className={styles.containerSidebar}>
      {menuItems.map(({ name, icon, path }) => (
        <SidebarItem key={name} name={name} icon={icon} path={path} />
      ))}
    </div>
  );
}

export default Sidebar;
