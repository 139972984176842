import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import { arrowLeft } from 'assets/icons';
import styles from './style.module.css';
import { getGeneralInfo, getSubscriptionPlanInfo } from './service';

const SubmitScreen = ({
  user,
  selectedClub,
  selectedPlan,
  card,
  createUser,
  onNext,
  planInfo,
  isRequesting,
  onBack,
}) => {
  const sendInfo = () => {
    createUser({
      user,
      club: +selectedClub?.clubAutomationClubId,
      plan: +selectedPlan?.id,
      card,
    });
  };

  useEffect(() => {
    if ((user && user.itemName) || user.contractTerm || user.userId) onNext();
  }, [user, onNext]);

  const generalInfo = getGeneralInfo(user);
  const subscriptionPlanInfo = getSubscriptionPlanInfo(planInfo, selectedClub?.name);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.sectionContainer}>
          <div className={styles.section}>
            <h1 className={styles.title}>Personal Details</h1>
            <div className={styles.container}>
              {generalInfo.map(({ title, value }) => (
                <p key={title} className={styles.sectionData}>
                  <b>{title}: </b>

                  <span>{value}</span>
                </p>
              ))}
            </div>
          </div>
          <div className={styles.line} />
          <div className={styles.section}>
            <h1 className={styles.title}>Subscription</h1>
            <div className={styles.container}>
              {subscriptionPlanInfo.map(({ title, value }) => (
                <p key={title} className={styles.sectionData}>
                  <b>{title}: </b>

                  <span>{value}</span>
                </p>
              ))}
            </div>
          </div>
        </div>

        <div>
          <h1 className={styles.title}>Card Information</h1>
          <div className={styles.container}>
            <p className={styles.sectionData}>
              <b>Card number: </b>

              <span>{card?.cardNumber}</span>
            </p>
            <p className={styles.sectionData}>
              <b>Expiration Date: </b>

              <span>
                {card?.cardExpirationMonth}/{card?.cardExpirationYear}
              </span>
            </p>
          </div>
        </div>
        <div className={styles.accessText}>
          By Clicking &quot;Accept &amp; Process&quot;, I accept that my subscription will
          auto-renew using the card on file. I will also be charged the total amount due
          today and billed on the 1st of the month cycle based on my billing type.
        </div>
        <div className={styles.buttonsContainer}>
          <div className={styles.back} onClick={onBack}>
            <img src={arrowLeft} alt="Back" className={styles.arrow} />
            <span>Back</span>
          </div>
          <Button
            disabled={isRequesting}
            isRequesting={isRequesting}
            className={styles.button}
            onClick={sendInfo}
          >
            Accept &amp; Process
          </Button>
        </div>
      </div>
    </>
  );
};

SubmitScreen.propTypes = {
  user: PropTypes.object.isRequired,
  selectedClub: PropTypes.object.isRequired,
  selectedPlan: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  planInfo: PropTypes.object.isRequired,
  createUser: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default SubmitScreen;
