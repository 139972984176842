import { connect } from 'react-redux';
import { Header } from 'components/Header';
import NotificationActions from 'store/notifications/actions';
import ClubActions from 'store/clubs/actions';

const mapStateToProps = state => {
  return {
    notifications: state.notifications.data,
    messagesHistory: state.messages.messagesHistory,
  };
};
const mapDispatchToProps = {
  handleReadOnOpenPopUp: NotificationActions.Creators.markAsReadAllNotifications,
  loadClubs: ClubActions.Creators.loadClubsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
