import React, { useContext, useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Spinner from 'components/shared/Spinner';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import MobileContext from 'context/MobileContext';
import StatusPopup from './StatusPopup';
import styles from './styles.module.css';
import Header from './Header';
import Form from './Form';

const ContactUs = ({
  clubs,
  profile,
  user,
  loadTopics,
  topics,
  postCase,
  loadClubs,
  isCreated,
  isRequesting,
  isRequestingUser,
  resetPreselectedFreezeTopic,
}) => {
  const isFreezeSelected = useSelector(state => state.topics.isFreezeSelected);
  const [selectedClub, selectClub] = useState(user?.favoriteLocation || '');
  const [isShowStatusPopup, showStatusPopup] = useState(false);

  useEffect(() => {
    loadTopics();
  }, [loadTopics]);

  useEffect(() => {
    loadClubs();
  }, [loadClubs]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (isCreated) {
      showStatusPopup(true);
    }
  }, [isCreated]);

  const inputRef = useRef(null);
  const [uploaded, setUploaded] = useState(false);
  const isMobile = useContext(MobileContext);
  return (
    <HeaderFooterLayout profile="Contact Us">
      <div className={styles.containerMain}>
        {(isRequesting || isShowStatusPopup) && (
          <div
            className={styles.popupBackground}
            onClick={() => showStatusPopup(false)}
          />
        )}

        {isRequestingUser ? <Spinner isWhite /> : null}

        {isShowStatusPopup && (
          <StatusPopup
            text="We're on it! We’ve received your request and one of our team members will respond shortly."
            className="contactUs"
          />
        )}

        {!isRequestingUser && (
          <>
            <Header
              clubs={clubs}
              selectedClub={selectedClub}
              changeClub={selectClub}
              isMobile={isMobile}
            />
            <Form
              user={user}
              profile={profile}
              selectedClub={{
                sfId: selectedClub,
                idCA: clubs.filter(club => club.sfId === selectedClub)[0]?.id,
              }}
              sendMessage={postCase}
              topics={topics}
              isFreezeSelected={isFreezeSelected}
              resetPreselectedFreezeTopic={resetPreselectedFreezeTopic}
              inputRef={inputRef}
              setUploaded={setUploaded}
              uploaded={uploaded}
            />
          </>
        )}
      </div>
    </HeaderFooterLayout>
  );
};
ContactUs.propTypes = {
  clubs: PropTypes.array.isRequired,
  topics: PropTypes.array,
  profile: PropTypes.object,
  user: PropTypes.object.isRequired,
  loadTopics: PropTypes.func.isRequired,
  loadClubs: PropTypes.func.isRequired,
  postCase: PropTypes.func.isRequired,
  isCreated: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  isRequestingUser: PropTypes.bool,
  resetPreselectedFreezeTopic: PropTypes.func.isRequired,
};
ContactUs.defaultProps = {
  topics: null,
  isRequestingUser: null,
  profile: null,
};
export default ContactUs;
