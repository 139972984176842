import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { buddyReferral } from 'assets/icons';
import styles from './style.module.css';

const ReferralPopup = ({ open, onClose, firstName, lastName }) => {
  return (
    <div>
      <Modal
        className={styles.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper elevation={3} className={styles.paper}>
            <>
              <img alt="You are invited" src={buddyReferral} className={styles.image} />
              <p className={styles.content}>
                <p>{firstName + ' ' + lastName} has invited you to join FITNESS SF. </p>
                <p>
                  Receive a $10 credit towards future membership dues after
                  joining.
                </p>
              </p>
            </>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};

ReferralPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

export default ReferralPopup;
