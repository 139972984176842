import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadTransactionRequest: ['payload'],
  loadTransactionSuccess: ['transaction'],
  loadTransactionFailure: ['error'],

  loadStatementRequest: ['payload'],
  loadStatementSuccess: ['status'],
  loadStatementFailure: ['error'],
});

const transactionActions = {
  Types,
  Creators,
};

export default transactionActions;
