import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { memberStatuses } from 'enums/memberStatuses';
import Message from './Message';
import styles from './styles.module.css';

const Main = ({ targetDialogMessagesHistory, targetDialog }) => {
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef?.current) messagesEndRef.current.scrollIntoView(false);
  };
  const memberStatus = useSelector(state => state.auth.user.memberStatus);
  const isStaff = memberStatus === memberStatuses.staff;
  useEffect(scrollToBottom, [targetDialogMessagesHistory]);

  return (
    <>
      {targetDialogMessagesHistory.length ? (
        <div className={styles.messagesListing} ref={messagesEndRef}>
          {targetDialogMessagesHistory.map(message => (
            <Message {...message} key={message.id} />
          ))}
          {/* <div ref={messagesEndRef} /> */}
        </div>
      ) : targetDialog ? (
        <div className={styles.emptyList}>Start your conversation here</div>
      ) : (
        <div className={styles.emptyList}>
          {isStaff ? (
            <>Contact with anybody directly using this chat.</>
          ) : (
            <>
              Look for your favorite trainers and contact them directly using this chat.
              Don’t hesitate to ask for training tips, recommendations…
              <br /> You will also be able to book your next online fitness coaching!
            </>
          )}
        </div>
      )}
    </>
  );
};

Main.propTypes = {
  targetDialogMessagesHistory: PropTypes.arrayOf(PropTypes.object),
  targetDialog: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

Main.defaultProps = {
  targetDialogMessagesHistory: [],
};
export default Main;
