import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import RenderAllClubs from './AllClubs';
import RenderFavoriteClubs from './FavoriteClubs';
import RenderSelectedClubs from './SelectedClubs';

const TrainersTab = ({
  trainers,
  locations,
  favoriteClubId,
  selectedClubId,
  setIsOpened,
  selectTrainer,
  setTrainerId,
  isMobile,
  isHideOtherLocations,
}) => {
  useEffect(() => {}, []);

  return (
    <>
      {selectedClubId === 'all' ? (
        <RenderAllClubs
          isHideOtherLocations={isHideOtherLocations}
          trainers={trainers}
          favoriteClubId={favoriteClubId}
          locations={locations}
          setIsOpened={setIsOpened}
          selectTrainer={selectTrainer}
          setTrainerId={setTrainerId}
          isMobile={isMobile}
        />
      ) : selectedClubId === favoriteClubId ? (
        <RenderFavoriteClubs
          trainers={trainers}
          favoriteClubId={favoriteClubId}
          locations={locations}
          selectTrainer={selectTrainer}
          setIsOpened={setIsOpened}
          setTrainerId={setTrainerId}
        />
      ) : (
        <RenderSelectedClubs
          trainers={trainers}
          selectedClubId={selectedClubId}
          locations={locations}
          selectTrainer={selectTrainer}
          setIsOpened={setIsOpened}
          setTrainerId={setTrainerId}
        />
      )}
    </>
  );
};
TrainersTab.propTypes = {
  trainers: PropTypes.arrayOf(PropTypes.object),
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  favoriteClubId: PropTypes.string.isRequired,
  selectedClubId: PropTypes.string.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  selectTrainer: PropTypes.func.isRequired,
  setTrainerId: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isHideOtherLocations: PropTypes.bool.isRequired,
};

TrainersTab.defaultProps = {
  trainers: [],
};
export default TrainersTab;
