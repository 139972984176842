import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import styles from './styles.module.css';

const BannedBlock = ({ dateOfFinishBan }) => {
  return (
    <div className={styles.wrapper}>
      <p>
        Uh Oh! Looks like you did not show for three consecutive reservations in a row.
        You will be able to make another reservation again in
        {moment(dateOfFinishBan).diff(moment(getSFTime('full')), 'days')}
        {moment(dateOfFinishBan).diff(moment(getSFTime('full'))) === 1 ? ' day' : ' days'}
        {/* . If you believe you received this in error, please
         <a href="/contact-us" className={styles.link}>
         contact us
         </a> */}
        . Thank you for your understanding.
      </p>
    </div>
  );
};

BannedBlock.propTypes = {
  dateOfFinishBan: PropTypes.string.isRequired,
};

export default BannedBlock;
