import { spawn } from 'redux-saga/effects';
import { authRootSaga } from './auth/sagas';
import { classesSaga } from './home/sagas';
import { clubsSaga } from './clubs/sagas';
import { topicsSaga } from './topics/sagas';
import { trainersSaga } from './training/sagas';
import { analyticsSaga } from './analytics/sagas';
import { classesScheduleSaga } from './classes/sagas';
import { pdfSaga } from './pdf/sagas';
import { transactionSaga } from './transaction/sagas';
import { caseSaga } from './case/sagas';
import { historySaga } from './history/sagas';
import { billingSaga } from './billing/sagas';
import { notificationsSaga } from './notifications/sagas';
import { membershipSaga } from './membership/sagas';
import { packagesSaga } from './packages/sagas';
import { messagesSaga } from './messages/sagas';
import { ssoSaga } from './sso/sagas';
import { reservationsSaga } from './reservations/sagas';
import { clubsStatisticSaga } from './clubsStatistic/sagas';
import { safetyCommitmentSaga } from './safetyCommitment/sagas';
import { sessionCallSaga } from './sessionCall/sagas';
import { classCallSaga } from './classCall/sagas';
import { premiumVideosSaga } from './premiumVideos/sagas';
import { signUpSaga } from './signUp/sagas';
import { adminSaga } from './admin/sagas';
import { referralSaga } from './referral/sagas';
import { newOlsSaga } from './newOls/sagas';

export default function*() {
  yield spawn(authRootSaga);
  yield spawn(classesSaga);
  yield spawn(clubsSaga);
  yield spawn(topicsSaga);
  yield spawn(trainersSaga);
  yield spawn(analyticsSaga);
  yield spawn(classesScheduleSaga);
  yield spawn(transactionSaga);
  yield spawn(pdfSaga);
  yield spawn(caseSaga);
  yield spawn(billingSaga);
  yield spawn(historySaga);
  yield spawn(notificationsSaga);
  yield spawn(membershipSaga);
  yield spawn(packagesSaga);
  yield spawn(messagesSaga);
  yield spawn(ssoSaga);
  yield spawn(reservationsSaga);
  yield spawn(clubsStatisticSaga);
  yield spawn(safetyCommitmentSaga);
  yield spawn(sessionCallSaga);
  yield spawn(classCallSaga);
  yield spawn(premiumVideosSaga);
  yield spawn(signUpSaga);
  yield spawn(adminSaga);
  yield spawn(referralSaga);
  yield spawn(newOlsSaga);
}
