import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  isRequestingEmail: false,
  isRequestingNewUser: false,
  isRequestingUploadPhoto: false,
  isRequestingUpdateExistingUser: false,
  plans: [],
  membershipPlanInfo: [],
  newOlsPlans: [],
  userExistenceData: {},
  userPersonalInfo: {},
  uploadedPhoto: {},
  newUserInfo: {},
  paymentDetails: {},
  existingUserNewCard: {},
  updatedUserDetails: {},
  editedCardInfo: {},
  user: { state: 'CA' },
  card: {},
  exsistingUserCardDetails: {},
  planInfo: {},
  referralPerson: {},
  error: null,
};

const createNewUserRequest = (state = INITIAL_STATE) => {
  return { ...state, isRequestingNewUser: true, error: null };
};

const createNewUserSuccess = (state = INITIAL_STATE, { newUserInfo }) => {
  return {
    ...state,
    newUserInfo,
    isRequestingNewUser: false,
  };
};

const createNewUserFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequestingNewUser: false,
    error,
  };
};

const uploadPhotoRequest = (state = INITIAL_STATE) => {
  return { ...state, isRequestingUploadPhoto: true, error: null };
};

const uploadPhotoSuccess = (state = INITIAL_STATE, { uploadedPhoto }) => {
  return {
    ...state,
    uploadedPhoto,
    isRequestingUploadPhoto: false,
  };
};

const uploadPhotoFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequestingUploadPhoto: false,
    error,
  };
};
const updateExistingUserRequest = (state = INITIAL_STATE) => {
  return { ...state, isRequestingUpdateExistingUser: true, error: null };
};

const updateExistingUserSuccess = (state = INITIAL_STATE, { updatedUserDetails }) => {
  return {
    ...state,
    updatedUserDetails,
    isRequestingUpdateExistingUser: false,
  };
};

const updateExistingUserFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequestingUpdateExistingUser: false,
    error,
  };
};
const request = (state = INITIAL_STATE) => {
  return { ...state, isRequesting: true, error: null };
};

const emailRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequestingEmail: true,
    error: null,
  };
};

const emailFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequestingEmail: false,
    userExistenceData: {},
    error,
  };
};

const resetArrayState = (state = INITIAL_STATE, { data }) => {
  if (data === 'newOlsPlans') {
    return {
      ...state,
      newOlsPlans: [],
      isRequesting: false,
    };
  }
};

const resetObjectState = (state = INITIAL_STATE, { data }) => {
  if (data === 'userExistenceData') {
    return {
      ...state,
      userExistenceData: [],
      isRequesting: false,
    };
  } else if (data === 'paymentDetails') {
    return {
      ...state,
      paymentDetails: [],
      isRequesting: false,
    };
  }
};

const failure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    error,
  };
};

const getPlansSuccess = (state = INITIAL_STATE, { plans }) => {
  return {
    ...state,
    plans,
    isRequesting: false,
  };
};

const getMembershipPlanInfoSuccess = (state = INITIAL_STATE, { membershipPlanInfo }) => {
  return {
    ...state,
    membershipPlanInfo,
    isRequesting: false,
  };
};

const getNewOlsPlansSuccess = (state = INITIAL_STATE, { newOlsPlans }) => {
  return {
    ...state,
    newOlsPlans,
    isRequesting: false,
  };
};

const findUserByEmailSuccess = (state = INITIAL_STATE, { userExistenceData }) => {
  return {
    ...state,
    userExistenceData,
    isRequestingEmail: false,
  };
};

const addUserPersonalInfo = (state = INITIAL_STATE, { userPersonalInfo }) => {
  return {
    ...state,
    userPersonalInfo,
    isRequesting: false,
  };
};

const addCard = (state = INITIAL_STATE, { card }) => {
  return {
    ...state,
    card,
    isRequesting: false,
  };
};

const createUserSuccess = (state = INITIAL_STATE, { user }) => {
  if (user && (user.itemName || user.contractTerm || user.userId))
    return {
      ...state,
      user,
      isRequesting: false,
    };
  return {
    ...state,
    isRequesting: false,
  };
};

const getPaymentDetailsSuccess = (state = INITIAL_STATE, { paymentDetails }) => {
  return {
    ...state,
    paymentDetails,
    isRequesting: false,
  };
};

const addExistingUserNewCardSuccess = (
  state = INITIAL_STATE,
  { existingUserNewCard }
) => {
  return {
    ...state,
    existingUserNewCard,
    isRequesting: false,
  };
};

const editNewCardSuccess = (state = INITIAL_STATE, { existingUserNewCard }) => {
  return {
    ...state,
    existingUserNewCard,
    isRequesting: false,
  };
};

const editExistingUserCardSuccess = (state = INITIAL_STATE, { editedCardInfo }) => {
  return {
    ...state,
    editedCardInfo,
    isRequesting: false,
  };
};

const getPlanInfoSuccess = (state = INITIAL_STATE, { planInfo, user }) => {
  return {
    ...state,
    planInfo,
    user,
    isRequesting: false,
  };
};

const createGuestSuccess = (state = INITIAL_STATE, { user }) => {
  return {
    ...state,
    user,
    isRequesting: false,
  };
};

const addUserDetails = (state = INITIAL_STATE, { user }) => {
  return {
    ...state,
    user,
    isRequesting: false,
  };
};

const checkReferralCodeSuccess = (state = INITIAL_STATE, { person }) => {
  return {
    ...state,
    referralPerson: person,
    isRequesting: false,
  };
};

const checkPromoCodeSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    referralPerson: { ...data?.user, plan: data?.plan, clubSfId: data?.club },
  };
};

export const HANDLERS = {
  [Types.GET_PLANS_REQUEST]: request,
  [Types.GET_PLANS_SUCCESS]: getPlansSuccess,
  [Types.GET_PLANS_FAILURE]: failure,

  [Types.GET_MEMBERSHIP_PLAN_INFO_REQUEST]: request,
  [Types.GET_MEMBERSHIP_PLAN_INFO_SUCCESS]: getMembershipPlanInfoSuccess,
  [Types.GET_MEMBERSHIP_PLAN_INFO_FAILURE]: failure,

  [Types.GET_NEW_OLS_PLANS_REQUEST]: request,
  [Types.GET_NEW_OLS_PLANS_SUCCESS]: getNewOlsPlansSuccess,
  [Types.GET_NEW_OLS_PLANS_FAILURE]: failure,

  [Types.FIND_USER_BY_EMAIL_REQUEST]: emailRequest,
  [Types.FIND_USER_BY_EMAIL_SUCCESS]: findUserByEmailSuccess,
  [Types.FIND_USER_BY_EMAIL_FAILURE]: emailFailure,

  [Types.UPLOAD_PHOTO_REQUEST]: uploadPhotoRequest,
  [Types.UPLOAD_PHOTO_SUCCESS]: uploadPhotoSuccess,
  [Types.UPLOAD_PHOTO_FAILURE]: uploadPhotoFailure,

  [Types.CREATE_NEW_USER_REQUEST]: createNewUserRequest,
  [Types.CREATE_NEW_USER_SUCCESS]: createNewUserSuccess,
  [Types.CREATE_NEW_USER_FAILURE]: createNewUserFailure,

  [Types.ADD_CARD]: addCard,

  [Types.RESET_ARRAY_STATE]: resetArrayState,
  [Types.RESET_OBJECT_STATE]: resetObjectState,

  [Types.ADD_USER_PERSONAL_INFO]: addUserPersonalInfo,

  [Types.ADD_EXISTING_USER_NEW_CARD_REQUEST]: request,
  [Types.ADD_EXISTING_USER_NEW_CARD_SUCCESS]: addExistingUserNewCardSuccess,
  [Types.ADD_EXISTING_USER_NEW_CARD_FAILURE]: failure,

  [Types.EDIT_NEW_CARD_REQUEST]: request,
  [Types.EDIT_NEW_CARD_SUCCESS]: editNewCardSuccess,
  [Types.EDIT_NEW_CARD_FAILURE]: failure,

  [Types.UPDATE_EXISTING_USER_REQUEST]: updateExistingUserRequest,
  [Types.UPDATE_EXISTING_USER_SUCCESS]: updateExistingUserSuccess,
  [Types.UPDATE_EXISTING_USER_FAILURE]: updateExistingUserFailure,

  [Types.EDIT_EXISTING_USER_CARD_REQUEST]: request,
  [Types.EDIT_EXISTING_USER_CARD_SUCCESS]: editExistingUserCardSuccess,
  [Types.EDIT_EXISTING_USER_CARD_FAILURE]: failure,

  [Types.GET_PLAN_INFO_REQUEST]: request,
  [Types.GET_PLAN_INFO_SUCCESS]: getPlanInfoSuccess,
  [Types.GET_PLAN_INFO_FAILURE]: failure,

  [Types.ADD_USER_DETAILS_REQUEST]: request,
  [Types.ADD_USER_DETAILS_SUCCESS]: addUserDetails,
  [Types.ADD_USER_DETAILS_FAILURE]: failure,

  [Types.GET_PAYMENT_DETAILS_REQUEST]: request,
  [Types.GET_PAYMENT_DETAILS_SUCCESS]: getPaymentDetailsSuccess,
  [Types.GET_PAYMENT_DETAILS_FAILURE]: failure,

  [Types.CREATE_GUEST_REQUEST]: request,
  [Types.CREATE_GUEST_SUCCESS]: createGuestSuccess,
  [Types.CREATE_GUEST_FAILURE]: failure,

  [Types.CHECK_REFERRAL_CODE_REQUEST]: request,
  [Types.CHECK_REFERRAL_CODE_SUCCESS]: checkReferralCodeSuccess,
  [Types.CHECK_PROMO_CODE_SUCCESS]: checkPromoCodeSuccess,
  [Types.CHECK_REFERRAL_CODE_FAILURE]: failure,

  [Types.CREATE_USER_REQUEST]: request,
  [Types.CREATE_USER_SUCCESS]: createUserSuccess,
  [Types.CREATE_USER_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
