import { connect } from 'react-redux';
import HomePage from 'pages/HomePage';
import Actions from 'store/home/actions';
import ReservationActions from 'store/reservations/actions';
import SessionCallActions from 'store/sessionCall/actions';
import ClassCallActions from 'store/classCall/actions';
import ChatActions from 'store/messages/actions';
import TrainingActions from 'store/training/actions';
import AuthActions from 'store/auth/actions';
import topicsActions from 'store/topics/actions';

const mapStateToProps = state => {
  return {
    favoriteLocationClasses: state.home.favoriteLocationClasses,
    sessions: state.home.sessions,
    isRequesting: state.home.isRequesting,
    favoriteClassesIsRequesting: state.home.favoriteClassesIsRequesting,
    favoriteLocationClassesIsRequesting: state.home.favoriteLocationClassesIsRequesting,
    upcomingReservations: state.reservations.upcomingReservations,
    isDeleteReservationRequesting: state.reservations.isDeleteRequesting,
    isSessionCallPopUpOpened: state.sessionCall.isPopUpOpened,
    isClassCallPopUpOpened: state.classCall.isPopUpOpened,
    user: state.auth.user,
    vaccineStatus: state.auth?.profile?.vaccineStatus,
    clubs: state.clubs.clubs,
  };
};
const mapDispatchToProps = {
  loadProfile: AuthActions.Creators.loadProfileRequest,
  loadHome: Actions.Creators.loadHomeRequest,
  addClassToFavorite: Actions.Creators.addClassToFavoriteHomepageRequest,
  removeClassFromFavorite: Actions.Creators.removeClassFromFavoriteHomepageRequest,
  setTargetSession: Actions.Creators.setTargetSession,
  deleteReservation: ReservationActions.Creators.deleteReservationRequest,
  handleOpenCallPopUp: SessionCallActions.Creators.handleOpenCallPopUp,
  setTrainerId: ChatActions.Creators.setTrainerId,
  handleOpenClassCallPopUp: ClassCallActions.Creators.handleOpenClassCallPopUp,
  cleanCancelSessionEmail: TrainingActions.Creators.cleanCancelSessionEmail,
  sendCancelSessionEmail: TrainingActions.Creators.sendCancelSessionEmailRequest,
  checkMemberStatus: AuthActions.Creators.checkMemberStatusRequest,
  preselectFreezeTopic: topicsActions.Creators.preselectFreezeTopic,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
