export default Object.freeze({
  cardioGroup: [
    'aqua',
    'water',
    'swimming',
    'aquatics',
    'h2o',
    'pool',
    'urban kick',
    'nidra',
    'zumba',
  ],
  muscleGroup: ['yoga', 'vinyasa', 'pilates', 'barre', 'hatha', 'flow'],
  relaxGroup: ['yoga', 'vinyasa'],
  loseWeightGroup: ['ride', 'spin', 'cycle'],
  keepInShape: ['urban kick', 'nidra', 'zumba', 'hiit', 'six pack'],
});
