import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Main from 'components/Auth/LoginPage';
import SignUpLayout from 'layouts/SignUpLayout';
import MobileContext from 'context/MobileContext';

const LoginPage = props => {
  const isMobile = useContext(MobileContext);

  return (
    <SignUpLayout height={isMobile ? 300 : 330}>
      <Main {...props} />
    </SignUpLayout>
  );
};

LoginPage.propTypes = {
  login: PropTypes.func.isRequired,
};

LoginPage.defaultProps = {};

export default LoginPage;
