import React, { useState } from 'react';
import PropTypes from 'prop-types';
import VaccineVerificationLayout from 'layouts/VaccineVerificationLayout';
import VaccineVerification from 'components/VaccineVerification';

const VaccineVerificationPage = ({ isAuth, ...props }) => {
  const [step, setStep] = useState(0);
  return (
    <VaccineVerificationLayout step={step} isAuth={isAuth}>
      <VaccineVerification setStep={setStep} step={step} isAuth={isAuth} {...props} />
    </VaccineVerificationLayout>
  );
};

VaccineVerificationPage.propTypes = {
  isAuth: PropTypes.bool,
  user: PropTypes.object.isRequired,
  setVaccineStatus: PropTypes.func.isRequired,
  checkContactId: PropTypes.func.isRequired,
  personByContactId: PropTypes.object.isRequired,
  vaccineStatus: PropTypes.string,
};

VaccineVerificationPage.defaultProps = {
  isAuth: false,
  vaccineStatus: '',
};

export default VaccineVerificationPage;
