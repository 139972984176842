/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Formik } from 'formik';
import { requestResetValidation } from 'validation/auth.validation';
import MobileContext from 'context/MobileContext';
import SignUpLayout from 'layouts/SignUpLayout';
import Field from 'components/shared/Field';
import Button from 'components/shared/Button';
import Text from 'components/shared/Text';
import { lockIcon } from 'assets/icons';
import history from 'store/history';
import styles from './style.module.css';

const ResetPassword = ({ requestReset, isRequesting, requestType, error }) => {
  const [showError, setShowError] = useState(false);
  const isMobile = useContext(MobileContext);

  useEffect(() => {
    if (requestType === 'REQUEST_RESET_FAILURE' && error) {
      setShowError(true);
    }
  }, [requestType, error]);

  useEffect(() => {}, []);

  const handleBackClick = () => {
    history.push('/login');
  };

  return (
    <SignUpLayout height={400}>
      <div className={styles.page}>
        <img src={lockIcon} alt="lock" className={styles.lock} />
        <Text tag="p" type="p1" className={styles.header}>
          Forgot Password
        </Text>

        <Text tag="p" type="p2" className={styles.paragraph}>
          Enter the email address that&apos;s associated with your FITNESS SF account.
        </Text>

        <Text tag="p" type="p2" className={styles.p2}>
          We will send you a password reset link
        </Text>

        <Formik
          initialValues={{ email: '' }}
          validationSchema={requestResetValidation}
          onSubmit={({ email }) => {
            requestReset(email?.toLowerCase());
          }}
        >
          {({
            handleSubmit,
            values,
            errors,
            touched,
            dirty,
            isValid,
            handleBlur,
            handleChange,
          }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <Field
                width={isMobile ? '260px' : '300px'}
                onChange={e => {
                  setShowError(false);
                  handleChange(e);
                }}
                value={values.email}
                invalid={showError ? true : touched.email && Boolean(errors.email)}
                error={showError ? error : errors.email}
                name="email"
                placeholder="Email address"
                disabled={isRequesting}
                onBlur={handleBlur}
                icon="email"
              />

              <Button
                type="submit"
                width="275px"
                isRequesting={isRequesting}
                disabled={!(isValid && dirty) || isRequesting}
              >
                Request Reset Link
              </Button>
            </form>
          )}
        </Formik>

        <p className={styles.link} onClick={() => handleBackClick()}>
          Cancel
        </p>
      </div>
    </SignUpLayout>
  );
};

ResetPassword.propTypes = {
  requestReset: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool,
  error: PropTypes.string,
  requestType: PropTypes.string,
};

ResetPassword.defaultProps = {
  isRequesting: false,
  error: '',
  requestType: '',
};

ResetPassword.defaultProps = {};

export default ResetPassword;
