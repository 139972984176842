import React from 'react';
import Select from 'components/shared/Select';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

export const convertClubs = data => {
  if (!data) return [];
  const locations = data.map(item => {
    return { label: item.name, value: item.sfId };
  });
  return locations.sort((a, b) => (a.label > b.label ? 1 : -1));
};

const Header = ({ clubs, selectedClub, changeClub, isMobile }) => {
  const options = convertClubs(clubs);

  return (
    <div className={styles.location}>
      <div className={styles.content}>
        {!isMobile && <h1>Contact Us</h1>}
        <Select
          hideIndicatorSeparator
          hideBorder
          options={options}
          onChange={({ value }) => changeClub(value)}
          value={selectedClub}
          width={isMobile ? '100%' : 700}
        />
      </div>
    </div>
  );
};
Header.propTypes = {
  clubs: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(null)]).isRequired,
  selectedClub: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  changeClub: PropTypes.func.isRequired,
};
export default Header;
