import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/shared/Button';
import RadioButton from 'components/shared/RadioButton';
import { arrowLeft } from 'assets/icons';
import styles from './style.module.css';

const SelectPlanSection = ({
  selectedClub,
  plans,
  selectedPlan,
  setSelectedPlan,
  onNext,
  onBack,
}) => {
  const [isShowPlans, setShowPlans] = useState(false);
  const clubPlans = plans.map(({ id, name, price, description }) => {
    return {
      id,
      type:
        name && name.toLowerCase().match(/month/)
          ? 'monthly'
          : name.toLowerCase().match(/year/)
          ? 'annually'
          : '',
      name: name && name.split('-') && name.split('-')[1],
      price: `$${price}`,
      description,
    };
  });

  useEffect(() => {
    const currentPlans = plans.filter(el =>
      el.locationIds.some(i => i === +selectedClub)
    );
    if (currentPlans?.length > 0) setShowPlans(true);
  }, [selectedClub, plans]);

  return (
    isShowPlans && (
      <>
        {clubPlans.map(c => (
          <div
            className={`${styles.card} ${selectedPlan === c.id && styles.activeCard}`}
            key={c.id}
            onClick={() => setSelectedPlan(c.id)}
          >
            <div className={styles.wrapper}>
              <div className={styles.radioButton}>
                <RadioButton value={selectedPlan === c.id} />
              </div>

              <div>
                <h1 className={styles.name}>{c.name}</h1>
                <p className={styles.description}>{c.description}</p>
              </div>
            </div>

            <div className={styles.price}>
              <div className={styles.line} />
              <p>
                <span className={styles.cost}>{c.price}</span> {c.type}
              </p>
            </div>
          </div>
        ))}
        <div className={styles.buttonsContainer}>
          <div className={styles.back} onClick={onBack}>
            <img src={arrowLeft} alt="Back" className={styles.arrow} />
            <span>Back</span>
          </div>
          <Button disabled={!selectedPlan} className={styles.button} onClick={onNext}>
            Next
          </Button>
        </div>
      </>
    )
  );
};

SelectPlanSection.propTypes = {
  onBack: PropTypes.func.isRequired,
  selectedClub: PropTypes.string.isRequired,
  plans: PropTypes.array.isRequired,
  selectedPlan: PropTypes.string.isRequired,
  setSelectedPlan: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

SelectPlanSection.defaultValue = {
  plans: [],
};

export default SelectPlanSection;
