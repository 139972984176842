/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/shared/IconSVG';
import { imgVisa, imgMastercard, imgDiscover, imgAmex } from 'assets/logos';
import { editIcon } from 'assets/icons';
import MobileContext from 'context/MobileContext';
import ConfirmPopup from 'components/shared/ConfirmPopup';
import Button from 'components/shared/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Fade, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles.module.css';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    background: '#FF5F1F',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  root: {
    backgroundColor: 'red',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade direction="up" ref={ref} {...props} />;
});

const Card = ({
  loadBillingUrl,
  saveCardInfo,
  setAddNewCard,
  url,
  type,
  lastFourDigits,
  expiration,
  id,
  isExpired,
  deleteMode,
  setDeletePopup,
  edit,
  notPrimaryCard,
}) => {
  const [Gatewayopen, setGatewayOpen] = React.useState(false);

  const handleClose = () => {
    setGatewayOpen(false);
    setAddNewCard(false);
    edit(false);
  };

  const handleSaveClose = () => {
    setGatewayOpen(false);
  };

  const handleOpen = () => {
    setGatewayOpen(true);
  };
  const classes = useStyles();

  function windowassignListeners() {
    window.addEventListener(
      'message',
      function listener(event) {
        if (event.origin !== process.env.REACT_APP_CLUB_AUTOMATION_URL) {
          return;
        }
        const {
          data: { cc_merchant_unique_id },
        } = event;
        if (!isEmpty(cc_merchant_unique_id)) {
          window.messageReceived = true;
          handleSaveClose();
        }
      },
      false
    );
  }

  return (
    <div>
      <>
        <Dialog
          fullScreen
          open={Gatewayopen}
          onClose={handleClose}
          width="100%"
          TransitionComponent={Transition}
        >
          <AppBar position="fixed">
            <Toolbar variant="dense">
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" component="div">
                Update Card
              </Typography>
            </Toolbar>
          </AppBar>
          {url ? (
            <iframe
              title="Update Card"
              id="Editpayment"
              src={`${url}&parentUrl=${window.location.href}`}
              width="700px"
              style={{
                display: 'block',
                alignSelf: 'center',
                overflow: 'hidden',
                position: 'relative',
                marginLeft: '-20px',
                marginTop: '40px',
              }}
              scrolling="no"
              height={document.documentElement.clientHeight - 50}
            />
          ) : (
            <Box sx={{ width: '100%' }}>
              <LinearProgress color="secondary" />
            </Box>
          )}
        </Dialog>
      </>

      <div className={styles.wrapper} key={id}>
        {notPrimaryCard && deleteMode ? (
          <div onClick={() => setDeletePopup(id)} className={styles.deleteIcon}>
            -
          </div>
        ) : (
          <></>
        )}
        <div className={`${styles.card} ${deleteMode && styles.deleteModeCard}`}>
          <div className={styles.cardInfo}>
            <Icon
              src={
                type === 'visa'
                  ? imgVisa
                  : type === 'amex'
                    ? imgAmex
                    : type === 'mastercard'
                      ? imgMastercard
                      : imgDiscover
              }
              height="24px"
              width="40px"
              className={styles.iconLogo}
            />
            <p className={!isExpired ? styles.cardNumber : styles.cardNumberExpired}>
              <span className={styles.number}>**** **** **** {lastFourDigits}</span>
            </p>
          </div>

          <div className={styles.cardInfo}>
            <img
              src={editIcon}
              className={styles.edit}
              alt=""
              onClick={() => {
                edit(id);
                loadBillingUrl();
                assignListeners(saveCardInfo);
                setAddNewCard(true);
                windowassignListeners(saveCardInfo);
                handleOpen();
              }}
            />
            <p className={!isExpired ? styles.cardNumber : styles.cardNumberExpired}>
              <span>Exp {expiration}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export function assignListeners(saveCardInfo) {
  window.addEventListener(
    'message',
    function listener(event) {
      if (event.origin !== process.env.REACT_APP_CLUB_AUTOMATION_URL) {
        return;
      }
      const {
        data: {
          cc_number,
          expiration_year,
          expiration_month,
          cardConnect_expiry,
          cc_merchant_unique_id,
          expiration_date
        },
      } = event;
      if (!isEmpty(cc_merchant_unique_id)) {
        window.messageReceived = true;
        saveCardInfo({
          cc_number,
          expiration_year,
          expiration_month,
          cardConnect_expiry,
          cc_merchant_unique_id,
          expiration_date
        });
      }
    },
    false
  );
}

function CreditCards({
  loadBillingUrl,
  url,
  notPrimaryCard,
  makePrimary,
  profile,
  deleteCard,
  deleteMode,
  primaryCard,
  setEditMode,
  saveCardInfo,
  setAddNewCard,
}) {
  const isMobile = useContext(MobileContext);
  const [openDeletePopup, setDeletePopup] = useState(false);

  return (
    <>
      <div className={styles.cardsContainer}>
        {(isMobile || deleteMode) && (
          <div>
            <p className={styles.subTitle}>My primary card:</p>
            <Card
              key={primaryCard?.id}
              deleteMode={deleteMode}
              type={primaryCard?.type}
              lastFourDigits={primaryCard?.lastFourDigits}
              expiration={primaryCard?.expiration}
              id={primaryCard?.id}
              isExpired={primaryCard?.isExpired}
              setDeletePopup={setDeletePopup}
              edit={setEditMode}
              url={url}
              loadBillingUrl={loadBillingUrl}
              saveCardInfo={saveCardInfo}
              setAddNewCard={setAddNewCard}
            />
          </div>
        )}
        {(isMobile || (deleteMode && notPrimaryCard.length > 0)) && (
          <p className={styles.subTitle}>My other cards: </p>
        )}
        {notPrimaryCard.map(({ type, lastFourDigits, expiration, id, isExpired }) => (
          <Card
            url={url}
            key={id}
            deleteMode={deleteMode}
            type={type}
            lastFourDigits={lastFourDigits}
            expiration={expiration}
            id={id}
            isExpired={isExpired}
            setDeletePopup={setDeletePopup}
            edit={setEditMode}
            loadBillingUrl={loadBillingUrl}
            saveCardInfo={saveCardInfo}
            setAddNewCard={setAddNewCard}
            notPrimaryCard={notPrimaryCard}
          />
        ))}
        <ConfirmPopup open={!!openDeletePopup} onClose={() => setDeletePopup(false)}>
          <div className={styles.deletePopup}>
            <h1>Are you sure you want to remove the card?</h1>
            <div className={styles.buttons}>
              <Button
                onClick={() => {
                  deleteCard(openDeletePopup);
                  setDeletePopup(false);
                }}
                className={styles.button}
                width="100px"
              >
                Remove
              </Button>
              <Button
                styleType="tertiary"
                onClick={() => setDeletePopup(false)}
                width="100px"
              >
                Cancel
              </Button>
            </div>
          </div>
        </ConfirmPopup>
      </div>
    </>
  );
}

CreditCards.propTypes = {
  notPrimaryCard: PropTypes.array.isRequired,
  makePrimary: PropTypes.func.isRequired,
  deleteCard: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  deleteMode: PropTypes.bool.isRequired,
  primaryCard: PropTypes.object.isRequired,
  loadBillingUrl: PropTypes.func.isRequired,
  saveCardInfo: PropTypes.func.isRequired,
};

export default CreditCards;
