import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import VideoPopup from 'components/LocationsPage/VideoPopup';
import { checkMembership } from 'services/checkDigitalPlan';
import { memberStatuses } from 'enums/memberStatuses';
import DiscoverDigital from 'components/VideosOnDemand/DiscoverDigital';
import Content from './Content';

const VideoPage = ({ membership, videos, isRequestingMembership, memberStatus }) => {
  const [showContent, setShowContent] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null);

  useEffect(() => {
    if (membership && !isRequestingMembership) {
      const isExistMemberShipPlan =
        checkMembership(membership) || memberStatus === memberStatuses.staff;

      setShowContent(isExistMemberShipPlan);
      setOpenPopup(!isExistMemberShipPlan);
    }
  }, [membership, isRequestingMembership, memberStatus]);

  return (
    <>
      <Content
        title={document?.location?.pathname
          ?.replace('/on_demand/', '')
          .replace(/%20/g, ' ')}
        videoList={videos}
        setActiveVideo={setActiveVideo}
        showContent={showContent}
      />
      {activeVideo && (
        <VideoPopup
          link={activeVideo.url}
          open={activeVideo}
          handleClose={() => setActiveVideo(null)}
          content="video"
        />
      )}

      {!showContent && openPopup && (
        <DiscoverDigital open={!showContent} handleClose={() => setOpenPopup(false)} />
      )}
    </>
  );
};

VideoPage.propTypes = {
  membership: PropTypes.array.isRequired,
  memberStatus: PropTypes.string.isRequired,
  videos: PropTypes.array.isRequired,
  isRequestingMembership: PropTypes.bool.isRequired,
};

export default VideoPage;
