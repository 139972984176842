import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/shared/MaterialUITable';
import Select from 'components/shared/Select';
import Spinner from 'components/shared/Spinner';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import styles from './style.module.css';
import { handleClubs, getColumns } from './service';
import ConfirmActionPopup from './ConfirmActionPopup';
import EditReservation from './EditReservation';
import AddReservation from './AddReservation';

const Reservations = ({
  clubs,
  loadReservations,
  removeReservations,
  updateReservation,
  addReservation,
  searchReservations,
  searchUserReservations,
  reservations,
  isRequesting,
}) => {
  const [clubsOptions, setClubsOptions] = useState([]);
  const [club, setClub] = useState({});
  const [reservationIndex, setReservationIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openAddPopup, setOpenAddPopup] = useState(false);

  useEffect(() => {
    const options = handleClubs(clubs);
    setClubsOptions(options);
    setClub(options[0]);
  }, [clubs]);

  useEffect(() => {
    if (club?.value) loadReservations(club.value);
  }, [club]);

  useEffect(() => {
    searchUserReservations(searchValue);
  }, [searchValue, searchUserReservations]);

  useEffect(() => {
    if (isRequesting) setReservationIndex(0);
  }, [isRequesting]);

  const onClickMore = () => {
    setReservationIndex(reservationIndex + 1);
  };

  const onClickBack = () => {
    setReservationIndex(reservationIndex - 1);
  };

  return (
    <>
      <div className={styles.clubSelect}>
        <Select
          hideIndicatorSeparator
          options={clubsOptions}
          value={club?.value}
          onChange={e => setClub(e)}
        />
      </div>
      {club?.value && (
        <div className={styles.clubInfo}>
          <p>
            SF id: {club?.sfId}, CA id: {club.clubAutomationClubId}{' '}
          </p>
          <p>Working hours: {club?.openingHours} </p>
        </div>
      )}
      {isRequesting ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.menu}>
            <div>
              <Button
                disabled={!selectedItems.length}
                onClick={() => setConfirmPopup(true)}
              >
                Remove
              </Button>
              <Button
                disabled={!selectedItems.length || selectedItems.length > 1}
                className={styles.button}
                onClick={() => setOpenEditPopup(true)}
              >
                Edit
              </Button>
              <Button className={styles.button} onClick={() => setOpenAddPopup(true)}>
                Add
              </Button>
            </div>

            <Input
              type="search"
              width="300px"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder="Search reservations..."
            />
          </div>

          {reservations.length > 0 &&
            reservationIndex > 0 &&
            !searchReservations?.length && (
              <div className={styles.arrowContainer} onClick={() => onClickBack()}>
                <span className={styles.arrowBack} />
                <p>Back Reservations</p>
              </div>
            )}

          {reservations[reservationIndex]?.length > 0 && (
            <div className={styles.table}>
              <Table
                data={
                  searchReservations.length
                    ? searchReservations
                    : reservations[reservationIndex]
                }
                columns={getColumns(selectedItems, setSelectedItems)}
              />
            </div>
          )}

          {reservations.length > 0 &&
            reservationIndex < reservations.length - 1 &&
            !searchReservations?.length && (
              <div className={styles.arrowContainer} onClick={() => onClickMore()}>
                <span className={styles.arrowNext} />
                <p>More Reservations</p>
              </div>
            )}
        </>
      )}

      <ConfirmActionPopup
        open={confirmPopup}
        onClose={() => setConfirmPopup(false)}
        onRemoveItems={() => {
          removeReservations(selectedItems.join());
          setSelectedItems([]);
        }}
        isRequesting={isRequesting}
      />

      <EditReservation
        clubsOptions={clubsOptions}
        reservation={
          searchReservations?.find(({ id }) => selectedItems[0] === id) ||
          reservations[reservationIndex]?.find(({ id }) => selectedItems[0] === id)
        }
        updateReservation={e => {
          updateReservation(e);
          setSelectedItems([]);
        }}
        open={openEditPopup}
        onClose={() => setOpenEditPopup(false)}
        isRequesting={isRequesting}
      />

      <AddReservation
        clubsOptions={clubsOptions}
        addReservation={e => {
          addReservation(e);
          setSelectedItems([]);
        }}
        open={openAddPopup}
        onClose={() => setOpenAddPopup(false)}
        isRequesting={isRequesting}
      />
    </>
  );
};

Reservations.propTypes = {
  clubs: PropTypes.array.isRequired,
  searchReservations: PropTypes.array.isRequired,
  loadReservations: PropTypes.func.isRequired,
  removeReservations: PropTypes.func.isRequired,
  updateReservation: PropTypes.func.isRequired,
  addReservation: PropTypes.func.isRequired,
  reservations: PropTypes.array.isRequired,
  searchUserReservations: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

export default Reservations;
