import React from 'react';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import { Classes } from 'components';

export const ClassesPage = props => {
  return (
    <HeaderFooterLayout>
      <Classes {...props} />
    </HeaderFooterLayout>
  );
};
