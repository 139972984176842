/* eslint-disable no-param-reassign */
import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  topics: null,
  error: null,
  isFreezeSelected: false,
};

const preselectFreezeTopic = (state = INITIAL_STATE, { option }) => {
  return {
    ...state,
    isFreezeSelected: option,
  };
};
const resetPreselectedFreezeTopic = (state = INITIAL_STATE) => {
  return {
    ...state,
    isFreezeSelected: false,
  };
};
const loadTopicsRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const loadTopicsSuccess = (state = INITIAL_STATE, { topics }) => {
  return {
    ...state,
    isRequesting: false,
    topics,
  };
};
const loadTopicsFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    topics: null,
    error,
  };
};

export const HANDLERS = {
  [Types.LOAD_TOPICS_REQUEST]: loadTopicsRequest,
  [Types.LOAD_TOPICS_SUCCESS]: loadTopicsSuccess,
  [Types.LOAD_TOPICS_FAILURE]: loadTopicsFailure,
  [Types.PRESELECT_FREEZE_TOPIC]: preselectFreezeTopic,
  [Types.RESET_PRESELECTED_FREEZE_TOPIC]: resetPreselectedFreezeTopic,
};

export default createReducer(INITIAL_STATE, HANDLERS);
