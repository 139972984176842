import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  isMarkTrainerSuccess: false,
  trainers: null,
  favoriteLocation: '',
  filteredTrainers: null,
  favoriteTrainers: null,
  filteredFavoriteTrainers: null,
  personalTrainers: [],
  error: null,
  selectedClubId: '',
  searchValue: '',
  isSentEmail: false,
};

const loadTrainersRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const loadTrainersSuccess = (
  state = INITIAL_STATE,
  { trainers, favoriteTrainers, favoriteLocation }
) => {
  return {
    ...state,
    isRequesting: false,
    trainers,
    favoriteLocation,
    selectedClubId: 'all',
    filteredTrainers: trainers,
    favoriteTrainers,
    filteredFavoriteTrainers: favoriteTrainers,
  };
};
const loadTrainersFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    trainers: null,
    favoriteLocation: null,
    filteredTrainers: null,
    favoriteTrainers: null,
    filteredFavoriteTrainers: null,
    error,
  };
};

const loadPersonalTrainersRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const loadPersonalTrainersSuccess = (state = INITIAL_STATE, { personalTrainers }) => {
  return {
    ...state,
    isRequesting: false,
    personalTrainers,
  };
};
const loadPersonalTrainersFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    error,
  };
};

const sendCancelSessionEmailRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const sendCancelSessionEmailSuccess = (state = INITIAL_STATE, { isSentEmail }) => {
  return {
    ...state,
    isRequesting: false,
    isSentEmail,
  };
};
const sendCancelSessionEmailFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    error,
  };
};

const cleanCancelSessionEmail = (state = INITIAL_STATE) => {
  return {
    ...state,
    isSentEmail: false,
  };
};

const selectTrainingFavoriteClub = (state = INITIAL_STATE, { selectedClubId }) => {
  return {
    ...state,
    selectedClubId,
  };
};

const changeTrainingSearch = (state = INITIAL_STATE, { searchValue }) => {
  return {
    ...state,
    searchValue,
  };
};

const filterTrainers = (
  state = INITIAL_STATE,
  { filteredTrainers, filteredFavoriteTrainers }
) => {
  return {
    ...state,
    filteredTrainers,
    filteredFavoriteTrainers,
  };
};
const markTrainerAsFavoriteSuccess = (
  state = INITIAL_STATE,
  { trainers, favoriteTrainers }
) => {
  return {
    ...state,
    trainers,
    filteredTrainers: trainers,
    favoriteTrainers,
    filteredFavoriteTrainers: favoriteTrainers,
    isMarkTrainerSuccess: true,
  };
};

const markTrainerAsFavoriteError = (
  state = INITIAL_STATE,
  { trainers, favoriteTrainers }
) => {
  return {
    ...state,
    trainers,
    filteredTrainers: trainers,
    favoriteTrainers,
    filteredFavoriteTrainers: favoriteTrainers,
    isMarkTrainerSuccess: false,
  };
};

const markTrainerAsFavorite = (state = INITIAL_STATE) => {
  return {
    ...state,
    isMarkTrainerSuccess: false,
  };
};
const unmarkTrainerAsFavorite = (state = INITIAL_STATE) => {
  return {
    ...state,
    isMarkTrainerSuccess: false,
  };
};

const unmarkTrainerAsFavoriteSuccess = (
  state = INITIAL_STATE,
  { trainers, favoriteTrainers }
) => {
  return {
    ...state,
    trainers,
    filteredTrainers: trainers,
    favoriteTrainers,
    filteredFavoriteTrainers: favoriteTrainers,
    isMarkTrainerSuccess: true,
  };
};

const unmarkTrainerAsFavoriteError = (
  state = INITIAL_STATE,
  { error, trainers, favoriteTrainers }
) => {
  return {
    ...state,
    error,
    trainers,
    filteredTrainers: trainers,
    favoriteTrainers,
    filteredFavoriteTrainers: favoriteTrainers,
    isMarkTrainerSuccess: false,
  };
};

export const HANDLERS = {
  [Types.LOAD_TRAINERS_REQUEST]: loadTrainersRequest,
  [Types.LOAD_TRAINERS_SUCCESS]: loadTrainersSuccess,
  [Types.LOAD_TRAINERS_FAILURE]: loadTrainersFailure,

  [Types.LOAD_PERSONAL_TRAINERS_REQUEST]: loadPersonalTrainersRequest,
  [Types.LOAD_PERSONAL_TRAINERS_SUCCESS]: loadPersonalTrainersSuccess,
  [Types.LOAD_PERSONAL_TRAINERS_FAILURE]: loadPersonalTrainersFailure,

  [Types.SEND_CANCEL_SESSION_EMAIL_REQUEST]: sendCancelSessionEmailRequest,
  [Types.SEND_CANCEL_SESSION_EMAIL_SUCCESS]: sendCancelSessionEmailSuccess,
  [Types.SEND_CANCEL_SESSION_EMAIL_FAILURE]: sendCancelSessionEmailFailure,
  [Types.CLEAN_CANCEL_SESSION_EMAIL]: cleanCancelSessionEmail,

  [Types.SELECT_TRAINING_FAVORITE_CLUB]: selectTrainingFavoriteClub,
  [Types.CHANGE_TRAINING_SEARCH]: changeTrainingSearch,
  [Types.FILTER_TRAINERS]: filterTrainers,

  [Types.MARK_TRAINER_AS_FAVORITE]: markTrainerAsFavorite,
  [Types.MARK_TRAINER_AS_FAVORITE_SUCCESS]: markTrainerAsFavoriteSuccess,
  [Types.MARK_TRAINER_AS_FAVORITE_ERROR]: markTrainerAsFavoriteError,

  [Types.UNMARK_TRAINER_AS_FAVORITE]: unmarkTrainerAsFavorite,
  [Types.UNMARK_TRAINER_AS_FAVORITE_SUCCESS]: unmarkTrainerAsFavoriteSuccess,
  [Types.UNMARK_TRAINER_AS_FAVORITE_ERROR]: unmarkTrainerAsFavoriteError,
};

export default createReducer(INITIAL_STATE, HANDLERS);
