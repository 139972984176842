import { connect } from 'react-redux';
import EatLovePage from 'pages/EatLovePage';
import Actions from 'store/sso/actions';

const mapStateToProps = state => {
  return {
    token: state.sso.token,
    isRequesting: state.sso.isRequesting,
  };
};
const mapDispatchToProps = {
  loadSsoToken: Actions.Creators.loadSsoTokenRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(EatLovePage);
