import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getCancelMembershipInfoRequest: [],
  getCancelMembershipInfoSuccess: ['cancelInfo'],
  getCancelMembershipInfoFailure: ['error'],

  addUserGroupRequest: [],
  addUserGroupSuccess: ['isAddUserGroup'],
  addUserGroupFailure: ['error'],

  removeMembershipGroupRequest: ['reason'],
  removeMembershipGroupSuccess: ['success'],
  removeMembershipGroupFailure: ['error'],
});

const membershipActions = {
  Types,
  Creators,
};

export default membershipActions;
