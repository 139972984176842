import React from 'react';
import TrainerCard from 'components/TrainingPage/TrainerCard';
import TrainersSection from 'components/TrainingPage/TrainersSection';
import NoTrainers from 'components/TrainingPage/NoTrainers';
import PropTypes from 'prop-types';
import { findClubName, findTrainersByClubId } from '../../helpers';
import styles from '../styles.module.css';

const RenderSelectedClubs = ({
  trainers,
  selectedClubId,
  locations,
  setIsOpened,
  selectTrainer,
  setTrainerId,
}) => {
  const selectedLocationTrainers = findTrainersByClubId(selectedClubId, trainers);
  const selectedClubName = findClubName(selectedClubId, locations);
  return (
    <TrainersSection>
      {selectedLocationTrainers?.length ? (
        <div className={styles.inner}>
          {selectedLocationTrainers?.length
            ? selectedLocationTrainers.map(trainer => {
                return (
                  <div className={styles.cardWrapper}>
                    <TrainerCard
                      {...trainer}
                      setIsOpened={setIsOpened}
                      selectTrainer={selectTrainer}
                      setTrainerId={setTrainerId}
                    />
                  </div>
                );
              })
            : null}
        </div>
      ) : (
        <NoTrainers />
      )}
    </TrainersSection>
  );
};

RenderSelectedClubs.propTypes = {
  trainers: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedClubId: PropTypes.string.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  selectTrainer: PropTypes.bool.isRequired,
  setTrainerId: PropTypes.func.isRequired,
};

export default RenderSelectedClubs;
