import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import Spinner from 'components/shared/Spinner';
import { changePasswordValidation } from 'validation/auth.validation';
import Field from 'components/shared/Field';
import Button from 'components/shared/Button';
import MobileContext from 'context/MobileContext';
import styles from './styles.module.css';

const initialValues = {
  oldPassword: '',
  password: '',
  confirmPassword: '',
};

const UpdatePassword = ({ isRequesting, updatePassword }) => {
  const isMobile = useContext(MobileContext);
  useEffect(() => {}, []);

  return (
    <HeaderFooterLayout profile="Update Password">
      <div className={styles.containerMain}>
        {isRequesting ? (
          <Spinner isWhite />
        ) : (
          <div className={styles.wrapper}>
            {!isMobile && <h1 className={styles.title}>Update Password</h1>}
            <Formik
              initialValues={initialValues}
              validationSchema={changePasswordValidation}
              onSubmit={values => updatePassword(values)}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
                touched,
                handleBlur,
                isValid,
                dirty,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    value={values.oldPassword}
                    onChange={handleChange}
                    name="oldPassword"
                    type="password"
                    width="250px"
                    placeholder="Old Password"
                    className={styles.inputName}
                    invalid={touched.oldPassword && Boolean(errors.oldPassword)}
                    error={errors.oldPassword}
                    onBlur={handleBlur}
                  />
                  <p className={styles.text}>
                    Your password must be a minimum of 6 characters long. We suggest it
                    contains at least one capital letter, one number and a special
                    character (?!@#$%^--&amp;*()_+`~).
                  </p>
                  <div className={styles.column}>
                    <Field
                      value={values.password}
                      onChange={handleChange}
                      name="password"
                      type="password"
                      width="250px"
                      placeholder="New Password"
                      className={styles.inputName}
                      invalid={touched.password && Boolean(errors.password)}
                      error={errors.password}
                      onBlur={handleBlur}
                      withStrength
                    />
                    <Field
                      value={values.confirmPassword}
                      onChange={handleChange}
                      name="confirmPassword"
                      type="password"
                      width="250px"
                      placeholder="Confirm Password"
                      className={styles.inputName}
                      invalid={touched.confirmPassword && Boolean(errors.confirmPassword)}
                      error={errors.confirmPassword}
                      onBlur={handleBlur}
                      withStrength
                    />
                  </div>

                  <Button
                    type="submit"
                    disabled={
                      !(
                        isValid &&
                        dirty &&
                        values.oldPassword &&
                        values.password &&
                        values.confirmPassword
                      )
                    }
                    width="255px"
                  >
                    Update password
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </HeaderFooterLayout>
  );
};
UpdatePassword.propTypes = {
  isRequesting: PropTypes.bool.isRequired,
  updatePassword: PropTypes.func.isRequired,
};
export default UpdatePassword;
