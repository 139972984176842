/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import Carousel from 'components/shared/Carousel';
import { PopUp } from 'components/shared/PopUp';
import VideoCard from 'components/VideosOnDemand/VideoCard';
import VideoPopup from 'components/LocationsPage/VideoPopup';
import VideoRightPopup from 'components/VideosOnDemand/VideoRightPopup';
import history from 'store/history';
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const VideoSection = () => {
  const [activeVideo, setActiveVideo] = useState(null);
  const [isShowVideo, setIsShowVideo] = useState(false);
  const videoList = useSelector(state => state.premiumVideos.popularVideos);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: 'LOAD_POPULAR_VIDEOS_REQUEST',
    });
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>
          <span>Popular Videos</span>
          <div className={styles.line} />
          <p onClick={() => history.push('/on_demand')}>See all</p>
        </h1>
        <div className={styles.cards} id="home_page-video-carousel">
          <Carousel responsive={responsive}>
            {videoList.map(video => (
              <VideoCard
                key={video.id}
                video={video}
                onWatch={setActiveVideo}
                available
              />
            ))}
          </Carousel>
        </div>
      </div>
      {!isShowVideo && activeVideo && (
        <PopUp
          isOpened={activeVideo}
          setIsOpened={() => {
            setActiveVideo(null);
            setIsShowVideo(false);
          }}
        >
          <VideoRightPopup setShow={setIsShowVideo} video={activeVideo} />
        </PopUp>
      )}
      {isShowVideo && (
        <VideoPopup
          link={activeVideo?.url}
          open={activeVideo}
          handleClose={() => {
            setActiveVideo(null);
            setIsShowVideo(false);
          }}
          content="video"
        />
      )}
    </>
  );
};

export default VideoSection;
