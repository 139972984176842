import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const RadioButton = ({ value, onChange, disabled, isWhite, ...props }) => {
  return (
    <div
      className={`${value ? styles.checked : styles.notChecked} ${disabled &&
        styles.disabled} ${isWhite && styles.white}`}
      onClick={onChange}
      {...props}
    />
  );
};

RadioButton.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  isWhite: PropTypes.bool,
};

RadioButton.defaultProps = {
  onChange: () => {},
  value: false,
  disabled: false,
  isWhite: false,
};

export default RadioButton;
