import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import DiscoverNewPlan from 'components/ProfilePage/MembershipSubscription/DiscoverNewPlan';
import { videoOnDemand } from 'assets/background-images';
import styles from './style.module.css';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '20 !important',
    maxWidth: '600px',
    margin: 'auto',
    overflowY: 'auto',
  },
  paper: {
    '@media(max-width: 450px)': {
      marginTop: 160,
    },
  },
}));

const DiscoverDigital = ({ open, handleClose, subscribe, isRequesting }) => {
  const classes = useStyles();
  useEffect(() => {
    if (isRequesting) handleClose();
  }, [isRequesting, handleClose]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {}}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper elevation={0} className={classes.paper}>
            <div className={styles.imageContainer}>
              <img src={videoOnDemand} alt="videoOnDemand" className={styles.img} />
            </div>

            <div style={{ padding: 20 }}>
              <DiscoverNewPlan subscribe={subscribe} popup />
            </div>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};

DiscoverDigital.propTypes = {
  open: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  subscribe: PropTypes.func,
};

DiscoverDigital.defaultProps = {
  subscribe: () => {},
  isRequesting: false,
};

export default DiscoverDigital;
