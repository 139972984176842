import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import styles from './styles.module.css';

const SessionModal = ({ children, isOpened, wrapperStyles }) => {
  const isMobile = useContext(MobileContext);

  useEffect(() => {
    if (isOpened) {
      document.body.style.overflowY = 'hidden';
      document.body.style.height = '100%';
    } else {
      document.body.style.position = 'static';
      document.body.style.overflowY = 'auto';
    }
  }, [isOpened]);

  return (
    <div className={styles.container}>
      {isOpened ? (
        <>
          <div className={styles.shadow} />
          <div
            className={`${styles.modalContainer} ${
              isOpened ? styles.openedModalContainer : null
            } ${isMobile ? styles.containerMobile : null}`}
            style={wrapperStyles}
          >
            {children}
          </div>
        </>
      ) : null}
    </div>
  );
};

SessionModal.propTypes = {
  children: PropTypes.element.isRequired,
  isOpened: PropTypes.bool.isRequired,
  wrapperStyles: PropTypes.object,
};
SessionModal.defaultProps = {
  wrapperStyles: {},
};

export default SessionModal;
