import React from 'react';
import PropTypes from 'prop-types';
import { sendIcon } from 'assets/icons';
import styles from './styles.module.css';

const Footer = ({ sendMessage, messageText, typeMessage, targetDialog }) => {
  const handleSendMessage = () => {
    if (messageText !== '') sendMessage();
  };
  const handleEnterPress = e => {
    if (e.key === 'Enter') handleSendMessage();
  };

  return (
    <div className={styles.wrapper}>
      {targetDialog ? (
        <>
          <input
            type="text"
            name="input"
            className={styles.input}
            placeholder="Type Message..."
            value={messageText}
            onChange={e => {
              typeMessage(e.target.value);
            }}
            onKeyDown={handleEnterPress}
          />
          <div onClick={handleSendMessage}>
            <img alt="chevron" src={sendIcon} className={styles.icon} />
          </div>
        </>
      ) : null}
    </div>
  );
};

Footer.propTypes = {
  sendMessage: PropTypes.func.isRequired,
  messageText: PropTypes.string.isRequired,
  typeMessage: PropTypes.func.isRequired,
  targetDialog: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

export default Footer;
