import React from 'react';
import PropTypes from 'prop-types';
import LocationIcon from 'assets/icons/location.svg';
import styles from './style.module.css';

const ReservationCard = ({
  ids,
  date,
  duration,
  clubName,
  room,
  cancelButtonAction,
  cardStyles,
  isCancelAvailable,
  fullStartDate,
  note,
}) => {
  return (
    <div className={styles.reservationWrapper} style={cardStyles}>
      {isCancelAvailable ? (
        <button
          type="button"
          onClick={event => {
            event.stopPropagation();
            cancelButtonAction(ids, fullStartDate);
          }}
          className={styles.cancelButton}
        >
          Cancel
        </button>
      ) : null}

      {note && <p className={styles.reservationNote}>{note}</p>}

      <p className={styles.title}>{date}</p>
      <p className={styles.resDate}>{duration}</p>
      <div className={styles.locationSection}>
        <img src={LocationIcon} alt="location" className={styles.locationIcon} />
        <p className={styles.location}>
          {clubName} {room && <span>&bull; {room}</span>}
        </p>
      </div>
    </div>
  );
};

ReservationCard.propTypes = {
  ids: PropTypes.array,
  date: PropTypes.string,
  duration: PropTypes.string,
  cardStyles: PropTypes.object,
  clubName: PropTypes.string,
  room: PropTypes.string,
  cancelButtonAction: PropTypes.func,
  isCancelAvailable: PropTypes.bool.isRequired,
  fullStartDate: PropTypes.string,
  note: PropTypes.string.isRequired,
};
ReservationCard.defaultProps = {
  clubName: '',
  room: '',
  ids: [],
  date: '',
  duration: '',
  cardStyles: {},
  cancelButtonAction: () => {},
  fullStartDate: '',
};

export default ReservationCard;
