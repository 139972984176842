import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const Toggle = ({ children, invalid, onChange, isActive, ...props }) => {
  return (
    <div className={styles.wrapper}>
      <label className={styles.switch}>
        <input
          type="checkbox"
          name="checkbox"
          onChange={onChange}
          checked={isActive}
          {...props}
        />
        <span className={styles.slider} />
      </label>
      {children}
    </div>
  );
};

Toggle.propTypes = {
  children: PropTypes.node,
  invalid: PropTypes.bool,
  isActive: PropTypes.bool,
  onChange: PropTypes.func,
};

Toggle.defaultProps = {
  children: '',
  invalid: false,
  isActive: false,
  onChange: () => {},
};

export default Toggle;
