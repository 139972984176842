import { createReducer } from 'reduxsauce';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import compact from 'lodash/compact';
import actions from './actions';
import trainingActions from '../training/actions';

const { Types } = actions;
const trainingActionType = trainingActions.Types;

const INITIAL_STATE = {
  isRequesting: false,
  favoriteClassesIsRequesting: false,
  favoriteLocationClassesIsRequesting: false,
  favoriteClasses: [],
  favoriteLocationClasses: [],
  error: null,
  sessions: null,
  targetSession: null,
  isSessionQRAvailable: false,
};

const loadHomeRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    favoriteClassesIsRequesting: true,
    favoriteLocationClassesIsRequesting: true,
    error: null,
  };
};

const loadHomeFavoriteClassesRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    favoriteClassesIsRequesting: true,
    error: null,
  };
};
const loadHomeFavoriteClassesSuccess = (state = INITIAL_STATE, { favoriteClasses }) => {
  return {
    ...state,
    favoriteClassesIsRequesting: false,
    favoriteClasses,
  };
};
const loadHomeFavoriteClassesFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    favoriteClassesIsRequesting: false,
    error,
  };
};

const loadHomeLocationClassesRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    favoriteLocationClassesIsRequesting: true,
    error: null,
  };
};
const loadHomeLocationClassesSuccess = (
  state = INITIAL_STATE,
  { favoriteLocationClasses }
) => {
  return {
    ...state,
    favoriteLocationClassesIsRequesting: false,
    favoriteLocationClasses,
  };
};
const loadHomeLocationClassesFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    favoriteLocationClassesIsRequesting: false,
    error,
  };
};
const request = (state = INITIAL_STATE) => {
  return {
    ...state,
    error: null,
  };
};

const addClassToFavoriteSuccess = (state = INITIAL_STATE, { classId }) => {
  return {
    ...state,
    favoriteClasses: state.favoriteClasses.map(groupEx => {
      return groupEx.classId === Number(classId)
        ? { ...groupEx, isFavorite: true }
        : { ...groupEx };
    }),
    favoriteLocationClasses: state.favoriteLocationClasses.map(groupEx => {
      return groupEx.classId === Number(classId)
        ? { ...groupEx, isFavorite: true }
        : { ...groupEx };
    }),
  };
};

const addClassToFavoriteFailure = (state = INITIAL_STATE, { classId, error }) => {
  return {
    ...state,
    error,
    favoriteClasses: state.favoriteClasses.map(groupEx => {
      return groupEx.classId === Number(classId)
        ? { ...groupEx, isFavorite: false }
        : { ...groupEx };
    }),
    favoriteLocationClasses: state.favoriteLocationClasses.map(groupEx => {
      return groupEx.classId === Number(classId)
        ? { ...groupEx, isFavorite: false }
        : { ...groupEx };
    }),
  };
};

const loadSessionsRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const loadSessionsSuccess = (
  state = INITIAL_STATE,
  { sessions, isSessionQRAvailable }
) => {
  const sessionsList =
    sessions?.length > 0
      ? compact(
          sessions.map(s => {
            const { date, endTime } = s;
            const sessionEndDate = moment(`${date}T${endTime}`).format(
              'YYYY-MM-DD HH:mm:ss'
            );
            const SFTime = momentTZ()
              .tz('America/Los_Angeles')
              .format('YYYY-MM-DD HH:mm:ss');
            const diff = moment(sessionEndDate).diff(SFTime, 'minutes');

            if (diff >= 0) {
              return s;
            }
            return null;
          })
        )
      : [];
  return {
    ...state,
    isRequesting: false,
    sessions: sessionsList,
    isSessionQRAvailable,
  };
};
const loadSessionsFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    error,
  };
};

const setTargetSession = (state = INITIAL_STATE, { targetSession }) => {
  return {
    ...state,
    targetSession,
  };
};

const removeClassFromFavoriteSuccess = (state = INITIAL_STATE, { classId }) => {
  return {
    ...state,
    favoriteClasses: state.favoriteClasses.map(groupEx => {
      return groupEx.classId === classId
        ? { ...groupEx, isFavorite: false }
        : { ...groupEx };
    }),
    favoriteLocationClasses: state.favoriteLocationClasses.map(groupEx => {
      return groupEx.classId === classId
        ? { ...groupEx, isFavorite: false }
        : { ...groupEx };
    }),
  };
};

const removeClassFromFavoriteFailure = (state = INITIAL_STATE, { classId, error }) => {
  return {
    ...state,
    error,
    favoriteClasses: state.favoriteClasses.map(groupEx => {
      return groupEx.classId === classId
        ? { ...groupEx, isFavorite: true }
        : { ...groupEx };
    }),
    favoriteLocationClasses: state.favoriteLocationClasses.map(groupEx => {
      return groupEx.classId === classId
        ? { ...groupEx, isFavorite: true }
        : { ...groupEx };
    }),
  };
};

const sendCancelSessionEmailRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const sendCancelSessionEmailSuccess = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: false,
  };
};
const sendCancelSessionEmailFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    error,
  };
};

export const HANDLERS = {
  [Types.LOAD_HOME_REQUEST]: loadHomeRequest,

  [Types.LOAD_HOME_FAVORITE_CLASSES_REQUEST]: loadHomeFavoriteClassesRequest,
  [Types.LOAD_HOME_FAVORITE_CLASSES_SUCCESS]: loadHomeFavoriteClassesSuccess,
  [Types.LOAD_HOME_FAVORITE_CLASSES_FAILURE]: loadHomeFavoriteClassesFailure,

  [Types.LOAD_HOME_LOCATION_CLASSES_REQUEST]: loadHomeLocationClassesRequest,
  [Types.LOAD_HOME_LOCATION_CLASSES_SUCCESS]: loadHomeLocationClassesSuccess,
  [Types.LOAD_HOME_LOCATION_CLASSES_FAILURE]: loadHomeLocationClassesFailure,

  [Types.LOAD_SESSIONS_REQUEST]: loadSessionsRequest,
  [Types.LOAD_SESSIONS_SUCCESS]: loadSessionsSuccess,
  [Types.LOAD_SESSIONS_FAILURE]: loadSessionsFailure,

  [Types.ADD_CLASS_TO_FAVORITE_HOMEPAGE_REQUEST]: request,
  [Types.ADD_CLASS_TO_FAVORITE_HOMEPAGE_SUCCESS]: addClassToFavoriteSuccess,
  [Types.ADD_CLASS_TO_FAVORITE_HOMEPAGE_FAILURE]: addClassToFavoriteFailure,

  [Types.REMOVE_CLASS_FROM_FAVORITE_HOMEPAGE_REQUEST]: request,
  [Types.REMOVE_CLASS_FROM_FAVORITE_HOMEPAGE_SUCCESS]: removeClassFromFavoriteSuccess,
  [Types.REMOVE_CLASS_FROM_FAVORITE_HOMEPAGE_FAILURE]: removeClassFromFavoriteFailure,

  [trainingActionType.SEND_CANCEL_SESSION_EMAIL_REQUEST]: sendCancelSessionEmailRequest,
  [trainingActionType.SEND_CANCEL_SESSION_EMAIL_SUCCESS]: sendCancelSessionEmailSuccess,
  [trainingActionType.SEND_CANCEL_SESSION_EMAIL_FAILURE]: sendCancelSessionEmailFailure,

  [Types.SET_TARGET_SESSION]: setTargetSession,
};

export default createReducer(INITIAL_STATE, HANDLERS);
