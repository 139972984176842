import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  isSaveRequesting: false,
  isDeleteRequesting: false,
  isModifyRequesting: false,
  reservations: [],
  upcomingReservations: [],
  reservationUpcomingDaySlotList: [],
  reservedItems: [],
  isReservationAvailable: true,
  isQRAvailable: false,
  isBanned: false,
  dateOfFinishBan: null,
  missedCount: null,
  error: null,
};

const initLoadReservationsRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const loadReservationsRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    reservedItems: [],
    error: null,
  };
};
const loadReservationsSuccess = (state = INITIAL_STATE, { reservations }) => {
  return {
    ...state,
    isRequesting: false,
    reservations,
  };
};
const loadReservationsFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    error,
  };
};
const saveReservationsRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isSaveRequesting: true,
    error: null,
  };
};
const saveReservationsSuccess = (state = INITIAL_STATE, { reservations }) => {
  return {
    ...state,
    isSaveRequesting: false,
    reservations,
  };
};
const saveReservationsFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isSaveRequesting: false,
    error,
  };
};
const deleteReservationRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isDeleteRequesting: true,
    error: null,
  };
};
const deleteReservationSuccess = (state = INITIAL_STATE, { reservations }) => {
  return {
    ...state,
    isDeleteRequesting: false,
    reservations,
  };
};
const deleteReservationFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isDeleteRequesting: false,
    error,
  };
};
const loadUpcomingReservationsRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const loadUpcomingReservationsSuccess = (
  state = INITIAL_STATE,
  { upcomingReservations, reservationUpcomingDaySlotList, isQRAvailable }
) => {
  return {
    ...state,
    isRequesting: false,
    upcomingReservations,
    reservationUpcomingDaySlotList,
    isQRAvailable,
  };
};
const loadUpcomingReservationsFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    error,
  };
};

const calculateReservedSlots = (
  state = INITIAL_STATE,
  { reservedItems, isReservationAvailable }
) => {
  return {
    ...state,
    reservedItems,
    isReservationAvailable,
  };
};
const modifyReservationsRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isModifyRequesting: true,
    error: null,
  };
};
const modifyReservationsSuccess = (state = INITIAL_STATE, { reservations }) => {
  if (reservations)
    return {
      ...state,
      isModifyRequesting: false,
      reservations,
    };
  return {
    ...state,
    isModifyRequesting: false,
  };
};
const modifyReservationsFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isModifyRequesting: false,
    error,
  };
};
const loadReservationsStatusRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    error: null,
  };
};
const loadReservationsStatusSuccess = (
  state = INITIAL_STATE,
  { isBanned, dateOfFinishBan, missedCount }
) => {
  return {
    ...state,
    isBanned,
    dateOfFinishBan,
    missedCount,
  };
};
const loadReservationsStatusFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isBanned: false,
    dateOfFinishBan: null,
    error,
  };
};

export const HANDLERS = {
  [Types.INIT_LOAD_RESERVATIONS_REQUEST]: initLoadReservationsRequest,
  [Types.LOAD_RESERVATIONS_REQUEST]: loadReservationsRequest,
  [Types.LOAD_RESERVATIONS_SUCCESS]: loadReservationsSuccess,
  [Types.LOAD_RESERVATIONS_FAILURE]: loadReservationsFailure,

  [Types.SAVE_RESERVATIONS_REQUEST]: saveReservationsRequest,
  [Types.SAVE_RESERVATIONS_SUCCESS]: saveReservationsSuccess,
  [Types.SAVE_RESERVATIONS_FAILURE]: saveReservationsFailure,

  [Types.DELETE_RESERVATION_REQUEST]: deleteReservationRequest,
  [Types.DELETE_RESERVATION_SUCCESS]: deleteReservationSuccess,
  [Types.DELETE_RESERVATION_FAILURE]: deleteReservationFailure,

  [Types.LOAD_UPCOMING_RESERVATIONS_REQUEST]: loadUpcomingReservationsRequest,
  [Types.LOAD_UPCOMING_RESERVATIONS_SUCCESS]: loadUpcomingReservationsSuccess,
  [Types.LOAD_UPCOMING_RESERVATIONS_FAILURE]: loadUpcomingReservationsFailure,

  [Types.MODIFY_RESERVATIONS_REQUEST]: modifyReservationsRequest,
  [Types.MODIFY_RESERVATIONS_SUCCESS]: modifyReservationsSuccess,
  [Types.MODIFY_RESERVATIONS_FAILURE]: modifyReservationsFailure,

  [Types.LOAD_RESERVATIONS_STATUS_REQUEST]: loadReservationsStatusRequest,
  [Types.LOAD_RESERVATIONS_STATUS_SUCCESS]: loadReservationsStatusSuccess,
  [Types.LOAD_RESERVATIONS_STATUS_FAILURE]: loadReservationsStatusFailure,

  [Types.CALCULATE_RESERVED_SLOTS]: calculateReservedSlots,
};

export default createReducer(INITIAL_STATE, HANDLERS);
