import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  isAddUserGroup: null,
  isRemoveMembershipGroupSuccess: null,
  error: null,
  cancelInfo: null,
};

const request = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};

const getCancelMembershipInfoSuccess = (state = INITIAL_STATE, { cancelInfo }) => {
  return {
    ...state,
    isRequesting: false,
    cancelInfo,
  };
};

const addUserGroupSuccess = (state = INITIAL_STATE, { isAddUserGroup }) => {
  return {
    ...state,
    isRequesting: false,
    isAddUserGroup,
  };
};

const removeMembershipGroupSuccess = (state = INITIAL_STATE, { success }) => {
  return {
    ...state,
    isRequesting: false,
    isRemoveMembershipGroupSuccess: success,
  };
};

const failure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    isAddUserGroup: null,
    error,
  };
};

export const HANDLERS = {
  [Types.GET_CANCEL_MEMBERSHIP_INFO_REQUEST]: request,
  [Types.GET_CANCEL_MEMBERSHIP_INFO_SUCCESS]: getCancelMembershipInfoSuccess,
  [Types.GET_CANCEL_MEMBERSHIP_INFO_FAILURE]: failure,

  [Types.ADD_USER_GROUP_REQUEST]: request,
  [Types.ADD_USER_GROUP_SUCCESS]: addUserGroupSuccess,
  [Types.ADD_USER_GROUP_FAILURE]: failure,

  [Types.REMOVE_MEMBERSHIP_GROUP_REQUEST]: request,
  [Types.REMOVE_MEMBERSHIP_GROUP_SUCCESS]: removeMembershipGroupSuccess,
  [Types.REMOVE_MEMBERSHIP_GROUP_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
