import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import Button from 'components/shared/Button';
import styles from './styles.module.css';

const DisableReservationPopUp = ({
  cancelReservation,
  isDeleteRequesting,
  handleClose,
}) => {
  const isMobile = useContext(MobileContext);
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Modify your reservation</h3>
      <p className={styles.question}>
        You already made a spot reservation and are not allowed to make another one.
      </p>
      <p className={styles.question}>
        In order to make a new reservation, you need to cancel your current one.
      </p>
      <div className={styles.buttonsWrapper}>
        <Button
          onClick={cancelReservation}
          isRequesting={isDeleteRequesting}
          width={isMobile ? '255px' : '300px'}
          height="40px"
          loaderStyles={{
            left: isMobile ? '0' : '3px',
          }}
          className={styles.button}
        >
          Cancel my reservation
        </Button>
        <Button
          styleType="text"
          className={styles.blackButton}
          onClick={() => handleClose(false)}
        >
          No thanks
        </Button>
      </div>
    </div>
  );
};

DisableReservationPopUp.propTypes = {
  cancelReservation: PropTypes.func.isRequired,
  isDeleteRequesting: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DisableReservationPopUp;
