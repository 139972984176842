import { createReducer } from 'reduxsauce';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  packages: [],
  targetPackage: null,
  error: null,
};

const loadPackagesRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const loadPackagesSuccess = (state = INITIAL_STATE, { packages }) => {
  const packagesClone = cloneDeep(packages);
  return {
    ...state,
    packages: packagesClone.map(el => {
      return { ...el, purchasedAt: moment(el.purchasedAt).format('MM/DD/YYYY') };
    }),
    isRequesting: false,
  };
};
const loadPackagesFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    packages: null,
    error,
  };
};
const setTargetPackage = (state = INITIAL_STATE, { targetPackage }) => {
  return {
    ...state,
    targetPackage,
  };
};
export const HANDLERS = {
  [Types.LOAD_PACKAGES_REQUEST]: loadPackagesRequest,
  [Types.LOAD_PACKAGES_SUCCESS]: loadPackagesSuccess,
  [Types.LOAD_PACKAGES_FAILURE]: loadPackagesFailure,

  [Types.SET_TARGET_PACKAGE]: setTargetPackage,
};

export default createReducer(INITIAL_STATE, HANDLERS);
