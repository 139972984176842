import { connect } from 'react-redux';
import QRModal from 'components/QRModal';
import Actions from 'store/safetyCommitment/actions';

const mapStateToProps = state => {
  return {
    isSafetyCommitmentRequesting: state.safetyCommitment.isRequesting,
  };
};
const mapDispatchToProps = {
  sendSafetyCommitment: Actions.Creators.sendSafetyCommitmentRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(QRModal);
