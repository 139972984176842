import React from 'react';
import PropTypes from 'prop-types';
import { starEnabled } from 'assets/icons';
import styles from './style.module.css';

const VideoCard = ({
  video,
  onWatch,
  available,
  starAction,
  isFavorite,
  setFavorite,
}) => {
  const { id, title, description, author, thumbnail = null } = video;

  return (
    <div className={available ? styles.cardContainer : styles.unavailableCardContainer}>
      <div
        className={styles.wrapper}
        onClick={() => {
          if (available) onWatch(video);
          window.gtag('event', 'Video', {
            event_label: title,
            event_category: 'play',
          });
          window.gtag('event', `video - ${title}`, {
            event_label: title,
            event_category: 'play',
          });
        }}
      >
        <img
          src={`https://fsf-assets.s3.us-west-1.amazonaws.com/${thumbnail}`}
          className={styles.image}
          alt="Thumbnail"
        />

        {starAction && (
          <div
            className={styles.starContainer}
            onClick={() => setFavorite(id, !isFavorite)}
          >
            <div className={styles.starCircle} />
            <img
              src={starEnabled}
              className={isFavorite ? styles.starActive : styles.starDisable}
              alt="star"
            />
          </div>
        )}

        {!available && <div className={styles.imgOverlay} />}
      </div>
      <p className={styles.title}>{title}</p>
      <p className={styles.description}>{author}</p>
    </div>
  );
};

VideoCard.propTypes = {
  video: PropTypes.object.isRequired,
  onWatch: PropTypes.func.isRequired,
  available: PropTypes.bool.isRequired,
  starAction: PropTypes.bool,
  isFavorite: PropTypes.bool,
  setFavorite: PropTypes.func,
};

VideoCard.defaultProps = {
  starAction: false,
  isFavorite: false,
  setFavorite: () => {},
};

export default VideoCard;
