/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react';
import moment, { duration } from 'moment';
import momentTZ from 'moment-timezone';
import PropTypes from 'prop-types';
import {
  likeEnabled,
  likeDisabled,
  locationIcon,
  hours,
  buddy,
  cameraSvgIcon,
} from 'assets/icons';
import styles from './styles.module.css';

const CLASS_CALL_FEATURE_ENABLED = process.env.REACT_APP_CLASS_CALL_FEATURE
  ? JSON.parse(process.env.REACT_APP_CLASS_CALL_FEATURE)
  : false;

export const CalendarCard = ({
  groupEx,
  setIsDetailsOpened,
  addClassToFavorite,
  removeClassFromFavorite,
  handleOpenClassCallPopUp,
  user,
}) => {
  const {
    classInfo,
    startTime,
    endTime,
    date,
    isFavorite,
    trainerName,
    classId,
    isLive,
    isVirtual,
  } = groupEx;

  const { club, name, programName } = classInfo;

  const calculateDuration = () => {
    const classDuration = duration(
      moment(`${date} ${endTime}`).valueOf() - moment(`${date} ${startTime}`).valueOf()
    );
    return `(${classDuration.hours() * 60 + classDuration.minutes()}min)`;
  };

  const isTimeToStartStream = () => {
    const now = new Date();
    const userTimezone = momentTZ.tz.guess(true);
    let today = moment(now).format('YYYY-MM-DD HH:mm:ss');
    if (momentTZ.tz(userTimezone).format('z') !== 'PDT') {
      today = momentTZ(today)
        .tz('America/Los_Angeles')
        .format('YYYY-MM-DD HH:mm:ss');
    }
    const startTimeFormatted = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm:ss');
    const endTimeFormatted = moment(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm:ss');
    if (
      moment(today).isBetween(startTimeFormatted, endTimeFormatted) &&
      moment(today).isSameOrAfter(startTimeFormatted, 'day')
    ) {
      return true;
    }
    return false;
  };

  const [isCallActive, setIsCallActive] = useState(false);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    if (componentIsMounted.current) {
      setIsCallActive(isTimeToStartStream());
      const interval = setInterval(() => {
        setIsCallActive(isTimeToStartStream());
      }, 1000);

      return () => {
        // will unmount
        clearTimeout(interval);
      };
    }
  }, []);

  useEffect(() => {
    return () => {
      componentIsMounted.current = null;
    };
  }, []);

  return (
    <div
      onClick={() => setIsDetailsOpened(true)}
      className={
        isFavorite
          ? [styles.cardContainer, styles.cardContainerLiked].join(' ')
          : styles.cardContainer
      }
    >
      <div className={styles.mainContent}>
        <div className={styles.cardTitleWrapper}>
          <p className={styles.classTitle}>
            <span>{programName || name}</span>
            {(isLive || isVirtual) && <span className={styles.label}>Watch Live!</span>}
          </p>
        </div>
        <span className={styles.classTime}>
          <img className={styles.hoursIcon} src={hours} alt="hours" />
          {moment(`${date} ${startTime}`).format('h:mma')} {calculateDuration()}
        </span>
        <span className={styles.classExtraText}>
          <img className={styles.additionalInfoIcon} src={locationIcon} alt="location" />
          {club.name}
          {trainerName && (
            <img
              className={[styles.additionalInfoIcon, styles.instructorIcon].join(' ')}
              src={buddy}
              alt="instructor"
            />
          )}
          {trainerName}
        </span>
      </div>
      <div className={styles.classIcons}>
        <div
          className={styles.likeWrapper}
          onClick={event => {
            event.stopPropagation();
            if (isFavorite) {
              removeClassFromFavorite(classId);
            } else {
              addClassToFavorite(classId);
            }
          }}
        >
          <img
            className={styles.likeIcon}
            src={isFavorite ? likeDisabled : likeEnabled}
            alt="liked"
          />
        </div>
        {CLASS_CALL_FEATURE_ENABLED && (isLive || isVirtual) ? (
          <div
            className={[
              ((user.isTrainer && user.name !== trainerName) || !isCallActive) &&
              !isVirtual
                ? styles.cameraIconWrapperDisabled
                : styles.cameraIconWrapperActive,
              isVirtual && styles.cameraIconDisableClick,
            ].join(' ')}
            onClick={event => {
              event.stopPropagation();
              if (!((user.isTrainer && user.name !== trainerName) || !isCallActive))
                handleOpenClassCallPopUp(
                  { classId, name: programName || name, date, startTime },
                  true
                );
            }}
          >
            <img
              src={cameraSvgIcon}
              alt="camera"
              className={[
                styles.sessionCardIcon,
                ((user.isTrainer && user.name !== trainerName) || !isCallActive) &&
                !isVirtual
                  ? styles.disabledCamera
                  : styles.activeCamera,
              ].join(' ')}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

CalendarCard.propTypes = {
  groupEx: PropTypes.object.isRequired,
  setIsDetailsOpened: PropTypes.func.isRequired,
  addClassToFavorite: PropTypes.func.isRequired,
  removeClassFromFavorite: PropTypes.func.isRequired,
  handleOpenClassCallPopUp: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
