/* eslint-disable no-restricted-globals */
import { string, number, object, boolean, ref } from 'yup';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import moment from 'moment';

export function calculateStrength(password) {
  const capLetterRegex = /[A-Z]/;
  const letterRegex = /[a-z]/;
  const numberRegex = /[0-9]/;
  const specialCharacterRegex = /[-?!@#$%^&*()_+`~]/;
  const isLetter = password.match(letterRegex) !== null;
  const isCapLetter = password.match(capLetterRegex) !== null;
  const isNumber = password.match(numberRegex) !== null;
  const isSpecialCharacter = password.match(specialCharacterRegex) !== null;

  if (password.length === 0) {
    return -1;
  }

  let strength = 0;
  if (isLetter && password.length >= 6 && password.length <= 25) strength += 1; // medium
  if (isCapLetter && password.length >= 6 && password.length <= 25) strength += 1; // strong
  if (isNumber && password.length >= 6 && password.length <= 25) strength += 1; // strong
  if (isSpecialCharacter && password.length >= 6 && password.length <= 25) strength += 1; // strong

  return strength;
}
const nameRegex = /^[A-Za-z ]*$/;
const cityRegex = /^[A-Za-z\s'-. ]*$/;
const phoneRegex = /[-()_ ]/g;
const emergencyContactRegex = /^[A-Za-z. ]*$/;
export const emailAddressRegex = /^[A-Za-z0-9!#$%&'*+/=?^`{|}~._]+@[a-z]+\.[a-z]{2,}$/;
const zipcodeRegex = /^[0-9]{5}$/;

export const loginValidation = object().shape({
  email: string()
    .email('email is not valid')
    .max(80, 'email should be a maximum of 80 characters long')
    .required('email is required')
    .lowercase(),
  password: string()
    .min(6, 'password should be a minimum of 6 characters long')
    .max(80, 'password should be a maximum of 80 characters long')
    .required('password is required'),
});

export const loginValidationForFamily = object().shape({
  email: string()
    .email('email is not valid')
    .max(80, 'email should be a maximum of 80 characters long')
    .required('email is required')
    .lowercase(),
  password: string()
    .min(6, 'password should be a minimum of 6 characters long')
    .max(80, 'password should be a maximum of 80 characters long')
    .required('password is required'),
  idCA: string()
    .matches(/^\d+$/, 'club id must be a number')
    .required('club id is required for family member'),
});

export const requestResetValidation = object().shape({
  email: string()
    .email('email is not valid')
    .max(80, 'email should be a maximum of 80 characters long')
    .required('email is required')
    .lowercase(),
});

export const resetPasswordValidation = object().shape({
  password: string()
    .min(6, 'password should be a minimum of 6 characters long')
    .max(25, 'password should be a maximum of 25 characters long')
    .required('password is required')
    .test(
      'validate-strength',
      'password should be at least medium strength', // restrict users to enters less strong password
      value => {
        if (value !== undefined) {
          const strength = calculateStrength(value);
          return strength >= 1;
        }
      }
    )
    .test('validate-forbidden', 'password contains forbidden characters', value => {
      if (value !== undefined) {
        const allowedCharacters = /[^(0-9)(a-z)(A-Z)(-?!@#$%^&*()_+`~)]/;
        const restrictedCharacters = /[,.|\":<>[\]{}`\\';/]/;
        const isAllowedCharacter = value.match(allowedCharacters) !== null;
        const isRestrictCharacter = restrictedCharacters.test(value);

        return !isAllowedCharacter && !isRestrictCharacter;
      }
    }),
  confirmPassword: string()
    .required('password confirmation is required')
    .oneOf([ref('password'), null], 'passwords must match'),
});

export const changePasswordValidation = object().shape({
  oldPassword: string().required('Old password is required'),
  password: string()
    .min(6, 'New password should be a minimum of 6 characters long')
    .max(25, 'New password should be a maximum of 25 characters long')
    .required('New password is required')
    .test(
      'validate-strength',
      'New password should be at least medium strength', // restrict users to enters less strong password
      value => {
        if (value !== undefined) {
          const strength = calculateStrength(value);
          return strength >= 1;
        }
      }
    )
    .test('validate-forbidden', 'New password contains forbidden characters', value => {
      if (value !== undefined) {
        const allowedCharacters = /[^(0-9)(a-z)(A-Z)(-?!@#$%^&*()_+`~)]/;
        const restrictedCharacters = /[,.|\":<>[\]{}`\\';/]/;
        const isAllowedCharacter = value.match(allowedCharacters) !== null;
        const isRestrictCharacter = restrictedCharacters.test(value);

        return !isAllowedCharacter && !isRestrictCharacter;
      }
    }),
  confirmPassword: string()
    .required('Password confirmation is required')
    .oneOf([ref('password'), null], 'Passwords must match'),
});

export const profileInfoSchema = object().shape({
  phone: string()
    .transform(value => value.replace(phoneRegex, ''))
    .min(10, 'Phone Number must contain at least 10 Numbers')
    .required('Phone number is required'),
  address: string().required('Address is required'),
  city: string()
    .matches(nameRegex, 'Please enter valid city name')
    .required('City is required'),
  state: string()
    .matches(nameRegex, 'Please enter valid state name')
    .required('State is required'),
  zipCode: number().required('Zip Code is required'),
  emergencyContact: string()
    .matches(emergencyContactRegex, 'Please enter valid contact name')
    .required('Emergency Contact Name is required'),
  emergencyPhone: string()
    .transform(value => value.replace(phoneRegex, ''))
    .min(10, 'Emergency Phone must contain at least 10 Numbers')
    .required('Emergency Contact Phone is required'),
});
export const newRegistrationInfoSchema = object().shape({
  firstName: string()
    // .matches(nameRegex, 'Please enter a valid first name')
    .required('First Name is required')
    .test('validate-forbidden', 'Please enter a valid first name', value => {
      if (value !== undefined) {
        const allowedCharacters = /[^(0-9)(a-z)(A-Z)(-.?!@#$%^&*()_+`~\') ]/;
        const restrictedCharacters = /[,|\":<>[\]{}`\;/]/;
        const isAllowedCharacter = value.match(allowedCharacters) !== null;
        const isRestrictCharacter = restrictedCharacters.test(value);

        return !isAllowedCharacter && !isRestrictCharacter;
      }
    })
    .test('validate-forbidden', 'Please enter a valid first name', value => {
      if (value !== undefined) {
        const alphabetsRequired = /[a-zA-Z]/
        const isAlphabetsPresent = alphabetsRequired.test(value)
        return isAlphabetsPresent
      }
    }),
  lastName: string()
    // .matches(nameRegex, 'Please enter a valid last name')
    .required('Last Name is required')
    .test('validate-forbidden', 'Please enter a valid last name', value => {
      if (value !== undefined) {
        const allowedCharacters = /[^(0-9)(a-z)(A-Z)(-.?!@#$%^&*()_+`~\') ]/;
        const restrictedCharacters = /[,|\":<>[\]{}`\;/]/;
        const isAllowedCharacter = value.match(allowedCharacters) !== null;
        const isRestrictCharacter = restrictedCharacters.test(value);

        return !isAllowedCharacter && !isRestrictCharacter;
      }
    })
    .test('validate-forbidden', 'Please enter a valid last name', value => {
      if (value !== undefined) {
        const alphabetsRequired = /[a-zA-Z]/
        const isAlphabetsPresent = alphabetsRequired.test(value)
        return isAlphabetsPresent
      }
    }),
  birthday: string()
    .required('Birthday is required')
    .test('valid-date', 'Invalid Date', value => {
      if (!value) {
        return false;
      }
      const [month, day, year] = value.split('-').map(Number);
      const currentYear = new Date().getFullYear(); // Get the current year
      return (
        month >= 1 &&
        month <= 12 &&
        day >= 1 &&
        day <= 31 &&
        year >= 1900 && // Adjust the minimum year to your desired value
        year <= currentYear // Allow only up to the current year or a reasonable max year
      );
    }),
  phone: string()
    .transform(value => value.replace(phoneRegex, ''))
    .test('no-leading-zero', ' Phone number should not start with zero', value => {
      return /^(?!0)[0-9]+$/.test(value);
    })
    .min(10, 'Phone must contain at least 10 Numbers')
    .required('Phone number is required'),
  address: string()
    .required('Address is required')
    .test('validate-forbidden', 'Please enter a valid address', value => {
      if (value !== undefined) {
        const allowedCharacters = /[^(0-9)(a-z)(A-Z)(-.?!@#$%^&*()_+`~\') ]/;
        const restrictedCharacters = /[,|\":<>[\]{}`\;/]/;
        const isAllowedCharacter = value.match(allowedCharacters) !== null;
        const isRestrictCharacter = restrictedCharacters.test(value);

        return !isAllowedCharacter && !isRestrictCharacter;
      }
    })
    .test('validate-forbidden', 'Please enter a valid address', value => {
      if (value !== undefined) {
        const alphanumreicRequired = /[a-zA-Z0-9]/
        const isAlphanumrericPresent = alphanumreicRequired.test(value)
        return isAlphanumrericPresent
      }
    }),
  city: string()
    // .matches(cityRegex, 'Please enter a valid city name')
    .required('City is required')
    .test('validate-forbidden', 'Please enter a valid city', value => {
      if (value !== undefined) {
        const allowedCharacters = /[^(0-9)(a-z)(A-Z)(\s'-.?!@#$%^&*()_+`~) ]/;
        const restrictedCharacters = /[,|\":<>[\]{}`\\;/]/;
        const isAllowedCharacter = value.match(allowedCharacters) !== null;
        const isRestrictCharacter = restrictedCharacters.test(value);

        return !isAllowedCharacter && !isRestrictCharacter;
      }
    })
    .test('validate-forbidden', 'Please enter a valid city', value => {
      if (value !== undefined) {
        const alphabetsRequired = /[a-zA-Z]/
        const isAlphabetsPresent = alphabetsRequired.test(value)
        return isAlphabetsPresent
      }
    }),
  state: string()
    // .matches(nameRegex, 'Please enter a valid state name')
    .required('State is required'),
  zipCode: string()
    .matches(zipcodeRegex, 'Please enter a valid zipcode')
    .required('Zip Code is required'),
  emergencyContactName: string()
    // .matches(emergencyContactRegex, 'Please enter a valid contact name')
    .required('Emergency Contact Name is required')
    .test('validate-forbidden', 'Please enter a valid contact name', value => {
      if (value !== undefined) {
        const allowedCharacters = /[^(0-9)(a-z)(A-Z)(-.?!@#$%^&*()_+`~\') ]/;
        const restrictedCharacters = /[,|\":<>[\]{}`\;/]/;
        const isAllowedCharacter = value.match(allowedCharacters) !== null;
        const isRestrictCharacter = restrictedCharacters.test(value);

        return !isAllowedCharacter && !isRestrictCharacter;
      }
    })
    .test('validate-forbidden', 'Please enter a valid contact name', value => {
      if (value !== undefined) {
        const alphabetsRequired = /[a-zA-Z]/
        const isAlphabetsPresent = alphabetsRequired.test(value)
        return isAlphabetsPresent
      }
    }),
  emergencyContactPhone: string()
    .transform(value => value.replace(phoneRegex, ''))
    .test(
      'no-leading-zero',
      ' Emergency contact number should not start with zero',
      value => {
        return /^(?!0)[0-9]+$/.test(value);
      }
    )
    .min(10, 'Contact Phone must contain at least 10 Numbers')
    .required('Emergency Contact Phone is required'),
});
export const registrationInfoSchema = object().shape({
  firstName: string()
    .matches(nameRegex, 'Please enter a valid first name')
    .required('First Name is required'),
  lastName: string()
    .matches(nameRegex, 'Please enter a valid last name')
    .required('Last Name is required'),
  birthday: string()
    .required('Birthday is required')
    .test('Invalid Date', 'Invalid Date', value => {
      const sfDate = getSFTime();
      const condition =
        moment(sfDate).diff(value, 'day') > 0 &&
        moment(sfDate).diff(value, 'year') < 100 &&
        moment(sfDate).diff(value, 'year') >= 18;
      return value
        ? isNaN(moment(sfDate).diff(value, 'day')) // case for Firefox
          ? value
          : condition
        : false;
    }),
  email: string()
    .email('email is not valid')
    .max(80, 'email should be a maximum of 80 characters long')
    .required('email is required'),
  phone: string()
    .transform(value => value.replace(phoneRegex, ''))
    .min(10, 'Phone must contain at least 10 Numbers')
    .required('Phone number is required'),
  address: string().required('Address is required'),
  city: string()
    .matches(nameRegex, 'Please enter a valid city name')
    .required('City is required'),
  state: string()
    .matches(nameRegex, 'Please enter a valid state name')
    .required('State is required'),
  zipCode: number().required('Zip Code is required'),
  emergencyContactName: string()
    .matches(emergencyContactRegex, 'Please enter a valid contact name')
    .required('Emergency Contact Name is required'),
  emergencyContactPhone: string()
    .transform(value => value.replace(phoneRegex, ''))
    .min(10, 'Contact Phone must contain at least 10 Numbers')
    .required('Emergency Contact Phone is required'),
});

export const experienceUsSchema = object().shape({
  firstName: string()
    .matches(nameRegex, 'Please enter a valid first name')
    .required('First Name is required'),
  lastName: string()
    .matches(nameRegex, 'Please enter a valid last name')
    .required('Last Name is required'),
  email: string()
    .email('email is not valid')
    .max(80, 'email should be a maximum of 80 characters long')
    .required('email is required'),
  phone: string()
    .transform(value => value.replace(phoneRegex, ''))
    .min(10, 'Phone must contain at least 10 Numbers')
    .required('Phone number is required'),
});
