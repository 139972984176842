import { takeLatest, call, put } from 'redux-saga/effects';
import api from 'services/api';
import packagesActions from './actions';
import errorActions from '../error/actions';

const {
  Types: { LOAD_SSO_TOKEN_REQUEST },
  Creators: { loadSsoTokenSuccess, loadSsoTokenFailure },
} = packagesActions;
const {
  Creators: { setError },
} = errorActions;
function* loadSsoToken() {
  try {
    const { data } = yield call(api.post, `/sso`);
    yield put(loadSsoTokenSuccess(data));
  } catch (error) {
    yield put(loadSsoTokenFailure(error));
    yield put(setError('Connection error.'));
  }
}

export function* ssoSaga() {
  yield takeLatest(LOAD_SSO_TOKEN_REQUEST, loadSsoToken);
}
