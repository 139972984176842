import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const Spinner = ({ fixed, small }) => {
  return (
    <div
      className={`${styles.loader} ${fixed && styles.fixed} ${small && styles.small}`}
    />
  );
};

Spinner.propTypes = {
  fixed: PropTypes.bool,
  small: PropTypes.bool,
};

Spinner.defaultProps = {
  fixed: true,
  small: false,
};

export default Spinner;
