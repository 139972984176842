export default Object.freeze({
  '/': 'Home',
  '': 'Home',
  '/nutrition': 'Nutrition',
  '/classes': 'Classes',
  '/on_demand': 'On demand',
  '/reservation': 'Reserve a spot',
  '/analytics': 'Analytics',
  '/training': 'Trainers',
  '/locations': 'Locations',
  '/reset_password': 'Reset password',
  '/new_password': 'Create new password',
  '/join': 'Join',
  '/experience-us': 'Experience-us',
  '/login': 'Login',
  '/profile': 'My account',
  '/my-account': 'My account',
  '/update-user-password': 'Update Password',
  '/membership-subscription': 'Membership Subscription',
  '/invite': 'Invite and Benefit',
  '/billing-information': 'Billing',
  '/statements-transactions': 'Statements-transactions',
  '/packages': 'Packages',
  '/contact-us': 'Contact-us',
  '/checkin-history': 'Check-in history',
  '/settings': 'Settings',
  '/logout': 'Logout',
  '/admin': 'Admin',
  '/qr': 'Qr Code',
  '/our-classes': 'Our Classes',
  '/create_success': 'Success',
  '/request_success': 'Success',
  '/choose_favorite_location': 'Choose favorite location',
  '/verify-status': 'Vaccine verification',
});
