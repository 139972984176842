import { connect } from 'react-redux';
import MembershipSubscription from 'components/ProfilePage/MembershipSubscription';
import Actions from 'store/auth/actions';
import pdfActions from 'store/pdf/actions';
import membershipActions from 'store/membership/actions';
import topicsActions from 'store/topics/actions';
import billingActions from 'store/billing/actions';

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    profile: state.auth.profile,
    isAddUserGroup: state.membership.isAddUserGroup,
    isRequestingMembership: state.membership.isRequesting,
    isRequesting: state.auth.isRequesting,
    isRequestingPDF: state.pdf.isRequesting,
    membership: state.auth.membership,
  };
};

const mapDispatchToProps = {
  loadBilling: billingActions.Creators.loadBillingRequest,
  loadMembership: Actions.Creators.loadMembershipRequest,
  loadAgreement: pdfActions.Creators.loadAgreementRequest,
  postCancelationDate: Actions.Creators.postCancelationDateRequest,
  addUserGroup: membershipActions.Creators.addUserGroupRequest,
  preselectFreezeTopic: topicsActions.Creators.preselectFreezeTopic,
};

export default connect(mapStateToProps, mapDispatchToProps)(MembershipSubscription);
