/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OpenVidu } from 'openvidu-browser';
import moment from 'moment';
import IconSVG from 'components/shared/IconSVG';
import { cameraIcon, resizeIcon, muteIcon, unCallIcon, micIcon } from 'assets/icons';
import Spinner from 'components/shared/Spinner';
import MobileContext from 'context/MobileContext';
import authUtils from 'utils/auth';
import UserModel from './userModel';
import OvVideoComponent from './ovVideo';
import styles from './styles.module.css';

const CallHeader = ({
  participantName,
  callDurationString,
  showResize,
  numberOfSubscribers,
}) => {
  const handleFullScreen = () => {
    const isInFullScreen =
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);
    const docElm = document.getElementById('video-call');
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };
  const numberString = numberOfSubscribers ? ` - ${numberOfSubscribers} watching` : '';
  return (
    <div className={styles.headerWrapper}>
      <span className={styles.duration}>{callDurationString || ''}</span>
      <span className={styles.participantName}>
        {`${participantName}${numberString}` || ''}
      </span>
      {showResize && (
        <div onClick={handleFullScreen} className={styles.resizeWrapper}>
          <IconSVG src={resizeIcon} width="15px" height="15px" />
        </div>
      )}
    </div>
  );
};

const CallPublisherFooter = ({
  camStatusChanged,
  micStatusChanged,
  leaveSession,
  localUser,
  recordHandler,
  isRecording,
  isStopRecordingRequest,
}) => {
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.iconWrapper} onClick={() => camStatusChanged()}>
        <IconSVG src={cameraIcon} width="18px" height="12px" />
        <span className={styles.iconText}>
          {localUser.isVideoActive() ? 'Stop camera' : 'Start camera'}
        </span>
      </div>
      <div className={styles.iconWrapper}>
        <div className={styles.endCallWrapper} onClick={() => leaveSession()}>
          <IconSVG src={unCallIcon} width="18px" height="8px" />
        </div>
        <span className={styles.iconText}>Leave</span>
      </div>
      <div className={styles.iconWrapper} onClick={() => micStatusChanged()}>
        <IconSVG src={muteIcon} width="12px" height="18px" />
        <span className={styles.iconText}>
          {localUser.isAudioActive() ? 'Mute' : 'Unmute'}
        </span>
      </div>
      <div
        className={[
          styles.iconWrapper,
          isStopRecordingRequest && styles.disabledDiv,
        ].join(' ')}
        onClick={() => {
          recordHandler();
        }}
      >
        <IconSVG src={micIcon} width="18px" height="18px" />
        <span className={styles.iconText}>
          {isRecording ? 'Stop recording' : 'Start recording'}
        </span>
      </div>
    </div>
  );
};

const CallSubscriberFooter = ({ leaveSession }) => {
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.iconWrapper} style={{ marginRight: '0' }}>
        <div className={styles.endCallWrapper} onClick={() => leaveSession()}>
          <IconSVG src={unCallIcon} width="18px" height="8px" />
        </div>
        <span className={styles.iconText}>Leave</span>
      </div>
    </div>
  );
};

const userModel = new UserModel();

class VideoRoomComponent extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      session: undefined,
      localUser: undefined,
      subscriber: undefined,
      callIsActive: false,
      streamIsPlaying: false,
      localVideoDuration: null,
      subVideoDuration: null,
      streamStartTime: null,
      isReconnecting: false,
      isConnectionError: false,
      streamIsDestroyed: false,
    };

    this.joinSession = this.joinSession.bind(this);
    this.leaveSession = this.leaveSession.bind(this);
    this.onbeforeunload = this.onbeforeunload.bind(this);
    this.camStatusChanged = this.camStatusChanged.bind(this);
    this.micStatusChanged = this.micStatusChanged.bind(this);
    this.startDate = Date.now();
  }

  componentDidMount() {
    const { getCallSessionToken } = this.props;
    const isOnIOS =
      /iP(ad|od|hone)/i.test(navigator.userAgent) &&
      /WebKit/i.test(navigator.userAgent) &&
      !/(CriOS|FxiOS|OPiOS|mercury)/i.test(navigator.userAgent);
    const eventName = isOnIOS ? 'pagehide' : 'beforeunload';
    window.addEventListener(eventName, this.onbeforeunload);
    this._isMounted = true;
    getCallSessionToken();
  }

  componentDidUpdate(prevProps, prevState) {
    const { callSessionToken, recordingUrl, classId } = this.props;
    const { callIsActive, streamIsPlaying, streamStartTime } = this.state;
    if (callSessionToken !== prevProps.callSessionToken) {
      this.joinSession();
    }
    if (prevState.callIsActive !== callIsActive) {
      if (callIsActive && this._isMounted) {
        this.localInterval = setInterval(() => {
          const now = moment(Date.now());
          const start = moment(this.startDate);
          const diff = now.diff(start, 'seconds');
          const formatted = moment.utc(diff * 1000).format('mm:ss');
          this.setState({ localVideoDuration: formatted });
        }, 1000);
        if (!callIsActive && this._isMounted) {
          clearInterval(this.interval);
        }
      }
    }
    if (prevState.streamIsPlaying !== streamIsPlaying) {
      if (streamIsPlaying && this._isMounted) {
        this.subInterval = setInterval(() => {
          const now = moment(Date.now());
          const start = moment(streamStartTime);
          const diff = now.diff(start, 'seconds');
          const formatted = moment.utc(diff * 1000).format('mm:ss');
          this.setState({ subVideoDuration: formatted });
        }, 1000);
        if (!streamIsPlaying && this._isMounted) {
          clearInterval(this.interval);
        }
      }
    }
    if (recordingUrl && prevProps.recordingUrl !== recordingUrl) {
      fetch(recordingUrl)
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL || window.webkitURL;
          const video = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = video;
          a.download = `${classId.date}-${classId.startTime} - ${classId.name} - ${classId.classId}`;
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            url.revokeObjectURL(video);
            document.body.removeChild(a);
          }, 1000);
        });
    }
  }

  componentWillUnmount() {
    this.leaveSession();
    const isOnIOS =
      /iP(ad|od|hone)/i.test(navigator.userAgent) &&
      /WebKit/i.test(navigator.userAgent) &&
      !/(CriOS|FxiOS|OPiOS|mercury)/i.test(navigator.userAgent);
    const eventName = isOnIOS ? 'pagehide' : 'beforeunload';
    window.removeEventListener(eventName, this.onbeforeunload);
    this._isMounted = false;
    clearInterval(this.localInterval);
    clearInterval(this.subInterval);
  }

  onbeforeunload(event) {
    const { classId, callSessionToken } = this.props;
    const { session } = this.state;
    const id = classId.classId;
    if (session) {
      session.disconnect();
    }
    const baseUrl = new URL(process.env.REACT_APP_API_URI).toString();
    const token = authUtils.getToken();
    const isOnIOS =
      /iP(ad|od|hone)/i.test(navigator.userAgent) &&
      /WebKit/i.test(navigator.userAgent) &&
      !/(CriOS|FxiOS|OPiOS|mercury)/i.test(navigator.userAgent);
    if (isOnIOS) {
      navigator.sendBeacon(
        `${baseUrl}global/remove-user`,
        JSON.stringify({ id, token: callSessionToken })
      );
    } else {
      fetch(`${baseUrl}calls/remove-user`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, token: callSessionToken }),
        keepalive: true,
        mode: 'cors',
      });
    }

    return true;
  }

  joinSession() {
    this.OV = new OpenVidu();

    this.setState(
      {
        session: this.OV.initSession(),
      },
      () => {
        this.subscribeToStreamCreated();

        this.connectToSession();
      }
    );
  }

  connectToSession() {
    const { callSessionToken } = this.props;
    if (callSessionToken !== undefined) {
      this.connect(callSessionToken);
    }
  }

  connect(token) {
    const { session } = this.state;
    const { user } = this.props;
    session
      .connect(token, { clientData: user.username })
      .then(() => {
        this.connectWebCam();
      })
      .catch(error => {
        this.setState({ isConnectionError: true });
      });
  }

  connectWebCam() {
    const { session } = this.state;
    const { user } = this.props;
    if (user.isTrainer) {
      const publisher = this.OV.initPublisher(undefined, {
        audioSource: undefined,
        videoSource: undefined,
        publishAudio: userModel.isAudioActive(),
        publishVideo: userModel.isVideoActive(),
        resolution: '640x480',
        frameRate: 30,
        insertMode: 'APPEND',
      });
      if (session.capabilities.publish) {
        session.publish(publisher).then(() => {});
      }
      userModel.setStreamManager(publisher);
    }

    userModel.setNickname(user.username);
    userModel.setConnectionId(session.connection.connectionId);
    userModel.setScreenShareActive(false);
    this.subscribeToStreamDestroyed();
    this.subscribeToSessionReconnect();
    this.setState({ localUser: userModel, callIsActive: true });
  }

  leaveSession() {
    const { session } = this.state;
    const { removeUser, clearCallState } = this.props;
    const mySession = session;

    if (mySession) {
      mySession.disconnect();
    }

    this.OV = null;
    this.setState({
      session: undefined,
      subscriber: undefined,
      localUser: undefined,
      callIsActive: false,
      streamIsPlaying: false,
    });
    removeUser();
    clearCallState();
  }

  camStatusChanged() {
    userModel.setVideoActive(!userModel.isVideoActive());
    userModel.getStreamManager().publishVideo(userModel.isVideoActive());
    this.sendSignalUserChanged({ isVideoActive: userModel.isVideoActive() });
    this.setState({ localUser: userModel });
  }

  micStatusChanged() {
    userModel.setAudioActive(!userModel.isAudioActive());
    userModel.getStreamManager().publishAudio(userModel.isAudioActive());
    this.sendSignalUserChanged({ isAudioActive: userModel.isAudioActive() });
    this.setState({ localUser: userModel });
  }

  sendSignalUserChanged(data) {
    const { session } = this.state;
    const signalOptions = {
      data: JSON.stringify(data),
      type: 'userChanged',
    };
    session.signal(signalOptions);
  }

  deleteSubscriber(stream) {
    this.setState({ subscriber: undefined });
  }

  subscribeToStreamCreated() {
    const { session, localUser } = this.state;
    session.on('streamCreated', event => {
      const sub = session.subscribe(event.stream, undefined);
      const newUser = new UserModel();
      newUser.setStreamManager(sub);
      const nickname = event.stream.connection.data.slice(5);
      newUser.setNickname(JSON.parse(nickname).serverData.name);
      newUser.setConnectionId(event.stream.connection.connectionId);
      newUser.setType('remote');
      this.setState(
        {
          subscriber: newUser,
          callIsActive: true,
          streamIsPlaying: true,
          streamStartTime: Date.now(),
        },
        () => {
          if (localUser) {
            this.sendSignalUserChanged({
              isAudioActive: localUser.isAudioActive(),
              isVideoActive: localUser.isVideoActive(),
            });
          }
        }
      );
    });
  }

  subscribeToStreamDestroyed() {
    const { session } = this.state;
    session.on('streamDestroyed', event => {
      this.deleteSubscriber(event.stream);
      this.setState({ streamIsDestroyed: true, streamIsPlaying: false });
      event.preventDefault();
    });
  }

  subscribeToSessionReconnect() {
    const { session } = this.state;
    session.on('reconnecting', () => {
      this.setState({ isReconnecting: true });
    });
    session.on('reconnected', () => {
      this.setState({ isReconnecting: false });
    });
    session.on('sessionDisconnected', event => {
      if (event.reason === 'networkDisconnect') {
        this.deleteSubscriber(event.stream);
        this.leaveSession();
        event.preventDefault();
      }
    });
  }

  render() {
    const {
      localUser,
      callIsActive,
      localVideoDuration,
      subscriber,
      subVideoDuration,
      isReconnecting,
      isConnectionError,
      streamIsDestroyed,
      session,
    } = this.state;
    const {
      isRequesting,
      user,
      startRecording,
      stopRecording,
      isRecording,
      recordingUrl,
      isStopRecordingRequest,
    } = this.props;
    const isMobile = this.context;
    const numberOfSubscribers =
      session?.remoteConnections && Object.keys(session?.remoteConnections).length;
    const recordHandler = isRecording ? stopRecording : startRecording;

    return (
      <>
        {isConnectionError ? (
          <div className={isMobile ? styles.mobileWrapper : styles.wrapper}>
            <div className={styles.reconnecting}>
              Something went wrong. Try to close and connect again
            </div>
            <div
              className={[styles.button, styles.removeButton].join(' ')}
              onClick={() => this.leaveSession()}
            >
              Close
            </div>
          </div>
        ) : user.isTrainer ? (
          <>
            {isRequesting || !callIsActive ? (
              <Spinner height={isMobile && '100vh'} />
            ) : (
              <>
                {localUser !== undefined &&
                  localUser.getStreamManager() !== undefined &&
                  user.isTrainer && (
                    <div
                      className={isMobile ? styles.mobileWrapper : styles.wrapper}
                      id="video-call"
                    >
                      <CallHeader
                        participantName="You"
                        callDurationString={localVideoDuration}
                        showResize={localUser !== undefined && !isMobile}
                        numberOfSubscribers={numberOfSubscribers}
                      />
                      {isReconnecting ? (
                        <div className={styles.wait}>Reconnecting...</div>
                      ) : (
                        <OvVideoComponent user={localUser} isTrainer />
                      )}
                      <CallPublisherFooter
                        camStatusChanged={this.camStatusChanged}
                        micStatusChanged={this.micStatusChanged}
                        localUser={localUser}
                        leaveSession={this.leaveSession}
                        recordHandler={recordHandler}
                        isRecording={isRecording}
                        recordingUrl={recordingUrl}
                        isStopRecordingRequest={isStopRecordingRequest}
                      />
                    </div>
                  )}
              </>
            )}
          </>
        ) : (
          <>
            {isRequesting ? (
              <Spinner height={isMobile && '100vh'} />
            ) : (
              <>
                {subscriber === undefined ? (
                  <div className={isMobile ? styles.mobileWrapper : styles.wrapper}>
                    <CallHeader showResize={subscriber !== undefined && !isMobile} />
                    <div className={styles.wait}>
                      {streamIsDestroyed
                        ? 'This video stream has been ended by the trainer. You can press the disconnect button now.'
                        : 'Waiting for trainer'}
                    </div>
                    <CallSubscriberFooter leaveSession={this.leaveSession} />
                  </div>
                ) : (
                  <>
                    {subscriber !== undefined &&
                    subscriber.getStreamManager() !== undefined ? (
                      <div
                        key={subscriber.streamManager.stream.streamId}
                        id="video-call"
                        className={isMobile ? styles.mobileWrapper : styles.wrapper}
                      >
                        <CallHeader
                          participantName={subscriber.nickname}
                          callDurationString={subVideoDuration}
                          showResize={subscriber !== undefined && !isMobile}
                        />
                        {isReconnecting ? (
                          <div className={styles.reconnecting}>Reconnecting...</div>
                        ) : (
                          <OvVideoComponent user={subscriber} isTrainer={false} />
                        )}
                        <CallSubscriberFooter leaveSession={this.leaveSession} />
                      </div>
                    ) : (
                      <div className={isMobile ? styles.mobileWrapper : styles.wrapper}>
                        <div className={styles.wait}>Something went wrong</div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
}

VideoRoomComponent.contextType = MobileContext;

CallHeader.propTypes = {
  participantName: PropTypes.string,
  callDurationString: PropTypes.string,
  showResize: PropTypes.bool,
  numberOfSubscribers: PropTypes.number.isRequired,
};

CallHeader.defaultProps = {
  participantName: '',
  callDurationString: '',
  showResize: false,
};

CallPublisherFooter.propTypes = {
  camStatusChanged: PropTypes.func.isRequired,
  micStatusChanged: PropTypes.func.isRequired,
  leaveSession: PropTypes.func.isRequired,
  localUser: PropTypes.object.isRequired,
  recordHandler: PropTypes.func.isRequired,
  isRecording: PropTypes.bool.isRequired,
  isStopRecordingRequest: PropTypes.bool.isRequired,
};

CallSubscriberFooter.propTypes = {
  leaveSession: PropTypes.func.isRequired,
};

VideoRoomComponent.propTypes = {
  getCallSessionToken: PropTypes.func.isRequired,
  callSessionToken: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  removeUser: PropTypes.func.isRequired,
  clearCallState: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  classId: PropTypes.number.isRequired,
  startRecording: PropTypes.func.isRequired,
  stopRecording: PropTypes.func.isRequired,
  isRecording: PropTypes.bool.isRequired,
  recordingUrl: PropTypes.string.isRequired,
  isStopRecordingRequest: PropTypes.bool.isRequired,
};

export default VideoRoomComponent;
