import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import clockSVG from 'assets/icons/clock.svg';
import { cameraSvgIcon } from 'assets/icons/index';
import { useSelector } from 'react-redux';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import Button from 'components/shared/Button';
import styles from './styles.module.css';

const handleTargetSession = targetSession => {
  if (targetSession === null)
    return {
      title: '',
      date: '',
      location: '',
      studioName: '',
      trainerName: '',
      trainerCAid: '',
      dateOrigin: '',
      startTime: '',
      endTime: '',
      callId: '',
    };
  const {
    title,
    date,
    dateOrigin,
    startTime,
    endTime,
    location,
    studioName,
    trainerCAid,
    personName,
    callId,
  } = targetSession;
  return {
    title,
    date,
    dateOrigin,
    startTime,
    endTime,
    location,
    studioName,
    trainerName: personName,
    trainerCAid,
    callId,
  };
};

const SessionDetails = ({
  isOpen,
  targetSession,
  setTrainerId,
  setCancelSession,
  handleOpenCallPopUp,
}) => {
  const {
    date,
    studioName,
    trainerCAid,
    trainerName,
    dateOrigin,
    startTime,
    endTime,
    callId,
  } = handleTargetSession(targetSession);

  const [inFuture, setInFuture] = useState(false);
  const [now, setNow] = useState(false);
  const [isAllowCancelSession, setAllowCancelSession] = useState(false);

  useEffect(() => {
    const sfDateTime = getSFTime('full');

    const startSessionDate = moment(`${dateOrigin} ${startTime}`).format(
      'YYYY-MM-DD HH:mm:ss'
    );

    const endSessionDate = moment(`${dateOrigin} ${endTime}`).format(
      'YYYY-MM-DD HH:mm:ss'
    );

    const diffStartTime = moment(sfDateTime).diff(startSessionDate, 'minutes');
    const diffEndTime = moment(sfDateTime).diff(endSessionDate, 'minutes');

    if (diffStartTime > 0 && diffEndTime > 0) {
      // Completed
      setNow(false);
      setInFuture(false);
      setAllowCancelSession(false);
    }

    if (diffStartTime >= 0 && diffEndTime <= 0) {
      // Now
      setNow(true);
      setInFuture(false);
      setAllowCancelSession(false);
    }

    if (diffStartTime < 0 && diffEndTime < 0) {
      // Will be
      setNow(false);
      // minutes
      if (Math.abs(diffStartTime) < 60) {
        setInFuture(`${Math.abs(diffStartTime)} minutes`);
        setAllowCancelSession(false);
      }
      // hours
      else if (Math.floor(Math.abs(diffStartTime) / 60) < 24) {
        const hour = Math.floor(Math.abs(diffStartTime) / 60);
        const minutes = Math.abs(diffStartTime) - hour * 60;
        setInFuture(`${hour} ${hour > 1 ? 'hours' : 'hour'} ${minutes} minutes`);
        setAllowCancelSession(false);
      }
      // days
      else {
        const days = Math.floor(Math.abs(diffStartTime) / (60 * 24));
        const hour = Math.floor(Math.abs(diffStartTime) / 60) - days * 24;
        setInFuture(
          `${days} ${days > 1 ? 'days' : 'day'} ${hour} ${hour > 1 ? 'hours' : 'hour'}`
        );
        setAllowCancelSession(true);
      }
    }
  }, [targetSession]);

  const trainers = useSelector(state => state.training.trainers);
  const userName = useSelector(state => state.auth.user.name);
  const currentTrainer =
    trainers?.length > 0 && trainers.find(tr => tr.idCA === trainerCAid.toString());

  const cancelSessions = () => {
    setCancelSession({
      clubName: studioName,
      trainerName,
      date,
      memberName: userName,
    });
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={styles.sessionPhoto}
          style={{ backgroundImage: `url(${currentTrainer?.photo})` }}
        />
        <div className={styles.mainContent}>
          <p className={styles.name}>{trainerName}</p>
          {now && <p className={styles.joinNow}>Join Now!</p>}
          <div className={styles.dash} />
          {date ? (
            <p className={styles.date}>
              <img alt="clock" src={clockSVG} className={styles.clockIcon} />
              {date}
            </p>
          ) : null}
          <div className={styles.policy}>
            Cancellation policy:
            <br />
            You may modify or cancel your session up to 24 hours before it starts.
          </div>
        </div>
        {now && <p className={styles.startedSession}>Your session has started !</p>}
        {now && (
          <div className={styles.contactWrapper}>
            <div
              className={[styles.button, styles.cameraButton].join(' ')}
              onClick={event => {
                event.stopPropagation();
                handleOpenCallPopUp(callId, trainerName, true);
              }}
            >
              <img className={styles.chatIcon} src={cameraSvgIcon} alt="chat" />
              Join the Session
            </div>
          </div>
        )}
        {inFuture && isAllowCancelSession && (
          <div className={styles.buttonWrapper}>
            <Button onClick={cancelSessions} className={styles.cancelButton}>
              Cancel this session
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

SessionDetails.propTypes = {
  isOpen: PropTypes.bool,
  targetSession: PropTypes.object,
  setTrainerId: PropTypes.func,
  setCancelSession: PropTypes.func,
  handleOpenCallPopUp: PropTypes.func,
};

SessionDetails.defaultProps = {
  isOpen: false,
  targetSession: {},
  setTrainerId: () => {},
  setCancelSession: () => {},
  handleOpenCallPopUp: () => {},
};

export default SessionDetails;
