/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { filter as filterIcon } from 'assets/icons';
import Input from 'components/shared/Input';
import TabletContext from 'context/TabletContext';
import MobileContext from 'context/MobileContext';
import { PopUp } from 'components/shared';
import compact from 'lodash/compact';
import Filtration from 'components/shared/ExerciseFilter/Filtration';
import Button from 'components/shared/Button';
import styles from './style.module.css';

const Header = ({
  searchValue,
  setSearchValue,
  filter,
  setFilter,
  filterPremiumVideo,
  categoryList,
}) => {
  const isTablet = useContext(TabletContext);
  const isMobile = useContext(MobileContext);

  const [options, setOptions] = useState([]);
  const [isShowFiltersPopup, showFiltersPopup] = useState(false);

  useEffect(() => {
    if (!filter) setOptions(categoryList);
  }, [isShowFiltersPopup, categoryList, filter]);

  const applyFilter = () => {
    const optionsList = compact(
      options.map(el => {
        if (el.isSelected) return el.title;
        return null;
      })
    );
    filterPremiumVideo(optionsList);
    setFilter(true);
    showFiltersPopup(false);
  };

  const onClosePopup = () => {
    showFiltersPopup(false);
    setOptions(categoryList);
  };

  const resetFilters = () => {
    setOptions(categoryList);
    filterPremiumVideo([]);
  };

  const filtering = (
    <div className={styles.filters}>
      {isMobile ? (
        <div className={styles.mobileHeader}>
          <p onClick={onClosePopup}>Close</p>
          <h3>Filters</h3>
          <p onClick={applyFilter}>Apply</p>
        </div>
      ) : (
        <h1>
          <p>Filters</p>
          <span onClick={resetFilters}>Reset All</span>
        </h1>
      )}
      <Filtration categories={options} setCategories={setOptions} />
      <div className={styles.button}>
        {isMobile ? (
          <Button styleType="tertiary" width="200px" onClick={resetFilters}>
            Reset filters
          </Button>
        ) : (
          <Button styleType="tertiary" width="200px" onClick={applyFilter}>
            Apply Filters
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className={styles.wrapper}>
        {!isMobile && <h1 className={styles.title}>On Demand Videos</h1>}
        <div className={styles.searching}>
          <Input
            type="search"
            width={isTablet ? '100%' : '300px'}
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            placeholder="Search"
          />
          <img src={filterIcon} onClick={() => showFiltersPopup(true)} alt="" />
        </div>
      </div>

      <PopUp
        isOpened={isShowFiltersPopup}
        setIsOpened={showFiltersPopup}
        isShowCloseButton={!isMobile}
      >
        {filtering}
      </PopUp>
    </>
  );
};

Header.propTypes = {
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  filter: PropTypes.bool.isRequired,
  setFilter: PropTypes.func.isRequired,
  filterPremiumVideo: PropTypes.func.isRequired,
  categoryList: PropTypes.array.isRequired,
};

export default Header;
