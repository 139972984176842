import { connect } from 'react-redux';
import GuestClasses from 'components/GuestClasses';
import classesActions from 'store/classes/actions';
import clubsActions from 'store/clubs/actions';

const mapStateToProps = state => {
  return {
    clubs: state.clubs.clubs,
    classes: state.classes.guestClasses,
    isRequesting: state.classes.isRequesting,
    loadClubsIsRequesting: state.clubs.loadClubsIsRequesting,
  };
};
const mapDispatchToProps = {
  loadClubs: clubsActions.Creators.loadClubsRequest,
  loadClassesSchedule: classesActions.Creators.loadGuestClassesScheduleRequest,
  changeSelectedClub: classesActions.Creators.changeSelectedClub,
};

export default connect(mapStateToProps, mapDispatchToProps)(GuestClasses);
