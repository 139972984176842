/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { myAccountDigital } from 'assets/background-images';
import styles from './styles.module.css';

const SubscriptionPlan = () => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Virtual Membership</h1>
      <h3 className={styles.subTitle}>
        Congratulations! You&#39;ve got a Virtual Membership Subscription!
      </h3>
      <p className={styles.listTitle}>This subscription includes :</p>
      <ul className={styles.list}>
        <li>- Your favorite class broadcasted </li>
        <li>- Live sessions with a trainer</li>
        <li>- Videos On Demand</li>
        <li>- Workouts Builder (Coming Soon)</li>
        <li>- Your Nutrition companion</li>
      </ul>
      <img alt="training" src={myAccountDigital} className={styles.image} />
    </div>
  );
};

export default SubscriptionPlan;
