import { connect } from 'react-redux';
import RegistrationPage from 'pages/RegistrationPage';
import Actions from 'store/signUp/actions';
import clubsActions from 'store/clubs/actions';
import billingActions from 'store/billing/actions';

const mapStateToProps = state => {
  return {
    clubs: state.clubs.clubs,
    plans: state.signUp.plans,
    planInfo: state.signUp.planInfo,
    user: state.signUp.user,
    card: state.signUp.card,
    referralPerson: state.signUp.referralPerson,
    isRequesting: state.signUp.isRequesting,
    url: state.billing?.url?.url ? state.billing.url.url : '',
  };
};
const mapDispatchToProps = {
  loadClubs: clubsActions.Creators.loadClubsRequest,
  getPlans: Actions.Creators.getPlansRequest,
  addUserDetails: Actions.Creators.addUserDetailsRequest,
  addCard: Actions.Creators.addCard,
  loadBillingUrl: billingActions.Creators.loadBillingUrlRequest,
  getPlanInfo: Actions.Creators.getPlanInfoRequest,
  createUser: Actions.Creators.createUserRequest,
  checkReferralCode: Actions.Creators.checkReferralCodeRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
