import React from 'react';
import PropTypes from 'prop-types';
import 'react-html5-camera-photo/build/css/index.css';
import Button from 'components/shared/Button';
import Field from 'components/shared/Field';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import styles from './style.module.css';

const TitleSection = ({ isAuth }) => {
  return isAuth ? (
    <>
      <p className={styles.header}>Provide Photo Proof of Your Vaccination Status</p>
      <div className={styles.dash} />
      <p className={styles.paragraph}>
        Upload or take a Photo of your vaccination card or digital vaccination record.
      </p>
    </>
  ) : (
    <>
      <p className={styles.paragraph}>Hello Member,</p>
      <p className={styles.paragraph}>
        <span className={styles.bold}>
          Please upload or take a photo of your COVID-19 proof of vaccination.
        </span>{' '}
        Acceptable proof of vaccination includes a photo or scanned copy of your
        vaccination card or other government-issued vaccination record. Please do not
        upload additional documents or personal information.
      </p>
      <p className={`${styles.bold} ${styles.paragraph}`}>
        Note: Once verified, we will delete your proof of vaccination.
      </p>
      <div className={styles.longDash} />
    </>
  );
};

const PhotoSourceSection = ({
  isAuth,
  takePhoto,
  preview,
  setPreview,
  onNext,
  onSubmit,
  photo,
  isAgree,
  setIsAgree,
  contactId,
}) => {
  return (
    <div className={`${styles.container} ${isAuth && styles.containerMarginTop}`}>
      {preview ? (
        <div className={styles.imgSection}>
          <img src={preview} className={styles.img} alt="preview" />
          <Button
            className={styles.button}
            onClick={() => {
              takePhoto('');
              setPreview('');
              setIsAgree(false);
            }}
          >
            Back
          </Button>
          <div className={styles.checkbox}>
            <Field
              type="checkbox"
              value={isAgree}
              onChange={() => setIsAgree(!isAgree)}
            />
            <div onClick={() => setIsAgree(!isAgree)}>
              By checking this box, I agree that I am the owner of this proof of
              vaccination.
            </div>
          </div>
          <Button disabled={!isAgree} className={styles.button} onClick={onSubmit}>
            SUBMIT
          </Button>
        </div>
      ) : (
        <>
          <TitleSection isAuth={isAuth} />
          <BrowserView>
            <div className={`${isAuth && styles.providePhotoContainer}`}>
              <Button
                className={`${styles.button} ${!contactId && styles.disabledButton}`}
              >
                <input
                  disabled={!contactId}
                  id="photoUpload"
                  type="file"
                  className={styles.photoInput}
                  onChange={({ target }) => {
                    setPreview(URL.createObjectURL(target.files[0]));
                    takePhoto(target.files[0]);
                  }}
                />
                <label htmlFor="photoUpload">UPLOAD PHOTO</label>
              </Button>
              <Button className={styles.button} disabled={!contactId} onClick={onNext}>
                TAKE PHOTO
              </Button>
            </div>
          </BrowserView>

          <MobileView>
            <Button className={`${styles.button} ${!contactId && styles.disabledButton}`}>
              <>
                <input
                  id="photoUpload"
                  type="file"
                  disabled={!contactId}
                  className={styles.photoInput}
                  onChange={({ target }) => {
                    setPreview(URL.createObjectURL(target.files[0]));
                    takePhoto(target.files[0]);
                  }}
                />
                <label htmlFor="photoUpload">PROVIDE PHOTO</label>
              </>
            </Button>
          </MobileView>
        </>
      )}
    </div>
  );
};

PhotoSourceSection.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  photo: PropTypes.object.isRequired,
  takePhoto: PropTypes.func.isRequired,
  preview: PropTypes.string.isRequired,
  setPreview: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isAgree: PropTypes.bool.isRequired,
  setIsAgree: PropTypes.func.isRequired,
  contactId: PropTypes.string.isRequired,
};

TitleSection.propTypes = {
  isAuth: PropTypes.bool.isRequired,
};

export default PhotoSourceSection;
