/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import history from 'store/history';
import SelectClubSection from './SelectClubSection';
import SelectPlanSection from './SelectPlanSection';
import PersonalInfo from './PersonalInfo';
import TermsConditions from './TermsConditions';
import TakePhotoSection from './TakePhotoSection';
import CongratulationsSection from './CongratulationsSection';
import SubmitScreen from './SubmitScreen';
import styles from './style.module.css';
import ReferralPopup from './ReferralPopup';

const titles = [
  'Choose your primary location',
  'Choose your plan',
  'Enter your personal details',
  'Take your photo',
  'Terms and Conditions',
  'Confirm your info',
  'Congratulation!',
];

const Registration = ({
  url,
  title,
  setTab,
  setTitle,
  clubs,
  user,
  card,
  plans,
  referralPerson,
  isRequesting,
  getPlans,
  addUserDetails,
  loadBillingUrl,
  addCard,
  createNewUser,
  getPlanInfo,
  planInfo,
  checkReferralCode,
  createUser,
}) => {
  const [section, setSection] = useState(0);

  // new member's data
  const [selectedClub, setSelectedClub] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [photo, takePhoto] = useState(user?.photo || '');
  const [isOpenReferralPopup, setOpenReferralPopup] = useState(false);

  useEffect(() => {
    if (selectedClub) getPlans(selectedClub);
  }, [selectedClub, getPlans]);

  useEffect(() => {
    if (photo) addUserDetails({ ...user, photo });
  }, [photo, addUserDetails]);

  useEffect(() => {
    if (history?.location?.search) checkReferralCode(history?.location?.search);
  }, []);
  useEffect(() => {
    const { firstName, lastName, plan = null, clubSfId = null } = referralPerson;
    if (clubSfId) setSelectedClub(clubSfId);
    if (plan?.id) {
      setSelectedPlan(+plan?.id);
      onNext(2);
      setSection(2);
    }
    if (firstName && lastName && !plan) setOpenReferralPopup(true);
  }, [referralPerson]);

  const onNext = newTitle => {
    if (newTitle) setTitle(titles[newTitle]);
    else setTitle(null);
    if (newTitle === 3) setTab(1);
    if (newTitle === 4) setTab(2);
    setSection(section + 1);
  };

  const onBack = newTitle => {
    if (newTitle || newTitle === 0) setTitle(titles[newTitle]);
    else setTitle(null);
    if (newTitle === 2) setTab(0);
    if (newTitle === 3) setTab(1);
    setSection(section - 1);
  };

  const components = [
    <SelectClubSection
      clubs={clubs}
      selectedClub={selectedClub}
      setSelectedClub={setSelectedClub}
      onNext={() => onNext(1)}
    />,
    <SelectPlanSection
      selectedClub={
        selectedClub &&
        clubs.find(({ clubAutomationClubId }) => selectedClub == clubAutomationClubId)
          ?.clubAutomationClubId
      }
      plans={plans}
      selectedPlan={selectedPlan}
      setSelectedPlan={setSelectedPlan}
      onNext={() => onNext(2)}
      onBack={() => onBack(0)}
    />,
    <PersonalInfo
      user={user}
      isRequesting={isRequesting}
      referralPerson={referralPerson}
      addUserDetails={e =>
        getPlanInfo({
          ...e,
          photo,
          club: clubs.find(
            ({ clubAutomationClubId }) => selectedClub == clubAutomationClubId
          )?.clubAutomationClubId,
          plan: selectedPlan,
        })
      }
      onNext={() => onNext(3)}
      onBack={referralPerson?.plan?.id ? null : () => onBack(1)}
    />,
    <TakePhotoSection
      photo={photo}
      takePhoto={takePhoto}
      onNext={() => onNext(4)}
      onBack={() => onBack(2)}
    />,
    <TermsConditions
      url={url}
      plan={referralPerson?.plan || plans.find(p => p.id === selectedPlan)}
      onNext={() => {
        getPlanInfo({
          ...user,
          club: clubs.find(
            ({ clubAutomationClubId }) => selectedClub == clubAutomationClubId
          )?.clubAutomationClubId,
          plan: selectedPlan,
        });
        onNext(5);
      }}
      card={card}
      userName={`${user?.firstName} ${user?.lastName}`}
      loadBillingUrl={loadBillingUrl}
      addCard={addCard}
      onBack={() => onBack(3)}
    />,
    <SubmitScreen
      user={user}
      selectedClub={
        selectedClub &&
        clubs.find(({ clubAutomationClubId }) => selectedClub === clubAutomationClubId)
      }
      selectedPlan={referralPerson?.plan || plans.find(({ id }) => selectedPlan === id)}
      card={card}
      planInfo={planInfo}
      createNewUser={createNewUser}
      onNext={() => onNext(6)}
      isRequesting={isRequesting}
      onBack={() => onBack(4)}
      createUser={createUser}
    />,
    <CongratulationsSection />,
  ];

  return (
    <div>
      <div className={styles.header}>{title}</div>
      <div className={styles.container}>{components[section]}</div>
      <ReferralPopup
        open={isOpenReferralPopup}
        onClose={() => setOpenReferralPopup(false)}
        firstName={referralPerson?.firstName}
        lastName={referralPerson?.lastName}
      />
    </div>
  );
};

Registration.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  clubs: PropTypes.array.isRequired,
  plans: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  referralPerson: PropTypes.object.isRequired,
  planInfo: PropTypes.object.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  getPlans: PropTypes.func.isRequired,
  addUserDetails: PropTypes.func.isRequired,
  loadBillingUrl: PropTypes.func.isRequired,
  addCard: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  getPlanInfo: PropTypes.func.isRequired,
  setTab: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  checkReferralCode: PropTypes.func.isRequired,
};

export default Registration;
