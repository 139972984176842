/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import history from 'store/history';
import { horizontalLogo } from 'assets/logos';
import styles from './style.module.css';

const Header = () => {
  const goToHomePage = () => {
    history.push('/');
  };

  return (
    <>
      <div className={styles.wrapper}>
        <img
          src={horizontalLogo}
          alt="logo"
          className={styles.logo}
          onClick={goToHomePage}
        />
        <div>
          <a href="/login" className={styles.bold}>
            Sign In
          </a>
        </div>
      </div>
    </>
  );
};

export default Header;
