import { connect } from 'react-redux';
import Settings from 'components/ProfilePage/Settings';
import Actions from 'store/notifications/actions';

const mapStateToProps = state => {
  return {
    pushNotifications: state.notifications.settings.pushNotifications,
    emailNotifications: state.notifications.settings.emailNotifications,
    categories: state.notifications.settings.categories,
    isRequesting: state.notifications.isRequesting,
    isSaveRequesting: state.notifications.isSaveRequesting,
  };
};

const mapDispatchToProps = {
  requestNotificationSettings: Actions.Creators.requestNotificationSettings,
  changeNotificationSettings: Actions.Creators.changeNotificationSettings,
  saveNotificationSettings: Actions.Creators.saveNotificationSettings,
  resetNotificationSettings: Actions.Creators.resetNotificationSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
