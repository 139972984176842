/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Base64 } from 'js-base64';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import styles from './style.module.css';
import { getOptions } from './service';

const ShareSection = ({ link, webSite, promoCodes }) => {
  const options = getOptions(link);
  const [links, setLinks] = useState([link]);
  const [isCopied, setCopied] = useState(false);
  const showNote = () => {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  useEffect(() => {
    if (isCopied || isCopied === 0) showNote();
  }, [isCopied]);

  useEffect(() => {
    if (promoCodes) {
      const codes = promoCodes?.split(',');
      const arr = codes.map(code => {
        const sfDateTime = getSFTime('');
        const code_base64 = Base64.encode(`plan=${code?.trim()}&date=${sfDateTime}`);
        return `${webSite}/join?code=${code_base64}`;
      });
      arr.unshift(link);
      setLinks(arr);
    }
  }, [promoCodes]);

  return (
    <div className={styles.shareSection}>
      <h1 className={styles.title}>Share your Referral Link</h1>
      <div className={styles.content}>
        {links.map((el, i) => (
          <div key={el}>
            <div className={styles.promoItemHeader}>
              {el !== link && <p>Sales promo-code*</p>}
              <p className={`${styles.copied} ${isCopied === i && styles.copiedShow}`}>
                Copied!
              </p>
            </div>

            <div className={styles.linkField}>
              <span>{el}</span>
              <p
                onClick={() => {
                  if (navigator?.clipboard) {
                    navigator.clipboard.writeText(el);
                    setCopied(+isCopied);
                  }
                }}
              >
                Copy
              </p>
            </div>
          </div>
        ))}

        <div className={styles.shareContainer}>
          {options.map(el => (
            <div onClick={el.onClick}>
              <img
                src={el.icon.url}
                alt=""
                className={`${styles.box} ${styles[`${el.title}`]}`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ShareSection.propTypes = {
  link: PropTypes.string.isRequired,
  webSite: PropTypes.string.isRequired,
  promoCodes: PropTypes.array.isRequired,
};

export default ShareSection;
