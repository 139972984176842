import React from 'react';
import PropTypes from 'prop-types';
import ReservationSlot from 'components/ReservationPage/ReservationList/ReservationSlot';
import Spinner from 'components/shared/Spinner';
import styles from './styles.module.css';

const ReservationList = ({
  reservations,
  upcomingReservations,
  handleSlotClick,
  isRequesting,
  closedClubText,
}) => {
  return (
    <div
      className={styles.wrapper}
      style={{
        marginTop: 30,
      }}
    >
      {isRequesting ? (
        <Spinner />
      ) : reservations?.length ? (
        <>
          {reservations.map(reservation => (
            <ReservationSlot
              {...reservation}
              upcomingReservations={upcomingReservations}
              key={reservation.timeSlot}
              slotClick={handleSlotClick}
            />
          ))}
        </>
      ) : (
        <div className={styles.noSpots}>{closedClubText}</div>
      )}
    </div>
  );
};

ReservationList.propTypes = {
  reservations: PropTypes.arrayOf(PropTypes.object),
  upcomingReservations: PropTypes.array.isRequired,
  openPopUp: PropTypes.func,
  handleSlotClick: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  closedClubText: PropTypes.string,
};
ReservationList.defaultProps = {
  reservations: [],
  openPopUp: null,
  closedClubText: '',
};

export default ReservationList;
