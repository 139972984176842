import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { closeCircleSvg, deleteIcon } from 'assets/icons';
import TabletContext from 'context/TabletContext';
import styles from './styles.module.css';

export const PopUp = ({
  children,
  isOpened,
  setIsOpened,
  wrapperStyles,
  backgroundColor,
  closeButtonStyles,
  isNotifications,
  isShowCloseButton,
  isFullyOpen,
}) => {
  const isTablet = useContext(TabletContext);
  useEffect(() => {
    if (isOpened) {
      // document.body.style.overflowY = 'hidden';
      document.body.style.height = '100%';
    } else {
      document.body.style.position = 'static';
      document.body.style.overflowY = 'auto';
    }
  }, [isOpened]);

  return (
    <>
      <div className={styles.container}>
        {isOpened && <div onClick={() => setIsOpened(false)} className={styles.shadow} />}
        <div
          className={`${styles.modalContainer} ${
            isOpened
              ? isFullyOpen
                ? styles.fullyOpenedModalContainer
                : styles.openedModalContainer
              : 'undefined'
          }`}
          style={wrapperStyles}
        >
          <div
            className={`${styles.wrapper} && ${isFullyOpen && styles.wrapperFullyOpen}`}
            style={{ backgroundColor }}
          >
            {isShowCloseButton && (
              <>
                {isNotifications && isTablet ? (
                  <div onClick={() => setIsOpened(false)}>
                    <img
                      className={styles.closeIcon}
                      src={isNotifications ? deleteIcon : closeCircleSvg}
                      alt="close"
                      style={closeButtonStyles}
                    />
                  </div>
                ) : isNotifications && !isTablet ? null : (
                  <div onClick={() => setIsOpened(false)}>
                    <img
                      className={styles.closeIcon}
                      src={isNotifications ? deleteIcon : closeCircleSvg}
                      alt="close"
                      style={closeButtonStyles}
                    />
                  </div>
                )}
              </>
            )}

            {children}
          </div>
        </div>
      </div>
    </>
  );
};

PopUp.propTypes = {
  children: PropTypes.element.isRequired,
  isOpened: PropTypes.bool.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  wrapperStyles: PropTypes.object,
  backgroundColor: PropTypes.string,
  closeButtonStyles: PropTypes.object,
  isNotifications: PropTypes.bool,
  isShowCloseButton: PropTypes.bool,
  isFullyOpen: PropTypes.bool,
};
PopUp.defaultProps = {
  wrapperStyles: {},
  backgroundColor: 'transparent',
  closeButtonStyles: {},
  isNotifications: false,
  isShowCloseButton: true,
  isFullyOpen: false,
};
