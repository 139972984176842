import { connect } from 'react-redux';
import MyAccount from 'components/ProfilePage/MyAccount';
import Actions from 'store/auth/actions';
import membershipActions from 'store/membership/actions';

const mapStateToProps = state => {
  return {
    clubs: state.clubs.clubs,
    user: state.auth.user,
    profile: state.auth.profile,
    isRequesting: state.auth.isRequesting,
    cancelInfo: state.membership.cancelInfo,
  };
};

const mapDispatchToProps = {
  loadProfile: Actions.Creators.loadProfileRequest,
  changeProfileInfo: Actions.Creators.changeProfileInfoRequest,
  changeFavoriteLocation: Actions.Creators.changeFavoriteLocationRequest,
  getCancelMembershipInfo: membershipActions.Creators.getCancelMembershipInfoRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
