/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'components';
import Filtration from 'components/shared/ExerciseFilter/Filtration';
import Sorting from 'components/shared/ExerciseFilter/Sorting';
import compact from 'lodash/compact';
import styles from './styles.module.css';
import { dayTimesCards, getClassCategories, goalsCards } from './services';

const ClassFilter = ({ filters, setFilters }) => {
  const tabsData = [
    { name: 'Category', index: 0 },
    { name: 'Sort by', index: 1 },
  ];

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [categories, setCategories] = useState(getClassCategories());

  const [isShowVirtualClasses, setShowVirtualClasses] = useState(false);
  const [dayTime, setDayTime] = useState(dayTimesCards);
  const [goals, setGoals] = useState(goalsCards);

  useEffect(() => {
    setFilters({
      ...filters,
      categories: compact(
        categories.map(({ title, isSelected }) => {
          if (isSelected) return title?.toLowerCase();
        })
      ),
    });
  }, [categories]);

  useEffect(() => {
    setFilters({
      ...filters,
      isShowVirtualClasses,
    });
  }, [isShowVirtualClasses]);

  useEffect(() => {
    setFilters({
      ...filters,
      dayTime: compact(
        dayTime.map(({ valueFrom, valueTo, isSelected }) => {
          if (isSelected) return { valueFrom, valueTo };
        })
      ),
    });
  }, [dayTime]);

  useEffect(() => {
    setFilters({
      ...filters,
      goals: compact(
        goals.map(({ value, isSelected }) => {
          if (isSelected) return value;
        })
      ),
    });
  }, [goals]);

  const resetFilters = () => {
    setCategories(getClassCategories());
    setShowVirtualClasses(false);
    setDayTime(dayTimesCards);
    setGoals(goalsCards);

    setFilters({
      categories: [],
      isShowVirtualClasses: false,
      dayTime: [],
      goals: [],
    });
  };

  // remove filters after destroy component
  useEffect(() => {
    return () => resetFilters();
  }, []);

  return (
    <div className={styles.container}>
      <h1>
        <p>Filters</p>
        <span onClick={resetFilters}>Reset All</span>
      </h1>
      <Tabs
        tabsData={tabsData}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
      {
        {
          0: <Filtration categories={categories} setCategories={setCategories} />,
          1: (
            <Sorting
              isShowVirtualClasses={isShowVirtualClasses}
              setShowVirtualClasses={setShowVirtualClasses}
              dayTime={dayTime}
              setDayTime={setDayTime}
              goal={goals}
              setGoal={setGoals}
            />
          ),
        }[activeTabIndex]
      }
    </div>
  );
};

ClassFilter.propTypes = {
  filters: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default ClassFilter;
