import React from 'react';
import PropTypes from 'prop-types';
// import Button from 'components/shared/Button';
import styles from './styles.module.css';

const items = [
  // 'Live, streamed classes online',
  // 'Live personal training sessions with your trainer',
  // 'Videos on demand from our library',
  'INTERACTIVE FITNESS CLASSES - Experience fitness group classes',
  'ON-DEMAND - Workout library of on-demand & replay classes that will be available anytime, anywhere.',
  'ONE-ON-ONE VIRTUAL TRAINING - Training Clients can now workout with their Personal Trainer via one-on-one videoconferencing.',
  'NUTRITION - Personalized meal recommendations and coaching to help you build lasting, healthy habits.',
];

const DiscoverNewPlan = ({ subscribe, popup }) => {
  return (
    <div className={styles.section}>
      <h1 className={`${styles.title} ${popup && styles.popupTitle}`}>
        Reactivate your membership to gain access to all of our cool features!
      </h1>
      {popup && <div className={styles.line} />}
      <h2 className={styles.subHeader}>Features include:</h2>
      <div className={styles.servicesList}>
        {items.map(el => (
          <p key={el} className={styles.service}>
            - {el}
          </p>
        ))}
      </div>
      {/* <Button onClick={subscribe}>Subscribe today!</Button> */}
      <div className={styles.linkContent}>
        <p className={styles.link}>Get in touch with us today!</p>
        <a href="mailto:info@fitnesssf.com" target="blank" className={styles.link}>
          info@fitnesssf.com
        </a>
      </div>
    </div>
  );
};

DiscoverNewPlan.propTypes = {
  subscribe: PropTypes.func,
  popup: PropTypes.bool,
};

DiscoverNewPlan.defaultProps = {
  subscribe: () => {},
  popup: false,
};

export default DiscoverNewPlan;
