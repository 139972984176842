import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadAgreementRequest: ['payload'],
  loadAgreementSuccess: [],
  loadAgreementFailure: ['error'],

  loadHistoryPDFRequest: ['payload'],
  loadHistoryPDFSuccess: [],
  loadHistoryPDFFailure: ['error'],

  clearError: [],
});

const pdfActions = {
  Types,
  Creators,
};

export default pdfActions;
