/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useContext, useState } from 'react';
import Select from 'components/shared/Select';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { download, filter, print } from 'assets/icons';
import Input from 'components/shared/Input';
import Doc from 'services/PDF/DocService';
import TabletContext from 'context/TabletContext';
import styles from './styles.module.css';

const handleClubs = clubs => {
  if (!Array.isArray(clubs)) return [];
  const activeClubs = clubs.map(club => {
    if (club.totalCapacity > 0)
      return { label: club.name, value: club.clubAutomationClubId };
    return {
      label: club.name,
      value: club.clubAutomationClubId,
      withLabelClosed: true,
    };
  });
  activeClubs.push({ label: 'All locations', value: 'all' });
  return activeClubs.sort((a, b) => (a.label > b.label ? 1 : -1));
};

export const ClassesHeader = ({
  selectedClub,
  setSelectedClub,
  clubs,
  pdfRef,
  searchValue,
  searchClasses,
  isMobile,
  lastSucceeded,
  changeSelectedClub,
  showFilters,
}) => {
  const isTablet = useContext(TabletContext);
  const [clubsOptions, setClubsOptions] = useState([]);
  const activeLocationClub = useSelector(state => state.clubs.activeLocationClub);

  useEffect(() => {
    setClubsOptions(handleClubs(clubs));
  }, [clubs]);

  const handleDownloadClick = () => {
    Doc.createPdf(pdfRef.current);
  };

  const handlePrintClick = () => {
    window.print();
  };

  useEffect(() => {
    changeSelectedClub('');
  }, [changeSelectedClub]);

  const handleClubChange = select => {
    setSelectedClub(select.value);
  };

  useEffect(() => {
    if (
      !selectedClub &&
      activeLocationClub?.length &&
      activeLocationClub[0].clubAutomationClubId
    ) {
      handleClubChange({ value: activeLocationClub[0].clubAutomationClubId });
    }
  }, [clubsOptions, activeLocationClub]);

  return (
    <>
      <div className={styles.wrapper}>
        {!isMobile && <p className={styles.title}>Classes</p>}

        <div className={styles.section}>
          <div className={styles.search}>
            <Select
              value={lastSucceeded || selectedClub}
              width="250px"
              onChange={handleClubChange}
              options={clubsOptions}
              hideIndicatorSeparator
              hideBorder
              boldValue
            />
            <div className={styles.input}>
              <Input
                type="search"
                width="250px"
                value={searchValue}
                onChange={e => searchClasses(e.target.value)}
                placeholder="Search"
              />
            </div>
            {/* <img
              src={filter}
              className={styles.filterIcon}
              onClick={showFilters}
              alt=""
            /> */}
          </div>

          {!isTablet && !isMobile && (
            <div className={styles.print}>
              <div className={styles.squareButton} onClick={() => handleDownloadClick()}>
                <img className={styles.download} src={download} alt="download" />
                <p className={styles.tooltip}>Export</p>
              </div>
              <div className={styles.squareButton} onClick={() => handlePrintClick()}>
                <img className={styles.printIcon} src={print} alt="print" />
                <p className={styles.tooltip}>Print</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

ClassesHeader.propTypes = {
  selectedClub: PropTypes.string,
  setSelectedClub: PropTypes.func.isRequired,
  clubs: PropTypes.array,
  isMobile: PropTypes.bool.isRequired,
  pdfRef: PropTypes.object.isRequired,
  searchClasses: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  lastSucceeded: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  changeSelectedClub: PropTypes.func.isRequired,
  showFilters: PropTypes.func.isRequired,
};

ClassesHeader.defaultProps = {
  clubs: [],
  searchValue: '',
  lastSucceeded: '',
  selectedClub: '',
};
