import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import actions from './actions';
import errorActions from '../error/actions';

const {
  Types: {
    LOAD_ADMIN_RESERVATIONS_REQUEST,
    REMOVE_RESERVATIONS_REQUEST,
    EDIT_RESERVATION_REQUEST,
    ADD_RESERVATION_REQUEST,
    SEARCH_USER_REQUEST,
  },
  Creators: {
    loadAdminReservationsSuccess,
    loadAdminReservationsFailure,

    removeReservationsSuccess,
    removeReservationsFailure,

    editReservationSuccess,
    editReservationFailure,

    addReservationSuccess,
    addReservationFailure,

    searchUserSuccess,
    searchUserFailure,
  },
} = actions;

const {
  Creators: { setError },
} = errorActions;

function* loadAdminReservations({ club }) {
  try {
    const { data } = yield call(api.get, `/admin/reservations?club=${club}`);
    yield put(loadAdminReservationsSuccess(data));
  } catch (error) {
    yield put(loadAdminReservationsFailure(error));
    yield put(setError('Failed to load reservations.'));
  }
}

function* removeReservations({ reservationsId }) {
  try {
    const { data } = yield call(api.remove, `/admin/reservations?ids=${reservationsId}`);
    yield put(removeReservationsSuccess(data));
  } catch (error) {
    yield put(removeReservationsFailure(error));
    yield put(setError('Failed to remove reservations.'));
  }
}

function* editReservation({ reservation }) {
  try {
    const { data } = yield call(api.put, `/admin/reservations`, reservation);
    yield put(editReservationSuccess(data));
  } catch (error) {
    yield put(editReservationFailure(error));
    yield put(setError('Failed to edit reservation.'));
  }
}

function* addReservation({ reservation }) {
  try {
    const { data } = yield call(api.post, `/admin/reservations`, reservation);
    if (data.error) {
      yield put(addReservationFailure(data.error));
      yield put(setError(data.error));
    } else yield put(addReservationSuccess(data));
  } catch (error) {
    yield put(addReservationFailure(error));
    yield put(setError('Failed to add reservation.'));
  }
}

function* searchUser({ params }) {
  try {
    const { data } = yield call(api.get, `/admin/users?params=${params}`);
    yield put(searchUserSuccess(data));
  } catch (error) {
    yield put(searchUserFailure(error));
    yield put(setError('Failed to load user detail.'));
  }
}

export function* adminSaga() {
  yield takeLatest(LOAD_ADMIN_RESERVATIONS_REQUEST, loadAdminReservations);
  yield takeLatest(REMOVE_RESERVATIONS_REQUEST, removeReservations);
  yield takeLatest(EDIT_RESERVATION_REQUEST, editReservation);
  yield takeLatest(ADD_RESERVATION_REQUEST, addReservation);
  yield takeLatest(SEARCH_USER_REQUEST, searchUser);
}
