import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatPrice } from '../service';
import styles from './styles.module.css';

const TableTransaction = ({ data }) => {
  return (
    <div className={styles.transaction}>
      {data.map(({ description, date, price, name }) => (
        <div key={name} className={styles.card}>
          <div className={styles.row}>
            <p className={styles.bold}>{description}</p>
            <p>{moment(date).format('MM/DD/YYYY')}</p>
            <p className={styles.price}>{formatPrice(price.toString())}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
TableTransaction.propTypes = {
  data: PropTypes.object.isRequired,
};
export default TableTransaction;
