import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/shared/Spinner';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';

const eatloveUrl = process.env.REACT_APP_EATLOVE_URL;

const HealthPage = ({ token, isRequesting, loadSsoToken }) => {
  useEffect(() => {
    loadSsoToken();
  }, [loadSsoToken]);

  return (
    <HeaderFooterLayout>
      {!token ? (
        <Spinner />
      ) : (
        <div
          className="eatlove-scroll-wrapper"
          style={{ height: document.documentElement.clientHeight - 50 }}
        >
          <iframe
            id="eatLoveFrame"
            src={`https://${eatloveUrl}/embed?ftsfauthn=${token}`}
            width="100%"
            height={document.documentElement.clientHeight - 50}
            title="iframe"
          />
        </div>
      )}
    </HeaderFooterLayout>
  );
};

HealthPage.propTypes = {
  token: PropTypes.string,
  isRequesting: PropTypes.bool.isRequired,
  loadSsoToken: PropTypes.func.isRequired,
};

HealthPage.defaultProps = {
  token: null,
};

export default HealthPage;
