import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadPremiumVideosRequest: ['section'],
  loadPremiumVideosSuccess: ['videos'],
  loadPremiumVideosFailure: ['error'],

  loadPopularVideosRequest: [],
  loadPopularVideosSuccess: ['videos'],
  loadPopularVideosFailure: ['error'],

  loadFavoriteVideosRequest: [],
  loadFavoriteVideosSuccess: ['favoriteVideos'],
  loadFavoriteVideosFailure: ['error'],

  addFavoriteVideosRequest: ['videoId'],
  addFavoriteVideosSuccess: ['video'],
  addFavoriteVideosFailure: ['error'],

  removeFavoriteVideosRequest: ['videoId'],
  removeFavoriteVideosSuccess: ['videoId'],
  removeFavoriteVideosFailure: ['error'],

  searchPremiumVideo: ['searchValue'],
  filterPremiumVideo: ['category'],
});

const premiumVideosActions = {
  Types,
  Creators,
};

export default premiumVideosActions;
