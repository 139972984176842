/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { arrowLeftBlack, arrowRight, calendarIcon, closeIcon } from 'assets/icons';
import moment from 'moment';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import styles from './styles.module.css';
import Button from '../Button';

const MonthDatePicker = ({
  initialDate,
  search,
  searchButtonLabel,
  isStatement,
  secondaryAction,
}) => {
  const sfDate = getSFTime();
  const [date, setDate] = useState(initialDate);
  const [year, setYear] = useState(moment(initialDate, 'YYYY-MM-DD').format('YYYY'));
  const [open, setOpen] = useState(false);
  const options = moment.months();

  useEffect(() => {
    if (open) setYear(+moment(date, 'YYYY-MM-DD').format('YYYY'));
    else setDate(initialDate);
  }, [open]);

  return (
    <div className={styles.container}>
      <div className={styles.input} onClick={() => setOpen(!open)}>
        <p>{moment(initialDate, 'YYYY-MM-DD').format('MMM YYYY')}</p>
        <img src={calendarIcon} alt="" />
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper elevation={0} className={styles.paper}>
            <div>
              <div className={styles.header}>
                <div className={styles.headerAction}>
                  <img src={arrowLeftBlack} alt="" onClick={() => setYear(year - 1)} />
                  <p>{year}</p>
                  <img
                    src={arrowRight}
                    alt=""
                    onClick={() => setYear(year + 1)}
                    style={
                      +year === +moment(sfDate).format('YYYY')
                        ? { visibility: 'hidden' }
                        : {}
                    }
                  />
                </div>
                <img
                  className={styles.closeIcon}
                  src={closeIcon}
                  alt=""
                  onClick={() => setOpen(false)}
                />
              </div>
              <div className={styles.content}>
                {+year !== +moment(sfDate).format('YYYY')
                  ? options.map((el, i) => (
                      <div
                        key={`${el}-${year}`}
                        className={`${styles.month} ${+moment(date).format('YYYY') ===
                          +year &&
                          +moment(date).format('M') === i + 1 &&
                          styles.activeMonth}`}
                        onClick={() => setDate(`${year}-${i + 1}-01`)}
                      >
                        {el}
                      </div>
                    ))
                  : options.map((el, i) => (
                      <div
                        key={`${el}-${year}`}
                        className={`${
                          moment(sfDate).isBefore(`${year}-${i + 1}-01`)
                            ? styles.disabledMonth
                            : styles.month
                        }  ${+moment(date).format('YYYY') === +year &&
                          +moment(date).format('M') === i + 1 &&
                          styles.activeMonth}`}
                        onClick={() => {
                          if (!moment(sfDate).isBefore(`${year}-${i + 1}-01`)) {
                            setDate(`${year}-${i + 1}-01`);
                          }
                        }}
                      >
                        {el}
                      </div>
                    ))}
              </div>
              <div className={styles.button}>
                {search && (
                  <Button
                    onClick={() => {
                      search(date);
                      setOpen(false);
                    }}
                    className={styles.buttonEl}
                  >
                    {searchButtonLabel}
                  </Button>
                )}
                {isStatement && (
                  <Button
                    styleType="tertiary"
                    onClick={() => {
                      secondaryAction(date);
                      setOpen(false);
                    }}
                    className={styles.statementButton}
                  >
                    Email Statement
                  </Button>
                )}
              </div>
            </div>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
};

MonthDatePicker.propTypes = {
  initialDate: PropTypes.string.isRequired,
  search: PropTypes.func.isRequired,
  isStatement: PropTypes.bool,
  secondaryAction: PropTypes.func,
  searchButtonLabel: PropTypes.string,
};
MonthDatePicker.defaultProps = {
  isStatement: false,
  searchButtonLabel: 'Search',
  secondaryAction: () => {},
};
export default MonthDatePicker;
