import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import MainContent from 'components/ReservationPage/MainContent';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import TabletContext from 'context/TabletContext';

const handleClubs = clubs => {
  if (!Array.isArray(clubs)) return [];
  return clubs.map(club => ({ label: club.name, value: club.sfId }));
};

const ReservationPage = ({
  clubs,
  reservations,
  upcomingReservations,
  isRequesting,
  initLoadReservations,
  loadReservations,
  userFavoriteLocation,
  saveReservations,
  deleteReservation,
  isSaveRequesting,
  isDeleteRequesting,
  isReservationAvailable,
  modifyReservations,
  isModifyRequesting,
  isReservationBanned,
  dateOfFinishBan,
  missedCount,
  reservationUpcomingDaySlotList,
  rooms,
  loadRooms,
  targetClubIsRequesting,
  selectedClub,
  selectClub,
  checkMemberStatus,
  loadMembership,
}) => {
  const isTablet = useContext(TabletContext);
  const mainProps = {
    clubs: handleClubs(clubs),
    reservations,
    isRequesting,
    upcomingReservations,
    initLoadReservations,
    loadReservations,
    userFavoriteLocation,
    saveReservations,
    deleteReservation,
    isSaveRequesting,
    isDeleteRequesting,
    isTablet,
    isReservationAvailable,
    modifyReservations,
    isModifyRequesting,
    isReservationBanned,
    dateOfFinishBan,
    missedCount,
    reservationUpcomingDaySlotList,
    rooms,
    loadRooms,
    targetClubIsRequesting,
    selectedClub,
    selectClub,
    checkMemberStatus,
  };
  useEffect(() => {
    loadMembership();
  }, []);
  return (
    <HeaderFooterLayout>
      <MainContent {...mainProps} />
    </HeaderFooterLayout>
  );
};
ReservationPage.propTypes = {
  clubs: PropTypes.oneOfType([PropTypes.array, PropTypes.arrayOf(PropTypes.object)])
    .isRequired,
  reservations: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  upcomingReservations: PropTypes.array.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  targetClubIsRequesting: PropTypes.bool.isRequired,
  initLoadReservations: PropTypes.func.isRequired,
  loadReservations: PropTypes.func.isRequired,
  userFavoriteLocation: PropTypes.string.isRequired,
  saveReservations: PropTypes.func.isRequired,
  deleteReservation: PropTypes.func.isRequired,
  isSaveRequesting: PropTypes.bool.isRequired,
  isDeleteRequesting: PropTypes.bool.isRequired,
  isReservationAvailable: PropTypes.bool.isRequired,
  modifyReservations: PropTypes.func.isRequired,
  isModifyRequesting: PropTypes.bool.isRequired,
  isReservationBanned: PropTypes.bool.isRequired,
  dateOfFinishBan: PropTypes.string,
  missedCount: PropTypes.number,
  reservationUpcomingDaySlotList: PropTypes.array.isRequired,
  rooms: PropTypes.array.isRequired,
  loadRooms: PropTypes.func.isRequired,
  selectedClub: PropTypes.string,
  selectClub: PropTypes.func.isRequired,
  checkMemberStatus: PropTypes.func.isRequired,
  loadMembership: PropTypes.func.isRequired,
};

ReservationPage.defaultProps = {
  dateOfFinishBan: '',
  missedCount: 0,
  selectedClub: null,
};

export default ReservationPage;
