import { connect } from 'react-redux';
import LocationsPage from 'pages/LocationsPage';
import Actions from 'store/clubs/actions';
import authActions from 'store/auth/actions';
import classesActions from 'store/classes/actions';

const mapStateToProps = state => {
  return {
    clubs: state.clubs.clubs,
    loadClubsIsRequesting: state.clubs.loadClubsIsRequesting,
    targetClubIsRequesting: state.clubs.targetClubIsRequesting,
    targetClub: state.clubs.targetClub,
    favoriteClub: state.auth.user.favoriteLocationCAId,
  };
};
const mapDispatchToProps = {
  loadClubs: Actions.Creators.loadClubsRequest,
  selectTargetClub: Actions.Creators.selectTargetClub,
  loadClub: Actions.Creators.loadClubRequest,
  changeFavoriteLocation: authActions.Creators.changeFavoriteLocationRequest,
  changeClassesSelectedClub: classesActions.Creators.changeSelectedClub,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsPage);
