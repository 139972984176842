import React from 'react';
import TrainerCard from 'components/TrainingPage/TrainerCard';
import TrainersSection from 'components/TrainingPage/TrainersSection';
import NoTrainers from 'components/TrainingPage/NoTrainers';
import PropTypes from 'prop-types';
import { findClubName, findTrainersByClubId, findOtherClubs } from '../../helpers';
import styles from '../styles.module.css';

const RenderFavoriteClubs = ({
  trainers,
  favoriteClubId,
  locations,
  setIsOpened,
  selectTrainer,
  setTrainerId,
}) => {
  const favoriteLocationTrainers = findTrainersByClubId(favoriteClubId, trainers);
  const favoriteClubName = findClubName(favoriteClubId, locations);
  const otherClubsTrainers = findOtherClubs(favoriteClubId, trainers, locations);
  return (
    <>
      <TrainersSection>
        {favoriteLocationTrainers?.length ? (
          <div className={styles.inner}>
            {favoriteLocationTrainers?.length
              ? favoriteLocationTrainers.map(trainer => {
                  return (
                    <div key={trainer.idCA} className={styles.cardWrapper}>
                      <TrainerCard
                        {...trainer}
                        setIsOpened={setIsOpened}
                        selectTrainer={selectTrainer}
                        setTrainerId={setTrainerId}
                      />
                    </div>
                  );
                })
              : null}
          </div>
        ) : (
          <NoTrainers />
        )}
      </TrainersSection>
      {/* {otherClubsTrainers?.length
        ? otherClubsTrainers.map(club => (
            <div key={club.location}>
              {club?.trainers?.length ? (
                <TrainersSection title={club.location}>
                  <div className={styles.inner}>
                    {club?.trainers?.length
                      ? club.trainers.map(trainer => {
                          return (
                            <div key={trainer.trainerId} className={styles.cardWrapper}>
                              <TrainerCard
                                {...trainer}
                                setIsOpened={setIsOpened}
                                selectTrainer={selectTrainer}
                                setTrainerId={setTrainerId}
                              />
                            </div>
                          );
                        })
                      : null}
                  </div>
                </TrainersSection>
              ) : null}
            </div>
          ))
        : null} */}
    </>
  );
};

RenderFavoriteClubs.propTypes = {
  trainers: PropTypes.arrayOf(PropTypes.object),
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  favoriteClubId: PropTypes.string.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  selectTrainer: PropTypes.func.isRequired,
  setTrainerId: PropTypes.func.isRequired,
};

RenderFavoriteClubs.defaultProps = {
  trainers: [],
};

export default RenderFavoriteClubs;
