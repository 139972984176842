import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import VideoCard from 'components/VideosOnDemand/VideoCard';
import { arrowLeftBlack } from 'assets/icons';
import MobileContext from 'context/MobileContext';
import styles from './style.module.css';

const Content = ({ setActiveVideo, showContent, title, videoList }) => {
  const isMobile = useContext(MobileContext);
  return (
    <div className={styles.videosWrapper}>
      <>
        {videoList?.length > 0 ? (
          <>
            <div className={styles.breadcrumb}>
              <img src={arrowLeftBlack} alt="arrow" className={styles.arrow} />
              <NavLink to="/on_demand">{isMobile ? title : 'Back'}</NavLink>
            </div>
            {!isMobile && (
              <div className={styles.sectionHeader}>
                <h1 className={styles.title}>{title}</h1>
              </div>
            )}
            <div className={styles.videosContainer}>
              {videoList.map(video => (
                <VideoCard
                  key={video.id}
                  video={video}
                  onWatch={setActiveVideo}
                  available={showContent}
                />
              ))}
            </div>
          </>
        ) : (
          <p className={styles.emptySection}>There are no such videos</p>
        )}
      </>
    </div>
  );
};

Content.propTypes = {
  videoList: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  setActiveVideo: PropTypes.func.isRequired,
  showContent: PropTypes.bool.isRequired,
};

export default Content;
