import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import Spinner from 'components/shared/Spinner';
import MainContent from 'components/LocationsPage/MainContent/index';
import loadGoogleMapApi from 'services/loadGoogleApi';

const LocationsPage = ({
  loadClubs,
  clubs,
  loadClubsIsRequesting,
  selectTargetClub,
  changeFavoriteLocation,
  changeClassesSelectedClub,
  targetClubIsRequesting,
  targetClub,
  loadClub,
  favoriteClub,
}) => {
  const [isGoogleMapApiLoaded, setGoogleMapApiLoaded] = useState(false);

  useEffect(() => {
    loadClubs();
  }, [loadClubs]);

  useEffect(() => {
    loadGoogleMapApi(() => setGoogleMapApiLoaded(true));
  }, []);

  const mainProps = {
    clubs,
    selectTargetClub,
    changeFavoriteLocation,
    changeClassesSelectedClub,
    favoriteClub,
    targetClub,
    targetClubIsRequesting,
    loadClub,
  };

  return (
    <HeaderFooterLayout>
      {isGoogleMapApiLoaded && !loadClubsIsRequesting ? (
        <MainContent {...mainProps} />
      ) : (
        <Spinner />
      )}
    </HeaderFooterLayout>
  );
};

LocationsPage.propTypes = {
  loadClubs: PropTypes.func.isRequired,
  clubs: PropTypes.arrayOf(PropTypes.object),
  loadClubsIsRequesting: PropTypes.bool.isRequired,
  selectTargetClub: PropTypes.func.isRequired,
  changeFavoriteLocation: PropTypes.func.isRequired,
  changeClassesSelectedClub: PropTypes.func.isRequired,
  targetClubIsRequesting: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
    .isRequired,
  loadClub: PropTypes.func.isRequired,
  targetClub: PropTypes.object.isRequired,
  favoriteClub: PropTypes.string.isRequired,
};
LocationsPage.defaultProps = {
  clubs: [],
};
export default LocationsPage;
