import { createReducer } from 'reduxsauce';
import actions from './actions';
import {
  searchVideo,
  formattedVideo,
  addFavoriteVideo,
  removeFavoriteVideo,
  setCategory,
} from './service';

const { Types } = actions;
const INITIAL_STATE = {
  videos: [],
  categories: [],
  popularVideos: [],
  favoriteVideos: [],
  searchVideos: [],
  isRequesting: false,
  error: null,
};

const request = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
  };
};

const loadPremiumVideosRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    categories: [],
    videos: [],
  };
};

const loadPopularVideosSuccess = (state = INITIAL_STATE, { videos }) => {
  return {
    ...state,
    isRequesting: false,
    popularVideos: videos,
  };
};

const loadPremiumVideosSuccess = (state = INITIAL_STATE, { videos }) => {
  return {
    ...state,
    isRequesting: false,
    categories: setCategory(videos),
    videos,
  };
};

const loadFavoriteVideosSuccess = (state = INITIAL_STATE, { favoriteVideos }) => {
  return {
    ...state,
    isRequesting: false,
    favoriteVideos,
  };
};

const addFavoriteVideosSuccess = (state = INITIAL_STATE, { video }) => {
  const { favoriteVideos, videos } = addFavoriteVideo(
    state.favoriteVideos,
    state.videos,
    video
  );
  return {
    ...state,
    isRequesting: false,
    favoriteVideos,
    videos,
  };
};

const removeFavoriteVideosSuccess = (state = INITIAL_STATE, { videoId }) => {
  const { favoriteVideos, videos } = removeFavoriteVideo(
    state.favoriteVideos,
    state.videos,
    videoId
  );
  return {
    ...state,
    isRequesting: false,
    favoriteVideos,
    videos,
  };
};

const searchPremiumVideo = (state = INITIAL_STATE, { searchValue }) => {
  const newVideosList = searchVideo(state.videos, searchValue);
  return {
    ...state,
    searchVideos: newVideosList,
  };
};

const filterPremiumVideo = (state = INITIAL_STATE, { category }) => {
  const newVideosList = formattedVideo(state.videos, category);
  return {
    ...state,
    searchVideos: newVideosList,
  };
};

const failure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    error,
  };
};

export const HANDLERS = {
  [Types.LOAD_PREMIUM_VIDEOS_REQUEST]: request,
  [Types.LOAD_FAVORITE_VIDEOS_REQUEST]: request,

  [Types.LOAD_PREMIUM_VIDEOS_REQUEST]: loadPremiumVideosRequest,
  [Types.LOAD_PREMIUM_VIDEOS_SUCCESS]: loadPremiumVideosSuccess,
  [Types.LOAD_POPULAR_VIDEOS_SUCCESS]: loadPopularVideosSuccess,
  [Types.LOAD_FAVORITE_VIDEOS_SUCCESS]: loadFavoriteVideosSuccess,
  [Types.ADD_FAVORITE_VIDEOS_SUCCESS]: addFavoriteVideosSuccess,
  [Types.REMOVE_FAVORITE_VIDEOS_SUCCESS]: removeFavoriteVideosSuccess,

  [Types.SEARCH_PREMIUM_VIDEO]: searchPremiumVideo,
  [Types.FILTER_PREMIUM_VIDEO]: filterPremiumVideo,

  [Types.LOAD_PREMIUM_VIDEOS_FAILURE]: failure,
  [Types.LOAD_FAVORITE_VIDEOS_FAILURE]: failure,
  [Types.ADD_FAVORITE_VIDEOS_FAILURE]: failure,
  [Types.REMOVE_FAVORITE_VIDEOS_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
