import { connect } from 'react-redux';
import AdminPage from 'pages/AdminPage';
import Actions from 'store/admin/actions';
import ClassActions from 'store/clubs/actions';

const mapStateToProps = state => {
  return {
    clubs: state.clubs.clubs,
    reservations: state.admin.reservations,
    searchReservations: state.admin.searchReservations,
    user: state.admin.user,
    isRequesting: state.admin.isRequesting,
  };
};
const mapDispatchToProps = {
  loadClubs: ClassActions.Creators.loadClubsRequest,
  loadReservations: Actions.Creators.loadAdminReservationsRequest,
  removeReservations: Actions.Creators.removeReservationsRequest,
  updateReservation: Actions.Creators.editReservationRequest,
  addReservation: Actions.Creators.addReservationRequest,
  searchUser: Actions.Creators.searchUserRequest,
  searchUserReservations: Actions.Creators.searchUserReservations,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
