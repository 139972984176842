import React from 'react';
import Button from 'components/shared/Button';
import styles from './style.module.css';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import { useContext } from 'react';
import { arrowLeft } from 'assets/icons';
const BackButton = ({ onBack, backButtonDisable, backButtonName }) => {
  const isMobile = useContext(MobileContext);
  return (
    <>
      <div className={`${styles.wrapper} ${isMobile ? styles.mob : null}`}>
        <div className={styles.innerWrapper}>
          <Button
            disabled={backButtonDisable}
            className={`${styles.btn} ${isMobile ? styles.mob : ''}`}
            onClick={onBack}
          >
            {!isMobile ? (
              <>
                <img className={styles.arrowIcon} src={arrowLeft} />
                {backButtonName}
              </>
            ) : (
              <> {backButtonName}</>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

BackButton.propTypes = {
  onBack: PropTypes.func.isRequired,
  backButtonName: PropTypes.string,
};
export default BackButton;
