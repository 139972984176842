import React, { useEffect, useState, useContext } from 'react';
import MobileContext from 'context/MobileContext';
import PropTypes from 'prop-types';
import Select from 'components/shared/Select';
// import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  reservationBannerDesktop,
  reservationBannerMobile,
} from 'assets/background-images';
// import Button from 'components/shared/Button';
import DigitalReservationPopup from 'components/Header/DigitalReservationPopup';
// import { checkDigitalPlan } from 'services/checkDigitalPlan';
import Chart from './Chart';
import styles from './style.module.css';

const handleClubs = clubs => {
  if (!Array.isArray(clubs)) return [];
  const activeClubs = clubs.map(club => {
    if (club.totalCapacity > 0) return { label: club.name, value: club.sfId };
    return {
      label: club.name,
      value: club.sfId,
      isDisabled: true,
    };
  });
  return activeClubs;
};

const ClubActivity = ({ membership, loadStatistic, clubsStatistic, selectClub }) => {
  const clubs = useSelector(state => state.clubs.clubs);
  const activeLocationClub = useSelector(state => state.clubs.activeLocationClub);
  const favoriteLocationClubSfId = useSelector(
    state => state.auth.user.favoriteLocationCAId
  );
  // const upcomingReservations = useSelector(
  //  state => state.reservations.upcomingReservations
  // );
  const [selectedLocation, setSelectedLocationLocation] = useState(null);
  const [clubsOptions, setClubsOptions] = useState([]);
  const [openDigitalReservationPopup, setOpenDigitalReservationPopup] = useState(false);

  const handleChangeLocation = value => {
    setSelectedLocationLocation(value);
    loadStatistic(value);
  };
  // const isExistDigitalPlan = checkDigitalPlan(membership);
  useEffect(() => {
    setClubsOptions(handleClubs(clubs));
  }, [clubs]);

  useEffect(() => {
    const isActiveFavoriteClub =
      activeLocationClub?.length > 0 &&
      activeLocationClub.find(c => c.sfId === favoriteLocationClubSfId);

    if (isActiveFavoriteClub) setSelectedLocationLocation(favoriteLocationClubSfId);
    else if (activeLocationClub?.length && activeLocationClub[0].sfId) {
      setSelectedLocationLocation(activeLocationClub[0].sfId);
      loadStatistic(activeLocationClub[0].sfId);
    }
  }, [activeLocationClub, loadStatistic]);

  const isMobile = useContext(MobileContext);
  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundImage: isMobile
          ? `url(${reservationBannerMobile})`
          : `url(${reservationBannerDesktop})`,
      }}
    >
      <h3 className={styles.title}>Location Activity</h3>
      <Select
        hideIndicatorSeparator
        hideBorder
        value={selectedLocation}
        options={clubsOptions}
        width={isMobile ? '280px' : '300px'}
        onChange={e => {
          handleChangeLocation(e.value);
          selectClub(e.value);
        }}
      />
      <p className={styles.description}>Typical usage at the selected location :</p>

      <Chart clubsStatistic={clubsStatistic} />
      {/* {!isExistDigitalPlan ? (
        <>
          {upcomingReservations?.length ? (
            <>
              <Button disabled className={styles.button}>
                Reserve a Spot
              </Button>
            </>
          ) : (
            <NavLink to="/reservation">
              <Button className={styles.button} width={250}>
                Reserve a Spot
              </Button>
            </NavLink>
          )}
        </>
      ) : (
        <Button
          className={styles.button}
          onClick={() => setOpenDigitalReservationPopup(true)}
        >
          Reserve a Spot
        </Button>
      )} */}
      <DigitalReservationPopup
        open={openDigitalReservationPopup}
        handleClose={() => setOpenDigitalReservationPopup(!openDigitalReservationPopup)}
      />
    </div>
  );
};

ClubActivity.propTypes = {
  membership: PropTypes.array.isRequired,
  loadStatistic: PropTypes.func.isRequired,
  selectClub: PropTypes.func.isRequired,
  clubsStatistic: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};
export default ClubActivity;
