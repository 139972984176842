/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Table from 'components/shared/Table';
import styles from './styles.module.css';

const columns = [
  {
    Header: 'Date',
    accessor: 'time',
    Cell: ({ cell: { value } }) => {
      return <>{moment(value).format('MM/DD/YYYY')}</>;
    },
    id: 1,
  },
  {
    Header: 'Location',
    accessor: 'location',
    Cell: ({ cell: { value } }) => {
      return (
        <>
          <span className={styles.location}>{value}</span>
        </>
      );
    },
    id: 2,
  },
  {
    Header: 'Time',
    accessor: 'time',
    Cell: ({ cell: { value } }) => {
      return (
        <>
          <span className={styles.time}>{moment(value).format('h:mm a')}</span>
        </>
      );
    },
    id: 3,
  },
];

function TableHistoryDesktop({ data }) {
  return (
    <div className={styles.history}>
      <Table data={data} columns={columns} />
    </div>
  );
}
TableHistoryDesktop.propTypes = {
  data: PropTypes.array,
};

TableHistoryDesktop.defaultProps = {
  data: [],
};

export default TableHistoryDesktop;
