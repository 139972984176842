import { connect } from 'react-redux';
import SessionModal from 'components/shared/SessionCallPopUp/ModalWrapper';
import callActions from 'store/sessionCall/actions';

const mapStateToProps = state => {
  return {
    isCallAllow: state.sessionCall.allow,
    isRequesting: state.sessionCall.isRequesting,
    friendId: state.sessionCall.friendId,
    friendSocketId: state.sessionCall.friendSocketId,
    hasIncomingCall: state.sessionCall.hasIncomingCall,
    callId: state.sessionCall.callId,
    username: state.sessionCall.username,
    credential: state.sessionCall.credential,
  };
};
const mapDispatchToProps = {
  handleOpenCallPopUp: callActions.Creators.handleOpenCallPopUp,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionModal);
