import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  data: [],
  error: null,
  selectedClub: null,
};

const loadClubsStatisticRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const loadClubsStatisticSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    isRequesting: false,
    data,
  };
};
const loadClubsStatisticFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    error,
  };
};

const selectClub = (state = INITIAL_STATE, { clubId }) => {
  return {
    ...state,
    selectedClub: clubId,
  };
};

export const HANDLERS = {
  [Types.LOAD_CLUBS_STATISTIC_REQUEST]: loadClubsStatisticRequest,
  [Types.LOAD_CLUBS_STATISTIC_SUCCESS]: loadClubsStatisticSuccess,
  [Types.LOAD_CLUBS_STATISTIC_FAILURE]: loadClubsStatisticFailure,

  [Types.SELECT_CLUB]: selectClub,
};

export default createReducer(INITIAL_STATE, HANDLERS);
