import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadHomeRequest: [],

  loadHomeFavoriteClassesRequest: null,
  loadHomeFavoriteClassesSuccess: ['favoriteClasses'],
  loadHomeFavoriteClassesFailure: ['error'],

  loadHomeLocationClassesRequest: null,
  loadHomeLocationClassesSuccess: ['favoriteLocationClasses'],
  loadHomeLocationClassesFailure: ['error'],

  addClassToFavoriteHomepageRequest: ['classId'],
  addClassToFavoriteHomepageSuccess: ['classId'],
  addClassToFavoriteHomepageFailure: ['classId', 'error'],

  removeClassFromFavoriteHomepageRequest: ['classId'],
  removeClassFromFavoriteHomepageSuccess: ['classId'],
  removeClassFromFavoriteHomepageFailure: ['classId', 'error'],

  loadSessionsRequest: null,
  loadSessionsSuccess: ['sessions', 'isSessionQRAvailable'],
  loadSessionsFailure: ['error'],

  setTargetSession: ['targetSession'],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
