/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import inputStyles from 'components/shared/Input/styles.module.css';
import styles from './style.module.css';

const MaskInput = ({
  value,
  name,
  label,
  invalid,
  id='',
  className,
  width,
  error,
  inputClassName,
  rightIcon,
  boxShadow,
  fontFamily,
  ...props
}) => {
  const labelRef = useRef(null);
  const [isHideLabel, setHideLabel] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (labelRef.current && !labelRef.current.contains(event.target))
        setHideLabel(false);
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [labelRef, isHideLabel]);

  return (
    <div className={`${styles.field} ${className}`}>
      <InputMask
        type="tel"
        name={name}
        id={id}
        className={`${inputStyles.input} ${invalid && inputStyles.invalid} ${
          styles.inputMask
        } ${invalid && styles.invalid} ${inputClassName}`}
        invalid={invalid}
        error={error}
        value={value}
        hasvalue={value?.length > 0 ? 'true' : 'false'}
        style={{ width, boxShadow: boxShadow, fontFamily: fontFamily }}
        {...props}
      />
      <label
        className={`${inputStyles.formLabel} ${styles.label} ${isHideLabel &&
          styles.hideLabel}`}
        onClick={() => setHideLabel(true)}
        ref={labelRef}
        htmlFor={name}
      >
        {label}
      </label>
      {rightIcon && <img src={rightIcon} className={inputStyles.rightIcon} alt="" />}

      <p className={`${styles.error}`} style={{ width }}>
        {invalid && error && error}
      </p>
    </div>
  );
};

MaskInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  width: PropTypes.string,
  rightIcon: PropTypes.string,
  invalid: PropTypes.bool,
  error: PropTypes.string,
  boxShadow: PropTypes.string,
  fontFamily: PropTypes.string,
};

MaskInput.defaultProps = {
  value: '',
  name: '',
  label: '',
  rightIcon: '',
  className: '',
  inputClassName: '',
  width: '',
  invalid: false,
  error: '',
  boxShadow: '',
  fontFamily: '',
};

export default MaskInput;
