import { select, call, put, takeLatest, delay, takeEvery } from 'redux-saga/effects';
import uniqBy from 'lodash/uniqBy';
import api from 'services/api';
import cloneDeep from 'lodash/cloneDeep';
import notificationActions from './actions';
import errorActions from '../error/actions';

const {
  Types: {
    REQUEST_NOTIFICATIONS,
    RECEIVE_NOTIFICATIONS,
    MARK_AS_READ_ALL_NOTIFICATIONS,
    REQUEST_NOTIFICATION_SETTINGS,
    CHANGE_NOTIFICATION_SETTINGS,
    SAVE_NOTIFICATION_SETTINGS,
  },
  Creators: {
    requestNotificationsSuccess,
    requestNotificationsFailure,
    receiveNotificationsSuccess,
    receiveNotificationsFailure,
    markAsReadAllNotificationsSuccess,
    markAsReadAllNotificationsFailure,
    requestNotificationSettingsSuccess,
    requestNotificationSettingsError,
    updateNotificationSettings,
    saveNotificationSettingsSuccess,
    saveNotificationSettingsError,
  },
} = notificationActions;

const {
  Creators: { setError },
} = errorActions;

const getNotifications = state => state.notifications.data;
const getNotificationSettings = state => state.notifications.settings;

function* requestNotifications() {
  try {
    const { data } = yield call(api.get, '/notifications');
    yield put(requestNotificationsSuccess(data));
  } catch (err) {
    yield put(requestNotificationsFailure(err));
  }
}
function* receiveNotificationSaga({ data }) {
  try {
    const currentNotifications = yield select(getNotifications);
    const notReadCurrentNotifications = currentNotifications.filter(
      item => !item.isRead
    )[0].data;
    const uniqueNotifications = yield uniqBy(
      [...notReadCurrentNotifications, ...data],
      'id'
    ).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    yield put(receiveNotificationsSuccess(uniqueNotifications));
  } catch (err) {
    yield put(receiveNotificationsFailure(err));
    yield put(setError('Load failed.'));
  }
}

function* markAsReadAllNotificationSaga({ notificationIds }) {
  try {
    yield delay(3000);
    const { data } = yield call(api.put, `notifications/${notificationIds}`);
    yield put(markAsReadAllNotificationsSuccess(data));
  } catch (err) {
    yield put(markAsReadAllNotificationsFailure(err));
  }
}

function* requestNotificationSettingsSaga() {
  try {
    const { data } = yield call(api.get, '/notifications/settings');
    yield put(requestNotificationSettingsSuccess(data));
  } catch (err) {
    yield put(requestNotificationSettingsError(err));
    yield put(setError('Load failed.'));
  }
}

function* watchChangeNotificationSettings({ isEmail, notificationType }) {
  try {
    const notificationSettings = yield select(getNotificationSettings);
    const cloned = cloneDeep(notificationSettings);
    const { pushNotifications, emailNotifications, categories } = cloned;

    if (isEmail) {
      if (emailNotifications.includes(notificationType.toString())) {
        const updated = emailNotifications.filter(
          notification => notification !== notificationType.toString()
        );
        yield put(
          updateNotificationSettings({
            pushNotifications,
            categories,
            emailNotifications: updated,
          })
        );
      } else {
        emailNotifications.push(notificationType.toString());
        yield put(
          updateNotificationSettings({
            pushNotifications,
            categories,
            emailNotifications,
          })
        );
      }
    } else if (pushNotifications.includes(notificationType.toString())) {
      const updated = pushNotifications.filter(
        notification => notification !== notificationType.toString()
      );
      yield put(
        updateNotificationSettings({
          pushNotifications: updated,
          categories,
          emailNotifications,
        })
      );
    } else {
      pushNotifications.push(notificationType.toString());
      yield put(
        updateNotificationSettings({
          pushNotifications,
          categories,
          emailNotifications,
        })
      );
    }
  } catch (err) {
    /* eslint-disable-next-line no-console */
    console.log({ err });
  }
}

function* saveNotificationSettingsSaga() {
  try {
    const notificationSettings = yield select(getNotificationSettings);
    const { pushNotifications, emailNotifications } = notificationSettings;
    const pussNotificationsIdsString = pushNotifications.join(',');
    const emailNotificationsIdsString = emailNotifications.join(',');
    const { data } = yield call(api.put, '/notifications/settings', {
      pushNotifications: pussNotificationsIdsString,
      emailNotifications: emailNotificationsIdsString,
    });
    yield put(saveNotificationSettingsSuccess(data));
  } catch (err) {
    yield put(saveNotificationSettingsError(err));
    yield put(setError('Save failed.'));
  }
}

export function* notificationsSaga() {
  yield takeLatest(RECEIVE_NOTIFICATIONS, receiveNotificationSaga);
  yield takeLatest(MARK_AS_READ_ALL_NOTIFICATIONS, markAsReadAllNotificationSaga);
  yield takeLatest(REQUEST_NOTIFICATION_SETTINGS, requestNotificationSettingsSaga);
  yield takeEvery(CHANGE_NOTIFICATION_SETTINGS, watchChangeNotificationSettings);
  yield takeLatest(SAVE_NOTIFICATION_SETTINGS, saveNotificationSettingsSaga);

  yield takeLatest(REQUEST_NOTIFICATIONS, requestNotifications);
}
