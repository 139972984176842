import { takeLeading, call, put, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import classesActions from './actions';
import errorActions from '../error/actions';

const {
  Types: {
    LOAD_CLASSES_SCHEDULE_REQUEST,
    LOAD_GUEST_CLASSES_SCHEDULE_REQUEST,
    LOAD_FAVORITE_CLASSES_REQUEST,
    ADD_CLASS_TO_FAVORITE_REQUEST,
    REMOVE_CLASS_FROM_FAVORITE_REQUEST,
  },
  Creators: {
    loadClassesScheduleSuccess,
    loadClassesScheduleFailure,

    loadGuestClassesScheduleSuccess,
    loadGuestClassesScheduleFailure,

    loadFavoriteClassesSuccess,
    loadFavoriteClassesFailure,

    addClassToFavoriteSuccess,
    addClassToFavoriteFailure,

    removeClassFromFavoriteSuccess,
    removeClassFromFavoriteFailure,
  },
} = classesActions;

const {
  Creators: { setError },
} = errorActions;

function* loadClasses({ payload }) {
  const { startDate, clubId } = payload;

  try {
    const { data } = yield call(
      api.get,
      `/classes?clubId=${clubId}&startDate=${startDate}`
    );

    yield put(loadClassesScheduleSuccess({ classes: data, clubId }));
  } catch (error) {
    yield put(loadClassesScheduleFailure(error));
    yield put(setError('Failed to load Classes.'));
  }
}

function* loadGuestClasses({ payload }) {
  const { startDate, clubId } = payload;

  try {
    const { data } = yield call(
      api.get,
      `/guest-classes?clubId=${clubId}&startDate=${startDate}`
    );

    yield put(loadGuestClassesScheduleSuccess({ classes: data, clubId }));
  } catch (error) {
    yield put(loadGuestClassesScheduleFailure(error));
    yield put(setError('Failed to load Classes.'));
  }
}
function* loadFavoritesClasses({ payload }) {
  const { startDate, clubId } = payload;

  try {
    const { data } = yield call(
      api.get,
      `/classes/favorites?startDate=${startDate}&clubId=${clubId}`
    );
    yield put(loadFavoriteClassesSuccess(data));
  } catch (error) {
    yield put(loadFavoriteClassesFailure(error));
    yield put(setError('Failed to load favorite classes.'));
  }
}

function* addClassToFavorite({ classId }) {
  try {
    const { data } = yield call(api.post, `/classes/addToFavorites?classId=${classId}`);
    yield put(addClassToFavoriteSuccess(data));
  } catch (error) {
    yield put(addClassToFavoriteFailure(error));
    yield put(setError('Failed to add to favorite.'));
  }
}

function* removeClassFromFavorite({ classId }) {
  try {
    const { data } = yield call(
      api.remove,
      `/classes/removeFromFavorites?classId=${classId}`
    );
    yield put(removeClassFromFavoriteSuccess(data));
  } catch (error) {
    yield put(removeClassFromFavoriteFailure(error));
    yield put(setError('Failed to remove from favorite.'));
  }
}

export function* classesScheduleSaga() {
  yield takeLatest(LOAD_CLASSES_SCHEDULE_REQUEST, loadClasses);
  yield takeLatest(LOAD_GUEST_CLASSES_SCHEDULE_REQUEST, loadGuestClasses);
  yield takeLeading(LOAD_FAVORITE_CLASSES_REQUEST, loadFavoritesClasses);
  yield takeLeading(ADD_CLASS_TO_FAVORITE_REQUEST, addClassToFavorite);
  yield takeLeading(REMOVE_CLASS_FROM_FAVORITE_REQUEST, removeClassFromFavorite);
}
