import React from 'react';
import PropTypes from 'prop-types';
import { likeSelected, likeNotSelected } from 'assets/icons/index';
import styles from './style.module.css';

const LocationBlock = ({
  title,
  city,
  street,
  clubPhoneNumber,
  src,
  handleClick,
  value,
  selectedLocation,
  maxWidth,
  minHeight,
  wrapperStyles,
  favoriteClub,
  changeFavoriteLocation,
}) => {
  return favoriteClub ? (
    <div
      style={{
        maxWidth,
        minHeight,
        ...wrapperStyles,
      }}
      className={styles.wrapper}
      onClick={() => handleClick(value)}
    >
      <div>
        <img src={src} alt="" className={styles.image} />
        <div className={styles.wrapperContent}>
          <span className={styles.title}>{title}</span>
          <div className={styles.wrapperInfo}>
            <span>{city}</span>
            <span>{street}</span>
          </div>
          <span className={styles.phone}>(+1) {clubPhoneNumber}</span>
        </div>
      </div>

      <div
        className={styles.favoriteIconMobile}
        onClick={e => {
          e.stopPropagation();
          changeFavoriteLocation();
        }}
      >
        <img
          src={favoriteClub === value ? likeSelected : likeNotSelected}
          alt="favorite"
        />
      </div>
    </div>
  ) : (
    <div
      style={{
        backgroundImage: `url(${src})`,
        ...wrapperStyles,
      }}
      className={styles.container}
      onClick={() => handleClick(value)}
    >
      <div className={selectedLocation === value && styles.selected} />
      <span className={styles.title}>{title}</span>
    </div>
  );
};

LocationBlock.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
  handleClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.any]),
  selectedLocation: PropTypes.string,
  wrapperStyles: PropTypes.object,
  city: PropTypes.string,
  street: PropTypes.string,
  clubPhoneNumber: PropTypes.string,
  favoriteClub: PropTypes.string,
  changeFavoriteLocation: PropTypes.func,
};
LocationBlock.defaultProps = {
  handleClick: () => {},
  value: null,
  selectedLocation: '',
  maxWidth: '380px',
  minHeight: '240px',
  wrapperStyles: null,
  city: '',
  street: '',
  clubPhoneNumber: '',
  favoriteClub: '',
  changeFavoriteLocation: () => {},
};
export default LocationBlock;
