/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  calendarOrangeIcon,
  cameraSvgIcon,
  locationIcon,
  clockOrangeIcon,
} from 'assets/icons';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import styles from './style.module.css';

const SESSION_CALL_FEATURE_ENABLED = process.env.REACT_APP_SESSION_CALL_FEATURE
  ? JSON.parse(process.env.REACT_APP_SESSION_CALL_FEATURE)
  : false;
const calculateISCallActive = (startDate, endDate) => {
  let isCallActive = false;
  const sfDateTime = getSFTime('full');
  if (!startDate || !endDate) return isCallActive;
  const diffBeforeStart = moment(sfDateTime).diff(startDate, 'minutes');
  const diffBeforeEnd = moment(sfDateTime).diff(endDate, 'minutes');
  isCallActive = diffBeforeStart >= 0 && diffBeforeEnd <= 0;
  return isCallActive;
};
const SessionCard = ({
  title,
  note,
  date,
  dateOrigin,
  startTime,
  endTime,
  location,
  studioName,
  personName,
  participantId,
  trainerCAid,
  infoButtonAction,
  handleOpenCallPopUp,
  callId,
  sessionStartDateTime,
  sessionEndDateTime,
  mobileCard,
}) => {
  const [isCallActive, setIsCallActive] = useState(false);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    if (componentIsMounted.current) {
      setIsCallActive(calculateISCallActive(sessionStartDateTime, sessionEndDateTime));
      const interval = setInterval(() => {
        setIsCallActive(calculateISCallActive(sessionStartDateTime, sessionEndDateTime));
      }, 1000);

      return () => {
        // will unmount
        clearTimeout(interval);
      };
    }
  }, []);

  useEffect(() => {
    return () => {
      componentIsMounted.current = null;
    };
  }, []);

  return (
    <div
      className={`${styles.classWrapper} ${styles.leftBlueBorder}`}
      // onClick={() => {
      //   infoButtonAction({
      //     title,
      //     date,
      //     dateOrigin,
      //     startTime,
      //     endTime,
      //     location,
      //     studioName,
      //     personName,
      //     participantId,
      //     trainerCAid,
      //     callId,
      //   });
      // }}
    >
      <div className={styles.sessionsInfo}>
        <div style={{ display: 'flex' }}>
          <div className={styles.trainer}>
            <img src={mobileCard?.trainer?.photo} alt="" />
            <p>{mobileCard?.trainer?.firstName}</p>
          </div>
          <div className={styles.line} />
        </div>

        <div className={styles.sessionsData}>
          <h1>Your next training session:</h1>
          <div className={styles.infoItem}>
            <img src={calendarOrangeIcon} alt="" className={styles.profileIcon} />
            <p className={styles.location}>{mobileCard?.date}</p>
          </div>
          <div className={styles.infoItem}>
            <img src={clockOrangeIcon} alt="" className={styles.profileIcon} />
            <p className={styles.location}>{mobileCard?.time}</p>
          </div>
          <div className={styles.infoItem}>
            {(mobileCard?.locationName || studioName) && (
              <img
                src={locationIcon}
                alt=""
                className={`${styles.profileIcon} ${styles.orangeIcon}`}
              />
            )}
            <p className={styles.location}>{mobileCard?.locationName || studioName}</p>
          </div>
        </div>
      </div>

      <div className={styles.sessionsCardFooter}>
        {SESSION_CALL_FEATURE_ENABLED && (
          <div className={styles.personalInfo}>
            {isCallActive && (
              <div
                className={`${styles.cameraIconWrapperActive} ${styles.inSessionWrapperActive}`}
                onClick={event => {
                  event.stopPropagation();
                  handleOpenCallPopUp(callId, personName, true);
                }}
              >
                <p>In Session</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

SessionCard.propTypes = {
  title: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  dateOrigin: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  participantId: PropTypes.string.isRequired,
  studioName: PropTypes.string.isRequired,
  personName: PropTypes.string.isRequired,
  trainerCAid: PropTypes.number.isRequired,
  handleOpenCallPopUp: PropTypes.func.isRequired,
  infoButtonAction: PropTypes.func.isRequired,
  callId: PropTypes.string.isRequired,
  sessionStartDateTime: PropTypes.string.isRequired,
  sessionEndDateTime: PropTypes.string.isRequired,
  mobileCard: PropTypes.object.isRequired,
};

export default SessionCard;
