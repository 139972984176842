import React from 'react';
import PropTypes from 'prop-types';
import CheckBox from 'components/shared/Checkbox';
import styles from './styles.module.css';

const NotificationRow = ({
  isEmailAvailable,
  isPushAvailable,
  notificationName,
  isEmailChecked,
  isPushChecked,
  isInitial,
  isHeaderGroup,
  headerGroupTitle,
  changeNotificationSettings,
  notificationType,
  activeDisable,
}) => {
  return (
    <>
      {isInitial ? (
        <div className={styles.wrapperInitial}>
          <div>
            <p>Get notified when:</p>
            <p>Email</p>
            <p>Push</p>
          </div>
        </div>
      ) : isHeaderGroup ? (
        <div className={styles.wrapperHeaderGroup}>
          <p className={styles.headerGroupTitle}>{headerGroupTitle}</p>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div>
            <p className={styles.notificationName}>{notificationName}</p>
            {isEmailAvailable ? (
              <div className={styles.checkBoxWrapper}>
                <CheckBox
                  value={activeDisable || isEmailChecked}
                  onChange={() => {
                    if (!activeDisable)
                      changeNotificationSettings(true, notificationType);
                  }}
                  activeDisable={activeDisable}
                />
              </div>
            ) : (
              <div />
            )}
            {isPushAvailable ? (
              <div className={styles.checkBoxWrapper}>
                <CheckBox
                  value={isPushChecked}
                  onChange={() => {
                    changeNotificationSettings(false, notificationType);
                  }}
                />
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      )}
    </>
  );
};

NotificationRow.propTypes = {
  isEmailAvailable: PropTypes.bool,
  isPushAvailable: PropTypes.bool,
  notificationName: PropTypes.string,
  isEmailChecked: PropTypes.bool,
  isPushChecked: PropTypes.bool,
  isInitial: PropTypes.bool,
  changeNotificationSettings: PropTypes.func,
  notificationType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isHeaderGroup: PropTypes.bool,
  activeDisable: PropTypes.bool,
  headerGroupTitle: PropTypes.string,
};

NotificationRow.defaultProps = {
  isEmailAvailable: null,
  isPushAvailable: null,
  notificationName: null,
  isEmailChecked: null,
  isPushChecked: null,
  isInitial: false,
  activeDisable: false,
  changeNotificationSettings: null,
  notificationType: null,
  isHeaderGroup: false,
  headerGroupTitle: null,
};
export default NotificationRow;
