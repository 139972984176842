export function checkDigitalPlan(membership) {
  return membership?.length > 0
    ? !!membership.find(
        ({ name }) => name.includes('digital') || name.includes('Digital')
      )
    : false;
}

export function checkMembership(membership) {
  return membership?.length > 0;
}
