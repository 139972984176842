import React from 'react';
import ThankYouPage from 'components/Auth/ThankYouPage';

const RequestSuccess = () => {
  return (
    <ThankYouPage
      linkTo="/"
      linkText="Back to main page"
      message="Your new password has been created."
    />
  );
};

RequestSuccess.propTypes = {};

RequestSuccess.defaultProps = {};

export default RequestSuccess;
