import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { arrowLeft, sendIcon } from 'assets/icons';
import { fsfLogo } from 'assets/logos';
import { useSwipeable } from 'react-swipeable';
import { useSelector } from 'react-redux';
import { memberStatuses } from 'enums/memberStatuses';
import moment from 'moment';
import Message from './Message';
import styles from './styles.module.css';

const Dialog = ({
  targetDialog,
  clearTargetDialog,
  targetDialogMessagesHistory,
  sendMessage,
  messageText,
  typeMessage,
}) => {
  const { dialogPersonName, createdAt, photo } = targetDialog;
  const memberStatus = useSelector(state => state.auth.user.memberStatus);
  const isStaff = memberStatus === memberStatuses.staff;
  const isToday = createdAt && moment(createdAt).isSame(Date.now(), 'day');

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef?.current) messagesEndRef.current.scrollIntoView(false);
  };

  useEffect(scrollToBottom, [targetDialogMessagesHistory]);
  const handlers = useSwipeable({
    onSwipedRight: ({ deltaX }) => {
      if (deltaX < -100) clearTargetDialog();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  const handleSendMessage = () => {
    if (messageText !== '') sendMessage();
  };
  const handleEnterPress = e => {
    if (e.key === 'Enter') handleSendMessage();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div
          onClick={() => {
            clearTargetDialog();
          }}
        >
          <img src={arrowLeft} alt="back" className={styles.arrowIcon} />
        </div>
        {!isStaff ? (
          <div className={styles.photo} style={{ backgroundImage: `url(${photo})` }} />
        ) : null}
        <div className={styles.name}>{dialogPersonName || ''}</div>
        <div className={styles.lastDate}>
          {createdAt
            ? isToday
              ? moment(createdAt).format('hh:mm A')
              : moment(createdAt).format('MMM Do YY')
            : ''}
        </div>
      </div>
      <div
        className={styles.main}
        {...handlers}
        style={{ backgroundImage: `url(${fsfLogo})` }}
      >
        {targetDialogMessagesHistory.length ? (
          <div className={styles.messagesListing} ref={messagesEndRef}>
            {targetDialogMessagesHistory.map(message => (
              <Message {...message} key={message.id} />
            ))}
            {/* <div ref={messagesEndRef} /> */}
          </div>
        ) : targetDialog ? (
          <div className={styles.emptyList}>Start your conversation here</div>
        ) : (
          <div className={styles.emptyList}>
            {isStaff ? (
              <>Contact with anybody directly using this chat.</>
            ) : (
              <>
                Look for your favorite trainers and contact them directly using this chat.
                Don’t hesitate to ask for training tips, recommendations…
                <br /> You will also be able to book your next online fitness coaching!
              </>
            )}
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <>
          <input
            type="text"
            name="input"
            className={styles.input}
            placeholder="Type Message..."
            value={messageText}
            onChange={e => {
              typeMessage(e.target.value);
            }}
            onKeyDown={handleEnterPress}
          />
          <div onClick={handleSendMessage}>
            <img alt="chevron" src={sendIcon} className={styles.sendIcon} />
          </div>
        </>
      </div>
    </div>
  );
};

Dialog.propTypes = {
  targetDialog: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  clearTargetDialog: PropTypes.func.isRequired,
  targetDialogMessagesHistory: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  sendMessage: PropTypes.func.isRequired,
  messageText: PropTypes.string.isRequired,
  typeMessage: PropTypes.func.isRequired,
};

Dialog.defaultProps = {};

export default Dialog;
