import {
  takeEvery,
  takeLatest,
  call,
  put,
  spawn,
  select,
  fork,
} from 'redux-saga/effects';
import api from 'services/api';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import { loadClubs } from 'store/clubs/sagas';
import { loadSessions } from 'store/home/sagas';
import errorActions from 'store/error/actions';
import trainersActions from './actions';

const {
  Types: {
    LOAD_TRAINERS_REQUEST,
    LOAD_PERSONAL_TRAINERS_REQUEST,
    SEND_CANCEL_SESSION_EMAIL_REQUEST,
    CHANGE_TRAINING_SEARCH,
    MARK_TRAINER_AS_FAVORITE,
    UNMARK_TRAINER_AS_FAVORITE,
  },
  Creators: {
    loadTrainersSuccess,
    loadTrainersFailure,
    loadPersonalTrainersSuccess,
    loadPersonalTrainersFailure,
    sendCancelSessionEmailSuccess,
    sendCancelSessionEmailFailure,
    filterTrainers,
    markTrainerAsFavoriteSuccess,
    unmarkTrainerAsFavoriteSuccess,
    markTrainerAsFavoriteError,
    unmarkTrainerAsFavoriteError,
  },
} = trainersActions;

const {
  Creators: { setError },
} = errorActions;

const selectTrainers = state => state.training.trainers;
const selectFavoriteTrainers = state => state.training.favoriteTrainers;
const selectSearchValue = state => state.training.searchValue;

export function* loadTrainers() {
  try {
    const { data } = yield call(api.get, `/trainers`);

    const sortedTrainers = sortBy(data.trainers, trainer => trainer.name);
    const sortedFavoriteTrainers = sortBy(data.favoriteTrainers, trainer => trainer.name);
    yield put(
      loadTrainersSuccess(sortedTrainers, sortedFavoriteTrainers, data.favoriteLocation)
    );
  } catch (error) {
    yield put(loadTrainersFailure(error));
    yield put(setError('Failed to load Trainers.'));
  }
}

function* watchLoadPersonalTrainers() {
  yield takeEvery(LOAD_PERSONAL_TRAINERS_REQUEST, function* loadMyPersonalTrainers() {
    try {
      const { data } = yield call(api.get, `/trainers/personal`);
      yield put(loadPersonalTrainersSuccess(data));
    } catch (error) {
      yield put(sendCancelSessionEmailFailure(error));
      yield put(setError('Failed to load My Personal Trainers.'));
    }
  });
}

function* watchSendCancelSessionEmail() {
  yield takeEvery(SEND_CANCEL_SESSION_EMAIL_REQUEST, function* sendEmail({
    sessionData,
  }) {
    try {
      const { data } = yield call(api.post, `/sessions/`, sessionData);
      yield put(sendCancelSessionEmailSuccess(data));
    } catch (error) {
      yield put(loadPersonalTrainersFailure(error));
      yield put(setError('Failed to send email.'));
    }
  });
}

function validateSpecializations(specializations, searchValue) {
  let isInclude = false;
  if (!specializations?.length) return isInclude;
  for (let i = 0; i < specializations.length; i++) {
    if (
      specializations[i]
        .toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    )
      isInclude = true;
  }
  return isInclude;
}

function filterTrainersList(trainers, searchValue) {
  const filteredList = [];
  const clonedList = cloneDeep(trainers);

  for (let i = 0; i < clonedList.length; i++) {
    if (
      (clonedList[i].firstName &&
        clonedList[i].firstName
          .toString()
          .toLowerCase()
          .includes(searchValue.toString().toLowerCase())) ||
      clonedList[i].locationName
        .toString()
        .toLowerCase()
        .includes(searchValue.toString().toLowerCase()) ||
      validateSpecializations(clonedList[i].specializations, searchValue)
    ) {
      filteredList.push(clonedList[i]);
    }
  }
  return filteredList;
}

function* watchChangeSearch() {
  yield takeEvery(CHANGE_TRAINING_SEARCH, function* search({ searchValue }) {
    try {
      const trainers = yield select(selectTrainers);
      const favoriteTrainers = yield select(selectFavoriteTrainers);
      const filteredTrainers = filterTrainersList(trainers, searchValue);
      const filteredFavoriteTrainers = filterTrainersList(favoriteTrainers, searchValue);
      yield put(filterTrainers(filteredTrainers, filteredFavoriteTrainers));
    } catch (e) {
      /* eslint-disable-next-line no-console */
      console.log({ e });
    }
  });
}

function* watchMarkFavorite() {
  yield takeEvery(MARK_TRAINER_AS_FAVORITE, function* search({ trainer }) {
    try {
      const searchValue = yield select(selectSearchValue);
      const trainers = yield select(selectTrainers);
      const favoriteTrainers = yield select(selectFavoriteTrainers);

      const newTrainers = [];
      const newFavoriteTrainers = [...favoriteTrainers];

      trainers.forEach(t => {
        if (t.idCA === trainer.idCA) {
          newTrainers.push({ ...t, isFavorite: true });
          newFavoriteTrainers.push({ ...t, isFavorite: true });
        } else {
          newTrainers.push(t);
        }
      });

      const sortedFavoriteTrainers = sortBy(newFavoriteTrainers, o => o.name);

      yield put(markTrainerAsFavoriteSuccess(newTrainers, sortedFavoriteTrainers));
      const filteredTrainers = filterTrainersList(newTrainers, searchValue);
      const filteredFavoriteTrainers = filterTrainersList(
        sortedFavoriteTrainers,
        searchValue
      );

      yield put(filterTrainers(filteredTrainers, filteredFavoriteTrainers));

      yield call(
        api.post,
        `/trainers/favorites?trainerId=${trainer.trainerId}&idCA=${trainer.idCA}`
      );

      yield call(api.get, `/trainers`);
    } catch (error) {
      const searchValue = yield select(selectSearchValue);
      const trainers = yield select(selectTrainers);

      const newTrainers = [];

      trainers.forEach(t => {
        if (t.trainerId === trainer.trainerId) {
          newTrainers.push({ ...t, isFavorite: false });
        } else {
          newTrainers.push(t);
        }
      });

      const newFavoriteTrainers = newTrainers.filter(t => t.isFavorite);

      yield put(markTrainerAsFavoriteError(error, newTrainers, newFavoriteTrainers));
      const filteredTrainers = filterTrainersList(newTrainers, searchValue);
      const filteredFavoriteTrainers = filterTrainersList(
        newFavoriteTrainers,
        searchValue
      );

      yield put(filterTrainers(filteredTrainers, filteredFavoriteTrainers));

      yield put(setError('Failed to add trainer to favorite.'));
    }
  });
}

function* watchUnmarkFavorite() {
  yield takeEvery(UNMARK_TRAINER_AS_FAVORITE, function* search({ trainer }) {
    try {
      const searchValue = yield select(selectSearchValue);
      const trainers = yield select(selectTrainers);
      const favoriteTrainers = yield select(selectFavoriteTrainers);

      const newTrainers = [];
      const newFavoriteTrainers = favoriteTrainers.filter(
        t => t.trainerId !== trainer.trainerId
      );

      trainers.forEach(t => {
        if (t.trainerId === trainer.trainerId) {
          newTrainers.push({ ...t, isFavorite: false });
        } else {
          newTrainers.push(t);
        }
      });

      yield put(unmarkTrainerAsFavoriteSuccess(newTrainers, newFavoriteTrainers));
      const filteredTrainers = filterTrainersList(newTrainers, searchValue);
      const filteredFavoriteTrainers = filterTrainersList(
        newFavoriteTrainers,
        searchValue
      );
      yield put(filterTrainers(filteredTrainers, filteredFavoriteTrainers));

      yield call(api.remove, `/trainers/favorites?trainerId=${trainer.trainerId}`);
      yield call(api.get, `/trainers`);
    } catch (error) {
      const searchValue = yield select(selectSearchValue);
      const trainers = yield select(selectTrainers);

      const newTrainers = [];

      trainers.forEach(t => {
        if (t.trainerId === trainer.trainerId) {
          newTrainers.push({ ...t, isFavorite: true });
        } else {
          newTrainers.push(t);
        }
      });

      const newFavoriteTrainers = newTrainers.filter(t => t.isFavorite);

      const filteredTrainers = filterTrainersList(newTrainers, searchValue);
      const filteredFavoriteTrainers = filterTrainersList(
        newFavoriteTrainers,
        searchValue
      );
      yield put(filterTrainers(filteredTrainers, filteredFavoriteTrainers));

      yield put(unmarkTrainerAsFavoriteError(error, newTrainers, newFavoriteTrainers));
      yield put(setError('Failed to remove trainer from favorite.'));
    }
  });
}

export function* trainersSaga() {
  yield takeLatest(LOAD_TRAINERS_REQUEST, function* flow() {
    yield fork(loadClubs);
    yield fork(loadTrainers);
    yield fork(loadSessions);
  });
  yield spawn(watchLoadPersonalTrainers);
  yield spawn(watchSendCancelSessionEmail);
  yield spawn(watchChangeSearch);
  yield spawn(watchMarkFavorite);
  yield spawn(watchUnmarkFavorite);
}
