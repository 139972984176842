import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  isPopUpOpened: false,
  isRecording: false,
  callSessionToken: '',
  recording: '',
  awsUrl: '',
  isStopRecordingRequest: false,
  classId: '',
  error: null,
};

const clearCallState = (state = INITIAL_STATE) => {
  return {
    ...INITIAL_STATE,
  };
};

const getCallSessionTokenRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const getCallSessionTokenSuccess = (state = INITIAL_STATE, { callSessionToken }) => {
  return {
    ...state,
    callSessionToken,
    isRequesting: false,
  };
};
const getCallSessionTokenFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    callSessionToken: null,
    error,
  };
};

const removeUser = (state = INITIAL_STATE) => {
  return {
    ...state,
  };
};
const handleOpenClassCallPopUp = (state = INITIAL_STATE, { classId, isPopUpOpened }) => {
  return {
    ...state,
    classId,
    isPopUpOpened,
  };
};

const startRecordingRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
  };
};

const startRecordingSuccess = (state = INITIAL_STATE, { recording }) => {
  return {
    ...state,
    isRecording: true,
    recording,
    awsUrl: '',
  };
};

const startRecordingFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRecording: false,
    error,
  };
};

const stopRecordingRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isStopRecordingRequest: true,
  };
};

const stopRecordingSuccess = (state = INITIAL_STATE, { awsUrl }) => {
  return {
    ...state,
    isRecording: false,
    awsUrl,
    isStopRecordingRequest: false,
  };
};

const stopRecordingFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRecording: true,
    error,
    isStopRecordingRequest: false,
  };
};

export const HANDLERS = {
  [Types.GET_CALL_SESSION_TOKEN_REQUEST]: getCallSessionTokenRequest,
  [Types.GET_CALL_SESSION_TOKEN_SUCCESS]: getCallSessionTokenSuccess,
  [Types.GET_CALL_SESSION_TOKEN_FAILURE]: getCallSessionTokenFailure,
  [Types.REMOVE_USER]: removeUser,
  [Types.HANDLE_OPEN_CLASS_CALL_POP_UP]: handleOpenClassCallPopUp,
  [Types.CLEAR_CALL_STATE]: clearCallState,
  [Types.START_RECORDING_REQUEST]: startRecordingRequest,
  [Types.START_RECORDING_SUCCESS]: startRecordingSuccess,
  [Types.START_RECORDING_FAILURE]: startRecordingFailure,
  [Types.STOP_RECORDING_REQUEST]: stopRecordingRequest,
  [Types.STOP_RECORDING_SUCCESS]: stopRecordingSuccess,
  [Types.STOP_RECORDING_FAILURE]: stopRecordingFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
