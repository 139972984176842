import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/shared/Button';
import Text from 'components/shared/Text';
import LocationBlockSet from 'components/LocationsPage/LocationBlockSet';
import styles from './index.module.css';

const ChooseLocation = ({
  clubs,
  changeFavoriteLocation,
  changeFavoriteIsRequesting,
}) => {
  const [selectedLocation, setSelectedLocation] = useState('');

  useEffect(() => {}, []);

  return (
    <div className={styles.wrapper}>
      <Text tag="p" type="p1" className={styles.header}>
        Choose your favorite location:
      </Text>

      <LocationBlockSet
        isRegistration
        data={clubs}
        selectedLocation={selectedLocation}
        selectLocation={setSelectedLocation}
      />

      <div className={styles.buttonsWrapper}>
        <Button
          type="submit"
          disabled={changeFavoriteIsRequesting || !selectedLocation}
          onClick={() => changeFavoriteLocation(selectedLocation)}
          isRequesting={changeFavoriteIsRequesting}
          width="200px"
        >
          Ok
        </Button>
      </div>
    </div>
  );
};

ChooseLocation.propTypes = {
  clubs: PropTypes.array.isRequired,
  changeFavoriteLocation: PropTypes.func.isRequired,
  changeFavoriteIsRequesting: PropTypes.bool,
};

ChooseLocation.defaultProps = {
  changeFavoriteIsRequesting: false,
};

export default ChooseLocation;
