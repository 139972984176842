import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const MobileTableSessionsDetails = ({ data }) => {
  return (
    <>
      {data.length > 0 ? (
        <div className={styles.wrapper}>
          {data.map(item => (
            <div className={styles.cardWrapper}>
              <div className={styles.cardItem}>
                <div className={styles.header}>Session Date</div>
                <div className={styles.main}>{item.date}</div>
              </div>
              <div className={styles.cardItem}>
                <div className={styles.header}>Name</div>
                <div className={styles.main}>{item.name}</div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

MobileTableSessionsDetails.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
MobileTableSessionsDetails.defaultProps = {
  data: [],
};
export default MobileTableSessionsDetails;
