import { takeEvery, call, put, select, fork, takeLatest } from 'redux-saga/effects';
import api from 'services/api';
import moment from 'moment';
import errorActions from 'store/error/actions';
// import messageActions from 'store/messages/actions';
import statisticActions from 'store/clubsStatistic/actions';
// import { loadUpcomingReservations } from 'store/reservations/sagas';
import { loadClubs } from 'store/clubs/sagas';
import { loadMembership } from 'store/auth/sagas';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import classesActions from './actions';

const {
  Types: {
    LOAD_HOME_REQUEST,
    ADD_CLASS_TO_FAVORITE_HOMEPAGE_REQUEST,
    REMOVE_CLASS_FROM_FAVORITE_HOMEPAGE_REQUEST,
  },
  Creators: {
    loadHomeFavoriteClassesSuccess,
    loadHomeFavoriteClassesFailure,
    loadHomeLocationClassesSuccess,
    loadHomeLocationClassesFailure,
    addClassToFavoriteHomepageSuccess,
    addClassToFavoriteHomepageFailure,
    removeClassFromFavoriteHomepageSuccess,
    removeClassFromFavoriteHomepageFailure,
    loadSessionsSuccess,
    loadSessionsFailure,
  },
} = classesActions;

const {
  Creators: { setError },
} = errorActions;
// const {
//   Creators: { loadMessagesRequest },
// } = messageActions;
const {
  Creators: { loadClubsStatisticRequest },
} = statisticActions;

// const selectMessagesHistory = state => state.messages.messagesHistory;
const selectUserFavoriteLocation = state => state.auth.user.favoriteLocation;

function* loadFavoriteClasses() {
  try {
    const sfDateTime = getSFTime('full');
    const todaysDate = moment(sfDateTime).format('YYYY-MM-DD');
    const { data } = yield call(
      api.get,
      `/classes/favorites/today?todaysDate=${todaysDate}`
    );
    yield put(loadHomeFavoriteClassesSuccess(data));
  } catch (error) {
    yield put(loadHomeFavoriteClassesFailure(error));
    yield put(setError('Load classes error.'));
  }
}

function checkCalculateQrVisibility(sessions) {
  let isAvailable = false;
  if (sessions && sessions.length > 0) {
    const sfDateTime = getSFTime('full');
    const todaysSessions = sessions.filter(
      s =>
        moment(s.fullDate).format('YYYY-MM-DD') ===
        moment(sfDateTime).format('YYYY-MM-DD')
    );
    if (!todaysSessions.length) return isAvailable;
    const checkTime = todaysSessions.some(
      s =>
        moment(sfDateTime).diff(s.fullDate, 'minutes') >= -15 &&
        moment(sfDateTime).diff(s.fullDate, 'minutes') <= 30
    );

    isAvailable = checkTime;
  }
  return isAvailable;
}

export function* loadSessions() {
  try {
    const sfDateTime = getSFTime('full');
    const startDate = moment(sfDateTime).format('YYYY-MM-DD HH:mm:ss');
    const { data } = yield call(api.get, `/sessions?startDate=${startDate}`);
    const isSessionQRAvailable = checkCalculateQrVisibility(data);
    yield put(loadSessionsSuccess(data, isSessionQRAvailable));
  } catch (error) {
    yield put(loadSessionsFailure(error));
    yield put(setError('Load sessions error.'));
  }
}

function* loadClassesLocation() {
  try {
    const todaysDate = getSFTime();

    const response = yield call(
      api.get,
      `/classes/fav-location?todaysDate=${todaysDate}`
    );

    yield put(loadHomeLocationClassesSuccess(response.data));
  } catch (error) {
    yield put(loadHomeLocationClassesFailure(error));
    yield put(setError('Load classes error.'));
  }
}

function* addClassToFavorite({ classId }) {
  try {
    yield put(addClassToFavoriteHomepageSuccess(classId));

    yield call(api.post, `/classes/addToFavorites?classId=${classId}`);

    const sfDateTime = getSFTime('full');

    const todaysDate = moment(sfDateTime).format('YYYY-MM-DD');
    yield call(api.get, `/classes/favorites/today?todaysDate=${todaysDate}`);
    yield call(api.get, `/classes/fav-location?todaysDate=${todaysDate}`);
  } catch (error) {
    yield put(addClassToFavoriteHomepageFailure(classId, error));
    yield put(setError('Loading error.'));
  }
}

function* removeClassFromFavoriteRequest({ classId }) {
  try {
    yield put(removeClassFromFavoriteHomepageSuccess(classId));

    yield call(api.remove, `/classes/removeFromFavorites?classId=${classId}`);
    const sfDateTime = getSFTime('full');
    const todaysDate = moment(sfDateTime).format('YYYY-MM-DD');
    yield call(api.get, `/classes/favorites/today?todaysDate=${todaysDate}`);
    yield call(api.get, `/classes/fav-location?todaysDate=${todaysDate}`);
  } catch (error) {
    yield put(removeClassFromFavoriteHomepageFailure(classId, error));
    yield put(setError('Sorry, something went wrong.'));
  }
}

function* initLoadClubsStatistic() {
  try {
    const userFavoriteLocation = yield select(selectUserFavoriteLocation);
    yield put(loadClubsStatisticRequest(userFavoriteLocation));
  } catch (error) {
    yield put(setError('Sorry, something went wrong.'));
  }
}

// function* initLoadMessages() {
//   try {
//     const messagesHistory = yield select(selectMessagesHistory);
//     if (!messagesHistory.length) yield put(loadMessagesRequest());
//   } catch (error) {
//     yield put(setError('Sorry, something went wrong.'));
//   }
// }

export function* classesSaga() {
  yield takeLatest(LOAD_HOME_REQUEST, function* flow() {
    yield fork(loadClubs);
    yield fork(initLoadClubsStatistic);
    yield fork(loadSessions);
    yield fork(loadFavoriteClasses);
    yield fork(loadClassesLocation);
    // yield fork(loadUpcomingReservations);
    yield fork(loadMembership);
    // commented reason: chat feature was hided
    // yield fork(initLoadMessages);
  });

  yield takeEvery(ADD_CLASS_TO_FAVORITE_HOMEPAGE_REQUEST, addClassToFavorite);
  yield takeEvery(
    REMOVE_CLASS_FROM_FAVORITE_HOMEPAGE_REQUEST,
    removeClassFromFavoriteRequest
  );
}
