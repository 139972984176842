import React from 'react';
import PropTypes from 'prop-types';
import history from 'store/history';
import styles from './style.module.css';

const ServicesCard = ({ service = {} }) => {
  const { title = '', subTitle, redirectTo, img = '' } = service;
  const backgroundStyles = {};
  backgroundStyles.backgroundImage = `url(${img})`;
  backgroundStyles.backgroundSize = 'cover';
  return (
    <div
      className={styles.full_wrapper}
      style={{ ...backgroundStyles, cursor: redirectTo ? 'pointer' : 'auto' }}
      onClick={() => {
        if (redirectTo) history.push(redirectTo);
      }}
    >
      <p className={styles.title}>{title}</p>
      {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
    </div>
  );
};

ServicesCard.propTypes = {
  service: PropTypes.object.isRequired,
};

export default ServicesCard;
