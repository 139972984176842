import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  data: [
    { isRead: false, data: [] },
    { isRead: true, data: [] },
  ],
  settings: {},
  settingsCopy: {},
  error: null,
  isRequesting: false,
  isSaveRequesting: false,
};

const requestNotificationsSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    data,
    error: null,
  };
};

const requestNotificationsFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    error,
  };
};

const receiveNotificationsSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    data: state.data.map(item => (item.isRead === false ? { ...item, data } : item)),
    error: null,
  };
};

const receiveNotificationsFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    error,
  };
};

const markAsReadAllNotificationSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    data,
  };
};

const requestNotificationSettings = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
  };
};

const requestNotificationSettingsSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    settings: data,
    settingsCopy: data,
    isRequesting: false,
  };
};
const updateNotificationSettings = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    settings: data,
  };
};
const requestNotificationSettingsError = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    error,
  };
};
const saveNotificationSettings = (state = INITIAL_STATE) => {
  return {
    ...state,
    isSaveRequesting: true,
  };
};

const saveNotificationSettingsSuccess = (state = INITIAL_STATE, { data }) => {
  return {
    ...state,
    settings: data,
    settingsCopy: data,
    isSaveRequesting: false,
  };
};

const saveNotificationSettingsError = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    error,
    isSaveRequesting: false,
  };
};

const resetNotificationSettings = (state = INITIAL_STATE) => {
  return {
    ...state,
    settings: state.settingsCopy,
  };
};
export const HANDLERS = {
  [Types.REQUEST_NOTIFICATIONS_SUCCESS]: requestNotificationsSuccess,
  [Types.REQUEST_NOTIFICATIONS_FAILURE]: requestNotificationsFailure,

  [Types.RECEIVE_NOTIFICATIONS_SUCCESS]: receiveNotificationsSuccess,
  [Types.RECEIVE_NOTIFICATIONS_FAILURE]: receiveNotificationsFailure,

  [Types.MARK_AS_READ_ALL_NOTIFICATIONS_SUCCESS]: markAsReadAllNotificationSuccess,

  [Types.REQUEST_NOTIFICATION_SETTINGS]: requestNotificationSettings,
  [Types.REQUEST_NOTIFICATION_SETTINGS_SUCCESS]: requestNotificationSettingsSuccess,
  [Types.REQUEST_NOTIFICATION_SETTINGS_ERROR]: requestNotificationSettingsError,
  [Types.UPDATE_NOTIFICATION_SETTINGS]: updateNotificationSettings,

  [Types.SAVE_NOTIFICATION_SETTINGS]: saveNotificationSettings,
  [Types.SAVE_NOTIFICATION_SETTINGS_SUCCESS]: saveNotificationSettingsSuccess,
  [Types.SAVE_NOTIFICATION_SETTINGS_ERROR]: saveNotificationSettingsError,

  [Types.RESET_NOTIFICATION_SETTINGS]: resetNotificationSettings,
};

export default createReducer(INITIAL_STATE, HANDLERS);
