import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { likeSelected, likeNotSelected } from 'assets/icons/index';
import iconv from 'iconv-lite';
import MobileContext from 'context/MobileContext';
import SmallMobileContext from 'context/SmallMobileContext';
import styles from './style.module.css';

const TrainerCard = ({
  name,
  firstName,
  photo,
  specializations,
  locationName,
  locationId,
  cardStyles,
  setIsOpened,
  selectTrainer,
  credentials,
  trainerId,
  idCA,
  isChatAvailable,
  setTrainerId,
  showClub,
  isFavorite,
}) => {
  const isMobile = useContext(MobileContext);
  const isSmallMobile = useContext(SmallMobileContext);
  const dispatch = useDispatch();
  const setLike = () => {
    if (isFavorite) {
      dispatch({
        type: 'UNMARK_TRAINER_AS_FAVORITE',
        trainer: {
          trainerId,
          idCA,
        },
      });
    } else
      dispatch({
        type: 'MARK_TRAINER_AS_FAVORITE',
        trainer: {
          trainerId,
          idCA,
        },
      });
  };
  const handledSpecializations = specializations?.length
    ? specializations
        .map(specialization => {
          if (specialization === null || specialization === '') return false;
          const stringified = specialization.toString();

          return stringified.replace(/[^\x20-\x7E]/g, '');
        })
        .map(specialization => {
          return iconv.decode(specialization, 'utf-8').replace(/&amp;/g, '&');
        })
        .filter(specialization => specialization.length > 0)
    : [];

  return (
    <div
      className={styles.wrapper}
      style={cardStyles}
      onClick={() => {
        setIsOpened();
        selectTrainer({
          name,
          firstName,
          photo,
          location: locationName,
          specializations,
          credentials,
          trainerId,
          idCA,
          isChatAvailable,
          isFavorite,
        });
      }}
    >
      <div className={styles.wrapperContainer}>
        <div className={styles.trainerPhoto}>
          <img src={photo} alt="trainer" />
        </div>
        {/* isMobile && !isSmallMobile ? (
          <div
            className={styles.favoriteIconMobile}
            onClick={e => {
              e.stopPropagation();
              setLike();
            }}
          >
            <img src={isFavorite ? likeSelected : likeNotSelected} alt="favorite" />
          </div>
        ) : null */}
        <div className={styles.trainerInfo}>
          <p className={styles.name}>{firstName}</p>
          <div className={styles.skills}>
            {handledSpecializations?.length
              ? handledSpecializations.map(skill => (
                  <p key={skill} className={styles.skillItem}>
                    {skill}
                  </p>
                ))
              : null}
          </div>
        </div>
        {/* isMobile && !isSmallMobile ? null : (
          <div
            className={styles.favoriteIcon}
            onClick={e => {
              e.stopPropagation();
              setLike();
            }}
          >
            <img src={isFavorite ? likeSelected : likeNotSelected} alt="favorite" />
          </div>
        ) */}
      </div>
    </div>
  );
};

TrainerCard.propTypes = {
  name: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  specializations: PropTypes.arrayOf(PropTypes.string).isRequired,
  photo: PropTypes.string,
  cardStyles: PropTypes.object,
  selectTrainer: PropTypes.func.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  credentials: PropTypes.arrayOf(PropTypes.string).isRequired,
  locationName: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  trainerId: PropTypes.string.isRequired,
  idCA: PropTypes.string.isRequired,
  isChatAvailable: PropTypes.bool,
  setTrainerId: PropTypes.func.isRequired,
  showClub: PropTypes.bool,
  isFavorite: PropTypes.bool.isRequired,
};

TrainerCard.defaultProps = {
  photo: '',
  cardStyles: {},
  isChatAvailable: false,
  showClub: false,
};

export default TrainerCard;
