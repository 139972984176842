import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'components';
import Reservations from './Reservations';
import UserInfo from './UserInfo';

const AdminPageContent = ({
  clubs,
  loadReservations,
  removeReservations,
  updateReservation,
  addReservation,
  reservations,
  searchReservations,
  searchUserReservations,
  searchUser,
  user,
  isRequesting,
}) => {
  const tabsData = [
    { name: 'Reservations', index: 0 },
    { name: 'User Details', index: 1 },
  ];
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <>
      <Tabs
        tabsData={tabsData}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
      {
        {
          0: (
            <Reservations
              clubs={clubs}
              loadReservations={loadReservations}
              removeReservations={removeReservations}
              updateReservation={updateReservation}
              addReservation={addReservation}
              reservations={reservations}
              searchReservations={searchReservations}
              searchUserReservations={searchUserReservations}
              isRequesting={isRequesting}
            />
          ),
          1: (
            <UserInfo
              isRequesting={isRequesting}
              searchUserRequest={searchUser}
              user={user}
            />
          ),
        }[activeTabIndex]
      }
    </>
  );
};

AdminPageContent.propTypes = {
  clubs: PropTypes.array.isRequired,
  reservations: PropTypes.array.isRequired,
  searchReservations: PropTypes.array.isRequired,
  loadReservations: PropTypes.func.isRequired,
  removeReservations: PropTypes.func.isRequired,
  updateReservation: PropTypes.func.isRequired,
  addReservation: PropTypes.func.isRequired,
  searchUserReservations: PropTypes.func.isRequired,
  searchUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isRequesting: PropTypes.bool.isRequired,
};

export default AdminPageContent;
