import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { registrationInfoSchema } from 'validation/auth.validation';
import MobileContext from 'context/MobileContext';
import Field from 'components/shared/Field';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';
import MaskInput from 'components/shared/MaskInput';
import { arrowLeft } from 'assets/icons';
import styles from './style.module.css';
import { initialValues, stateOptions } from './service';

const PersonalInfo = ({
  user,
  referralPerson,
  isRequesting,
  addUserDetails,
  onNext,
  onBack,
}) => {
  const isMobile = useContext(MobileContext);
  const error = useSelector(state => state.signUp.error);
  const [state, setState] = useState(user?.state || 'CA');
  const inputWidth = isMobile ? '260px' : '300px';

  const [checkUserRequest, setCheckUserRequest] = useState(false);

  useEffect(() => {
    if (checkUserRequest && !isRequesting && !user.isExist && !error) {
      setCheckUserRequest(false);
      onNext();
    } else if (checkUserRequest && !isRequesting) setCheckUserRequest(false);
  }, [user, onNext]);

  return (
    <Formik
      initialValues={initialValues(user)}
      onSubmit={values => {
        setCheckUserRequest(true);
        addUserDetails({
          ...values,
          isExist: false,
          state,
          referralCode: referralPerson?.idCA || null,
          referralCodeSfId: referralPerson?.sfId || null,
        });
      }}
      validationSchema={registrationInfoSchema}
    >
      {({ handleSubmit, handleChange, values, errors, touched, isValid, dirty }) => {
        return (
          <>
            <div className={styles.form}>
              <h1 className={styles.title}>Personal Details</h1>
              <div className={styles.inputsWrapper}>
                <Field
                  value={values.firstName}
                  onChange={handleChange}
                  name="firstName"
                  width={inputWidth}
                  placeholder="FIRST NAME"
                  className={styles.input}
                  invalid={touched.firstName && Boolean(errors.firstName)}
                  error={errors.firstName}
                />
                <Field
                  value={values.lastName}
                  onChange={handleChange}
                  name="lastName"
                  width={inputWidth}
                  placeholder="LAST NAME"
                  className={styles.input}
                  invalid={touched.lastName && Boolean(errors.lastName)}
                  error={errors.lastName}
                />
                <MaskInput
                  value={values.birthday}
                  onChange={handleChange}
                  name="birthday"
                  placeholder="MM-DD-YYYY"
                  label="Birthday"
                  className={styles.input}
                  width={inputWidth}
                  invalid={touched.birthday && Boolean(errors.birthday)}
                  error={errors.birthday}
                  mask="99-99-9999"
                  maskChar={null}
                />
              </div>

              <h1 className={styles.title}>Contact Info</h1>
              <div className={styles.inputsWrapper}>
                <Field
                  value={values.email}
                  onChange={handleChange}
                  name="email"
                  type="email"
                  width={inputWidth}
                  placeholder="EMAIL"
                  className={styles.input}
                  invalid={touched.email && Boolean(errors.email)}
                  error={errors.email}
                />
                <MaskInput
                  value={values.phone}
                  onChange={handleChange}
                  name="phone"
                  label="PHONE NUMBER"
                  width={inputWidth}
                  className={styles.input}
                  invalid={touched.phone && Boolean(errors.phone)}
                  error={errors.phone}
                  mask="(999) 999 - 9999"
                />
                <Field
                  value={values.address}
                  onChange={handleChange}
                  name="address"
                  width={inputWidth}
                  placeholder="ADDRESS"
                  className={styles.input}
                  invalid={touched.address && Boolean(errors.address)}
                  error={errors.address}
                />
                <Field
                  value={values.city}
                  onChange={handleChange}
                  name="city"
                  width={inputWidth}
                  placeholder="CITY"
                  className={styles.input}
                  invalid={touched.city && Boolean(errors.city)}
                  error={errors.city}
                />
                <div className={styles.select}>
                  <p className={styles.label}>State</p>
                  <Select
                    label="State"
                    width={inputWidth}
                    hideIndicatorSeparator
                    options={stateOptions}
                    value={state}
                    onChange={e => setState(e.value)}
                  />
                </div>
                <Field
                  type="number"
                  value={values.zipCode}
                  onChange={handleChange}
                  name="zipCode"
                  width={inputWidth}
                  placeholder="ZIP CODE"
                  className={styles.input}
                  invalid={touched.zipCode && Boolean(errors.zipCode)}
                  error={errors.zipCode}
                />
              </div>

              <h1 className={styles.title}>Emergency Contact</h1>
              <div className={styles.inputsWrapper}>
                <Field
                  value={values.emergencyContactName}
                  onChange={handleChange}
                  name="emergencyContactName"
                  width={inputWidth}
                  placeholder="EMERGENCY CONTACT NAME"
                  className={styles.input}
                  invalid={
                    touched.emergencyContactName && Boolean(errors.emergencyContactName)
                  }
                  error={errors.emergencyContactName}
                />

                <MaskInput
                  value={values.emergencyContactPhone}
                  onChange={handleChange}
                  name="emergencyContactPhone"
                  label="EMERGENCY CONTACT PHONE"
                  width={inputWidth}
                  className={styles.input}
                  invalid={
                    touched.emergencyContactPhone && Boolean(errors.emergencyContactPhone)
                  }
                  error={errors.emergencyContactPhone}
                  mask="(999) 999 - 9999"
                />
              </div>
            </div>
            <div className={styles.buttonsContainer}>
              <div className={styles.back} onClick={onBack}>
                <img src={arrowLeft} alt="Back" className={styles.arrow} />
                <span>Back</span>
              </div>
              <Button
                isRequesting={isRequesting}
                className={styles.button}
                onClick={handleSubmit}
                disabled={isRequesting}
              >
                Next
              </Button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

PersonalInfo.propTypes = {
  user: PropTypes.object.isRequired,
  referralPerson: PropTypes.object,
  isRequesting: PropTypes.bool.isRequired,
  addUserDetails: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

PersonalInfo.defaultProps = {
  referralPerson: null,
};

export default PersonalInfo;
