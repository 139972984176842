import { createReducer } from 'reduxsauce';
import actions from './actions';
import { searchingClasses, filteringClasses } from './service';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  classes: [],
  clubs: [],
  favoriteClasses: null,
  lastSucceeded: '',
  filteredClasses: [],
  preselectedClub: '',
  searchValue: '',
  error: null,
  filters: {
    categories: [],
    isShowVirtualClasses: false,
    dayTime: [],
    goals: [],
  },
  guestClasses: [],
};

const changeSelectedClub = (state = INITIAL_STATE, { preselectedClub }) => {
  return {
    ...state,
    preselectedClub,
  };
};
const loadClassesScheduleRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const loadClassesScheduleSuccess = (
  state = INITIAL_STATE,
  { payload: { classes, clubId } }
) => {
  const newClassList = filteringClasses(state.classes, state.filters);
  return {
    ...state,
    isRequesting: false,
    lastSucceeded: clubId,
    classes,
    searchValue: '',
    filteredClasses: newClassList,
  };
};

const loadGuestClassesScheduleSuccess = (
  state = INITIAL_STATE,
  { payload: { classes, clubId } }
) => {
  return {
    ...state,
    isRequesting: false,
    lastSucceeded: clubId,
    guestClasses: classes,
    searchValue: '',
  };
};

const loadClassesScheduleFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    classes: null,
    filterClasses: null,
    error,
  };
};

const loadFavoriteClassesRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const loadFavoriteClassesSuccess = (state = INITIAL_STATE, { favoriteClasses }) => {
  return {
    ...state,
    isRequesting: false,
    favoriteClasses,
  };
};
const loadFavoriteClassesFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    favoriteClasses: null,
    error,
  };
};

const addClassToFavoriteRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    error: null,
  };
};

const addClassToFavoriteSuccess = (state = INITIAL_STATE, { addedClass }) => {
  return {
    ...state,
    classes: state.classes.map(item => {
      return item.classId === Number(addedClass)
        ? { ...item, isFavorite: true }
        : { ...item };
    }),
  };
};

const addClassToFavoriteFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    error,
  };
};

const removeClassFromFavoriteRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    error: null,
  };
};

const removeClassFromFavoriteSuccess = (state = INITIAL_STATE, { removedClassId }) => {
  return {
    ...state,
    classes: state.classes.map(item => {
      return item.classId === Number(removedClassId)
        ? { ...item, isFavorite: false }
        : { ...item };
    }),
  };
};

const removeClassFromFavoriteFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    error,
  };
};

const filterClasses = (state = INITIAL_STATE, { filters }) => {
  const newClassList = filteringClasses(state.classes, filters);
  return {
    ...state,
    filters,
    filteredClasses: newClassList,
  };
};

const searchClasses = (state = INITIAL_STATE, { searchValue }) => {
  const newClassList = searchingClasses(state.classes, searchValue);
  return {
    ...state,
    searchValue,
    filteredClasses: newClassList,
  };
};

export const HANDLERS = {
  [Types.LOAD_CLASSES_SCHEDULE_REQUEST]: loadClassesScheduleRequest,
  [Types.LOAD_CLASSES_SCHEDULE_SUCCESS]: loadClassesScheduleSuccess,
  [Types.LOAD_CLASSES_SCHEDULE_FAILURE]: loadClassesScheduleFailure,

  [Types.LOAD_GUEST_CLASSES_SCHEDULE_REQUEST]: loadClassesScheduleRequest,
  [Types.LOAD_GUEST_CLASSES_SCHEDULE_SUCCESS]: loadGuestClassesScheduleSuccess,
  [Types.LOAD_GUEST_CLASSES_SCHEDULE_FAILURE]: loadClassesScheduleFailure,

  [Types.LOAD_FAVORITE_CLASSES_REQUEST]: loadFavoriteClassesRequest,
  [Types.LOAD_FAVORITE_CLASSES_SUCCESS]: loadFavoriteClassesSuccess,
  [Types.LOAD_FAVORITE_CLASSES_FAILURE]: loadFavoriteClassesFailure,

  [Types.ADD_CLASS_TO_FAVORITE_REQUEST]: addClassToFavoriteRequest,
  [Types.ADD_CLASS_TO_FAVORITE_SUCCESS]: addClassToFavoriteSuccess,
  [Types.ADD_CLASS_TO_FAVORITE_FAILURE]: addClassToFavoriteFailure,

  [Types.REMOVE_CLASS_FROM_FAVORITE_REQUEST]: removeClassFromFavoriteRequest,
  [Types.REMOVE_CLASS_FROM_FAVORITE_SUCCESS]: removeClassFromFavoriteSuccess,
  [Types.REMOVE_CLASS_FROM_FAVORITE_FAILURE]: removeClassFromFavoriteFailure,

  [Types.SEARCH_CLASSES]: searchClasses,
  [Types.FILTER_CLASSES]: filterClasses,
  [Types.CHANGE_SELECTED_CLUB]: changeSelectedClub,
};

export default createReducer(INITIAL_STATE, HANDLERS);
