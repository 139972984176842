/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const TableHistory = ({ data, handleOpenPopUp }) => {
  return (
    <>
      {data?.length ? (
        data.map(item => {
          const { name, sessionsRemaining, packagePrice, purchasedAt, id } = item;
          return (
            <div key={id} className={styles.card} onClick={() => handleOpenPopUp(item)}>
              <div className={styles.row}>
                <p>{name}</p>
                {sessionsRemaining > 0 && <p className={styles.inUse}>IN USE</p>}
              </div>

              <div className={styles.row}>
                <p>{purchasedAt || '-'}</p>
              </div>

              <div className={styles.row}>
                <p>Amount: {sessionsRemaining}</p>
                <p className={styles.note}>${packagePrice}</p>
              </div>
            </div>
          );
        })
      ) : (
        <div className={styles.notExist}>No packages yet.</div>
      )}
    </>
  );
};
TableHistory.propTypes = {
  data: PropTypes.object.isRequired,
  handleOpenPopUp: PropTypes.func.isRequired,
};
export default TableHistory;
