import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import SmallMobileContext from 'context/SmallMobileContext';
import { memberStatuses } from 'enums/memberStatuses';
import Spinner from 'components/shared/Spinner';
import Location from './Location';
import Info from './Info';
import styles from './styles.module.css';

const MyAccount = ({
  clubs,
  user,
  profile,
  loadProfile,
  changeFavoriteLocation,
  changeProfileInfo,
  isRequesting,
  getCancelMembershipInfo,
  cancelInfo,
}) => {
  const isMobile = useContext(SmallMobileContext);
  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  useEffect(() => {
    if (user?.memberStatus === memberStatuses.onHold) getCancelMembershipInfo();
  }, []);

  return (
    <HeaderFooterLayout profile="My Account">
      <>
        {isRequesting ? (
          <Spinner isWhite />
        ) : (
          <>
            <Location
              clubs={clubs}
              user={user}
              photo={profile?.photo}
              changeFavoriteLocation={changeFavoriteLocation}
            />
            {user?.memberStatus === memberStatuses.onHold && (
              <div className={styles.onHoldMembership}>
                <h1>Your membership currently is on hold</h1>
                {cancelInfo?.reason && <p>{cancelInfo?.reason}</p>}
                {cancelInfo?.date && (
                  <p>{moment(cancelInfo?.date).format('MM/DD/YYYY')}</p>
                )}
              </div>
            )}
            <Info
              user={user}
              profile={profile}
              changeProfileInfo={changeProfileInfo}
              isMobile={isMobile}
            />
          </>
        )}
      </>
    </HeaderFooterLayout>
  );
};
MyAccount.propTypes = {
  clubs: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(null)]).isRequired,
  user: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  loadProfile: PropTypes.func.isRequired,
  changeProfileInfo: PropTypes.func.isRequired,
  changeFavoriteLocation: PropTypes.func.isRequired,
  getCancelMembershipInfo: PropTypes.func.isRequired,
  cancelInfo: PropTypes.object,
};

MyAccount.defaultProps = {
  cancelInfo: null,
};
export default MyAccount;
