import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Select from 'components/shared/Select';
import ReservationList from 'components/ReservationPage/ReservationList';
import DatePicker from 'components/ReservationPage/DatePicker';
import MyReservation from 'components/ReservationPage/MyReservation';
import moment from 'moment';
import RadioButton from 'components/shared/RadioButton';
import styles from './styles.module.css';

const handleClubs = clubs => {
  if (!Array.isArray(clubs)) return [];
  const activeClubs = clubs.map(club => {
    if (club.totalCapacity > 0) return { label: club.name, value: club.sfId };
    return {
      label: club.name,
      value: club.sfId,
      isDisabled: true,
    };
  });
  return activeClubs;
};

const MainContentDesktop = ({
  datesRange,
  targetClub,
  targetDate,
  targetRoom,
  handleChangeRoom,
  handleChangeClub,
  handleSelectDate,
  reservations,
  upcomingReservations,
  handleSlotClick,
  isRequesting,
}) => {
  const [clubsOptions, setClubsOptions] = useState([]);
  const [closedClubText, setClosedClubText] = useState('');
  const clubs = useSelector(state => state.clubs.clubs);
  const rooms = useSelector(state => state.clubs.rooms);
  const activeLocationClub = useSelector(state => state.clubs.activeLocationClub);
  const favoriteLocationClubSfId = useSelector(
    state => state.auth.user.favoriteLocationCAId
  );
  useEffect(() => {
    setClubsOptions(handleClubs(clubs));
  }, [clubs]);

  useEffect(() => {
    if (Array.isArray(rooms)) {
      handleChangeRoom(rooms[0]?.id);
    }
  }, [rooms]);

  useEffect(() => {
    if (!targetClub && activeLocationClub?.length && activeLocationClub[0].sfId) {
      const isActiveFavoriteClub =
        activeLocationClub?.length > 0 &&
        activeLocationClub.find(c => c.sfId === favoriteLocationClubSfId);

      if (!targetClub && isActiveFavoriteClub) handleChangeClub(favoriteLocationClubSfId);
      else if (!targetClub && activeLocationClub?.length && activeLocationClub[0].sfId) {
        handleChangeClub(activeLocationClub[0].sfId);
      }
    }
  }, [clubsOptions, targetClub, handleChangeClub, activeLocationClub]);

  useEffect(() => {
    const club = clubs.find(({ sfId }) => targetClub === sfId);
    setClosedClubText(
      `${club?.name} is closed on ${moment(targetDate?.date).format('dddd')}`
    );
  }, [targetClub, targetDate]);

  return (
    <>
      <div className={styles.headerSection}>
        <div className={styles.selectSection}>
          <div>
            <p className={styles.chooseTimeSlot}>Choose a Location:</p>
            <Select
              hideBorder
              hideIndicatorSeparator
              options={clubsOptions}
              value={targetClub}
              onChange={e => handleChangeClub(e.value)}
            />
          </div>

          {rooms?.length > 0 && (
            <div className={styles.select}>
              <p className={styles.chooseTimeSlot}>Select a gym:</p>
              {rooms.map(el => (
                <div
                  onClick={() => handleChangeRoom(el.id)}
                  key={el.id}
                  className={styles.room}
                >
                  <RadioButton value={targetRoom === el.id} />
                  <p>{el.title}</p>
                </div>
              ))}
            </div>
          )}
        </div>
        {upcomingReservations.length ? (
          <MyReservation reservedItems={upcomingReservations} action={handleSlotClick} />
        ) : null}
        <DatePicker
          datesRange={datesRange}
          selectDate={handleSelectDate}
          targetDate={targetDate}
        />
      </div>

      <ReservationList
        upcomingReservations={upcomingReservations}
        reservations={reservations}
        handleSlotClick={handleSlotClick}
        isRequesting={isRequesting}
        closedClubText={closedClubText}
      />
    </>
  );
};

MainContentDesktop.propTypes = {
  datesRange: PropTypes.oneOfType([PropTypes.array, PropTypes.arrayOf(PropTypes.object)])
    .isRequired,
  targetClub: PropTypes.string.isRequired,
  targetRoom: PropTypes.string.isRequired,
  targetDate: PropTypes.object.isRequired,
  handleChangeClub: PropTypes.func.isRequired,
  handleChangeRoom: PropTypes.func.isRequired,
  handleSelectDate: PropTypes.func.isRequired,
  reservations: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  upcomingReservations: PropTypes.array.isRequired,
  handleSlotClick: PropTypes.func.isRequired,
  isRequesting: PropTypes.bool.isRequired,
};
MainContentDesktop.defaultProps = {};

export default MainContentDesktop;
