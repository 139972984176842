import { connect } from 'react-redux';
import TrainingPage from 'pages/TrainingPage';
import Actions from 'store/training/actions';
import homeActions from 'store/home/actions';
import chatActions from 'store/messages/actions';
import sessionCallActions from 'store/sessionCall/actions';
import topicsActions from 'store/topics/actions';

const mapStateToProps = state => {
  return {
    trainers: state.training.filteredTrainers,
    myPersonalTrainers: state.training.personalTrainers,
    favoriteTrainers: state.training.filteredFavoriteTrainers,
    isRequesting: state.training.isRequesting,
    selectedClubId: state.training.selectedClubId,
    searchValue: state.training.searchValue,
    favoriteLocation: state.training.favoriteLocation,
    clubs: state.clubs.clubs,
    isMarkTrainerSuccess: state.training.isMarkTrainerSuccess,
    sessions: state.home.sessions,
    isSessionCallPopUpOpened: state.sessionCall.isPopUpOpened,
  };
};
const mapDispatchToProps = {
  loadTrainers: Actions.Creators.loadTrainersRequest,
  loadPersonalTrainers: Actions.Creators.loadPersonalTrainersRequest,
  selectTrainingFavoriteClub: Actions.Creators.selectTrainingFavoriteClub,
  changeTrainingSearch: Actions.Creators.changeTrainingSearch,
  markTrainerAsFavorite: Actions.Creators.markTrainerAsFavorite,
  unmarkTrainerAsFavorite: Actions.Creators.unmarkTrainerAsFavorite,
  setTargetSession: homeActions.Creators.setTargetSession,
  setTrainerId: chatActions.Creators.setTrainerId,
  handleOpenCallPopUp: sessionCallActions.Creators.handleOpenCallPopUp,
  cleanCancelSessionEmail: Actions.Creators.cleanCancelSessionEmail,
  sendCancelSessionEmail: Actions.Creators.sendCancelSessionEmailRequest,
  preselectFreezeTopic: topicsActions.Creators.preselectFreezeTopic,
};
export default connect(mapStateToProps, mapDispatchToProps)(TrainingPage);
