import React, { useContext, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import history from 'store/history';
import { notificationIcon, closeIcon } from 'assets/icons';
import { horizontalLogo } from 'assets/logos';
import TabletContext from 'context/TabletContext';
import { BurgerMenu, ProfileButton } from 'components';
import { PopUp } from 'components/shared';
import { memberStatuses } from 'enums/memberStatuses';
import NotificationCenter from 'components/Header/NotificationCenter';
import checkIsAdmin from 'services/checkIsAdmin';
// import { checkDigitalPlan } from 'services/checkDigitalPlan';
import { isMobile } from 'react-device-detect';
import styles from './styles.module.css';
// import DigitalReservationPopup from './DigitalReservationPopup';
import ProfileMenu from './ProfileMenu';

export const Header = ({
  notifications,
  handleReadOnOpenPopUp,
  loadClubs,
  isOpenProfileModal,
  setOpenProfileModal,
}) => {
  const dispatch = useDispatch();
  const isTablet = useContext(TabletContext);
  const memberStatus = useSelector(state => state.auth.user.memberStatus);
  const membership = useSelector(state => state.auth.membership);
  const isLimited =
    memberStatus === memberStatuses.onHold || memberStatus === memberStatuses.notActive;
  const email = useSelector(state => state.auth.user.email);
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const [isNotificationsOpened, setIsNotificationsOpened] = useState(false);
  const [stateNotifications, handleStateNotifications] = useState([]);
  const [unreadNotificationsCount, handleUnreadNotificationsCount] = useState(0);
  // const isExistDigitalPlan = checkDigitalPlan(membership);
  // const [openDigitalReservationPopup, setOpenDigitalReservationPopup] = useState(false);

  useEffect(() => {
    loadClubs();
  }, [loadClubs]);

  useEffect(() => {
    dispatch({
      type: 'LOAD_PROFILE_REQUEST',
    });
  }, []);

  const activeLocationClub = useSelector(state => state.clubs.activeLocationClub || []);

  useEffect(() => {
    if (notifications.length) {
      const result = [];
      const isRead = notifications.find(i => i.isRead).data;
      const isNotRead = notifications.find(i => !i.isRead).data;
      handleUnreadNotificationsCount(isNotRead.length);
      isNotRead.forEach(item => result.push(item));
      isRead.forEach(item => result.push(item));
      handleStateNotifications(result);
    }
  }, [notifications]);

  const mobileItems = [
    { name: 'Home', route: '/' },
    { name: 'Classes', route: '/classes' },
    { name: 'Nutrition', route: '/nutrition' },
    { name: 'On-Demand', route: '/on_demand' },
    { name: 'Locations', route: '/locations' },
    { name: 'Training', route: '/training' },
    { name: 'Get A Free Month', route: '/invite' },
  ];

  const browserItems = [
    { name: 'Home', route: '/' },
    { name: 'Classes', route: '/classes' },
    { name: 'Nutrition', route: '/nutrition' },
    { name: 'On-Demand', route: '/on_demand' },
    { name: 'Locations', route: '/locations' },
    { name: 'Training', route: '/training' },
    { name: 'Get A Free Month', route: '/invite' },
  ];

  const handleNavLinkPress = name => {};

  const renderHeaderButtons = () => {
    // const menuItems = isLimited
    //  ? limitedItems
    //  : activeLocationClub.length !== 0
    //  ? items
    //  : withoutReservationItems;

    const menuItems = isMobile ? mobileItems : browserItems;

    // const checkAdminPageAccess = checkIsAdmin(email);

    return (
      <>
        {/* checkAdminPageAccess && (
          <NavLink to="/admin" className={styles.isAdminButton}>
            A
          </NavLink>
        ) */}
        {menuItems.map(item => {
          if (item.route === '/nutrition')
            return (
              <NavLink
                key={item.name}
                to="/nutrition"
                activeClassName={item?.route && styles.headerItemActive}
                className={`${styles.headerItem} ${!item?.route && styles.disabledItem}`}
              >
                {item.name}
              </NavLink>
            );
          // if (item.route === '/reservation' && isExistDigitalPlan) {
          //   return (
          //     <div
          //       onClick={() => setOpenDigitalReservationPopup(true)}
          //       className={`${styles.headerItem} ${!item?.route && styles.disabledItem}`}
          //       style={{ cursor: 'pointer' }}
          //     >
          //       {item.name}
          //     </div>
          //   );
          // }
          return (
            <NavLink
              key={item.name}
              onClick={e => {
                handleNavLinkPress(item.name);
                if (!item?.route) e.preventDefault();
              }}
              exact
              to={item?.route || ''}
              activeClassName={item?.route && styles.headerItemActive}
              className={`${styles.headerItem} ${!item?.route && styles.disabledItem}`}
            >
              {item.name}
            </NavLink>
          );
        })}
        {/* openDigitalReservationPopup && (
          <DigitalReservationPopup
            open={openDigitalReservationPopup}
            handleClose={() =>
              setOpenDigitalReservationPopup(!openDigitalReservationPopup)
            }
          />
        ) */}
      </>
    );
  };

  const goToHomePage = () => {
    history.push('/');
  };

  const handleMenuPress = () => {
    setIsNotificationsOpened(false);
    setIsMobileMenuOpened(!isMobileMenuOpened);
  };

  const handleNotificationsPress = () => {
    setIsNotificationsOpened(!isNotificationsOpened);

    const unreadNotifications = stateNotifications.filter(item => !item.isRead);
    if (unreadNotifications?.length)
      handleReadOnOpenPopUp(unreadNotifications.map(notification => notification.id));
  };

  return (
    <div className={styles.container}>
      {isTablet && (
        <BurgerMenu
          renderHeaderButtons={renderHeaderButtons}
          isMobileMenuOpened={isMobileMenuOpened}
        />
      )}

      <div className={styles.headerContainer}>
        {isTablet && (
          <div className={styles.logoSection}>
            {isMobileMenuOpened ? (
              <div onClick={handleMenuPress} className="menu-icon__wrapper">
                <img className={styles.burgerMenuIcon} src={closeIcon} alt="Menu" />
              </div>
            ) : (
              <div onClick={handleMenuPress} className="menu-icon__wrapper">
                <div className={styles.burgerMenuIcon}>
                  <div className={styles.line} />
                  <div className={styles.line} />
                  <div className={styles.line} />
                </div>
              </div>
            )}
            <div onClick={goToHomePage} style={{ cursor: 'pointer', marginLeft: '10px' }}>
              <img src={horizontalLogo} alt="logo" className={styles.logoImage} />
            </div>
          </div>
        )}
        {!isTablet && (
          <div onClick={goToHomePage} style={{ cursor: 'pointer', marginLeft: '10px' }}>
            <img src={horizontalLogo} alt="logo" className={styles.logoImage} />
          </div>
        )}
        <div className={styles.headerWrapper}>
          <div className={styles.headerIconsContainer}>
            {!isLimited && (
              <div
                className={styles.notificationIconWrapper}
                onClick={handleNotificationsPress}
              >
                {unreadNotificationsCount ? (
                  <div className={styles.notificationCount}>
                    {unreadNotificationsCount}
                  </div>
                ) : null}
                <img
                  src={notificationIcon}
                  className={
                    isNotificationsOpened
                      ? `${styles.notificationIcon} ${
                          isTablet
                            ? styles.activeNotificationMobileIcon
                            : styles.activeNotificationIcon
                        }`
                      : styles.notificationIcon
                  }
                  alt="Notifications"
                />
              </div>
            )}
            <ProfileButton
              isOpenProfileModal={isOpenProfileModal}
              openProfileModal={() => setOpenProfileModal(!isOpenProfileModal)}
            />
          </div>
        </div>
      </div>
      <PopUp
        isOpened={isNotificationsOpened}
        setIsOpened={handleNotificationsPress}
        closeButtonStyles={{ top: '10px', left: '10px', boxShadow: 'none' }}
        isNotifications
      >
        <NotificationCenter notifications={stateNotifications} />
      </PopUp>
      <PopUp
        isOpened={isOpenProfileModal}
        setIsOpened={setOpenProfileModal}
        wrapperStyles={{ width: isTablet ? '100%' : 400 }}
        closeButtonStyles={{ display: 'none' }}
        isFullyOpen
      >
        <ProfileMenu />
      </PopUp>
      {isMobileMenuOpened && isTablet && (
        <div className={styles.shadow} onClick={handleMenuPress} />
      )}
    </div>
  );
};

Header.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleReadOnOpenPopUp: PropTypes.func.isRequired,
  loadClubs: PropTypes.func.isRequired,
  isOpenProfileModal: PropTypes.bool.isRequired,
  setOpenProfileModal: PropTypes.func.isRequired,
};
