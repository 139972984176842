import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { inBodyApp } from 'assets/background-images';
import history from 'store/history';
import MobileContext from 'context/MobileContext';
import styles from './styles.module.css';

const EmptySection = () => {
  const dispatch = useDispatch();
  const isMobile = useContext(MobileContext);

  const contactUs = () => {
    dispatch({
      type: 'PRESELECT_FREEZE_TOPIC',
      option: 'personalTraining',
    });
    history.push('/contact-us');
  };
  return (
    <div className={styles.emptySection}>
      <div className={styles.textBlock}>
        <h1 className={styles.header}>
        Once you have completed your InBody Test, your results will appear here.
        </h1>
        <div className={styles.textSection}>
          <div>
            <p className={styles.text}>
            Go beyond the scale with the InBody Test, a non-invasive body composition analysis that provides a detailed breakdown of your weight in terms of muscle, fat, and water on an InBody Result Sheet. An InBody Test can take anywhere from 15-120 seconds.
            </p>
            <p className={styles.boldText}>
            The InBody Test is included with your Complimentary FIT Session.
            </p><br/>
            <div className={styles.boldText}>
            You can also purchase additional Inbody Tests for $25."
            </div>
            {/* <div className={styles.buttonWrapper}>
             <div className={styles.button} onClick={contactUs}>
             Contact us
             </div>
             </div> */}
          </div>
          {isMobile && <img src={inBodyApp} alt="" />}
        </div>
      </div>
      {!isMobile && <img src={inBodyApp} alt="" />}
    </div>
  );
};

export default EmptySection;
