import { takeEvery, call, put, spawn } from 'redux-saga/effects';
import api from 'services/api';
import topicsActions from './actions';
import errorActions from '../error/actions';

const {
  Types: { LOAD_TOPICS_REQUEST },
  Creators: { loadTopicsSuccess, loadTopicsFailure },
} = topicsActions;

const {
  Creators: { setError },
} = errorActions;

function* watchLoadTopics() {
  yield takeEvery(LOAD_TOPICS_REQUEST, function* load() {
    try {
      const { data } = yield call(api.get, `/topics`);
      yield put(loadTopicsSuccess(data));
    } catch (error) {
      yield put(loadTopicsFailure(error));
      yield put(setError('Load topics error.'));
    }
  });
}

export function* topicsSaga() {
  yield spawn(watchLoadTopics);
}
