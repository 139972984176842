import { connect } from 'react-redux';
import Billing from 'components/ProfilePage/Billing';
import billingActions from 'store/billing/actions';

const mapStateToProps = state => {
  return {
    balance: state.billing?.billing?.balData?.individual
      ? state.billing.billing.balData
      : '',
    primaryCard: state.billing?.billing?.payData?.length
      ? state.billing.billing.payData.filter(it => it.isPrimary === true)[0]
      : {},
    notPrimaryCard: state.billing?.billing?.payData?.length
      ? state.billing.billing.payData.filter(it => !it.isPrimary === true)
      : [],
    url: state.billing?.url?.url ? state.billing.url.url : '',
    error: state.billing.error,
    isRequesting: state.billing.isRequesting,
    profile: state.auth.profile,
    cardInfo: state.billing.cardInfo,
  };
};

const mapDispatchToProps = {
  loadBilling: billingActions.Creators.loadBillingRequest,
  addCard: billingActions.Creators.addNewCardRequest,
  saveCardInfo: billingActions.Creators.saveCardInfoRequest,
  deleteCard: billingActions.Creators.deleteCardRequest,
  loadBillingUrl: billingActions.Creators.loadBillingUrlRequest,
  makePrimary: billingActions.Creators.makePrimaryRequest,
  makePayment: billingActions.Creators.makePaymentRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
