import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  loadTrainersRequest: null,
  loadTrainersSuccess: ['trainers', 'favoriteTrainers', 'favoriteLocation'],
  loadTrainersFailure: ['error'],

  loadPersonalTrainersRequest: null,
  loadPersonalTrainersSuccess: ['personalTrainers'],
  loadPersonalTrainersFailure: ['error'],

  sendCancelSessionEmailRequest: ['sessionData'],
  sendCancelSessionEmailSuccess: ['isSentEmail'],
  sendCancelSessionEmailFailure: ['error'],

  cleanCancelSessionEmail: null,

  selectTrainingFavoriteClub: ['selectedClubId'],
  changeTrainingSearch: ['searchValue'],
  filterTrainers: ['filteredTrainers', 'filteredFavoriteTrainers'],

  markTrainerAsFavorite: ['trainer'],
  markTrainerAsFavoriteSuccess: ['trainers', 'favoriteTrainers'],
  markTrainerAsFavoriteError: ['error', 'trainers', 'favoriteTrainers'],

  unmarkTrainerAsFavorite: ['trainer'],
  unmarkTrainerAsFavoriteSuccess: ['trainers', 'favoriteTrainers'],
  unmarkTrainerAsFavoriteError: ['error', 'trainers', 'favoriteTrainers'],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
