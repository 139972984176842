import { savePDF } from '@progress/kendo-react-pdf';

class DocService {
  createPdf = html => {
    savePDF(html, {
      paperSize: 'A2',
      landscape: true,
      fileName: 'schedule.pdf',
      margin: 30,
    });
  };
}

const Doc = new DocService();
export default Doc;
