import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  loadVisitsIsRequesting: false,
  // loadSessionsIsRequesting: false,
  loadInBodyIsRequesting: false,

  visits: {},
  // sessions: {},
  inBody: [],
  error: null,
};

const loadActivityRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    loadVisitsIsRequesting: true,
    // loadSessionsIsRequesting: true,
    loadInBodyIsRequesting: true,
    error: '',
  };
};

const loadVisitsSuccess = (state = INITIAL_STATE, { visits }) => {
  return {
    ...state,
    loadVisitsIsRequesting: false,
    visits,
    error: null,
  };
};

const loadVisitsFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    loadVisitsIsRequesting: false,
    error,
  };
};

// const loadSessionsSuccess = (state = INITIAL_STATE, { sessions }) => {
//   return {
//     ...state,
//     loadSessionsIsRequesting: false,
//     sessions,
//     error: null,
//   };
// };

// const loadSessionsFailure = (state = INITIAL_STATE, { error }) => {
//   return {
//     ...state,
//     loadSessionsIsRequesting: false,
//     error,
//   };
// };

const loadInBodySuccess = (state = INITIAL_STATE, { inBody }) => {
  return {
    ...state,
    loadInBodyIsRequesting: false,
    inBody,
    error: null,
  };
};

const loadInBodyFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    loadInBodyIsRequesting: false,
    error,
  };
};

export const HANDLERS = {
  [Types.LOAD_ACTIVITY_REQUEST]: loadActivityRequest,

  [Types.LOAD_VISITS_SUCCESS]: loadVisitsSuccess,
  [Types.LOAD_VISITS_FAILURE]: loadVisitsFailure,

  // [Types.LOAD_SESSIONS_SUCCESS]: loadSessionsSuccess,
  // [Types.LOAD_SESSIONS_FAILURE]: loadSessionsFailure,

  [Types.LOAD_IN_BODY_SUCCESS]: loadInBodySuccess,
  [Types.LOAD_IN_BODY_FAILURE]: loadInBodyFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
