import { connect } from 'react-redux';
import { ClassesPage } from 'pages/ClassesPage';
import classesActions from 'store/classes/actions';
import clubsActions from 'store/clubs/actions';
import errorActions from 'store/error/actions';
import ClassCallActions from 'store/classCall/actions';

const mapStateToProps = state => {
  return {
    clubs: state.clubs.clubs,
    classes: state.classes.classes,
    filteredClasses: state.classes.filteredClasses,
    filters: state.classes.filters,
    isExistFilters:
      state.classes.filters.categories?.length > 0 ||
      state.classes.filters.dayTime?.length > 0 ||
      state.classes.filters.goals?.length > 0 ||
      state.classes.filters.isShowVirtualClasses,
    favoriteClasses: state.classes.favoriteClasses,
    isRequesting: state.classes.isRequesting,
    loadClubsIsRequesting: state.clubs.loadClubsIsRequesting,
    searchValue: state.classes.searchValue,
    preselectedClub: state.classes.preselectedClub,
    lastSucceeded: state.classes.lastSucceeded,
    favoriteClub: state.auth.user.favoriteLocationCAId,
    isClassCallPopUpOpened: state.classCall.isPopUpOpened,
    user: state.auth.user,
  };
};
const mapDispatchToProps = {
  loadClubs: clubsActions.Creators.loadClubsRequest,
  loadClassesSchedule: classesActions.Creators.loadClassesScheduleRequest,
  loadFavoriteClasses: classesActions.Creators.loadFavoriteClassesRequest,
  addClassToFavorite: classesActions.Creators.addClassToFavoriteRequest,
  removeClassFromFavorite: classesActions.Creators.removeClassFromFavoriteRequest,
  searchClasses: classesActions.Creators.searchClasses,
  filterClasses: classesActions.Creators.filterClasses,
  changeSelectedClub: classesActions.Creators.changeSelectedClub,
  setError: errorActions.Creators.setError,
  handleOpenClassCallPopUp: ClassCallActions.Creators.handleOpenClassCallPopUp,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassesPage);
