import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import Spinner from 'components/shared/Spinner';
import Cards from './Cards';
import ReferralHistory from './ReferralHistory';
import ShareSection from './ShareSection';
import { slides } from './service';
import styles from './style.module.css';

const webSite = 'https://member.fitnesssf.com';

const InviteBenefit = ({
  sfId,
  promoCodes,
  invitedUsers,
  isRequesting,
  getInvitedUsers,
  getTrainerPromoCodes,
}) => {
  useEffect(() => {
    getInvitedUsers();
    getTrainerPromoCodes();
  }, []);

  return (
    <HeaderFooterLayout profile="Get a free month">
      {isRequesting ? (
        <Spinner />
      ) : (
        <div className={styles.container}>
          <div className={styles.info}>
            <p className={styles.title}>Refer a friend. Get a Free Month.</p>
            <p className={styles.subtitle}>
              Invite as many friends as you want and you could workout for free forever !
            </p>
          </div>
          <Cards slides={slides} />
          <ShareSection
            promoCodes={promoCodes}
            webSite={webSite}
            link={`${webSite}/join?m=${sfId}`}
          />
          <ReferralHistory list={invitedUsers} />
        </div>
      )}
    </HeaderFooterLayout>
  );
};

InviteBenefit.propTypes = {
  sfId: PropTypes.string.isRequired,
  promoCodes: PropTypes.array.isRequired,
  invitedUsers: PropTypes.array.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  getInvitedUsers: PropTypes.func.isRequired,
  getTrainerPromoCodes: PropTypes.func.isRequired,
};

export default InviteBenefit;
