import moment from 'moment';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';

const calculateSessionDate = _session => {
  if (!_session.date || !_session.startTime || !_session.endTime) return '';
  const sessionStartTime = `${_session.date} ${_session.startTime}`;
  const sessionEndTime = `${_session.date} ${_session.endTime}`;
  const sessionDuration = moment(sessionEndTime, 'YYYY//MM/DD HH:mm:ss').diff(
    moment(sessionStartTime, 'YYYY//MM/DD HH:mm:ss'),
    'minutes'
  );
  return `${moment(`${_session.date} ${_session.startTime}`).format(
    'h:mma'
  )} (${sessionDuration}min)`;
};

export const formatSession = (session, isTrainer, trainers) => {
  const title = moment(session?.fullDate).format('dddd MM/DD');
  const date = calculateSessionDate(session);
  const dateOrigin = session?.date;
  const startTime = session?.startTime;
  const endTime = session?.endTime;
  const location = session?.clubName;
  const studioName = session?.studioName;
  const personName = isTrainer ? session?.userName : session?.trainerName;
  const trainerCAid = session?.trainerCAid;
  const callId = session?.callId;
  const participantId = session?.participantId;
  const sessionStartDateTime =
    session?.date && session?.startTime ? `${session.date} ${session.startTime}` : '';
  const sessionEndDateTime =
    session?.date && session?.endTime ? `${session.date} ${session.endTime}` : '';
  const sfDateTime = getSFTime('full');
  const timeDiff = moment(sfDateTime).diff(
    moment(`${session.date}T${session.startTime}`).format('YYYY-MM-DDTHH:mm'),
    'minutes'
  );
  const note = timeDiff < 0 && timeDiff >= -120 ? 'Starts soon!' : '';
  const isAllowCancelSession =
    moment(sfDateTime).diff(
      moment(`${session.date}T${session.startTime}`).format('YYYY-MM-DDTHH:mm'),
      'hour'
    ) < -23;
  return {
    title,
    date,
    location,
    studioName,
    personName,
    userName: session?.userName,
    callId,
    participantId,
    sessionStartDateTime,
    sessionEndDateTime,
    dateOrigin,
    startTime,
    endTime,
    trainerCAid,
    note,
    isAllowCancelSession,
    mobileCard: {
      date: moment(session?.fullDate).format('MMMM DD'),
      time: moment(session?.fullDate).format('h a'),
      locationName: location,
      trainer: trainers?.find(({ idCA }) => +idCA === +session?.trainerCAid),
    },
  };
};
