/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import QRCOde from 'qrcode.react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { addToWallet, closeIcon } from 'assets/icons';
import { v1 as uuid } from 'uuid';
import { imgLogo as logo } from 'assets/logos';
import SafetyCommitment from 'components/QRModal/SafetyCommitment';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import styles from './styles.module.css';

function post(path, params, method = 'post') {
  const form = document.createElement('form');
  form.method = method;
  form.action = path;

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

const QRModal = ({
  setVisibility,
  isQRAvailable,
  isSessionQRAvailable,
  sendSafetyCommitment,
}) => {
  const handleClosePress = () => setVisibility(false);

  const code = useSelector(state => state.auth.user.scanCode);
  const fullName = useSelector(state => state.auth.user.name);

  const handleAddToWalletClick = () => {
    post(
      `${process.env.REACT_APP_API_URI}/addWalletCard/apple?scanCode=${code}&fullName=${fullName}`
    );
  };

  const isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1;

  return (
    <div
      className={`${styles.modalContainer} ${!code && styles.fullModalContainer}`}
      id="qr-modal"
      style={{ height: code && !isQRAvailable ? '100%' : null }}
    >
      <div className={styles.closeIcon} onClick={handleClosePress}>
        <img src={closeIcon} alt="close" />
      </div>
      <div className={styles.modalWrapper}>
        {code ? (
          <>
            {isQRAvailable || isSessionQRAvailable ? (
              <TwoStepsQr
                type={isSessionQRAvailable ? 'session' : 'reservation'}
                fullName={fullName}
                code={code}
                isSafari={isSafari}
                handleAddToWalletClick={handleAddToWalletClick}
                sendSafetyCommitment={sendSafetyCommitment}
              />
            ) : (
              <ReserveSpot />
            )}
          </>
        ) : (
          <div className={styles.modalWrapper}>
            <span className={styles.text}>
              Sorry, we can&#39;t find any check-in code for you. :(
            </span>
            <span className={styles.text}>
              You need to see a FITNESS SF Team Lead to help assigned a code for your
              check-in verification.
            </span>
          </div>
        )}
      </div>
      <img className={styles.logo} src={logo} alt="logo" />
    </div>
  );
};

QRModal.propTypes = {
  setVisibility: PropTypes.func,
  isQRAvailable: PropTypes.bool,
  isSessionQRAvailable: PropTypes.bool.isRequired,
  sendSafetyCommitment: PropTypes.func,
};

QRModal.defaultProps = {
  setVisibility: () => {},
  isQRAvailable: true,
  sendSafetyCommitment: () => {},
};

export default QRModal;

const ReserveSpot = () => {
  const qrModal = document.getElementById('qr-modal');
  if (qrModal?.style) qrModal.style.height = '100%';
  return (
    <div className={styles.reserveSpotWrapper}>
      <h3 className={styles.reserveSpotTitle}>Reserve a spot</h3>
      <p className={styles.reserveSpotText}>
        Please make a reservation before attending the gym.
      </p>
      <p className={styles.reserveSpotText}>
        Your QR code will become available 15 minutes before the start time before your
        reservation.
      </p>
      {/* <NavLink exact to="/reservation" className={styles.navlink}>
        Reserve a spot
      </NavLink> */}
    </div>
  );
};

const TwoStepsQr = ({
  type,
  fullName,
  code,
  isSafari,
  handleAddToWalletClick,
  sendSafetyCommitment,
}) => {
  const [commitmentPoints, setCommitmentPoints] = useState([false]);
  const [showQr, setShowQr] = useState(false);
  const [submitAvailable, setSubmitAvailable] = useState(false);

  const handleSubmitAvailable = () => {
    if (commitmentPoints.every(el => el)) {
      setSubmitAvailable(true);
    } else if (!showQr) setSubmitAvailable(false);
  };

  const handleChangeCommitmentPoints = (key, value) => {
    const answers = [...commitmentPoints];
    answers[key] = value;
    setCommitmentPoints([...answers]);
  };
  const handleSubmit = () => {
    sendSafetyCommitment();
    setShowQr(true);
  };
  useEffect(() => {
    handleSubmitAvailable();
  }, [commitmentPoints, handleSubmitAvailable]);

  if (!showQr) {
    return (
      <SafetyCommitment
        open={!showQr}
        commitmentPoints={commitmentPoints}
        handleChangeCommitmentPoints={handleChangeCommitmentPoints}
        isSubmitAvailable={submitAvailable}
        submit={handleSubmit}
      />
    );
  }

  return (
    <QR
      type={type}
      fullName={fullName}
      code={code}
      isSafari={isSafari}
      handleAddToWalletClick={handleAddToWalletClick}
    />
  );
};

TwoStepsQr.propTypes = {
  type: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  isSafari: PropTypes.bool.isRequired,
  handleAddToWalletClick: PropTypes.func.isRequired,
  sendSafetyCommitment: PropTypes.func.isRequired,
};

const QR = ({ type, fullName, code, isSafari, handleAddToWalletClick }) => {
  const upcomingReservations = useSelector(
    state => state.reservations.upcomingReservations
  );
  const sessions = useSelector(state => state.home.sessions);
  const clubs = useSelector(state => state.clubs.clubs);

  const getQrData = () => {
    let date = '';
    let clubName = '';
    let room = null;
    const sfDateTime = getSFTime('full');
    if (type === 'reservation') {
      const currentReservation = upcomingReservations.filter(
        e =>
          moment(sfDateTime).diff(e.date, 'minutes') >= -15 &&
          moment(sfDateTime).diff(e.date, 'minutes') <= 30
      );
      date = currentReservation[0]?.date;
      room = currentReservation[0]?.room;
      clubName = currentReservation[0]?.clubName;
      return { date, clubName, room };
    }
    if (type === 'session') {
      const todaySession = sessions.filter(
        s =>
          moment(s.fullDate).format('YYYY-MM-DD') ===
          moment(sfDateTime).format('YYYY-MM-DD')
      );
      date = todaySession?.length > 0 && todaySession[0]?.fullDate;
      const club =
        todaySession?.length > 0 && clubs.find(c => c.sfId === todaySession[0]?.clubId);
      clubName = club && club?.name;
    }
    return { date, clubName, room };
  };

  const { date, clubName, room } = getQrData();

  const qrModal = document.getElementById('qr-modal');
  if (qrModal?.style) qrModal.style.height = '100%';
  return (
    <>
      <span className={styles.title}>
        Reservations are no longer required, time to hit the gym!
      </span>
      <div
        className={`${styles.modalWrapper} ${type === 'session' &&
          styles.sessionWrapper}`}
      >
        <span className={styles.text}>
          Scan the QR Code at the front desk to check-in
        </span>
        {type === 'session' && <span className={styles.date}>Personal Training</span>}
        <QRCOde className={styles.code} value={code} />
        <span className={styles.uniq}>{uuid(8)}</span>
        <span className={styles.fullName}>{fullName}</span>
        <span className={styles.date}>
          {moment(date).format('dddd')} {'   '} {moment(date).format('hh:mm A')}
        </span>
        <span className={styles.date}>
          club: {clubName} {room && <span>&bull; {room}</span>}
        </span>
        <span className={styles.date}>{moment(date).format('MM/DD/YYYY')}</span>
        {/* {isSafari && (
          <div
            className={styles.addToWalletButton}
            onClick={() => handleAddToWalletClick()}
          >
            <img
              className={styles.addToWallet}
              src={addToWallet}
              alt="Add to Apple Wallet"
            />
          </div>
        )} */}
      </div>
    </>
  );
};

QR.propTypes = {
  type: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  isSafari: PropTypes.bool.isRequired,
  handleAddToWalletClick: PropTypes.func.isRequired,
};
