/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';

import TrainerCard from 'components/TrainingPage/TrainerCard';
import TrainersSection from 'components/TrainingPage/TrainersSection';
import PropTypes from 'prop-types';
import NoTrainers from 'components/TrainingPage/NoTrainers';
import SessionList from 'components/HomePage/SessionList';
import styles from './styles.module.css';

const MyTrainersTab = ({
  myPersonalTrainers,
  selectedClubId,
  sessions,
  selectTrainer,
  setIsOpened,
  handleSessionInfoClick,
  setTrainerId,
  handleOpenCallPopUp,
  isMobile,
}) => {
  useEffect(() => {}, []);
  return (
    <>
      {sessions?.length > 0 && (
        <SessionList
          title="My Next Sessions"
          sessions={sessions}
          handleSessionInfoClick={handleSessionInfoClick}
          setTrainerId={setTrainerId}
          handleOpenCallPopUp={handleOpenCallPopUp}
        />
      )}
      <TrainersSection
        title="My Personal Trainers"
        background={isMobile ? null : 'transparent'}
      >
        {myPersonalTrainers?.length > 0 ? (
          <div className={styles.inner}>
            {myPersonalTrainers?.length
              ? myPersonalTrainers.map(trainer => (
                  <div key={trainer.idCA} className={styles.trainerCardWrapper}>
                    <TrainerCard
                      {...trainer}
                      showClub
                      selectTrainer={selectTrainer}
                      setIsOpened={setIsOpened}
                      setTrainerId={setTrainerId}
                    />
                  </div>
                ))
              : null}
          </div>
        ) : (
          <NoTrainers myPersonalTrainers />
        )}
      </TrainersSection>
    </>
  );
};

MyTrainersTab.propTypes = {
  myPersonalTrainers: PropTypes.array,
  sessions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectedClubId: PropTypes.string.isRequired,
  selectTrainer: PropTypes.func.isRequired,
  setIsOpened: PropTypes.func.isRequired,
  handleSessionInfoClick: PropTypes.func.isRequired,
  setTrainerId: PropTypes.func.isRequired,
  handleOpenCallPopUp: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
MyTrainersTab.defaultProps = {
  myPersonalTrainers: [],
  sessions: null,
};
export default MyTrainersTab;
