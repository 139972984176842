import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import { Formik } from 'formik';
import { loginValidation, loginValidationForFamily } from 'validation/auth.validation';
import TabletContext from 'context/TabletContext';
import SmallMobileContext from 'context/SmallMobileContext';
import Field from 'components/shared/Field';
import Button from 'components/shared/Button';
import Text from 'components/shared/Text';
import history from 'store/history';
import { isIOS } from 'react-device-detect';
import styles from './style.module.css';
import LoginWithServices from './LoginWithServices';

const initialValues = { email: '', password: '', idCA: '' };

const Main = ({
  login,
  isRequesting,
  requestType,
  error,
  clearError,
  isFamily,
  checkFamilyIsRequesting,
  loginWithService,
}) => {
  const isTablet = useContext(TabletContext);
  const isMobile = useContext(SmallMobileContext);
  const inputWidth = isMobile ? '250px' : isTablet ? '300px' : '360px';
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (requestType === 'LOGIN_FAILURE' && error !== 'User is not exist') {
      setShowError(true);
    }
  }, [requestType, error]);

  useEffect(() => { }, []);

  const handleForgotPasswordClick = () => {
    clearError();
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={values => {
          login(values);
        }}
        validationSchema={isFamily ? loginValidationForFamily : loginValidation}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          isValid,
          dirty,
          handleBlur,
        }) => {
          return (
            <form className={`${styles.form} login-form`} onSubmit={handleSubmit}>
              <h1 className={styles.header}>Sign In</h1>
              <Field
                width={inputWidth}
                onChange={e => {
                  setShowError(false);
                  handleChange(e);
                }}
                value={values.email}
                invalid={showError ? true : touched.email && Boolean(errors.email)}
                error={showError ? error : errors.email}
                name="email"
                placeholder="Email address"
                onBlur={handleBlur}
                disabled={isRequesting}
                type="email"
                icon="email"
              />

              <Field
                type="password"
                width={inputWidth}
                onChange={e => {
                  setShowError(false);
                  handleChange(e);
                }}
                value={values.password}
                invalid={showError ? true : touched.password && Boolean(errors.password)}
                error={showError ? error : errors.password}
                name="password"
                placeholder="Password"
                onBlur={handleBlur}
                disabled={isRequesting}
                icon="password"
              />
              <NavLink to="/reset_password" onClick={handleForgotPasswordClick}>
                <Text className={styles.text}>Forgot Password?</Text>
              </NavLink>
              {!navigator.cookieEnabled && (
                <div className={styles.cookiesDisabled}>
                  Cookies are disabled. For the best performance please enable cookies in
                  browser settings and refresh the page.
                </div>
              )}
              <Button
                disabled={!(isValid && dirty) || isRequesting || checkFamilyIsRequesting}
                isRequesting={isRequesting || checkFamilyIsRequesting}
                className={styles.button}
                type="submit"
              >
                Login
              </Button>
            </form>
          );
        }}
      </Formik>

      <div className={styles.linkWrapper}>
        <Text className={styles.text}>Not a member yet ?</Text>
        <div onClick={() => history.push('/')} className={styles.link}>
          Join Now
        </div>
      </div>

      {/* <div className={styles.border}>


        <div className={styles.line} />
        <span> {isMobile ? 'Or' : 'Or sign in with'}</span>
      </div> */}
      {/* <LoginWithServices isMobile={isMobile} loginWithService={loginWithService} /> */}
    </div>
  );
};

Main.propTypes = {
  login: PropTypes.func.isRequired,
  requestType: PropTypes.string,
  isRequesting: PropTypes.bool,
  error: PropTypes.string,
  clearError: PropTypes.func.isRequired,
  loginWithService: PropTypes.func.isRequired,
  isFamily: PropTypes.bool,
  checkFamilyIsRequesting: PropTypes.bool,
};

Main.defaultProps = {
  requestType: '',
  isRequesting: false,
  error: null,
  isFamily: false,
  checkFamilyIsRequesting: false,
};

export default Main;
