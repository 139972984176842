/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import ReservationCard from 'components/shared/LessonCard/ReservationCard';
import styles from './styles.module.css';

const formatReservations = reservations => {
  if (!Array.isArray(reservations)) return [];
  const formattedReservations = [];
  const groupedByClub = groupBy(reservations, o => o.clubName);

  try {
    for (const clubProp in groupedByClub) {
      const clubReservations = groupedByClub[clubProp].map(o => ({
        ...o,
        dateHelper: moment(o.date).format('YYYY-MM-DD'),
      }));
      const clubReservationsGroupedByDateHelper = groupBy(
        clubReservations,
        o => o.dateHelper
      );
      for (const dateProp in clubReservationsGroupedByDateHelper) {
        const sfDateTime = getSFTime('full');

        clubReservationsGroupedByDateHelper[dateProp].forEach(element => {
          const ids = clubReservationsGroupedByDateHelper[dateProp].map(
            o => o.reservationId
          );

          const diff = moment(sfDateTime).diff(element.date, 'hours');
          const date = `${moment(element.date).format('dddd MM/DD')}`;
          const duration = `${moment(element.date).format('h:mmA')} (1 hour)`;
          const note = moment(sfDateTime).diff(element.date, 'minutes');
          formattedReservations.push({
            ids,
            date,
            duration,
            fullStartDate: element.date,
            clubName: clubProp,
            room: element.room,
            isCancelAvailable: diff <= -1,
            note: note < 0 && note >= -10 ? 'Starts in 10 mins!' : '',
          });
        });
      }
    }
  } catch (e) {
    return formattedReservations;
  }

  return formattedReservations;
};

const ReservationsList = ({ reservations, handleCancelReservationClick }) => {
  const formattedReservations = formatReservations(reservations);
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>My next reservation</h3>
      {formattedReservations.map(reservation => (
        <div className={styles.cardWrapper} key={reservation.date}>
          <ReservationCard
            cancelButtonAction={handleCancelReservationClick}
            {...reservation}
            cardStyles={{ minHeight: 'auto', width: '100%' }}
          />
        </div>
      ))}
    </div>
  );
};

ReservationsList.propTypes = {
  reservations: PropTypes.arrayOf(PropTypes.object),
  handleCancelReservationClick: PropTypes.func.isRequired,
};
ReservationsList.defaultProps = {
  reservations: [],
};
export default ReservationsList;
