import React from 'react';
import PropTypes from 'prop-types';
import Count from 'components/AnalyticsPage/Count';
import { newMemberShipPlanFull } from 'assets/background-images';
import styles from './style.module.css';

const labels = {
  week: 'This Week',
  month: 'This Month',
  year: 'This Year',
};

const VisitsCard = ({ title, visits }) => {
  return (
    <div
      className={styles.wrapper}
      style={{ backgroundImage: `url(${newMemberShipPlanFull})` }}
    >
      <p className={styles.title}>{title}</p>
      <div className={styles.activitiesWrapper}>
        {Object.keys(labels).map(key => (
          <div className={styles.activityItem} key={key}>
            <Count range={visits[key] ? visits[key] : 0} duration={200} />

            <p className={styles.activityName}>{labels[key]}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

VisitsCard.propTypes = {
  title: PropTypes.string.isRequired,
  visits: PropTypes.shape({
    week: PropTypes.number,
    month: PropTypes.number,
    year: PropTypes.number,
  }),
};

VisitsCard.defaultProps = {
  visits: {
    week: 0,
    month: 0,
    year: 0,
  },
};

export default VisitsCard;
