import { connect } from 'react-redux';
import Transaction from 'components/ProfilePage/Transaction';
import transactionActions from 'store/transaction/actions';

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    transaction: state.transaction.transaction,
    isTransactionRequesting: state.transaction.isTransactionRequesting,
    isStatementRequesting: state.transaction.isStatementRequesting,
    isDownload: state.transaction.isDownload,
  };
};

const mapDispatchToProps = {
  loadTransaction: transactionActions.Creators.loadTransactionRequest,
  resetTransaction: transactionActions.Creators.loadTransactionSuccess,
  loadStatement: transactionActions.Creators.loadStatementRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
