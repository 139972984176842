import { connect } from 'react-redux';
import InviteBenefit from 'components/ProfilePage/InviteBenefit';
import actions from 'store/referral/actions';

const mapStateToProps = state => {
  return {
    sfId: state.auth.user.sfId,
    invitedUsers: state.referral.invitedUsers,
    promoCodes: state.referral.promoCodes,
    isRequesting: state.referral.isRequesting,
  };
};
const mapDispatchToProps = {
  getInvitedUsers: actions.Creators.getInvitedUsersRequest,
  getTrainerPromoCodes: actions.Creators.getTrainerPromoCodesRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteBenefit);
