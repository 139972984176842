import { connect } from 'react-redux';
import Packages from 'components/ProfilePage/Packages';
import Actions from 'store/packages/actions';
import AnalyticsActions from 'store/analytics/actions';

const mapStateToProps = state => {
  return {
    packages: state.packages.packages,
    sessions: state.analytics.sessions,
    targetPackage: state.packages.targetPackage,
    isRequesting: state.packages.isRequesting,
  };
};

const mapDispatchToProps = {
  loadPackages: Actions.Creators.loadPackagesRequest,
  loadActivity: AnalyticsActions.Creators.loadActivityRequest,
  setTargetPackage: Actions.Creators.setTargetPackage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
