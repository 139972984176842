/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SessionCard from 'components/shared/LessonCard/SessionCard';
import TabletContext from 'context/TabletContext';
import { arrowRight } from 'assets/icons';
import history from 'store/history';
import styles from './style.module.css';
import { formatSession } from './service';

const SessionList = ({
  sessions,
  showAction,
  handleSessionInfoClick,
  handleOpenCallPopUp,
  setTrainerId,
  setCancelSession,
}) => {
  const isTablet = useContext(TabletContext);
  const isTrainer = useSelector(state => state.auth?.user?.isTrainer) || false;
  const trainers = useSelector(({ training }) => training.trainers);

  return (
    <div
      className={styles.wrapper}
      style={!showAction ? { maxWidth: 'inherit', marginLeft: 40 } : {}}
    >
      <h1 className={styles.title}>
        <span>My Upcoming Sessions</span>
        {isTablet && showAction ? (
          <img
            src={arrowRight}
            className={styles.arrow}
            onClick={() => history.push('/training')}
            alt=""
          />
        ) : (
          showAction && (
            <>
              <div className={styles.line} />
              <p onClick={() => history.push('/training')}>Manage</p>
            </>
          )
        )}
      </h1>
      <div className={styles.sessions}>
        {sessions?.length && trainers?.length > 0
          ? sessions.map((item, i) => {
              const info = formatSession(item, isTrainer, trainers);
              return (
                <div className={styles.item} key={`${i * 24}`}>
                  <SessionCard
                    {...info}
                    infoButtonAction={handleSessionInfoClick}
                    handleOpenCallPopUp={handleOpenCallPopUp}
                    setTrainerId={setTrainerId}
                    setCancelSession={setCancelSession}
                  />
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

SessionList.propTypes = {
  sessions: PropTypes.array,
  showAction: PropTypes.bool,
  handleSessionInfoClick: PropTypes.func.isRequired,
  setTrainerId: PropTypes.func.isRequired,
  handleOpenCallPopUp: PropTypes.func.isRequired,
  setCancelSession: PropTypes.func.isRequired,
};
SessionList.defaultProps = {
  sessions: [],
  showAction: false,
};
export default SessionList;
