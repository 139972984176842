/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import history from 'store/history';
import Carousel from 'components/shared/Carousel';
import 'react-multi-carousel/lib/styles.css';
import MobileContext from 'context/MobileContext';
import VideoCard from '../VideoCard';
import styles from './style.module.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const VideoContent = ({ setActiveVideo, showContent, title, videoList }) => {
  const isLinked = document?.location?.hash;
  const sectionRef = useRef(null);
  const isMobile = useContext(MobileContext);

  useEffect(() => {
    if (isLinked && isLinked.replace('#', '') === title)
      sectionRef.current.scrollIntoView({ block: 'center' });
  }, [isLinked]);

  return (
    <div className={styles.wrapper} ref={sectionRef}>
      <div className={styles.sectionHeader} id={title}>
        <h1>{title}</h1>
        {isMobile ? (
          <div
            className={styles.arrowSection}
            onClick={() => history.push(`/on_demand/${title}`)}
          >
            <p className={styles.seeAll}>SEE ALL</p>
          </div>
        ) : (
          <>
            <div className={styles.line} />
            <p onClick={() => history.push(`/on_demand/${title}`)}>
              {videoList.length} videos
            </p>
          </>
        )}
      </div>
      <div className={styles.carouselWrapper}>
        <Carousel responsive={responsive}>
          {videoList?.length > 0 &&
            videoList.map(video => (
              <VideoCard
                key={video.id}
                video={video}
                onWatch={setActiveVideo}
                available={showContent}
              />
            ))}
        </Carousel>
      </div>
    </div>
  );
};

VideoContent.propTypes = {
  videoList: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  setActiveVideo: PropTypes.func.isRequired,
  showContent: PropTypes.bool.isRequired,
};

export default VideoContent;
