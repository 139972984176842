import React from 'react';
import PropTypes from 'prop-types';
import { fsfLogo } from 'assets/logos';
import Main from './Main';
import SideBar from './SideBar';
import Header from './Header';
import Footer from './Footer';
import styles from './styles.module.css';

const MainContent = ({
  sendMessage,
  messageText,
  typeMessage,
  targetDialog,
  selectTargetDialog,
  resentMessages,
  targetDialogMessagesHistory,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.leftSideBar} style={{ backgroundImage: `url(${fsfLogo})` }}>
        <SideBar
          targetDialog={targetDialog}
          selectTargetDialog={selectTargetDialog}
          resentMessages={resentMessages}
        />
      </div>
      <div className={[styles.header, targetDialog && styles.headerBorder].join(' ')}>
        <Header targetDialog={targetDialog} />
      </div>
      <div className={styles.main}>
        <Main
          targetDialogMessagesHistory={targetDialogMessagesHistory}
          targetDialog={targetDialog}
        />
      </div>
      <div className={[styles.footer, targetDialog && styles.footerBorder].join(' ')}>
        <Footer
          sendMessage={sendMessage}
          messageText={messageText}
          typeMessage={typeMessage}
          targetDialog={targetDialog}
        />
      </div>
    </div>
  );
};

MainContent.propTypes = {
  sendMessage: PropTypes.func.isRequired,
  messageText: PropTypes.string.isRequired,
  typeMessage: PropTypes.func.isRequired,
  targetDialog: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  selectTargetDialog: PropTypes.func.isRequired,
  resentMessages: PropTypes.arrayOf(PropTypes.object),
  targetDialogMessagesHistory: PropTypes.arrayOf(PropTypes.object),
};

MainContent.defaultProps = {
  targetDialogMessagesHistory: [],
  resentMessages: [],
};

export default MainContent;
