import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';
import VideoPageContent from 'components/VideoPage';
import Spinner from 'components/shared/Spinner';

const VideoPage = ({
  membership,
  videos,
  isRequestingMembership,
  memberStatus,
  loadMembership,
  loadPremiumVideos,
  isRequesting,
}) => {
  useEffect(() => {
    loadMembership();
    loadPremiumVideos(document?.location?.pathname?.replace('/on_demand/', ''));
  }, [loadMembership, loadPremiumVideos]);

  return (
    <HeaderFooterLayout>
      {isRequesting ? (
        <Spinner />
      ) : (
        <VideoPageContent
          membership={membership}
          memberStatus={memberStatus}
          videos={videos}
          isRequestingMembership={isRequestingMembership}
        />
      )}
    </HeaderFooterLayout>
  );
};

VideoPage.propTypes = {
  membership: PropTypes.array.isRequired,
  memberStatus: PropTypes.string.isRequired,
  videos: PropTypes.array.isRequired,
  isRequestingMembership: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  loadMembership: PropTypes.func.isRequired,
  loadPremiumVideos: PropTypes.func.isRequired,
};

export default VideoPage;
