import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PopUp } from 'components/shared/PopUp';
import VideoPopup from 'components/LocationsPage/VideoPopup';
import { checkMembership } from 'services/checkDigitalPlan';
import { memberStatuses } from 'enums/memberStatuses';
import Header from './Header';
import DiscoverDigital from './DiscoverDigital';
import Content from './Content';
import VideoRightPopup from './VideoRightPopup';

const VideosOnDemand = ({
  membership,
  videos,
  categories,
  searchVideos,
  addUserGroup,
  searchPremiumVideo,
  filterPremiumVideo,
  isRequestingAddUserGroup,
  isRequestingMembership,
  memberStatus,
}) => {
  const [showContent, setShowContent] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [filter, setFilter] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    if (membership && !isRequestingMembership) {
      const isExistMemberShipPlan =
        checkMembership(membership) || memberStatus === memberStatuses.staff;

      setShowContent(isExistMemberShipPlan);
      setOpenPopup(!isExistMemberShipPlan);
    }
  }, [membership, isRequestingMembership, memberStatus]);

  useEffect(() => {
    if (searchValue) searchPremiumVideo(searchValue);
  }, [searchValue, searchPremiumVideo]);

  useEffect(() => {
    const optionsList = categories.map(title => {
      return { title, isSelected: false };
    });
    setCategoryList(optionsList);
  }, [categories]);

  const contentProps = {
    videos: searchValue || filter ? searchVideos : videos,
    setActiveVideo,
    showContent,
  };
  return (
    <>
      <Header
        categoryList={categoryList}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        filter={filter}
        setFilter={setFilter}
        filterPremiumVideo={filterPremiumVideo}
      />
      <Content {...contentProps} />,
      {!isShowVideo && activeVideo && (
        <PopUp isOpened={activeVideo} setIsOpened={() => setActiveVideo(null)}>
          <VideoRightPopup setShow={setIsShowVideo} video={activeVideo} />
        </PopUp>
      )}
      {isShowVideo && (
        <VideoPopup
          link={activeVideo?.url}
          open={activeVideo}
          handleClose={() => {
            setIsShowVideo(false);
            setActiveVideo(null);
          }}
          content="video"
        />
      )}
      {!showContent && openPopup && (
        <DiscoverDigital
          open={!showContent}
          handleClose={() => setOpenPopup(false)}
          subscribe={addUserGroup}
          isRequesting={isRequestingAddUserGroup}
        />
      )}
    </>
  );
};

VideosOnDemand.propTypes = {
  membership: PropTypes.array.isRequired,
  videos: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  searchVideos: PropTypes.array.isRequired,
  addUserGroup: PropTypes.func.isRequired,
  searchPremiumVideo: PropTypes.func.isRequired,
  filterPremiumVideo: PropTypes.func.isRequired,
  isRequestingAddUserGroup: PropTypes.bool.isRequired,
  isRequestingMembership: PropTypes.bool.isRequired,
  memberStatus: PropTypes.string.isRequired,
};

export default VideosOnDemand;
