import React from 'react';
import ThankYouPage from 'components/Auth/ThankYouPage';

const RequestSuccess = () => {
  return (
    <ThankYouPage
      message="We sent an email with instructions to reset your password."
      header="Forgot Password"
      linkTo="/"
      linkText="Back to sign in"
    />
  );
};

RequestSuccess.propTypes = {};

RequestSuccess.defaultProps = {};

export default RequestSuccess;
