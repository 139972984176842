import {
  AthleticTraining,
  CoresPilates,
  Cycling,
  Yoga,
  MartialArts,
  Dance,
  Recovery,
  TRX,
} from 'assets/classesIcons';

import { cap, cutlery, sun, moon } from 'assets/icons';

export const intensityPoints = [
  { value: 0, label: 'Light' },
  { value: 50, label: 'Medium' },
  { value: 100, label: 'Heavy' },
];

export const dayTimesCards = [
  {
    title: 'Morning',
    valueFrom: '0500',
    valueTo: '1200',
    icon: cap,
    isSelected: false,
  },
  {
    title: 'Lunch time',
    valueFrom: '1201',
    valueTo: '1400',
    icon: cutlery,
    isSelected: false,
  },
  {
    title: 'Afternoon',
    valueFrom: '1401',
    valueTo: '1700',
    icon: sun,
    isSelected: false,
  },
  {
    title: 'Evening',
    valueFrom: '1701',
    valueTo: '2300',
    icon: moon,
    isSelected: false,
  },
];

export const goalsCards = [
  { title: 'Improve Cardio', value: 'cardioGroup', isSelected: false },
  { title: 'Gain Muscle', value: 'muscleGroup', isSelected: false },
  { title: 'Loose weight', value: 'loseWeightGroup', isSelected: false },
  { title: 'Relax', value: 'relaxGroup', isSelected: false },
  { title: 'Keep in shape', value: 'loseWeightGroup', isSelected: false },
];

export const getClassCategories = () => {
  const list = [
    { title: 'Cycling', icon: Cycling, isSelected: false },
    { title: 'Yoga', icon: Yoga, isSelected: false },
    { title: 'Cores / Pilates', icon: CoresPilates, isSelected: false },
    { title: 'Athletic Training', icon: AthleticTraining, isSelected: false },
    { title: 'Dance', icon: Dance, isSelected: false },
    { title: 'Martial Arts', icon: MartialArts, isSelected: false },
    { title: 'Recovery', icon: Recovery, isSelected: false },
    { title: 'TRX', icon: TRX, isSelected: false },
    { title: 'Other', icon: '', isSelected: false },
  ];
  return list;
};
