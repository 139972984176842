import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { profileIcon } from 'assets/icons';
import TabletContext from 'context/TabletContext';
import styles from './styles.module.css';

const ProfileButton = ({ isOpenProfileModal, openProfileModal }) => {
  const isTablet = useContext(TabletContext);
  const photo = useSelector(({ auth }) => auth.profile?.photo);

  const handleProfileClick = () => {
    openProfileModal();
  };

  return (
    <div
      // to={isTablet ? '/profile' : '/my-account'}
      onClick={handleProfileClick}
      className={`${styles.profileIconWrapper} ${
        isTablet ? 'undefined' : isOpenProfileModal && styles.headerItemActive
      }`}
    >
      <img
        src={photo || profileIcon}
        className={photo ? styles.profilePhoto : styles.profileIcon}
        alt="Profile"
      />
    </div>
  );
};

ProfileButton.propTypes = {
  isOpenProfileModal: PropTypes.bool.isRequired,
  openProfileModal: PropTypes.func.isRequired,
};

export default ProfileButton;
