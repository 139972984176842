import React from 'react';

import PropTypes from 'prop-types';
import styles from './styles.module.css';

const DepositLimitStatus = ({ total, used }) => {
  const percentageUsed = (used / total) * 100;

  return (
    <>
      <div className={styles.detail}>
        You saw {used} out of {total}
      </div>
      <div className={styles.barContainer}>
        <div className={styles.barFill} style={{ width: `${percentageUsed}%` }} />
      </div>
    </>
  );
};
DepositLimitStatus.propTypes = {
  total: PropTypes.number.isRequired,
  used: PropTypes.number.isRequired,
};
export default DepositLimitStatus;
