import { takeLatest, call, put } from 'redux-saga/effects';
import api from 'services/api';
import premiumVideosActions from './actions';
import errorActions from '../error/actions';

const {
  Creators: { setError },
} = errorActions;

const {
  Types: {
    LOAD_PREMIUM_VIDEOS_REQUEST,
    LOAD_POPULAR_VIDEOS_REQUEST,
    LOAD_FAVORITE_VIDEOS_REQUEST,
    ADD_FAVORITE_VIDEOS_REQUEST,
    REMOVE_FAVORITE_VIDEOS_REQUEST,
  },
  Creators: {
    loadPremiumVideosSuccess,
    loadPremiumVideosFailure,

    loadPopularVideosSuccess,
    loadPopularVideosFailure,

    loadFavoriteVideosSuccess,
    loadFavoriteVideosFailure,

    addFavoriteVideosSuccess,
    addFavoriteVideosFailure,

    removeFavoriteVideosSuccess,
    removeFavoriteVideosFailure,
  },
} = premiumVideosActions;

function* loadPremiumVideos({ section }) {
  try {
    const { data } = yield call(api.get, `/premium-videos?section=${section || ''}`);
    yield put(loadPremiumVideosSuccess(data));
    if (data?.error) yield put(setError(data.error));
  } catch (error) {
    yield put(loadPremiumVideosFailure(error));
  }
}

function* loadPopularVideos() {
  try {
    const { data } = yield call(api.get, `/premium-videos/popular`);
    yield put(loadPopularVideosSuccess(data));
    if (data?.error) yield put(setError(data.error));
  } catch (error) {
    yield put(loadPopularVideosFailure(error));
  }
}

function* loadFavoriteVideos() {
  try {
    const { data } = yield call(api.get, '/premium-videos/favorite');
    yield put(loadFavoriteVideosSuccess(data));
    if (data?.error) yield put(setError(data.error));
  } catch (error) {
    yield put(loadFavoriteVideosFailure(error));
  }
}

function* addFavoriteVideos({ videoId }) {
  try {
    const { data } = yield call(
      api.post,
      `/premium-videos/add-favorite?videoId=${videoId}`
    );
    yield put(addFavoriteVideosSuccess(data));
    if (data?.error) yield put(setError(data.error));
  } catch (error) {
    yield put(addFavoriteVideosFailure(error));
  }
}

function* removeFavoriteVideos({ videoId }) {
  try {
    const { data } = yield call(
      api.remove,
      `/premium-videos/remove-favorite?videoId=${videoId}`
    );
    yield put(removeFavoriteVideosSuccess(data));
    if (data?.error) yield put(setError(data.error));
  } catch (error) {
    yield put(removeFavoriteVideosFailure(error));
  }
}

export function* premiumVideosSaga() {
  yield takeLatest(LOAD_PREMIUM_VIDEOS_REQUEST, loadPremiumVideos);
  yield takeLatest(LOAD_POPULAR_VIDEOS_REQUEST, loadPopularVideos);
  yield takeLatest(LOAD_FAVORITE_VIDEOS_REQUEST, loadFavoriteVideos);
  yield takeLatest(ADD_FAVORITE_VIDEOS_REQUEST, addFavoriteVideos);
  yield takeLatest(REMOVE_FAVORITE_VIDEOS_REQUEST, removeFavoriteVideos);
}
