import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const CallHeader = ({ callDurationString }) => {
  const participantName = useSelector(state => state.sessionCall.friendName);

  return (
    <div className={styles.headerWrapper}>
      <span className={styles.duration}>{callDurationString}</span>
      <span className={styles.participantName}>{participantName || ''}</span>
    </div>
  );
};

CallHeader.propTypes = {
  callDurationString: PropTypes.string.isRequired,
};
export default CallHeader;
