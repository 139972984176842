import { takeLatest, call, put } from 'redux-saga/effects';
import api from 'services/api';
import packagesActions from './actions';
import errorActions from '../error/actions';

const {
  Types: { LOAD_PACKAGES_REQUEST },
  Creators: { loadPackagesSuccess, loadPackagesFailure },
} = packagesActions;
const {
  Creators: { setError },
} = errorActions;
function* loadPackages() {
  try {
    const { data } = yield call(api.get, `/packages`);
    yield put(loadPackagesSuccess(data));
  } catch (error) {
    yield put(loadPackagesFailure(error));
    yield put(setError('Load packages error.'));
  }
}

export function* packagesSaga() {
  yield takeLatest(LOAD_PACKAGES_REQUEST, loadPackages);
}
