import React, { useEffect } from 'react';
import styles from './style.module.css';

const CongratulationsSection = ({ isMobile, selectedClub }) => {
  const backgroundStyle = {
    backgroundImage: `url(${
      isMobile ? selectedClub?.olsBgMobile : selectedClub?.olsBgDesktop
    })`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    width: '100%',
    height: isMobile ? '140vh' : '120vh',
    alignItems: 'center',
  };

  const points = [
    {
      serialNo: '1',
      content: <p>Visit the gym</p>,
    },
    {
      serialNo: '2',
      content: <p>Present your photo ID at the Front Desk</p>,
    },
    {
      serialNo: '3',
      content: (
        <p>
          Download the app from the
          <span>
            <a
              className={styles.highlightText}
              href="https://apps.apple.com/us/app/fitness-sf/id1484657961"
            >
              App Store
            </a>
          </span>
          or
          <br />
          <span>
            <a
              className={styles.highlightText}
              href="https://play.google.com/store/apps/details?id=com.follow.fitnesssf3"
            >
              Google Play Store
            </a>
          </span>
          .
        </p>
      ),
    },
    {
      serialNo: '4',
      content: <p> You're ready to start working out!</p>,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      window.location.reload();
    }, 10000);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div style={{ ...backgroundStyle }}>
        <div className={`${styles.overlayContent} ${isMobile ? styles.mobile : ''}`}>
          <h1 className={`${styles.welcomeHeading} ${isMobile ? styles.mobile : ''}  `}>
            Welcome to FITNESS SF <br /> {selectedClub?.displayName}
          </h1>
          <div className={styles.overlayContainer}>
            <div className={`${styles.overlay} ${isMobile ? styles.mobile : ''}`}>
              <h1 className={`${styles.contentHeading} ${isMobile ? styles.mobile : ''}`}>
                You're Almost There!
              </h1>
              <p
                className={`${styles.contentSubheading} ${isMobile ? styles.mobile : ''}`}
              >
                Follow these steps to complete the {isMobile ? <br /> : ''} process of
                activating
                {isMobile ? '' : <br />} your membership.
              </p>

              <div className={`${styles.divider} ${isMobile ? styles.mobile : ''}`}></div>
              {points.map((data, i) => {
                return (
                  <div
                    className={`${styles.pointWrapper} ${isMobile ? styles.mobile : ''}`}
                  >
                    <div className={`${styles.serial} ${isMobile ? styles.mobile : ''}`}>
                      <div
                        className={`${styles.itemNoDiv} ${isMobile ? styles.mobile : ''}`}
                      >
                        <p
                          className={`${styles.itemNo} ${isMobile ? styles.mobile : ''}`}
                        >
                          {data.serialNo}
                        </p>
                      </div>
                    </div>
                    <p className={`${styles.pointName} ${isMobile ? styles.mobile : ''}`}>
                      {data.content}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CongratulationsSection;
