/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import MobileContext from 'context/MobileContext';
import { BarChart, Bar, Cell, XAxis, CartesianGrid, Tooltip } from 'recharts';
import { arrowRightWhite as arrowRight, arrowLeftWhite as arrowLeft } from 'assets/icons';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import styles from './style.module.css';

const daysOfWeek = [
  { number: 1, name: 'Mondays' },
  { number: 2, name: 'Tuesdays' },
  { number: 3, name: 'Wednesdays' },
  { number: 4, name: 'Thursdays' },
  { number: 5, name: 'Fridays' },
  { number: 6, name: 'Saturdays' },
  { number: 0, name: 'Sundays' },
];

const amPmComparator = {
  0: '12 AM',
  1: '1 AM',
  2: '2 AM',
  3: '3 AM',
  4: '4 AM',
  5: '5 AM',
  6: '6 AM',
  7: '7 AM',
  8: '8 AM',
  9: '9 AM',
  10: '10 AM',
  11: '11 AM',
  12: '12 PM',
  13: '1 PM',
  14: '2 PM',
  15: '3 PM',
  16: '4 PM',
  17: '5 PM',
  18: '6 PM',
  19: '7 PM',
  20: '8 PM',
  21: '9 PM',
  22: '10 PM',
  23: '11 PM',
};

const Tick = ({ x, y, payload }) => {
  const splitted = payload.value.split(' ');
  const hours = [3, 6, 9, 12];
  if (hours.includes(Number(splitted[0])))
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={8}
          textAnchor="end"
          fill="#fff"
          fontFamily="Futura Book"
          fontSize="10px"
        >
          <tspan textAnchor="middle">{splitted[0]}</tspan>
        </text>
        <text
          x={0}
          y={0}
          dy={20}
          textAnchor="end"
          fill="#fff"
          fontFamily="Futura Book"
          fontSize="9px"
        >
          <tspan textAnchor="middle">{splitted[1]}</tspan>
        </text>
      </g>
    );
  return null;
};

Tick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
};

Tick.defaultProps = {
  x: null,
  y: null,
  payload: null,
};

const CustomTooltip = ({ active, payload, label, ...props }) => {
  const status = payload?.[0]?.payload?.status;
  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          width: 'auto',
          height: 'auto',
          border: '1px solid #d8d8d8',
          backgroundColor: '#ffffff',
          boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.1)',
          boxSizing: 'border-box',
          padding: '10px',
          borderRadius: '2px',
        }}
      >
        <p
          style={{
            color: '#444444',
            fontFamily: 'Futura Book',
            fontSize: '14px',
            fontWeight: '300',
            letterSpacing: '0',
            lineHeight: '19px',
            whiteSpace: 'nowrap',
          }}
        >{`${label.toLowerCase().replace(' ', '')}: ${status}`}</p>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string,
  payload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

CustomTooltip.defaultProps = {
  active: null,
  label: '',
  payload: null,
};

const getNameOfDay = dayNumber => {
  return daysOfWeek.find(dayObject => dayObject.number === dayNumber).name;
};

const Chart = ({ clubsStatistic }) => {
  const isMobile = useContext(MobileContext);
  const now = new Date();
  const [focusBar, setFocusBar] = useState(null);
  const [targetDayNumber, setTargetDayNumber] = useState(now.getDay());
  const [targetDayName, setTargetDayName] = useState(getNameOfDay(now.getDay()));
  const [targetDayStatistic, setTargetDayStatistic] = useState([]);

  const getStatisticPerDay = (day, statistic) => {
    const statisticPerDay = sortBy(statistic[day], o => o.time);
    const formatted = statisticPerDay.map(statisticItem => {
      return {
        time: amPmComparator[statisticItem.time],
        status: statisticItem.status,
        average: statisticItem.average,
      };
    });
    const result = uniqBy(formatted, 'time');
    setTargetDayStatistic(result);
  };

  useEffect(() => {
    getStatisticPerDay(targetDayNumber, clubsStatistic);
  }, [clubsStatistic]);

  const handleNextClick = () => {
    if (targetDayNumber === 6) {
      setTargetDayNumber(0);
      setTargetDayName(getNameOfDay(0));
      getStatisticPerDay(0, clubsStatistic);
    } else {
      setTargetDayNumber(targetDayNumber + 1);
      setTargetDayName(getNameOfDay(targetDayNumber + 1));
      getStatisticPerDay(targetDayNumber + 1, clubsStatistic);
    }
  };

  const handlePrevClick = () => {
    if (targetDayNumber === 0) {
      setTargetDayNumber(6);
      setTargetDayName(getNameOfDay(6));
      getStatisticPerDay(6, clubsStatistic);
    } else {
      setTargetDayNumber(targetDayNumber - 1);
      setTargetDayName(getNameOfDay(targetDayNumber - 1));
      getStatisticPerDay(targetDayNumber - 1, clubsStatistic);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.control}>
        <div onClick={handlePrevClick} className={styles.arrowWrapper}>
          <img src={arrowLeft} alt="Back" className={styles.arrow} />
        </div>
        <h3 className={styles.dayOfWeek}>{targetDayName}</h3>
        <div onClick={handleNextClick} className={styles.arrowWrapper}>
          <img src={arrowRight} alt="Next" className={styles.arrow} />
        </div>
      </div>
      <BarChart
        width={isMobile ? 280 : 280}
        height={isMobile ? 100 : 110}
        data={targetDayStatistic}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 15,
        }}
        onMouseMove={state => {
          if (state.isTooltipActive) {
            setFocusBar(state.activeTooltipIndex);
          } else {
            setFocusBar(null);
          }
        }}
      >
        <CartesianGrid horizontal vertical={false} stroke="#667F88" />
        <XAxis
          dataKey="time"
          stroke="#667F88"
          fontFamily="Futura Book"
          tick={<Tick />}
          interval={0}
        />
        <Tooltip cursor={{ fill: 'transparent' }} content={<CustomTooltip />} />
        <Bar
          dataKey="average"
          fill="#ffffff"
          width={10}
          barSize={16}
          radius={[2, 2, 0, 0]}
        >
          {targetDayStatistic.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={focusBar === index ? '#fe5000' : '#ffffff'}
            />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
};

Chart.propTypes = {
  clubsStatistic: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default Chart;
