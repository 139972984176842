import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import MobileContext from 'context/MobileContext';
import styles from './styles.module.css';
import { editIcon } from '../../../../assets/icons';
import IconSVG from '../../../shared/IconSVG';

const getSlotRange = timeStart => {
  const timeEnd = moment(timeStart).add(1, 'hours');
  return `${moment(timeStart).format('h:mma')} - ${moment(timeEnd).format('h:mma')}`;
};
const checkCancelAvailable = (reservedItems, sfDateTime) => {
  if (!Array.isArray(reservedItems) || !reservedItems.length) return false;
  let isAvailable = false;
  isAvailable = reservedItems.every(e => moment(sfDateTime).diff(e.date, 'hours') <= -1);
  return isAvailable;
};

const ReservationSlot = ({
  timeSlot,
  date,
  numberOfSeats,
  reservationId,
  status,
  slotClick,
  upcomingReservations,
}) => {
  const isMobile = useContext(MobileContext);
  const timeRangeString = getSlotRange(date);
  const sfDateTime = getSFTime('full');
  const isCancelAvailable = checkCancelAvailable(upcomingReservations, sfDateTime);
  const isModifyAvailable = moment(sfDateTime).diff(date, 'hours') <= -1;
  const isReserveAvailable = upcomingReservations.length
    ? status === 'open' &&
      moment(sfDateTime).diff(date, 'minutes') <= 15 &&
      isCancelAvailable
    : status === 'open' && moment(sfDateTime).diff(date, 'minutes') <= 15;
  const isShowReservation = upcomingReservations.find(
    el => el.reservationId === reservationId
  );
  if ((status === 'reserved' || status === 'full') && isShowReservation) {
    return (
      <div
        className={styles.wrapperReserved}
        style={{
          cursor: 'auto',
          justifyContent: isModifyAvailable ? 'inherit' : 'center',
        }}
      >
        <div className={styles.leftContainer}>
          <div className={styles.timeStatusWrapper}>
            <span className={styles.timeSlotReserved}>{timeRangeString}</span>
          </div>
          {isModifyAvailable ? (
            <div className={styles.cancelButtonsSection}>
              {isMobile ? (
                <IconSVG
                  src={editIcon}
                  handleClick={() => slotClick(status, reservationId)}
                />
              ) : (
                <button
                  type="button"
                  onClick={() => slotClick(status, reservationId)}
                  className={styles.reservedButton}
                >
                  Cancel
                </button>
              )}
            </div>
          ) : null}
        </div>
        <span className={styles.statusReserved}>Reserved!</span>
      </div>
    );
  }
  if (status === 'full') {
    return (
      <div className={styles.wrapperFull} style={{ cursor: 'auto' }}>
        <div className={styles.timeStatusWrapper}>
          <span className={styles.timeSlot}>{timeRangeString}</span>
          <span className={styles.statusFull}>Fully booked</span>
        </div>
        {isModifyAvailable && isShowReservation ? (
          <div className={styles.cancelButtonsSection}>
            <button
              type="button"
              onClick={() => slotClick('reserved', reservationId)}
              className={[styles.reservedButton, styles.reservedButtonBlack].join(' ')}
            >
              Cancel
            </button>
          </div>
        ) : null}
      </div>
    );
  }

  if (status === 'old') {
    return (
      <div className={styles.wrapperFull} style={{ cursor: 'auto' }}>
        <div className={styles.timeStatusWrapper}>
          <span className={`${styles.timeSlot} ${styles.oldTimeSlot}`}>
            {timeRangeString}
          </span>
        </div>
      </div>
    );
  }
  return (
    <div
      className={styles.wrapper}
      style={{ cursor: isReserveAvailable ? 'pointer' : 'auto' }}
      onClick={() => {
        if (isReserveAvailable) slotClick(status, timeSlot);
      }}
    >
      <div className={styles.timeStatusWrapper}>
        <div className={styles.leftContainer}>
          <div className={styles.timeStatusWrapper}>
            <span className={styles.timeSlot}>{timeRangeString}</span>
          </div>

          <div className={styles.bookButtonsSection}>
            <button
              type="button"
              onClick={() => {
                if (isReserveAvailable) slotClick(status, timeSlot);
              }}
              className={[styles.bookButton, styles.bookButtonBlack].join(' ')}
            >
              Book!
            </button>
          </div>
        </div>
        <span className={styles.numberOfSeats}>{`${numberOfSeats} spots available`}</span>
      </div>
    </div>
  );
};

ReservationSlot.propTypes = {
  timeSlot: PropTypes.number,
  numberOfSeats: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  slotClick: PropTypes.func.isRequired,
  reservationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  upcomingReservations: PropTypes.array.isRequired,
};

ReservationSlot.defaultProps = {
  timeSlot: null,
  reservationId: null,
};

export default ReservationSlot;
