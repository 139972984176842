import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePickerItem from 'components/ReservationPage/DatePickerItem';
import moment from 'moment';
import {
  arrowRight,
  arrowLeftBlack as arrowLeft,
  arrowLeftGray,
  arrowRightGray,
} from 'assets/icons';
import styles from './styles.module.css';

const DatePicker = ({ datesRange, selectDate, targetDate }) => {
  const [weekIndex, handleWeekIndex] = useState(0);

  const handleNextClick = () => {
    if (weekIndex !== 1) handleWeekIndex(weekIndex + 1);
  };
  const handlePrevClick = () => {
    if (weekIndex === 1) handleWeekIndex(weekIndex - 1);
  };

  useEffect(() => {
    selectDate(datesRange?.slice(weekIndex * 7, weekIndex * 7 + 7)[0]?.index);
  }, [weekIndex]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{targetDate?.monthOfYear}</div>
      <div className={styles.itemsWrapper}>
        <div onClick={handlePrevClick} className={styles.arrowWrapper}>
          <img src={weekIndex !== 0 ? arrowLeft : arrowLeftGray} alt="Back" />
        </div>
        {datesRange?.slice(weekIndex * 7, weekIndex * 7 + 7).map(date => (
          <div className={styles.itemWrapper} key={`${date?.index}${date?.date}`}>
            <DatePickerItem
              dayOfWeek={date.dayOfWeek}
              dayMonth={date.dayMonth}
              selectDate={selectDate}
              dateIndex={date.index}
              selectedDateIndex={targetDate.index}
              day={moment(date?.date).format('D')}
            />
          </div>
        ))}
        <div onClick={handleNextClick} className={styles.arrowWrapper}>
          <img src={weekIndex !== 1 ? arrowRight : arrowRightGray} alt="Forward" />
        </div>
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  datesRange: PropTypes.oneOfType([PropTypes.array, PropTypes.arrayOf(PropTypes.object)]),
  selectDate: PropTypes.func.isRequired,
  targetDate: PropTypes.object.isRequired,
};
DatePicker.defaultProps = {
  datesRange: [],
};

export default DatePicker;
