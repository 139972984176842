import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Menu from './Menu';
import styles from './style.module.css';

const RegistrationLayout = ({ tab, title, children }) => {
  return (
    <div className={styles.page}>
      <Header isHideMenu={title === 'Congratulation!'} />

      <div className={styles.layout}>
        {title !== 'Congratulation!' && <Menu tab={tab} />}

        <div className={styles.wrapper}>
          {title && title !== 'Congratulation!' && (
            <div className={styles.title}>
              <p>
                {title.substring(0, title.lastIndexOf(' '))}{' '}
                <span className={styles.highlight}>
                  {title.split(' ')[title.split(' ').length - 1]}
                </span>
              </p>
            </div>
          )}

          <div className={`${styles.formWrapper} ${styles.fullSection}`}>{children}</div>
        </div>
      </div>
    </div>
  );
};

RegistrationLayout.propTypes = {
  title: PropTypes.string.isRequired,
  tab: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
};

export default RegistrationLayout;
