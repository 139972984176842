import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CallWindow from 'components/shared/SessionCallPopUp/MainContentMobile/CallWindow';
import CallHeader from 'components/shared/SessionCallPopUp/MainContentMobile/MainWindow/CallHeader';
import CallFooter from 'components/shared/SessionCallPopUp/MainContentMobile/MainWindow/CallFooter';
import styles from './styles.module.css';

const MainWindow = ({
  localSrc,
  peerSrc,
  toggle,
  endCall,
  callDurationString,
  callIsActive,
  isRequesting,
  networkIsFailed,
}) => {
  const [clientHeight, setClientHeight] = useState(0);
  useEffect(() => {
    setClientHeight(document.documentElement.clientHeight);
  }, []);
  return (
    <div
      className={styles.wrapper}
      id="video-call"
      style={{ height: `${clientHeight}px` }}
    >
      <CallHeader callDurationString={callDurationString} />
      <CallWindow
        localSrc={localSrc}
        peerSrc={peerSrc}
        callIsActive={callIsActive}
        isRequesting={isRequesting}
        networkIsFailed={networkIsFailed}
      />
      <CallFooter toggle={toggle} localSrc={localSrc} endCall={endCall} />
    </div>
  );
};
MainWindow.propTypes = {
  localSrc: PropTypes.object.isRequired,
  peerSrc: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  endCall: PropTypes.func.isRequired,
  callDurationString: PropTypes.string.isRequired,
  callIsActive: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  networkIsFailed: PropTypes.bool.isRequired,
};

export default MainWindow;
