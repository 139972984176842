import React from 'react';
import PropTypes from 'prop-types';
import Dialogs from './Dialogs';
import DialogItem from './Dialogs/DialogItem';
import styles from './styles.module.css';

const SideBar = ({ selectTargetDialog, targetDialog, resentMessages }) => {
  const directorDialog = resentMessages.find(message => message.isDirector === true)
    ? resentMessages.find(message => message.isDirector === true)
    : null;

  const restDialogs = resentMessages.filter(message => message.isDirector === false);
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Chats</h3>
      {directorDialog ? (
        <div className={styles.directorWrapper}>
          <h3 className={styles.directorTitle}>Fitness Director</h3>
          <DialogItem
            {...directorDialog}
            selectTargetDialog={selectTargetDialog}
            targetDialog={targetDialog}
          />
        </div>
      ) : null}

      <Dialogs
        targetDialog={targetDialog}
        selectTargetDialog={selectTargetDialog}
        resentMessages={restDialogs}
      />
    </div>
  );
};

SideBar.propTypes = {
  selectTargetDialog: PropTypes.func.isRequired,
  targetDialog: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  resentMessages: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
};
SideBar.defaultProps = {
  resentMessages: [],
};
export default SideBar;
