import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './style.module.css';

function SidebarItem({ name, icon, path }) {
  return (
    <NavLink
      key={name}
      to={path}
      className={styles.wrapper}
      activeClassName={path && styles.active}
      exact
    >
      <div className={`${styles.menuItem} ${!path && styles.disabled}`}>
        <span className={styles.itemName}>{name}</span>
        {path && <img src={icon} className={styles.icon} alt="" />}
      </div>
    </NavLink>
  );
}

SidebarItem.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string,
  icon: PropTypes.string.isRequired,
};
SidebarItem.defaultProps = {
  path: '',
};

export default SidebarItem;
