import { takeLatest, call, put, delay } from 'redux-saga/effects';
import api from 'services/api';
import caseActions from './actions';
import errorActions from '../error/actions';

const {
  Types: { POST_CASE_REQUEST },
  Creators: { postCaseSuccess, postCaseFailure },
} = caseActions;

const {
  Creators: { setError },
} = errorActions;

function* load({ payload }) {
  try {
    yield call(api.post, `/case`, payload, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    yield put(postCaseSuccess(true));
    yield delay(2000);
    yield put(postCaseSuccess(false));
  } catch (error) {
    yield put(postCaseFailure(error));
    yield put(setError('Post case error.'));
  }
}

export function* caseSaga() {
  yield takeLatest(POST_CASE_REQUEST, load);
}
