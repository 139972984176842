import { connect } from 'react-redux';
import VaccineVerificationPage from 'pages/VaccineVerificationPage';
import authAction from 'store/auth/actions';
import signUpActions from 'store/signUp/actions';

const mapStateToProps = state => ({
  isAuth: state.auth.isAuth,
  user: state.auth.user,
  personByContactId: state.signUp.referralPerson,
  vaccineStatus: state.auth?.profile?.vaccineStatus,
});

const mapDispatchToProps = {
  setVaccineStatus: authAction.Creators.setVaccineStatusRequest,
  checkContactId: signUpActions.Creators.checkReferralCodeRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(VaccineVerificationPage);
