import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import { oakland } from 'assets/icons';
import { CircularProgress } from '@mui/material';
import NextButton from 'layouts/RegistrationLayoutNew/Buttons/NextButton';
const ClubCards = (
  data,
  selectedClub,
  setSelectedClub,
  onNext,
  loadSortedClubs,
  isMobile,
  isSmallMobile,
  isLargeMobile
) => {
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const handleMouseEnter = index => {
    setHoveredIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  const handleSelectClub = club => {
    if (isMobile) {
      setSelectedClub(club);
    } else {
      setSelectedClub(club);
      onNext();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={styles.main}>
        {data.length === 0 ? (
          <div>
            <CircularProgress
              className={styles.circularProgress}
              style={{ color: '#fe5000' }}
            />
          </div>
        ) : (
          <>
            {data?.map((club, i) => {
              return (
                <>
                  <div
                    onClick={() => {
                      handleSelectClub(club.clubautomationclubidC);
                    }}
                    id={club.clubautomationclubidC}
                    key={club.clubautomationclubidC}
                    className={`${styles.card}  }`}
                  >
                    <div
                      className={`${styles.backgroundImg} ${isMobile &&
                        selectedClub === club.clubautomationclubidC &&
                        styles.activeCard} `}
                    >
                      <div
                        className={`${styles.backgroundImage} `}
                        onMouseEnter={() => handleMouseEnter(i)}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          backgroundImage: `url(${
                            i === hoveredIndex
                              ? oakland
                              : !isMobile
                              ? club.olsBgDesktop
                              : club.olsBgMobile
                          })`,
                        }}
                      >
                        <div
                          className={`${styles.overlay} ${
                            isMobile ? styles.mobile : ''
                          } ${isSmallMobile ? styles.smallMob : ''} ${
                            isLargeMobile ? styles.largeMob : ''
                          }`}
                        >
                          <h1
                            className={`${styles.clubName} ${
                              isMobile ? styles.mobile : ''
                            } ${isSmallMobile ? styles.smallMob : ''} ${
                              isLargeMobile ? styles.largeMob : ''
                            }`}
                          >
                            {club.displayName}
                          </h1>
                          <p
                            className={`${styles.clubDistance} ${
                              isMobile ? styles.mobile : ''
                            } ${isSmallMobile ? styles.smallMob : ''} ${
                              isLargeMobile ? styles.largeMob : ''
                            }`}
                          >
                            {' '}
                            {i === hoveredIndex ? (
                              isMobile ? (
                                <>
                                  <p>{club.streetC} </p>
                                  <p className={styles.city}> {club.cityC}</p>
                                </>
                              ) : (
                                club.streetC + ' , ' + club.cityC
                              )
                            ) : club.miles !== null ? (
                              club.miles + ' miles away '
                            ) : null}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            {isMobile ? (
              <>
                <div className={styles.scroll}></div>
                <NextButton
                  onNext={() => onNext()}
                  disabled={selectedClub == null}
                  nextButtonName="Select"
                />
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

const SelectClubSection = ({
  clubs,
  selectedClub,
  setSelectedClub,
  onNext,
  loadSortedClubs,
  isMobile,
  isSmallMobile,
  isLargeMobile,
}) => {
  return (
    <>
      {ClubCards(
        clubs,
        selectedClub,
        setSelectedClub,
        onNext,
        loadSortedClubs,
        isMobile,
        isSmallMobile,
        isLargeMobile
      )}
    </>
  );
};

SelectClubSection.propTypes = {
  clubs: PropTypes.array.isRequired,
  selectedClub: PropTypes.string.isRequired,
  setSelectedClub: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default SelectClubSection;
