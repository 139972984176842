import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import moment from 'moment';

export const calculateFourWeeksDates = () => {
  let date = getSFTime();
  const datesRange = [];
  for (let i = 1; i < 8; i++) {
    const dayObject = {
      index: i,
      date: moment(date).format('YYYY-MM-DD'),
      dateReservation: moment(date).format('DD/MM/YYYY'),
      dayOfWeek: moment(date).format('dddd'),
      dayMonth: moment(date).format('MM/DD'),
      monthOfYear: moment(date).format('MMMM YYYY'),
    };
    datesRange.push(dayObject);
    date = moment(date).add(1, 'd');
  }
  return datesRange;
};

export const getClubById = (clubs, clubId) => {
  if (Array.isArray(clubs)) return clubs.find(club => club.value === clubId);
  return '';
};

export const checkCancelAvailable = reservedItems => {
  if (!Array.isArray(reservedItems) || !reservedItems.length) return false;
  let isAvailable = false;
  if (reservedItems.length === 1) {
    const sfDateTime = getSFTime('full');
    const diff = moment(sfDateTime).diff(reservedItems[0]?.date, 'hours');
    isAvailable = diff <= -1;
  }
  return isAvailable;
};
