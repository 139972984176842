import React from 'react';
import PropTypes from 'prop-types';
import history from 'store/history';
import styles from './style.module.css';

const EmptyCard = ({ icon, title, text, note, redirectTo }) => {
  return (
    <div className={styles.wrapper} onClick={() => history.push(redirectTo)}>
      <img src={icon} alt="" />
      <p className={styles.title}>{title}</p>
      <div className={styles.line} />
      <p className={styles.text}>{text}</p>
      <p className={styles.note}>{note}</p>
    </div>
  );
};

EmptyCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  redirectTo: PropTypes.string.isRequired,
};

export default EmptyCard;
