import { connect } from 'react-redux';
import ReservationPage from 'pages/ReservationPage';
import ClubsStatisticActions from 'store/clubsStatistic/actions';
import Actions from 'store/reservations/actions';
import ClubActions from 'store/clubs/actions';
import AuthActions from 'store/auth/actions';

const mapStateToProps = state => {
  return {
    clubs: state.clubs.clubs,
    rooms: state.clubs.rooms || [],
    targetClubIsRequesting: state.clubs.targetClubIsRequesting,
    reservations: state.reservations.reservations,
    upcomingReservations: state.reservations.upcomingReservations,
    isRequesting: state.reservations.isRequesting,
    isSaveRequesting: state.reservations.isSaveRequesting,
    isDeleteRequesting: state.reservations.isDeleteRequesting,
    userFavoriteLocation: state.auth.user.favoriteLocation,
    isReservationAvailable: state.reservations.isReservationAvailable,
    isModifyRequesting: state.reservations.isModifyRequesting,
    isReservationBanned: state.reservations.isBanned,
    dateOfFinishBan: state.reservations.dateOfFinishBan,
    missedCount: state.reservations.missedCount,
    reservationUpcomingDaySlotList: state.reservations.reservationUpcomingDaySlotList,
    selectedClub: state.clubsStatistic.selectedClub,
  };
};
const mapDispatchToProps = {
  initLoadReservations: Actions.Creators.initLoadReservationsRequest,
  loadReservations: Actions.Creators.loadReservationsRequest,
  loadRooms: ClubActions.Creators.loadRoomsRequest,
  saveReservations: Actions.Creators.saveReservationsRequest,
  deleteReservation: Actions.Creators.deleteReservationRequest,
  modifyReservations: Actions.Creators.modifyReservationsRequest,
  selectClub: ClubsStatisticActions.Creators.selectClub,
  checkMemberStatus: AuthActions.Creators.checkMemberStatusRequest,
  loadMembership: AuthActions.Creators.loadMembershipRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(ReservationPage);
