/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/shared/MaterialUITable';
import styles from './styles.module.css';

const createColumns = () => {
  return [
    {
      Header: 'Session date',
      accessor: 'date',
      Cell: ({ cell: { value } }) => {
        return <div className={styles.date}>{value}</div>;
      },
      textAlignHeader: 'left',
      textAlignCell: 'left',
      width: '30%',
      id: 1,
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ cell: { value } }) => {
        return <span className={styles.trainer}>{value || '-'}</span>;
      },
      textAlignHeader: 'left',
      textAlignCell: 'left',
      width: '35%',
      id: 3,
    },
  ];
};

function TableDetails({ data, handleOpenPopUp }) {
  const columns = createColumns(handleOpenPopUp);
  return <>{data.length ? <Table data={data} columns={columns} /> : null}</>;
}
TableDetails.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
TableDetails.defaultProps = { data: [] };

export default TableDetails;
