import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const VideoRightPopup = ({ video, setShow }) => {
  const { id, title, description, author, thumbnail = null } = video;

  return (
    <div className={styles.videoPopup}>
      <img
        className={styles.videoImage}
        src={`https://fsf-assets.s3.us-west-1.amazonaws.com/${thumbnail}`}
        alt="Thumbnail"
      />
      <div className={styles.text}>
        <h1>{title}</h1>
        <p className={styles.author}>{author}</p>
        <p className={styles.description}>{description}</p>
        <div className={styles.watchNow} onClick={() => setShow(id)}>
          WATCH NOW
        </div>
      </div>
    </div>
  );
};

VideoRightPopup.propTypes = {
  video: PropTypes.object.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default VideoRightPopup;
