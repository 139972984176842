/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DesktopTemplate from 'components/ReservationPage/MainContent/Desktop';
import MobileTemplate from 'components/ReservationPage/MainContent/Mobile';
import { PopUp } from 'components/shared/PopUp';
import { Modal } from 'components/shared/Modal';
import ReservePopUpInner from 'components/ReservationPage/ReservePopUp';
import BannedBlock from 'components/ReservationPage/MainContent/BannedBlock';
import CancelReservationPopUp from 'components/ReservationPage/CancelReservationPopUp';
import DisableReservationPopUp from 'components/ReservationPage/DisabledReservationsModal';
import { calculateFourWeeksDates, checkCancelAvailable, getClubById } from './service';

const MainContent = props => {
  const {
    clubs,
    rooms,
    loadRooms,
    loadReservations,
    reservations,
    upcomingReservations,
    isRequesting,
    isSaveRequesting,
    isDeleteRequesting,
    isTablet,
    isReservationBanned,
    dateOfFinishBan,
    missedCount,
    selectedClub,
    selectClub,
    checkMemberStatus,
    initLoadReservations,
    targetClubIsRequesting,
  } = props;
  const datesRange = calculateFourWeeksDates();
  const [targetDate, setTargetDate] = useState(datesRange[0]);
  const [targetRoom, setTargetRoom] = useState('');
  const [targetClub, setTargetClub] = useState(selectedClub || '');
  const [popUpIsOpened, setPopUpIsOpened] = useState(false);
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const [
    disabledReservationModalIsOpened,
    setDisabledReservationModalIsOpened,
  ] = useState(false);
  const [selectedTimeSlotNumber, setSelectedTimeSlotNumber] = useState(null);
  const [selectedTimeSlotIds, setSelectedTimeSlotIds] = useState([]);

  const [isSaveRequest, setIsSaveRequest] = useState(false);
  const [isDeleteRequest, setIsDeleteRequesting] = useState(false);

  useEffect(() => {
    checkMemberStatus();
  }, [checkMemberStatus]);

  useEffect(() => {
    if (targetClub) {
      initLoadReservations(targetDate.date, targetClub, targetRoom);
      selectClub(null);
    }
  }, [targetClub, initLoadReservations, selectClub]);

  useEffect(() => {
    if (isSaveRequesting) setIsSaveRequest(true);
  }, [isSaveRequesting]);

  useEffect(() => {
    if (isDeleteRequesting) setIsDeleteRequesting(true);
  }, [isDeleteRequesting]);

  useEffect(() => {
    if (isSaveRequest && !isSaveRequesting) {
      setIsSaveRequest(false);
      handleOpenPopUp(false);
    }
    if (isDeleteRequest && !isDeleteRequesting) {
      setIsDeleteRequesting(false);
      handleOpenModal(false);
      handleOpenDisabledReservationModal(false);
    }
  }, [
    isSaveRequesting,
    isDeleteRequesting,
    handleOpenModal,
    handleOpenDisabledReservationModal,
  ]);

  const handleChangeRoom = room => {
    setTargetRoom(room);
    if (targetClub) loadReservations(targetClub, targetDate.date, room);
  };

  const handleSelectDate = dayIndex => {
    if (targetDate.index !== dayIndex) {
      const selectedDate = datesRange.find(date => date.index === dayIndex);
      setTargetDate(selectedDate);
      loadReservations(targetClub, selectedDate.date, targetRoom);
    }
  };

  const handleOpenPopUp = value => {
    setPopUpIsOpened(value !== undefined ? value : !popUpIsOpened);
  };

  const handleOpenModal = value => {
    setModalIsOpened(value !== undefined ? value : !modalIsOpened);
  };

  const handleOpenDisabledReservationModal = value => {
    setDisabledReservationModalIsOpened(
      value !== undefined ? value : !disabledReservationModalIsOpened
    );
  };

  const handleSaveReservations = selectedSlots => {
    const { saveReservations } = props;
    const formattedSlots = selectedSlots.map(slot => ({
      date: slot.date,
      clubId: targetClub,
      room: targetRoom,
    }));
    saveReservations(formattedSlots);
  };

  const handleDeleteReservation = () => {
    const { deleteReservation } = props;
    const shouldLoadUpcoming = false;
    deleteReservation(selectedTimeSlotIds, shouldLoadUpcoming);
  };

  const handleSlotClick = (status, slotId) => {
    const { isReservationAvailable } = props;
    const isCancelAvailable = checkCancelAvailable(upcomingReservations);

    if (status === 'open' && !isReservationAvailable && isCancelAvailable) {
      const reservedSlotsIds = upcomingReservations.map(slot => slot.reservationId);
      setSelectedTimeSlotIds(reservedSlotsIds);
      handleOpenDisabledReservationModal();
    }
    if (status === 'open' && isReservationAvailable && !isCancelAvailable) {
      handleOpenPopUp();
      setSelectedTimeSlotNumber(slotId);
    }
    if (status === 'reserved' && !isCancelAvailable) {
      handleOpenModal();
      setSelectedTimeSlotIds([slotId]);
    }
    if ((status === 'reserved' || status === 'full') && isCancelAvailable) {
      const reservedSlotsIds = upcomingReservations.map(slot => slot.reservationId);
      setSelectedTimeSlotIds(reservedSlotsIds);
      handleOpenModal();
    }
  };

  const handleChangeClub = clubId => {
    setTargetClub(clubId);
    setTargetRoom('');
    loadReservations(clubId, targetDate.date, '');
    loadRooms(clubId);
  };

  const templateProps = {
    datesRange,
    targetDate,
    handleChangeClub,
    handleChangeRoom,
    handleSelectDate,
    clubs,
    rooms,
    targetClub,
    loadRooms,
    targetRoom,
    reservations,
    upcomingReservations,
    handleSlotClick,
    isRequesting,
    targetClubIsRequesting,
  };
  return (
    <>
      {isReservationBanned ? (
        <BannedBlock dateOfFinishBan={dateOfFinishBan} />
      ) : (
        <>
          {isTablet ? (
            <MobileTemplate {...templateProps} />
          ) : (
            <DesktopTemplate {...templateProps} />
          )}
          <PopUp
            isOpened={popUpIsOpened}
            setIsOpened={handleOpenPopUp}
            title="My reservation"
          >
            <ReservePopUpInner
              isOpen={popUpIsOpened}
              reservations={reservations}
              club={getClubById(clubs, targetClub)}
              targetRoom={
                targetRoom !== ''
                  ? rooms &&
                    rooms.length > 0 &&
                    rooms.find(r => +r.id === +targetRoom)?.title
                  : null
              }
              targetDate={targetDate}
              selectedTimeSlotNumber={selectedTimeSlotNumber}
              handleSaveReservations={handleSaveReservations}
              isSaveRequesting={isSaveRequesting}
              handleClose={handleOpenPopUp}
              missedCount={missedCount}
            />
          </PopUp>
          <Modal isOpened={modalIsOpened} setIsOpened={handleOpenModal}>
            <CancelReservationPopUp
              cancelReservation={handleDeleteReservation}
              handleClose={handleOpenModal}
              isDeleteRequesting={isDeleteRequesting}
              reservedItems={upcomingReservations}
              selectedTimeSlotIds={selectedTimeSlotIds}
            />
          </Modal>
          <Modal
            isOpened={disabledReservationModalIsOpened}
            setIsOpened={handleOpenDisabledReservationModal}
            wrapperStyles={{
              padding: isTablet ? '20px 10px' : '30px',
            }}
          >
            <DisableReservationPopUp
              cancelReservation={handleDeleteReservation}
              handleClose={handleOpenDisabledReservationModal}
              isDeleteRequesting={isDeleteRequesting}
            />
          </Modal>
        </>
      )}
    </>
  );
};

MainContent.propTypes = {
  clubs: PropTypes.oneOfType([PropTypes.array, PropTypes.arrayOf(PropTypes.object)])
    .isRequired,
  reservations: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  upcomingReservations: PropTypes.array.isRequired,
  initLoadReservations: PropTypes.func.isRequired,
  loadReservations: PropTypes.func.isRequired,
  userFavoriteLocation: PropTypes.string.isRequired,
  isSaveRequesting: PropTypes.bool.isRequired,
  isDeleteRequesting: PropTypes.bool.isRequired,
  deleteReservation: PropTypes.func,
  saveReservations: PropTypes.func.isRequired,
  isTablet: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  isReservationAvailable: PropTypes.bool.isRequired,
  modifyReservations: PropTypes.func.isRequired,
  isModifyRequesting: PropTypes.bool.isRequired,
  isReservationBanned: PropTypes.bool.isRequired,
  dateOfFinishBan: PropTypes.string,
  missedCount: PropTypes.number,
  rooms: PropTypes.array.isRequired,
  loadRooms: PropTypes.func.isRequired,
  targetClubIsRequesting: PropTypes.bool.isRequired,
  selectedClub: PropTypes.string,
  selectClub: PropTypes.func.isRequired,
  checkMemberStatus: PropTypes.func.isRequired,
};

MainContent.defaultProps = {
  deleteReservation: null,
  dateOfFinishBan: '',
  missedCount: 0,
  selectedClub: null,
};

export default MainContent;
