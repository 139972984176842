/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Table from 'components/shared/Table';
import { formatPrice } from '../service';
import styles from './styles.module.css';

const columns = [
  {
    Header: 'Product',
    accessor: `description`,

    Cell: ({ cell: { value } }) => {
      return (
        <>
          <span className={styles.item}>{value}</span>
        </>
      );
    },
    id: 1,
  },
  {
    Header: 'Date',
    accessor: 'date',
    Cell: ({ cell: { value } }) => {
      return <>{moment(value).format('MM/DD/YYYY')}</>;
    },
    id: 5,
  },
  {
    Header: 'Amount',
    accessor: 'price',
    Cell: ({ cell: { value } }) => {
      return (
        <>
          <span className={styles.price}>{formatPrice(value.toString())}</span>
        </>
      );
    },
    id: 2,
  },
];

function TableTransactionDesktop({ data }) {
  return (
    <div className={styles.transaction}>
      <Table data={data} columns={columns} hideHeader />
    </div>
  );
}
TableTransactionDesktop.propTypes = {
  data: PropTypes.array.isRequired,
};
export default TableTransactionDesktop;
