import { takeEvery, put, spawn, call } from 'redux-saga/effects';
import api from 'services/api';
import actions from './actions';

const {
  Types: { SEND_SAFETY_COMMITMENT_REQUEST },
  Creators: { sendSafetyCommitmentSuccess, sendSafetyCommitmentFailure },
} = actions;

function* watchSendSafetyCommitment() {
  yield takeEvery(SEND_SAFETY_COMMITMENT_REQUEST, function* load() {
    try {
      const { data } = yield call(api.post, `/safety-commitment`);
      yield put(sendSafetyCommitmentSuccess(data?.success));
    } catch (error) {
      yield put(sendSafetyCommitmentFailure(error));
    }
  });
}

export function* safetyCommitmentSaga() {
  yield spawn(watchSendSafetyCommitment);
}
