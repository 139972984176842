/* eslint-disable no-useless-escape */
import moment from 'moment';
import { classDetailsImage } from 'assets/background-images';
import {
  powerYoga,
  nidraYoga,
  vinyasaYoga,
  zumba,
  zumbaGold,
  sixPack,
  hiit,
  hipHop,
  bootCamp,
  urbanKick,
} from 'assets/classesImages';

export const getClassTime = (date, startTime, endTime) => {
  const day = moment(date).format('dddd');
  const dateText = moment(date).format('MMMM D, YYYY');
  const startTimeText = moment(`${date} ${startTime}`).format('h:mm a');
  const duration = `(${moment(`${date}T${endTime}`).diff(
    `${date}T${startTime}`,
    'minutes'
  )}min)`;

  return {
    classDate: `${day}, ${dateText}`,
    classTime: `${startTimeText} ${duration}`,
  };
};

export const getDescription = description => {
  const foundLink =
    description &&
    description.match(/https?\:\/\/\S+/) &&
    description.match(/https?\:\/\/\S+/)[0];

  const foundNotFullLink =
    description && description.match(/zoom?\S+/) && description.match(/zoom?\S+/)[0];

  const isExistLink = foundLink || foundNotFullLink;
  const replaceText = `
      <br />
      <br />
      <a href=${foundLink ||
        `https://${foundNotFullLink}`} style="color: #092dc7; padding-left: 4px">
        ${foundLink || `https://${foundNotFullLink}`}</a>
      <br />
    `;

  let formattedDescription = description;

  if (description && isExistLink) {
    formattedDescription = foundLink
      ? description.split(foundLink).join(replaceText)
      : description.split(foundNotFullLink).join(replaceText);
  }

  return formattedDescription;
};

export const getClassImage = name => {
  const className = name && name.toLowerCase();
  let image = classDetailsImage;
  if (typeof name !== 'undefined') {
    if (className.includes('power yoga')) image = powerYoga;
    if (className.includes('vinyasa')) image = vinyasaYoga;
    if (className.includes('nidra')) image = nidraYoga;
    if (className.includes('zumba')) image = zumba;
    if (className.includes('zumba gold')) image = zumbaGold;
    if (className.includes('six pack')) image = sixPack;
    if (className.includes('hiit')) image = hiit;
    if (className.includes('hip hop')) image = hipHop;
    if (className.includes('bootcamp')) image = bootCamp;
    if (className.includes('urban kick')) image = urbanKick;
  }
  return image;
};
