import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isTransactionRequesting: false,
  isStatementRequesting: false,
  transaction: null,
  error: null,
  isDownload: false,
};

const transactionRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isTransactionRequesting: true,
    error: null,
  };
};
const statementRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isStatementRequesting: true,
    error: null,
  };
};
const loadTransactionSuccess = (state = INITIAL_STATE, { transaction }) => {
  return {
    ...state,
    isTransactionRequesting: false,
    transaction,
  };
};
const loadStatementSuccess = (state = INITIAL_STATE, { status }) => {
  return {
    ...state,
    isStatementRequesting: false,
    isDownload: status,
  };
};
const failure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isStatementRequesting: false,
    isTransactionRequesting: false,
    transaction: null,
    error,
  };
};

export const HANDLERS = {
  [Types.LOAD_TRANSACTION_REQUEST]: transactionRequest,
  [Types.LOAD_TRANSACTION_SUCCESS]: loadTransactionSuccess,
  [Types.LOAD_TRANSACTION_FAILURE]: failure,

  [Types.LOAD_STATEMENT_REQUEST]: statementRequest,
  [Types.LOAD_STATEMENT_SUCCESS]: loadStatementSuccess,
  [Types.LOAD_STATEMENT_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
