import {
  newOnDemand,
  newOnDemand2,
  newOnDemandFull,
  newOnDemandFull2,
} from 'assets/background-images';

export const slides = [
  {
    img: newOnDemandFull,
    mobileImg: newOnDemand,
    title: 'New On-Demand',
    subtitle: 'Bodycombat 85',
    text: 'WATCH NOW',
    link: '/on_demand/BODYCOMBAT',
  },
  {
    img: newOnDemandFull2,
    mobileImg: newOnDemand2,
    title: 'New Group',
    subtitle: 'Fitness Classes',
    text: 'CLASS SCHEDULE',
    link: '/classes',
    leftText: true,
  },
];

export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
