import React, { useRef, useEffect } from 'react';
import screenfull from 'screenfull';
import PropTypes from 'prop-types';
import Spinner from 'components/shared/Spinner';
import { useSelector } from 'react-redux';
import styles from './styles.module.css';

const CallWindow = ({
  localSrc,
  peerSrc,
  callIsActive,
  isRequesting,
  networkIsFailed,
}) => {
  const peerVideo = useRef(null);
  const localVideo = useRef(null);
  const isInFullScreen = screenfull.isFullscreen;
  const isTrainer = useSelector(state => state.auth.user.isTrainer);
  useEffect(() => {
    if (peerVideo.current) {
      peerVideo.current.srcObject = peerSrc;
      peerVideo.current.muted = false;
    }
  }, [peerSrc]);
  useEffect(() => {
    if (localVideo.current) {
      localVideo.current.srcObject = localSrc;
    }
  }, [localSrc]);
  return (
    <div className={styles.wrapper}>
      <>
        {isRequesting ? (
          <div className={styles.SpinnerWrapper}>
            <Spinner height="45vh" />
          </div>
        ) : (
          <>
            {networkIsFailed ? (
              <div className={styles.wait}>Network Error</div>
            ) : (
              <>
                {callIsActive ? (
                  <>
                    <video
                      id="local"
                      className={styles.local}
                      autoPlay
                      muted
                      playsInline
                      ref={localVideo}
                    />
                    <video
                      id="remote"
                      className={styles.remote}
                      autoPlay
                      muted
                      playsInline
                      style={{
                        maxHeight: !isInFullScreen ? '50vh' : 'none',
                        height: !isInFullScreen ? '480px' : '100%',
                      }}
                      ref={peerVideo}
                    />
                  </>
                ) : (
                  <div className={styles.wait}>
                    {isTrainer ? 'Waiting for participant' : 'Waiting for trainer'}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};
CallWindow.propTypes = {
  localSrc: PropTypes.object.isRequired,
  peerSrc: PropTypes.object.isRequired,
  callIsActive: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool.isRequired,
  networkIsFailed: PropTypes.bool.isRequired,
};

export default CallWindow;
