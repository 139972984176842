import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import iconv from 'iconv-lite';
import Button from 'components/shared/Button';
import MobileContext from 'context/MobileContext';
// import { likeSelected, likeNotSelected } from 'assets/icons/index';
import styles from './styles.module.css';

const TrainerDetails = ({
  name,
  firstName,
  isFavorite,
  photo,
  location,
  specializations,
  credentials,
  clubId,
  trainerId,
  idCA,
  handleOpenPopUp,
  isChatAvailable,
  setTrainerId,
  preselectFreezeTopic,
}) => {
  const isMobile = useContext(MobileContext);
  const [profileClick, onProfileClick] = useState(false);
  const history = useHistory();
  const handledSpecializations = specializations?.length
    ? specializations
        .map(specialization => {
          if (specialization === null || specialization === '') return false;
          const stringified = specialization.toString();

          return stringified.replace(/[^\x20-\x7E]/g, '');
        })
        .map(specialization => {
          return iconv.decode(specialization, 'utf-8').replace(/&amp;/g, '&');
        })
        .filter(specialization => specialization.length > 0)
    : [];
  const handledCredentials = credentials?.length
    ? credentials
        .map(credential => {
          if (credential === null || credential === '') return false;
          const stringified = credential.toString();

          return stringified.replace(/[^\x20-\x7E]/g, '');
        })
        .map(credential => {
          return iconv.decode(credential, 'utf-8').replace(/&amp;/g, '&');
        })
        .filter(credential => credential.length > 0)
    : [];
  return (
    <div className={styles.wrapper}>
      <div
        className={`${styles.trainerPhoto} ${profileClick && styles.fullPhoto}`}
        onClick={() => onProfileClick(!profileClick)}
      >
        <img src={photo} alt="trainer" />
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.mainContent}>
          <div className={styles.headerContent}>
            <p className={styles.name}>{firstName}</p>
            {/* {isMobile ? (
              <img src={isFavorite ? likeSelected : likeNotSelected} alt="favorite" />
            ) : null} */}
          </div>
          {handledSpecializations?.length ? (
            <div className={styles.specializationsWrapper}>
              <p className={styles.specializationsTitle}>Specializations:</p>
              {handledSpecializations.map((specialization, index) => {
                if (index !== handledSpecializations.length - 1)
                  return (
                    <span key={`${index * 24}`} className={styles.specializationsItem}>
                      {specialization},{' '}
                    </span>
                  );
                return (
                  <span key={`${index * 24}`} className={styles.specializationsItem}>
                    {specialization}
                  </span>
                );
              })}
            </div>
          ) : null}
          {location ? (
            <div className={styles.locationsWrapper}>
              <p className={styles.locationsTitle}>Locations:</p>
              <span className={styles.locationsItem}>{location} </span>
            </div>
          ) : null}
        </div>

        <div className={styles.buttonSection}>
          {handledCredentials?.length ? (
            <div className={styles.credentialsWrapper}>
              <p className={styles.credentialsTitle}>Credentials:</p>
              {handledCredentials.map((credential, index) => {
                if (index !== handledCredentials.length - 1)
                  return (
                    <span key={`${index * 24}`} className={styles.credentialsItem}>
                      {credential},
                    </span>
                  );
                return (
                  <span key={`${index * 24}`} className={styles.credentialsItem}>
                    {credential}
                  </span>
                );
              })}
            </div>
          ) : null}
          {/*
           <p className={styles.questionItem}>Interested in working with {firstName} ?</p>
           <div className={styles.buttonWrapper}>
           <Button
           width={isMobile ? '225px' : '335px'}
           loaderStyles={{ left: '10px' }}
           onClick={() => {
           preselectFreezeTopic(`== Training with ${firstName} ==
           Please type your message here. clubId=${clubId}`);
           history.push('/contact-us');
           }}
           >
           Contact Us
           </Button>
           </div>
           */}
        </div>
      </div>
    </div>
  );
};

TrainerDetails.propTypes = {
  name: PropTypes.string,
  firstName: PropTypes.string,
  clubId: PropTypes.string,
  specializations: PropTypes.arrayOf(PropTypes.string),
  photo: PropTypes.string,
  location: PropTypes.string,
  credentials: PropTypes.arrayOf(PropTypes.string),
  trainerId: PropTypes.string,
  idCA: PropTypes.string,
  isFavorite: PropTypes.bool,
  handleOpenPopUp: PropTypes.func.isRequired,
  isChatAvailable: PropTypes.bool,
  setTrainerId: PropTypes.func.isRequired,
  preselectFreezeTopic: PropTypes.func.isRequired,
};

TrainerDetails.defaultProps = {
  name: '',
  specializations: [],
  photo: '',
  clubId: '',
  location: '',
  isChatAvailable: false,
  credentials: [],
  trainerId: '',
  idCA: '',
  firstName: '',
  isFavorite: false,
};

export default TrainerDetails;
