import React from 'react';
import PropTypes from 'prop-types';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Button from 'components/shared/Button';
import Field from 'components/shared/Field';
import { arrowLeft } from 'assets/icons';
import styles from './style.module.css';

const TakePhotoSection = ({
  photo,
  takePhoto,
  onBack,
  onSubmit,
  preview,
  setPreview,
  isAgree,
  setIsAgree,
  contactId,
  isAuth,
}) => {
  const onTakePhoto = async dataUri => {
    const photoFile = await fetch(dataUri)
      .then(r => r.blob())
      .then(blobFile => new File([blobFile], 'vaccine-proof.png', { type: 'image/png' }));
    takePhoto(photoFile);
    setPreview(dataUri);
  };

  return (
    <div className={`${styles.container} ${isAuth && styles.containerMarginTop}`}>
      {preview ? (
        <div className={styles.imgSection}>
          <img src={preview} className={styles.img} alt="photos" />
          <Button
            className={styles.button}
            onClick={() => {
              takePhoto('');
              setPreview('');
              setIsAgree(false);
            }}
          >
            To try one more time
          </Button>
          <Button
            className={styles.button}
            onClick={() => {
              onBack();
              takePhoto('');
              setPreview('');
              setIsAgree(false);
            }}
          >
            Back
          </Button>
          <div className={styles.checkbox}>
            <Field
              type="checkbox"
              value={isAgree}
              onChange={() => setIsAgree(!isAgree)}
            />
            <div onClick={() => setIsAgree(!isAgree)}>
              By checking this box, I agree that I am the owner of this proof of
              vaccination.
            </div>
          </div>
          <Button disabled={!isAgree} className={styles.button} onClick={onSubmit}>
            SUBMIT
          </Button>
        </div>
      ) : (
        <>
          <Camera
            onTakePhoto={onTakePhoto}
            idealFacingMode={FACING_MODES.USER}
            idealResolution={{ width: 640, height: 480 }}
            imageType={IMAGE_TYPES.JPG}
            sizeFactor={1}
          />
          <Button
            className={styles.button}
            onClick={() => {
              onBack();
              takePhoto('');
              setPreview('');
              setIsAgree(false);
            }}
          >
            Back
          </Button>
        </>
      )}
    </div>
  );
};

TakePhotoSection.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  photo: PropTypes.object.isRequired,
  takePhoto: PropTypes.func.isRequired,
  preview: PropTypes.string.isRequired,
  setPreview: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isAgree: PropTypes.bool.isRequired,
  setIsAgree: PropTypes.func.isRequired,
  contactId: PropTypes.string.isRequired,
};

export default TakePhotoSection;
