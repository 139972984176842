import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import LocationBlockSet from 'components/LocationsPage/LocationBlockSet';
import { PopUp } from 'components/shared/PopUp';
import MobileContext from 'context/MobileContext';
import LocationPopUp from '../LocationPopUp';
import MapComponent from '../Map';
import styles from './style.module.css';

const MainContent = ({
  clubs,
  selectTargetClub,
  changeFavoriteLocation,
  favoriteClub,
  targetClub,
  changeClassesSelectedClub,
}) => {
  const [selectedClubId, setSelectedClubId] = useState(null);
  const [popUpIsOpened, setOpenPopUp] = useState(false);
  const [favoriteClubName, setFavoriteClubName] = useState('');
  const isMobile = useContext(MobileContext);

  useEffect(() => {}, []);

  useEffect(() => {
    clubs.map(club => {
      if (club.clubAutomationClubId === favoriteClub) {
        setFavoriteClubName(club.name);
      }
    });
  }, [favoriteClub]);

  const handleOpenPopUp = () => {
    setOpenPopUp(!popUpIsOpened);
  };
  function selectClub(clubAutomationClubId) {
    const club = clubs.find(
      advancedClub => advancedClub.clubAutomationClubId === clubAutomationClubId
    );

    setSelectedClubId(clubAutomationClubId);
    selectTargetClub(club);
    handleOpenPopUp();
  }

  return (
    <>
      <div className={styles.wrapperBackground}>
        <div className={styles.wrapper}>
          <div className={styles.wrapperContent}>
            {isMobile ? null : <p className={styles.title}> Locations</p>}
            <div className={styles.blocks}>
              <LocationBlockSet
                data={clubs}
                selectedLocation={selectedClubId}
                selectLocation={selectClub}
                selectKey="clubAutomationClubId"
                selectedClubId={selectedClubId}
                favoriteClub={favoriteClub}
                changeFavoriteLocation={changeFavoriteLocation}
                isLocationPage
              />
            </div>
          </div>
          <div className={styles.map}>
            <MapComponent
              favoriteClubName={favoriteClubName}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              onMarkerClick={clubName => {
                const club = clubs.find(({ name }) => name === clubName);
                if (club?.clubAutomationClubId) selectClub(club?.clubAutomationClubId);
              }}
            />
          </div>
        </div>
      </div>
      <PopUp isOpened={popUpIsOpened} setIsOpened={handleOpenPopUp}>
        <LocationPopUp
          targetClub={targetClub}
          changeFavoriteLocation={changeFavoriteLocation}
          favoriteClub={favoriteClub}
          changeClassesSelectedClub={changeClassesSelectedClub}
        />
      </PopUp>
    </>
  );
};
MainContent.propTypes = {
  clubs: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectTargetClub: PropTypes.func.isRequired,
  favoriteClub: PropTypes.string.isRequired,
  changeFavoriteLocation: PropTypes.func.isRequired,
  targetClub: PropTypes.object,
  changeClassesSelectedClub: PropTypes.func.isRequired,
};
MainContent.defaultProps = {
  targetClub: {},
};
export default MainContent;
