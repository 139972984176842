import React, { useEffect } from 'react';
import CheckBox from 'components/shared/Checkbox';
import { noContactIcon, stayHomeIcon, washHandIcon } from 'assets/icons';
import Button from 'components/shared/Button';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const SafetyCommitment = ({
  open,
  commitmentPoints,
  handleChangeCommitmentPoints,
  isSubmitAvailable,
  submit,
}) => {
  useEffect(() => {
    if (open) {
      const qrModal = document.getElementById('qr-modal');
      if (qrModal?.style) qrModal.style.height = null;
    }
  }, [open]);

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>
        FITNESS SF Terms and Conditions & Health Questionaire
      </h2>

      <div className={styles.iconsWrapper}>
        <img src={stayHomeIcon} alt="stay home" className={styles.icon} />
        <img src={washHandIcon} alt="wash hands" className={styles.icon} />
        <img src={noContactIcon} alt="no contact" className={styles.icon} />
      </div>

      <p className={styles.subtitle}>
        I agree to the following before entering and using FITNESS SF facilities:
      </p>

      <ul className={styles.unorderedList}>
        <li className={styles.listItem}>
          I currently do not have COVID-19 or COVID-19 symptoms
        </li>
        <li className={styles.listItem}>
          I have not come in contact with anyone experiencing
          <a
            href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html"
            target="blank"
            className={styles.link}
          >
            COVID-19 symptoms
          </a>
        </li>
        <li className={styles.listItem}>
          In the last 10 days, I have not been diagnosed with COVID-19 or had a test
          confirming you have the virus
        </li>
        <li className={styles.listItem}>
          In the past 14 days, I have not had “Close Contact” with someone who was
          diagnosed with COVID-19 or had a test confirming they have the virus while they
          were contagious.
        </li>
        <li className={styles.listItem}>
          In the past 24 hours, including today, I have not had one or more symptoms that
          are new or not explained by another condition
        </li>
        <li className={styles.listItem}>
          To follow physical distancing protocol while inside FITNESS SF
        </li>
        <li className={styles.listItem}>
          To have my temperature taken when upon entering the premises
        </li>
        <li className={styles.listItem}>
          To wear a face covering at all times inside FITNESS SF
        </li>
        <li className={styles.listItem}>To sanitize hands upon entering and leaving</li>
        <li className={styles.listItem}>
          To disinfect equipment before and after each use
        </li>
        <li className={styles.listItem}>
          FITNESS SF COVID-19
          <a
            href="https://fitnesssf.com/terms-of-service"
            target="blank"
            className={styles.link}
          >
            Terms of Service
          </a>
          ,
          <a
            href="https://fitnesssf.com/privacy-policy"
            target="blank"
            className={styles.link}
          >
            Privacy Policy
          </a>
          , and
          <a
            href="https://fitnesssf.com/liability-waiver"
            target="blank"
            className={styles.link}
          >
            Liability waiver
          </a>
        </li>
      </ul>
      <div className={styles.checkBoxesWrapper}>
        <div className={styles.checkBoxRow}>
          <CheckBox
            value={commitmentPoints[0]}
            onChange={() => {
              handleChangeCommitmentPoints(0, !commitmentPoints[0]);
            }}
            orangeBorder
          />
          <span
            className={styles.checkBoxText}
            onClick={() => {
              handleChangeCommitmentPoints(0, !commitmentPoints[0]);
            }}
          >
            Agreed to proceed and continue
          </span>
        </div>
      </div>

      <div className={styles.description}>
        <p className={styles.textBold}>Everyone is doing their part together</p>
        <p className={styles.text}>
          The health and safety of our employees and members is extremely important. Thank
          you for your cooperation.
        </p>
      </div>
      <Button
        disabled={!isSubmitAvailable}
        className={styles.button}
        width="230px"
        onClick={() => {
          submit();
        }}
      >
        Submit
      </Button>
    </div>
  );
};

SafetyCommitment.propTypes = {
  open: PropTypes.bool.isRequired,
  commitmentPoints: PropTypes.array.isRequired,
  handleChangeCommitmentPoints: PropTypes.func.isRequired,
  isSubmitAvailable: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
};
export default SafetyCommitment;
