import moment from 'moment';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';

export const calculateFourWeeksDates = () => {
  let date = getSFTime();

  const datesRange = [];
  for (let i = 0; i < 14; i++) {
    const dayObject = {
      index: i,
      date: moment(date).format('YYYY-MM-DD'),
      dayOfWeek: moment(date).format('dddd'),
      dayMonth: moment(date).format('MM/DD'),
      monthOfYear: moment(date).format('MMMM YYYY'),
    };
    datesRange.push(dayObject);
    date = moment(date).add(1, 'd');
  }
  return datesRange;
};
