import React from 'react';
import PropTypes from 'prop-types';
import IconSVG from 'components/shared/IconSVG';
import { cameraIcon, muteIcon, unCallIcon } from 'assets/icons';
import styles from './styles.module.css';

const checkTrack = (stream, type) => {
  let enabled = false;
  if (stream) {
    if (type === 'audio') {
      stream.getAudioTracks().forEach(track => {
        enabled = track.enabled;
      });
    }
    if (type === 'video') {
      stream.getVideoTracks().forEach(track => {
        enabled = track.enabled;
      });
    }
  }
  return enabled;
};

const CallFooter = ({ toggle, endCall, localSrc }) => {
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.iconWrapper} onClick={() => toggle('video')}>
        <IconSVG src={cameraIcon} width="18px" height="12px" />
        {checkTrack(localSrc, 'video') ? (
          <span className={styles.iconText}>Stop camera</span>
        ) : (
          <span className={styles.iconText}>Start camera</span>
        )}
      </div>
      <div className={styles.iconWrapper} onClick={() => endCall(true)}>
        <div className={styles.endCallWrapper}>
          <IconSVG src={unCallIcon} width="18px" height="8px" />
        </div>
        <span className={styles.iconText}>Leave</span>
      </div>
      <div className={styles.iconWrapper} onClick={() => toggle('audio')}>
        <IconSVG src={muteIcon} width="12px" height="18px" />
        {checkTrack(localSrc, 'audio') ? (
          <span
            className={styles.iconText}
            style={{ textAlign: 'center', width: '35px' }}
          >
            Mute
          </span>
        ) : (
          <span
            className={styles.iconText}
            style={{ textAlign: 'center', width: '35px' }}
          >
            Unmute
          </span>
        )}
      </div>
    </div>
  );
};

CallFooter.propTypes = {
  localSrc: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  endCall: PropTypes.func.isRequired,
};

export default CallFooter;
