import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  initLoadReservationsRequest: ['date', 'club', 'room'],
  loadReservationsRequest: ['clubId', 'date', 'room'],
  loadReservationsSuccess: ['reservations'],
  loadReservationsFailure: ['error'],

  saveReservationsRequest: ['selectedReservations'],
  saveReservationsSuccess: ['reservations'],
  saveReservationsFailure: ['error'],

  loadUpcomingReservationsRequest: [],
  loadUpcomingReservationsSuccess: [
    'upcomingReservations',
    'reservationUpcomingDaySlotList',
    'isQRAvailable',
  ],
  loadUpcomingReservationsFailure: ['error'],

  deleteReservationRequest: ['reservationIds', 'shouldLoadUpcoming'],
  deleteReservationSuccess: ['reservations'],
  deleteReservationFailure: ['error'],

  modifyReservationsRequest: [
    'itemsIdsToDelete',
    'itemsToCreate',
    'withLoadReservationSlots',
  ],
  modifyReservationsSuccess: ['reservations'],
  modifyReservationsFailure: ['error'],

  loadReservationsStatusRequest: [],
  loadReservationsStatusSuccess: ['isBanned', 'dateOfFinishBan', 'missedCount'],
  loadReservationsStatusFailure: ['error'],

  calculateReservedSlots: ['reservedItems', 'isReservationAvailable'],
});

const Actions = {
  Types,
  Creators,
};

export default Actions;
