import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  isRequesting: false,
  token: null,
  error: null,
};

const loadSsoTokenRequest = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
    error: null,
  };
};
const loadSsoTokenSuccess = (state = INITIAL_STATE, { token }) => {
  return {
    ...state,
    token,
    isRequesting: false,
  };
};
const loadSsoTokenFailure = (state = INITIAL_STATE, { error }) => {
  return {
    ...state,
    isRequesting: false,
    error,
  };
};
export const HANDLERS = {
  [Types.LOAD_SSO_TOKEN_REQUEST]: loadSsoTokenRequest,
  [Types.LOAD_SSO_TOKEN_SUCCESS]: loadSsoTokenSuccess,
  [Types.LOAD_SSO_TOKEN_FAILURE]: loadSsoTokenFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
