/* eslint-disable consistent-return */
import cloneDeep from 'lodash/cloneDeep';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';

export const setCategory = videos => {
  return uniq(
    compact(uniq(videos.map(el => el.category)))
      .join(', ')
      .split(', ')
  );
};

export const searchVideo = (videos, searchValue) => {
  const result = [];

  for (let i = 0; i < videos.length; i++) {
    if (
      videos[i].title
        .toString()
        .toLowerCase()
        .includes(searchValue.toString().toLowerCase()) ||
      (videos[i].category &&
        videos[i].category
          .toString()
          .toLowerCase()
          .includes(searchValue.toString().toLowerCase()))
    ) {
      result.push(videos[i]);
    }
  }
  return result;
};

export const formattedVideo = (videos, categories) => {
  const videosCopy = cloneDeep(videos);
  return categories.length > 0
    ? videosCopy.filter(el => {
        if (el.category) {
          const elCategories = el.category?.split(', ');
          for (let i = 0; i < elCategories.length; i++) {
            if (categories.includes(elCategories[i])) return el;
          }
        }
      })
    : videos;
};

export const addFavoriteVideo = (favoriteVideos, videos, data) => {
  const favoriteVideosList = Array.from(favoriteVideos);
  favoriteVideosList.push(data);

  const videosList = Array.from(videos);
  const video = videosList.find(({ id }) => data.id === id);
  video.isFavorite = true;
  return { favoriteVideos: favoriteVideosList, videos: videosList };
};

export const removeFavoriteVideo = (favoriteVideos, videos, removedId) => {
  const favoriteVideosList = Array.from(favoriteVideos).filter(
    ({ id }) => id !== +removedId
  );
  const videosList = Array.from(videos);
  const video = videosList.find(({ id }) => +removedId === id);
  video.isFavorite = false;
  return { favoriteVideos: favoriteVideosList, videos: videosList };
};
