import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import MonthDatePicker from 'components/shared/MonthDatePicker';
import { getSFTime } from 'services/formatDateToTimezone/formatDateToTimezone';
import moment from 'moment';
import styles from './styles.module.css';

const StatementsHeader = ({ statementProps }) => {
  const { loadStatement, user } = statementProps;
  const sfDate = getSFTime();
  const [date, setDate] = useState(sfDate);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1>Statements</h1>
        <div className={styles.pickHistoryDate}>
          <MonthDatePicker
            initialDate={date}
            isStatement
            secondaryAction={d => {
              setDate(d);
              loadStatement({
                email: user?.email,
                type: 'individual',
                dates: moment(d).format('YYYY-MM'),
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
StatementsHeader.propTypes = {
  statementProps: PropTypes.object.isRequired,
};

export default StatementsHeader;
