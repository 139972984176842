import { createReducer } from 'reduxsauce';
import actions from './actions';

const { Types } = actions;
const INITIAL_STATE = {
  invitedUsers: [],
  promoCodes: '',
  isRequesting: false,
};

const request = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: true,
  };
};
const failure = (state = INITIAL_STATE) => {
  return {
    ...state,
    isRequesting: false,
  };
};
const getInvitedUsersSuccess = (state = INITIAL_STATE, { invitedUsers }) => {
  return {
    ...state,
    isRequesting: false,
    invitedUsers,
  };
};
const getTrainerPromoCodesSuccess = (state = INITIAL_STATE, { promoCodes }) => {
  return {
    ...state,
    isRequesting: false,
    promoCodes: promoCodes?.promoCodes,
  };
};

export const HANDLERS = {
  [Types.GET_INVITED_USERS_REQUEST]: request,
  [Types.GET_INVITED_USERS_SUCCESS]: getInvitedUsersSuccess,
  [Types.GET_INVITED_USERS_FAILURE]: failure,
  [Types.GET_TRAINER_PROMO_CODES_REQUEST]: request,
  [Types.GET_TRAINER_PROMO_CODES_SUCCESS]: getTrainerPromoCodesSuccess,
  [Types.GET_TRAINER_PROMO_CODES_FAILURE]: failure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
