import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MainContent from 'components/TrainingPage/MainContent';
import Spinner from 'components/shared/Spinner';
import HeaderFooterLayout from 'layouts/HeaderFooterLayout';

class TrainingPage extends Component {
  componentDidMount() {
    const { loadTrainers, loadPersonalTrainers } = this.props;
    loadTrainers();
    loadPersonalTrainers();
  }

  render() {
    const {
      trainers,
      myPersonalTrainers,
      favoriteTrainers,
      isRequesting,
      selectedClubId,
      selectTrainingFavoriteClub,
      searchValue,
      changeTrainingSearch,
      favoriteLocation,
      clubs,
      markTrainerAsFavorite,
      unmarkTrainerAsFavorite,
      isMarkTrainerSuccess,
      sessions,
      setTargetSession,
      setTrainerId,
      handleOpenCallPopUp,
      isSessionCallPopUpOpened,
      cleanCancelSessionEmail,
      sendCancelSessionEmail,
      preselectFreezeTopic,
    } = this.props;

    const mainProps = {
      trainers,
      myPersonalTrainers,
      favoriteTrainers,
      selectedClubId,
      selectTrainingFavoriteClub,
      searchValue,
      changeTrainingSearch,
      favoriteClubId: favoriteLocation,
      clubs,
      markTrainerAsFavorite,
      unmarkTrainerAsFavorite,
      isMarkTrainerSuccess,
      sessions,
      favoriteLocation,
      setTargetSession,
      setTrainerId,
      handleOpenCallPopUp,
      isSessionCallPopUpOpened,
      cleanCancelSessionEmail,
      sendCancelSessionEmail,
      preselectFreezeTopic,
    };

    return (
      <HeaderFooterLayout>
        {isRequesting ? <Spinner /> : <MainContent {...mainProps} />}
      </HeaderFooterLayout>
    );
  }
}

TrainingPage.propTypes = {
  setTargetSession: PropTypes.func.isRequired,
  loadTrainers: PropTypes.func.isRequired,
  loadPersonalTrainers: PropTypes.func.isRequired,
  trainers: PropTypes.arrayOf(PropTypes.object),
  myPersonalTrainers: PropTypes.array.isRequired,
  favoriteTrainers: PropTypes.arrayOf(PropTypes.object),
  isRequesting: PropTypes.bool.isRequired,
  selectedClubId: PropTypes.string.isRequired,
  selectTrainingFavoriteClub: PropTypes.func.isRequired,
  changeTrainingSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  favoriteLocation: PropTypes.string.isRequired,
  clubs: PropTypes.arrayOf(PropTypes.object).isRequired,
  markTrainerAsFavorite: PropTypes.func.isRequired,
  unmarkTrainerAsFavorite: PropTypes.func.isRequired,
  isMarkTrainerSuccess: PropTypes.bool.isRequired,
  sessions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setTrainerId: PropTypes.func.isRequired,
  handleOpenCallPopUp: PropTypes.func.isRequired,
  isSessionCallPopUpOpened: PropTypes.bool.isRequired,
  cleanCancelSessionEmail: PropTypes.func.isRequired,
  sendCancelSessionEmail: PropTypes.func.isRequired,
  preselectFreezeTopic: PropTypes.func.isRequired,
};

TrainingPage.defaultProps = {
  trainers: [],
  favoriteTrainers: [],
  sessions: null,
};

export default TrainingPage;
