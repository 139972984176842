import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import VisitsCard from 'components/AnalyticsPage/VisitsCard';
import download from 'assets/icons/download.svg';
import MonthDatePicker from 'components/shared/MonthDatePicker';
import styles from './styles.module.css';

const Header = ({
  loadHistory,
  resetHistory,
  changeParentState,
  limit,
  idCA,
  startDate,
  loadHistoryPDF,
  setUsed,
  visits,
  checkHistory,
}) => {
  const end = moment(startDate)
    .endOf('month')
    .format('YYYY-MM-DD');

  const start = moment(startDate).format('YYYY-MM-DD');

  const pdfStart = moment(startDate)
    .startOf('month')
    .format('MM-DD-YYYY');
  const pdfEnd = moment(startDate)
    .endOf('month')
    .format('MM-DD-YYYY');

  useEffect(() => {
    // resetHistory(null);
  }, [resetHistory]);

  return (
    <div className={styles.pickDateContainer}>
      {/* <VisitsCard title="Visits Completed" visits={visits || {}} /> */}
      <div className={styles.container}>
        <h1>Check-in History</h1>
        <div className={styles.pickHistoryDate}>
          <MonthDatePicker
            initialDate={startDate}
            search={date => {
              changeParentState(date);
              setUsed(limit);
              loadHistory({
                end: moment(date, 'YYYY-MM-DD')
                  .endOf('month')
                  .format('YYYY-MM-DD'),
                start: moment(date, 'YYYY-MM-DD')
                  .startOf('month')
                  .format('YYYY-MM-DD'),
                limit,
              });
            }}
          />
          <div
            className={`${styles.buttonHistory} ${
              checkHistory?.length === 0 ? styles.disable : ''
            }`}
            onClick={() => {
              if (checkHistory?.length !== 0) {
                loadHistoryPDF({ pdfEnd, pdfStart, idCA });
              }
            }}
          >
            Download PDF
            <img src={download} className={styles.iconDownload} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
Header.propTypes = {
  loadHistory: PropTypes.func.isRequired,
  resetHistory: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  changeParentState: PropTypes.func.isRequired,
  loadHistoryPDF: PropTypes.func.isRequired,
  setUsed: PropTypes.func.isRequired,
  idCA: PropTypes.string.isRequired,
  startDate: PropTypes.object.isRequired,
  visits: PropTypes.object.isRequired,
};

export default Header;
