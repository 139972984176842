import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fsfLogo } from 'assets/logos';
import { memberStatuses } from 'enums/memberStatuses';
import DialogsList from './DialogsList';
import DialogItem from './DialogsItem';
import styles from './styles.module.css';

const ResentDialogs = ({ selectTargetDialog, targetDialog, resentMessages }) => {
  const directorDialog = resentMessages.find(message => message.isDirector === true)
    ? resentMessages.find(message => message.isDirector === true)
    : null;
  const memberStatus = useSelector(state => state.auth.user.memberStatus);
  const isStaff = memberStatus === memberStatuses.staff;

  const restDialogs = resentMessages.filter(message => message.isDirector === false);
  return (
    <div className={styles.wrapper} style={{ backgroundImage: `url(${fsfLogo})` }}>
      <div className={styles.header}>
        <div className={styles.empty} />
        <div className={styles.title}>Chats</div>
        <div className={styles.close}>
          <Link to={isStaff ? '/profile' : '/'}>Close</Link>
        </div>
      </div>

      <div className={styles.marginWrapper}>
        {directorDialog ? (
          <div className={styles.directorWrapper}>
            <h3 className={styles.directorTitle}>Fitness Director</h3>
            <DialogItem
              {...directorDialog}
              selectTargetDialog={selectTargetDialog}
              targetDialog={targetDialog}
            />
          </div>
        ) : null}

        <DialogsList
          targetDialog={targetDialog}
          selectTargetDialog={selectTargetDialog}
          resentMessages={restDialogs}
        />
      </div>
    </div>
  );
};

ResentDialogs.propTypes = {
  selectTargetDialog: PropTypes.func.isRequired,
  targetDialog: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  resentMessages: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.string,
  ]),
};
ResentDialogs.defaultProps = {
  resentMessages: [],
};
export default ResentDialogs;
