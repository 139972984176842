/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import history from 'store/history';
import { horizontalLogo } from 'assets/logos';
import styles from './style.module.css';

const Header = ({ isHideMenu = true }) => {
  const goToHomePage = () => {
    history.push('/');
  };

  const items = [
    { title: 'FAQs', route: 'https://www.fitnesssf.com/faq' },
    { title: 'Need help?', route: 'https://fitnesssf.com/join' },
    { title: 'Already a member?', route: '/login' },
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <img
          src={horizontalLogo}
          alt="logo"
          className={styles.logo}
          onClick={goToHomePage}
        />
        <div>
          {!isHideMenu &&
            items.map(item => (
              <a key={item.title} href={item.route} className={styles.item}>
                {item.title}
              </a>
            ))}
          <a href="/login" className={styles.bold}>
            Sign In
          </a>
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  isHideMenu: PropTypes.bool.isRequired,
};

export default Header;
