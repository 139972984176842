import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const Cards = ({ slides }) => {
  return (
    <div className={styles.container}>
      <div className={styles.verticalLine} />
      <div className={styles.wrapper}>
        {slides.map(({ title, text, highlight, link = null }, i) => (
          <div className={styles.slide} key={`${i + 1}`}>
            <div className={`${styles.circle} ${highlight && styles.highlight}`}>
              {i + 1}
            </div>
            <div>
              <h3 className={styles.title}>{title}</h3>
              <div className={styles.text}>
                {text}
                {link?.text && (
                  <a href={link.ref} target="blank" className={styles.link}>
                    {link.text}
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Cards.propTypes = {
  slides: PropTypes.array.isRequired,
};

export default Cards;
